import { competencyMap, ICompetencyMapStates } from './types'

const initialState: ICompetencyMapStates = {
  documentType: {},
  data: {},
  error: null
}

const documentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case competencyMap.GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentType: action.payload.tipoDocumentoRaiz.filhos,
        error: null
      }

    case competencyMap.GET_DOCUMENT_ERROR:
      return {
        documentType: {},
        error: action.payload
      }

    case competencyMap.CLEAR_COMPETENCY_MAP:
      return initialState

    case competencyMap.SAVE_COMPETENCY_MAP_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        error: null
      }

    case competencyMap.SAVE_COMPETENCY_MAP_ERROR:
      return {
        data: {},
        error: action.payload
      }

    case competencyMap.UPDATE_COMPETENCY_MAP_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        error: null
      }

    case competencyMap.UPDATE_COMPETENCY_MAP_ERROR:
      return {
        data: {},
        error: action.payload
      }

    default:
      return state
  }
}

export default documentReducer
