import { MessageModalState } from './types'

const initialState = {
  type: false,
  title: undefined,
  message: undefined,
  titleButton: undefined
}

export default function modalReducer(state = initialState, action: any) {
  switch (action.type) {
    case MessageModalState.SHOW_MODAL: {
      return {
        ...state,
        type: action.payload.type,
        title: action.payload.title,
        message: action.payload.message,
        titleButton: action.payload.titleButton
      }
    }

    case MessageModalState.HIDE_MODAL:
      return {
        ...initialState
      }

    default:
      return initialState
  }
}
