import { ReactNode } from 'react'
import { Redirect } from 'react-router-dom'

interface VerifyFlagProps {
  children: ReactNode
  flag: boolean
}

export function VerifyRouterFlag({ children, flag }: VerifyFlagProps) {
  return (
    <>
      {flag ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/home'
          }}
        />
      )}
    </>
  )
}
