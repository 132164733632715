import * as Chakra from '@chakra-ui/react'
import * as S from './styles'
import Select from 'src/components/input-select'
import { THEME_PURPLE } from 'src/styles/colors'
import { CheckIcon, MessagesIcon } from 'src/assets/icons'
import { ChangeEvent, useEffect, useState } from 'react'
import Button from 'src/components/button'
import { MessageModalType } from 'src/store/message-modal/types'
import Modal from 'src/components/modal'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeFileTypes,
  directorUpdateFileApprove,
  directorUpdateFileDisapprove,
  directorUpdateFileUndo,
  getFileTypes,
  getSolicitationDocuments,
  getHistory,
  updateFileApprove,
  updateFileDisapprove,
  updateFileUndo
} from 'src/store/solicitation-details/actions'
import { ApplicationState } from 'src/store/rootReducer'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { showFile, showWorkflowFile } from 'src/store/files-download/actions'
import { VerifyCommentsFlag } from 'src/components/flags/comments'
import { VerifyRequiredDocumentationFlag } from 'src/components/flags/required-documentation'
import { UserProfileEnum } from 'src/store/login/types'
import { useParams } from 'react-router-dom'
import DocumentsUpload from 'src/components/documents-upload'
import HistoryModal from '../historyModal'
import { DocumentButtonsRelationship } from './components/rowButtons/buttonsRelationship'
import { DocumentButtonsDirector } from './components/rowButtons/buttonsDirector'
import { deleteSolicitationFile } from 'src/store/documents-upload/actions'

const Row = ({
  changeCheckedItem,
  data,
  item,
  openModal,
  openFile,
  approveDocument,
  undoDocument,
  filesTypes,
  changeFileType,
  openHistoryModal,
  openDeleteDocumentModal
}: any) => {
  const auth = useSelector((state: ApplicationState) => state.userReducer.auth)
  const userProfileId = Number(auth?.userProfile)
  const nameProfile = UserProfileEnum[userProfileId]
  const solicitation = useSelector(
    (state: ApplicationState) => state.solicitationDetailReducer.solicitation
  )
  return (
    <S.Tr key={item}>
      <S.Td>
        <>
          <Chakra.Checkbox
            isChecked={data.checked}
            onChange={() => changeCheckedItem(data)}
            isDisabled={
              data.fileStatus != 'Pendente' ||
              solicitation.status == 'Rejeitada'
            }
          />
        </>
      </S.Td>
      <S.Td>
        <Select
          disabled={
            data.fileStatus !== 'Pendente' ||
            nameProfile === 'DIRECTOR' ||
            solicitation.status == 'Rejeitada'
          }
          data-testid="documentType"
          name="documentType"
          onChange={(e) => changeFileType(data.fileId, e.target.value)}
          value={data.fileTypeId}
        >
          <>
            <option value="0" disabled>
              Selecione uma opção
            </option>
            {filesTypes.map((item: any) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </>
        </Select>
      </S.Td>
      {data.fileStatus === 'Rejeitado' ? (
        <S.Td>
          <S.FileName>{data.name}</S.FileName>
        </S.Td>
      ) : (
        <S.Td onClick={() => openFile(data.code, data.name)}>
          <S.FileNameLink>{data.name}</S.FileNameLink>
        </S.Td>
      )}

      <S.Td>
        <S.StatusBox status={data.fileStatus}>
          <S.StatusText>{data.fileStatus}</S.StatusText>
          <S.TooltipCard>
            <S.StatusIcon status={data.fileStatus} />
          </S.TooltipCard>
        </S.StatusBox>
      </S.Td>
      {/* <S.Td>
        <S.DateContainer>
          {data.validityDate != null
            ? moment(data.validityDate).format('DD/MM/YYYY')
            : '00/00/0000'}
        </S.DateContainer>
      </S.Td> */}

      <S.Td>
        <Chakra.Flex justifyContent={'end'}>
          {nameProfile === 'DIRECTOR' ? (
            <DocumentButtonsDirector
              approveDocument={() => approveDocument(data)}
              rejectDocument={() => openModal(data)}
              deleteDocument={() => openDeleteDocumentModal(data)}
              undoDocument={() => undoDocument(data)}
              openHistoryModal={() => openHistoryModal(data)}
              data={data}
            />
          ) : (
            <DocumentButtonsRelationship
              approveDocument={() => approveDocument(data)}
              rejectDocument={() => openModal(data)}
              deleteDocument={() => openDeleteDocumentModal(data)}
              undoDocument={() => undoDocument(data)}
              openHistoryModal={() => openHistoryModal(data)}
              data={data}
            />
          )}
        </Chakra.Flex>
      </S.Td>
    </S.Tr>
  )
}

const renderRow = (
  data: any,
  item: any,
  changeCheckedItem: any,
  openModal: any,
  openFile: any,
  approveDocument: any,
  undoDocument: any,
  filesTypes: any,
  changeFileType: any,
  openHistoryModal: any,

  openDeleteDocumentModal: any
) => (
  <Row
    key={item}
    item={item}
    data={data}
    changeCheckedItem={changeCheckedItem}
    openModal={openModal}
    openFile={openFile}
    approveDocument={approveDocument}
    undoDocument={undoDocument}
    filesTypes={filesTypes}
    changeFileType={changeFileType}
    openHistoryModal={openHistoryModal}
    openDeleteDocumentModal={openDeleteDocumentModal}
  />
)

const TableBody = ({
  tableData,
  changeCheckedItem,
  openModal,
  openFile,
  approveDocument,
  undoDocument,
  filesTypes,
  changeFileType,
  openHistoryModal,
  openDeleteDocumentModal
}: any) => {
  return (
    <tbody>
      {tableData.map((data: any, item: any) =>
        renderRow(
          data,
          item,
          changeCheckedItem,
          openModal,
          openFile,
          approveDocument,
          undoDocument,
          filesTypes,
          changeFileType,
          openHistoryModal,
          openDeleteDocumentModal
        )
      )}
    </tbody>
  )
}

const Table = ({ documents }: any) => {
  const dispatch = useDispatch()

  const [tableData, setTableData] = useState(documents)
  const [showModal, toggleModal] = useState(false)
  const [showHistoryModal, toggleHistoryModal] = useState(false)
  const [showUploadFileModal, toggleUploadFileModal] = useState(false)
  const [showDeleteFileModal, toggleDeleteFileModal] = useState(false)

  const [idFile, setIdFile] = useState(0)
  const [values, setValue] = useState({ justification: '' })
  const [checkedAll, setCheckedAll] = useState(false)

  const [fileToDelete, setFileToDelete] = useState('')

  const auth = useSelector((state: ApplicationState) => state.userReducer.auth)
  const userProfileId = Number(auth?.userProfile)
  const nameProfile = UserProfileEnum[userProfileId]
  const { id: solicitationId, technicalEvaluationId } = useParams<any>()

  const solicitation = useSelector(
    (state: ApplicationState) => state.solicitationDetailReducer.solicitation
  )

  const filesTypes = useSelector(
    (state: ApplicationState) => state.solicitationDetailReducer.filesTypes
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const filesInformation = useSelector(
    (state: ApplicationState) =>
      state.solicitationDetailReducer.filesInformation
  )

  const historyDocuments = useSelector(
    (state: ApplicationState) =>
      state.solicitationDetailReducer.historyDocuments
  )
  const [filesId, setfilesId] = useState<any>([])

  useEffect(() => {
    if (auth && auth.layerSevenToken) {
      dispatch(getFileTypes(auth.layerSevenToken))
    }
  }, [dispatch])

  useEffect(() => {
    const newDocumentsArray = documents.map((item: any) => {
      item.checked = false
      return item
    })
    setTableData(newDocumentsArray)
  }, [documents])

  const changeCheckedItem = (item: any) => {
    setfilesId([])
    const checkedItem = tableData.map((data: any) => {
      if (item.fileId === data.fileId) {
        data.checked = !data.checked
      }

      if (data.checked) {
        if (nameProfile === 'DIRECTOR') {
          setfilesId((filesId: any) => [
            ...filesId,
            data.fileInfoTechEvaluationId
          ])
        } else {
          setfilesId((filesId: any) => [...filesId, data.fileId])
        }
      }

      return data
    })

    setTableData(checkedItem)
  }

  const checkAll = (checked: boolean) => {
    if (!checked) {
      const checkedItem = tableData.map((data: any) => {
        if (data.fileStatus == 'Pendente') {
          data.checked = checked
        }
        return data
      })

      setfilesId([])
      setTableData(checkedItem)
      setCheckedAll(checked)
      return
    }

    if (nameProfile === 'DIRECTOR') {
      const checkedItem = tableData.map((data: any) => {
        if (data.fileStatus == 'Pendente') {
          setfilesId((filesId: any) => [
            ...filesId,
            data.fileInfoTechEvaluationId
          ])
          data.checked = checked
        }
        return data
      })

      setTableData(checkedItem)
      setCheckedAll(checked)
    } else {
      const checkedItem = tableData.map((data: any) => {
        if (data.fileStatus == 'Pendente') {
          setfilesId((filesId: any) => [...filesId, data.fileId])
          data.checked = checked
        }
        return data
      })

      setTableData(checkedItem)
      setCheckedAll(checked)
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target

    setValue({
      ...values,
      [name]: value
    })
  }

  const openModal = (data: any) => {
    toggleModal(true)
    if (nameProfile === 'DIRECTOR') {
      setIdFile(data.fileInfoTechEvaluationId)
    } else {
      setIdFile(data.fileId)
    }
  }

  const openHistoryModal = (data: any) => {
    const fileId = data.fileId

    if (auth && auth.layerSevenToken) {
      dispatch(getHistory(fileId, auth.layerSevenToken))
      toggleHistoryModal(true)
    }
  }

  const handleUploadDocumentModal = () => {
    if (auth && auth.layerSevenToken) {
      if (nameProfile === 'DIRECTOR') {
        dispatch(
          getSolicitationDocuments(
            auth.layerSevenToken,
            solicitation.cpfHealthProfessional,
            technicalEvaluationId
          )
        )
      } else {
        dispatch(
          getSolicitationDocuments(
            auth.layerSevenToken,
            solicitation.cpfHealthProfessional
          )
        )
      }
      toggleUploadFileModal(false)
    }
  }

  const openDeleteDocumentModal = (data: any) => {
    toggleDeleteFileModal(true)
    setFileToDelete(data.code)
  }

  const deleteFile = () => {
    if (auth && auth.layerSevenToken) {
      dispatch(
        deleteSolicitationFile(
          fileToDelete,
          auth.layerSevenToken,
          solicitation.cpfHealthProfessional,
          technicalEvaluationId
        )
      )
    }
    toggleDeleteFileModal(false)
  }

  const rejectDocument = () => {
    const fileId = idFile
    const { justification } = values
    if (auth && auth.layerSevenToken) {
      if (nameProfile === 'DIRECTOR') {
        dispatch(
          directorUpdateFileDisapprove(
            { fileInfoTechEvaluationId: fileId, justification, solicitationId },
            auth.layerSevenToken
          )
        )
      } else {
        dispatch(
          updateFileDisapprove(
            { fileId, justification, solicitationId },
            auth.layerSevenToken
          )
        )
      }
    }
    toggleModal(false)
  }

  const approveDocument = (data?: any) => {
    if (auth && auth.layerSevenToken) {
      if (nameProfile === 'DIRECTOR') {
        const arrayFilesId: number[] = []

        if (data) {
          arrayFilesId.push(data.fileInfoTechEvaluationId)
        } else {
          arrayFilesId.push(...filesId)
        }

        dispatch(
          directorUpdateFileApprove(
            {
              fileInfoTechEvaluationId: arrayFilesId,
              solicitationId
            },
            auth.layerSevenToken
          )
        )
      } else {
        const arrayFilesId: number[] = []

        if (data) {
          arrayFilesId.push(data.fileId)
        } else {
          arrayFilesId.push(...filesId)
        }

        dispatch(
          updateFileApprove(
            { fileId: arrayFilesId, solicitationId },
            auth.layerSevenToken
          )
        )
      }
    }
  }

  const undoDocument = (data: any) => {
    const fileId = data.fileId
    if (auth && auth.layerSevenToken) {
      if (nameProfile === 'DIRECTOR') {
        dispatch(
          directorUpdateFileUndo(
            {
              fileInfoTechEvaluationId: data.fileInfoTechEvaluationId,
              solicitationId
            },
            auth.layerSevenToken
          )
        )
      } else {
        dispatch(
          updateFileUndo(
            { fileId: fileId, solicitationId },
            auth.layerSevenToken
          )
        )
      }
    }
  }

  const openFile = (code: string, name: string) => {
    if (auth && auth.layerSevenToken) {
      dispatch(showWorkflowFile(code, name, auth.layerSevenToken))
    }
  }

  const changeFileType = (fileId: number, fileTypeId: number) => {
    const changeTable = () => {
      const newTable = tableData.map((data: any) => {
        if (data.fileId === fileId) {
          data.fileTypeId = fileTypeId
        }
        return data
      })
      setTableData(newTable)
    }
    if (auth && auth.layerSevenToken) {
      dispatch(
        changeFileTypes(
          auth.layerSevenToken,
          { fileId, fileTypeId, solicitationId },
          changeTable
        )
      )
    }
  }

  const disabledWithRelationship =
    nameProfile !== 'DIRECTOR' &&
    solicitation.status !== 'Em Análise' &&
    solicitation.status !== 'Nova'

  const disabledWithDirector =
    nameProfile === 'DIRECTOR' && !solicitation.isEditable

  const solicitationRejected = solicitation.status == 'Rejeitada'

  return (
    <>
      <S.TableContainer>
        <S.Title>Documentação</S.Title>

        {!solicitationRejected && (
          <S.ButtonsContainer>
            <Chakra.Flex alignItems="center" w="60%">
              <VerifyCommentsFlag>
                <MessagesIcon
                  fill={THEME_PURPLE}
                  stroke={THEME_PURPLE}
                  width="3rem"
                />
                <S.IconText marginRight={4}>Comentários internos</S.IconText>
              </VerifyCommentsFlag>

              <Chakra.Flex alignItems="center">
                <CheckIcon color={THEME_PURPLE} size={42} />
                <S.IconText>
                  <span onClick={() => approveDocument()}>
                    Aprovar selecionados
                  </span>
                </S.IconText>
              </Chakra.Flex>
            </Chakra.Flex>
            <Chakra.Box w="36%">
              <Button
                variant="normal"
                onClick={() => toggleUploadFileModal(true)}
                disabled={
                  nameProfile !== 'DIRECTOR'
                    ? disabledWithRelationship
                    : disabledWithDirector
                }
              >
                Upload de arquivos
              </Button>
            </Chakra.Box>
          </S.ButtonsContainer>
        )}

        <S.Table style={{ borderCollapse: 'separate' }}>
          <S.THead>
            <S.Tr>
              <S.Th>
                {!solicitationRejected && (
                  <Chakra.Checkbox
                    isChecked={checkedAll}
                    onChange={(e) => checkAll(e.target.checked)}
                  ></Chakra.Checkbox>
                )}
              </S.Th>
              <S.Th>Tipo documento</S.Th>
              <S.Th>Nome documento</S.Th>
              <S.Th>Status</S.Th>
              {/* <S.Th>Validade documento</S.Th> */}
              <S.Th></S.Th>
            </S.Tr>
          </S.THead>
          <TableBody
            {...{
              tableData,
              changeCheckedItem,
              openModal,
              openFile,
              approveDocument,
              undoDocument,
              filesTypes,
              changeFileType,
              openHistoryModal,
              openDeleteDocumentModal
            }}
          />
        </S.Table>
        <VerifyRequiredDocumentationFlag>
          <S.Accordion allowToggle>
            <Chakra.AccordionItem p={0} borderTop="none">
              <S.Title>
                <Chakra.AccordionButton p="8px 0">
                  <Chakra.Box flex="1" textAlign="left">
                    Veja aqui a documentação requerida para este cadastro
                  </Chakra.Box>
                  <Chakra.AccordionIcon />
                </Chakra.AccordionButton>
              </S.Title>
              <Chakra.AccordionPanel p={0}>
                <Chakra.List>
                  <S.DocumentsList>
                    Registro profissional (CRM, CRO, CREFITO, CRP, CRESP, CFN)
                  </S.DocumentsList>
                  <S.DocumentsList>Diploma médico</S.DocumentsList>
                  <S.DocumentsList>
                    Certidão ético-profissional atualizada
                  </S.DocumentsList>
                  <S.DocumentsList>Título de especialista</S.DocumentsList>
                  <S.DocumentsList>Título de especialista</S.DocumentsList>
                </Chakra.List>
              </Chakra.AccordionPanel>
            </Chakra.AccordionItem>
          </S.Accordion>
        </VerifyRequiredDocumentationFlag>
      </S.TableContainer>
      {showModal && (
        <Modal
          isOpen={showModal}
          onClose={toggleModal}
          size="sm"
          title="Deseja rejeitar esse documento?"
          titleButton=""
          type={MessageModalType.Error}
          isCentered={true}
        >
          <Chakra.Grid>
            <Chakra.GridItem>
              <p>Justifique porque o documento deve ser rejeitado</p>
            </Chakra.GridItem>
            <Chakra.GridItem>
              <Chakra.Textarea
                value={values.justification}
                onChange={handleInputChange}
                name="justification"
              />
            </Chakra.GridItem>
            <Chakra.Grid marginTop="5" templateColumns="repeat(4, 1fr)" gap={4}>
              <Chakra.GridItem colSpan={2}>
                <Button variant="outlined" onClick={() => toggleModal(false)}>
                  Cancelar
                </Button>
              </Chakra.GridItem>
              <Chakra.GridItem colSpan={2}>
                <Button
                  variant="normal"
                  disabled={!values.justification}
                  onClick={() => rejectDocument()}
                >
                  Rejeitar
                </Button>
              </Chakra.GridItem>
            </Chakra.Grid>
          </Chakra.Grid>
        </Modal>
      )}

      {showHistoryModal && (
        <HistoryModal
          showHistoryModal={showHistoryModal}
          toggleHistoryModal={toggleHistoryModal}
          historyDocuments={historyDocuments}
        />
      )}

      {showUploadFileModal && (
        <Modal
          isOpen={showUploadFileModal}
          onClose={handleUploadDocumentModal}
          size="3xl"
          title="Upload de documentos"
          titleButton=""
          type={MessageModalType.Upload}
          isCentered={true}
        >
          <Chakra.Grid>
            <Chakra.GridItem>
              <p>
                Envie comprovantes de documentos que estejam faltando ou para
                checagem da fonte.
              </p>

              <DocumentsUpload
                isUploadSolicitation
                acceptedMimeTypes="image/jpeg, image/jpg, image/png, application/pdf"
              />
            </Chakra.GridItem>
            <Chakra.Flex gridGap={4}>
              <Button
                variant="normal"
                onClick={() => handleUploadDocumentModal()}
              >
                Finalizar envio
              </Button>
            </Chakra.Flex>
          </Chakra.Grid>
        </Modal>
      )}

      {showDeleteFileModal && (
        <Modal
          isOpen={showDeleteFileModal}
          onClose={toggleDeleteFileModal}
          size="sm"
          title="Deseja excluir este documento?"
          titleButton=""
          type={MessageModalType.Error}
          isCentered={true}
        >
          <Chakra.Grid>
            <Chakra.Flex>
              <Button variant="normal" onClick={() => deleteFile()}>
                Excluir
              </Button>
            </Chakra.Flex>
          </Chakra.Grid>
        </Modal>
      )}
    </>
  )
}

export default Table
