import { AdditionalInfo, IAdditionalInfoStates } from './types'

const initialState: IAdditionalInfoStates = {
  additionalInfo: {},
  success: false,
  message: undefined,
  error: undefined
}

export default function additionalInfo(
  state: IAdditionalInfoStates = initialState,
  action: any
): IAdditionalInfoStates {
  switch (action.type) {
    case AdditionalInfo.ADDITIONAL_INFO_SUCCESS:
      return {
        ...state,
        additionalInfo: action.payload.additionalInformationResponse,
        success: action.payload.success,
        message: undefined,
        error: undefined
      }

    case AdditionalInfo.ADDITIONAL_INFO_ERROR:
      return {
        ...state,
        additionalInfo: {},
        success: false,
        message: action.payload,
        error: action.payload
      }

    case AdditionalInfo.GET_ADDITIONAL_INFO_SUCCESS:
      return {
        ...state,
        additionalInfo: action.payload
      }

    case AdditionalInfo.GET_ADDITIONAL_INFO_ERROR:
      return {
        ...state,
        additionalInfo: {},
        error: action.payload
      }

    case AdditionalInfo.CLEAR_ADDITIONAL_INFO:
      return initialState

    default:
      return state
  }
}
