import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { THEME_PURPLE } from 'src/styles/colors'

export const Container = styled(Chakra.Container)`
  max-width: 90%;
  margin-bottom: 5vh;
`

export const MenuContainer = styled(Chakra.GridItem)`
  margin-top: 20px;
`

export const FilterContainer = styled(Chakra.GridItem)`
  position: relative;
  display: block;
  margin-top: 5px;
`

export const TableContainer = styled(Chakra.GridItem)`
  max-height: 500px;
  overflow: auto;
  scrollbar-color: ${THEME_PURPLE} #f1f1f1;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: ${THEME_PURPLE};
  }
`
export const Box = styled(Chakra.Box)`
  border: 1px solid transparent;
  box-shadow: 0px 6px 10px 3px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-top: 20px;
  border-radius: 5px;
`
