import { ButtonProps } from './interface'
import { Button } from '@chakra-ui/react'

const Buttons = ({
  disabled,
  onClick,
  children,
  type,
  variant,
  maxW,
  fontSize
}: ButtonProps) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      type={type}
      variant={variant}
      data-testid="button"
      maxW={maxW}
      fontSize={fontSize}
    >
      {children}
    </Button>
  )
}

export default Buttons
