import { Center, Flex, HStack } from '@chakra-ui/react'
import { ACCESSIBILITY_SUCCESS, THEME_GREY } from 'src/styles/colors'
import styled from 'styled-components'

export const SectionNumber = styled(Center)`
  border-radius: 50%;
  border-width: 1px;
  border-color: ${THEME_GREY};
  border-style: solid;
  width: 50px;
  height: 50px;
`

export const SectionTitle = styled(Flex)`
  font-weight: 800;
  margin-top: 12px;
  margin-bottom: 12px;
`

export const UploadedFileName = styled(HStack)`
  color: ${ACCESSIBILITY_SUCCESS};
`

export const FileUploadedContainer = styled(HStack)`
  color: ${ACCESSIBILITY_SUCCESS};
`
