import { IThunkAction } from '../shared/types'
import { IRegistrationStepStates, RegistrationStepTypes } from './types'

const initialState: IRegistrationStepStates = {
  lastStepCompleted: undefined
}

const registrationStepReducer = (
  state: IRegistrationStepStates = initialState,
  action: IThunkAction
): IRegistrationStepStates => {
  switch (action.type) {
    case RegistrationStepTypes.GET_REGISTRATION_STEP_SUCCESS:
      return {
        ...state,
        lastStepCompleted: action.payload
      }

    case RegistrationStepTypes.CREATE_REGISTRATION_STEP_SUCCESS:
      return {
        ...state,
        lastStepCompleted: action.payload.registryStep
      }

    case RegistrationStepTypes.UPDATE_REGISTRATION_STEP_SUCCESS:
      return {
        ...state,
        lastStepCompleted: action.payload.registryStep
      }

    case RegistrationStepTypes.CLEAR_REGISTRATION_STEP:
      return initialState

    default:
      return state
  }
}

export default registrationStepReducer
