import { ReactNode } from 'react'

interface RequiredDocumentationFlag {
  children: ReactNode
}

export function VerifyRequiredDocumentationFlag({
  children
}: RequiredDocumentationFlag) {
  return <>{window._flags_.REQUIRED_DOCUMENTATION_ENABLED ? children : null}</>
}
