import * as Chakra from '@chakra-ui/react'
import InfoHeader from '../../../components/signup/info-header'
import { Link } from 'react-router-dom'
import * as I from '../../../assets/icons'
import * as S from './style'
import ROUTING_PATHS from './../../../routes/paths/index'
import { NUCLEO_ATENDIMENTO_AMERICAS_MED } from 'src/constants'
import Button from 'src/components/button'

const SignUpFailed = () => {
  const colSpan1 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 6
  })
  const colStart1 = Chakra.useBreakpointValue({
    base: 5,
    xl: 5,
    lg: 5,
    md: 5,
    sm: 1
  })
  const colStart2 = Chakra.useBreakpointValue({
    base: 6,
    xl: 6,
    lg: 6,
    md: 6,
    sm: 1
  })
  return (
    <>
      <InfoHeader value={5.2} />
      <S.Container>
        <Chakra.Grid templateColumns="repeat(6, 1fr)" gap={3}>
          <Chakra.GridItem colSpan={6}>
            <S.IconWrapper>
              <I.AttentionIcon />
            </S.IconWrapper>
            <S.TitleText>Não foi possível concluir seu cadastro.</S.TitleText>
            <S.DescriptionBoldText data-testid="descriptionBold">
              Ocorreu uma indisponibilidade em nosso sistema e não foi possível
              concluir o seu cadastro.
            </S.DescriptionBoldText>
            <S.DescriptionText data-testid="description">
              Volte ao cadastro do portal para tentar novamente, ou, por favor,
              tente mais tarde. Em caso de dúvidas, entre em contato com: <br />
              <a
                data-testid="site"
                target="blank"
                href={`${NUCLEO_ATENDIMENTO_AMERICAS_MED}`}
              >
                {'Núcleo de Atendimento Médico'}
              </a>
            </S.DescriptionText>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={6}>
            <Chakra.Divider
              borderColor="secondary"
              marginTop="5vh"
              marginBottom="5vh"
            />
          </Chakra.GridItem>

          <Chakra.GridItem colStart={colStart1} colSpan={colSpan1}>
            <Link to={ROUTING_PATHS.Login}>
              <Button variant="outlined" type="submit">
                Home
              </Button>
            </Link>
          </Chakra.GridItem>
          <Chakra.GridItem colStart={colStart2} colSpan={colSpan1}>
            <Link to={ROUTING_PATHS.SignUp_CreateAccount}>
              <Button variant="normal" type="submit">
                Voltar ao cadastro do portal
              </Button>
            </Link>
          </Chakra.GridItem>
        </Chakra.Grid>
      </S.Container>
    </>
  )
}

export default SignUpFailed
