import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { THEME_GREY, THEME_PURPLE } from '../../../styles/colors'

const commonTextStyles = `
font-size: 1.3em;
font-weight: 800;
`

export const Avatar = styled(Chakra.Avatar)`
  @media (max-width: 1200px) {
    width: 36px;
    height: 36px;
  }
`

export const UserText = styled(Chakra.Box)`
  ${commonTextStyles}
  color: ${THEME_GREY};
`

export const UserName = styled(Chakra.Box)`
  ${commonTextStyles}
  color: ${THEME_PURPLE};
`

export const DataContainer = styled(Chakra.VStack)`
  align-items: flex-start;

  @media (max-width: 700px) {
    display: none;
  }
`

export const SubText = styled(Chakra.Box)`
  color: ${THEME_PURPLE};
  margin-top: 0px;
  a {
    font-family: 'SoletoRegular';
    font-weight: 300;
  }
`
