import * as yup from 'yup'

export const emailSchema = yup.object().shape({
  email: yup.string().email().required()
})

export const registerSchema = yup.object().shape({
  password: yup
    .string()
    .required('')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'A senha deve conter no mínimo 8 caracteres. Sendo 1 letra maiúscula, 1 número e um caractere especial.'
    ),
  confirmPassword: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('password'), null], "Passwords don't match.")
})

export const loginSchema = yup.object().shape({
  cpf: yup.string().length(11, 'CPF inválido').required(),
  password: yup.string()
})
