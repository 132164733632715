import { FilesListTypes } from './types'
import Service from '../../services/files'
import DocumentService from '../../services/documents-upload'
import UserService from '../../services/user'
import { hideSpinner, showSpinner } from '../spinner/actions'
import { handleLayerSevenRequestError } from '../layer-seven/actions'

const getFilesSuccess = (data: any) => ({
  type: FilesListTypes.GET_FILES_SUCCESS,
  payload: data
})

const getRegistrationStepSuccess = (step: any) => ({
  type: FilesListTypes.GET_REGISTRATION_STEP_SUCCESS,
  payload: { registrationStep: step }
})

const getFilesError = (error: any) => ({
  type: FilesListTypes.GET_FILES_ERROR,
  payload: error
})

const deleteFileLoading = (show: boolean) => ({
  type: FilesListTypes.SHOW_DELETE_LOADING,
  payload: { showLoading: show }
})

export const getFilesList = (cpf: string, token: string) => (dispatch: any) => {
  Service()
    .getFiles(cpf, token)
    .then((response: any) => {
      dispatch(getFilesSuccess(response.data))
      return UserService().getUserRegistrationStep(cpf, token)
    })
    .then((response: any) =>
      dispatch(getRegistrationStepSuccess(response.data.registryStep))
    )
    .catch((error: any) => {
      dispatch(getFilesError(error))
      dispatch(
        handleLayerSevenRequestError(error, (newToken: string) =>
          dispatch(getFilesList(cpf, newToken))
        )
      )
    })
}

export const deleteFile =
  (cpf: string, code: string, token: string, currentData: any[]) =>
  (dispatch: any) => {
    dispatch(deleteFileLoading(true))
    DocumentService()
      .deleteFileFromStorage(cpf, code, token)
      .then((response: any) => {
        const newFilesArray = currentData.filter((file) => file.code !== code)
        dispatch(getFilesSuccess({ files: newFilesArray }))
        dispatch(deleteFileLoading(false))
      })
      .catch((error: any) => {
        dispatch(deleteFileLoading(false))
        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(deleteFile(cpf, code, newToken, currentData))
          )
        )
      })
  }

export const clearFilesList = () => ({
  type: FilesListTypes.CLEAR_FILES_LIST
})
