import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { useAppSelector } from 'src/hooks'
import Routes from './routes'
import Spinner from 'src/components/spinner'
import { Flex } from '@chakra-ui/react'
import { ApplicationState } from 'src/store/rootReducer'
import './styles/globalStyle.css'
import Modal from 'src/components/modal'
import { hideModal } from 'src/store/message-modal/actions'
import { setPrivacyCookieByEnv } from './utils/privacy-cookies'
import { ENVIRONMENT } from './constants'

const App = () => {
  const dispatch = useDispatch()
  const modalState = useSelector(
    (state: ApplicationState) => state.messageModal
  )
  const MessageModal = () => (
    <Modal
      isOpen={modalState.message}
      onClose={() => dispatch(hideModal())}
      size="2xl"
      type={modalState.type}
      title={modalState.title}
      titleButton={modalState.titleButton}
    >
      {modalState.message}
    </Modal>
  )
  const spinner = useAppSelector(
    (state: ApplicationState) => state.spinnerReducer.loading
  )

  useEffect(() => {
    setPrivacyCookieByEnv(ENVIRONMENT)
  }, [])

  return (
    <Router>
      {spinner && <Spinner />}
      <Flex direction="column">
        {MessageModal()}
        <Routes />
      </Flex>
    </Router>
  )
}

export default App
