import { MessageModalState, MessageModalType } from './types'

export const hideModal = () => (dispatch: any) =>
  dispatch({
    type: MessageModalState.HIDE_MODAL
  })

export const showModal =
  (
    type: MessageModalType,
    title: string,
    message: any,
    titleButton: string,
    onClickButton?: () => void
  ) =>
  (dispatch: any) =>
    dispatch({
      payload: {
        type,
        title,
        message,
        titleButton,
        onClickButton
      },
      type: MessageModalState.SHOW_MODAL
    })
