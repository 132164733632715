import { THEME_GREY } from 'src/styles/colors'

interface Props {
  size?: string
}

const FavoriteIcon = ({ size }: Props) => (
  <svg
    id="Icone_Favoritar"
    data-name="Icone / Favoritar"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 42 42"
  >
    <g
      id="Grupo_6543"
      data-name="Grupo 6543"
      transform="translate(-933.938 -828.313)"
    >
      <path
        id="Caminho_150"
        data-name="Caminho 150"
        d="M29.665,21.064a2.58,2.58,0,0,0-2.49-1.806H19.731l-2.344-7.119a2.579,2.579,0,0,0-2.487-1.8H14.89a2.58,2.58,0,0,0-2.483,1.819l-2.276,7.1H2.625A2.619,2.619,0,0,0,1.09,24l6.073,4.39-2.338,7.1a2.579,2.579,0,0,0,.941,2.933,2.58,2.58,0,0,0,3.08.009l6.084-4.4,6.011,4.39a2.619,2.619,0,0,0,4.038-2.914L22.7,28.388l6.022-4.4a2.58,2.58,0,0,0,.945-2.927ZM27.69,22.581l-7.039,5.14,2.667,8.318a.873.873,0,0,1-1.346.971l-7.036-5.138-7.112,5.14a.873.873,0,0,1-1.34-.981l2.737-8.312L2.113,22.584A.873.873,0,0,1,2.625,21H11.4l2.665-8.312a.847.847,0,0,1,.828-.606h0a.847.847,0,0,1,.83.6L18.468,21h8.707a.873.873,0,0,1,.515,1.578Z"
        transform="translate(939.938 824.974)"
        fill={THEME_GREY}
        stroke={THEME_GREY}
        strokeWidth="1.3"
      />
    </g>
    <rect
      id="Retângulo_3557"
      data-name="Retângulo 3557"
      width="42"
      height="42"
      fill="none"
    />
  </svg>
)

export default FavoriteIcon
