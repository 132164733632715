import {
  ACCESSIBILITY_ERROR,
  THEME_GREY,
  THEME_MEDIUM_GREY,
  THEME_PURPLE
} from 'src/styles/colors'
import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'

export const Select = styled(Chakra.Select)`
  border: 1px solid;
  border-color: ${(props) =>
    props.required ? ACCESSIBILITY_ERROR : THEME_GREY};
  border-radius: 5px;
  height: 46px;
  width: 100%;
  min-width: 0px;
  position: relative;
  padding: 0 10px;
  background-color: white;
  color: ${(props) => (props.value ? THEME_GREY : THEME_MEDIUM_GREY)};

  :hover {
    color: ${THEME_GREY};
  }

  &:focus {
    border: 2px solid;
    border-color: ${(props) =>
      props.required ? ACCESSIBILITY_ERROR : THEME_PURPLE};
    background-color: white;
    outline-style: none;
    box-shadow: none;
    color: ${THEME_GREY};
  }

  &:disabled {
    opacity: 0.4;
    border-color: ${THEME_GREY};
    cursor: not-allowed;
    color: ${THEME_GREY};
  }
`
