interface Props {
  size?: string
  color?: string
}

export const UndoIcon = ({ size, color }: Props) => (
  <svg
    id="Icone_Refazer"
    data-name="Icone / Refazer"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 42 42"
  >
    <g
      id="Grupo_6593"
      data-name="Grupo 6593"
      transform="translate(-751.833 -1016.039)"
    >
      <g id="Grupo_6568" data-name="Grupo 6568">
        <path
          id="Caminho_3149"
          data-name="Caminho 3149"
          d="M25.745,4v8.745H17"
          transform="translate(761.232 1021.495)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Caminho_3150"
          data-name="Caminho 3150"
          d="M28.489,20.489A13.117,13.117,0,1,1,25.4,6.847L32.148,13.2"
          transform="translate(754.829 1021.038)"
          fill="none"
          stroke="#4a4f4f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </g>
    <rect
      id="Retângulo_3572"
      data-name="Retângulo 3572"
      width="42"
      height="42"
      fill="none"
    />
  </svg>
)
