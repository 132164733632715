import * as S from './styles'
import * as Chakra from '@chakra-ui/react'
import * as I from 'src/assets/icons'
import CustomTextField from 'src/components/text-field'
import Button from 'src/components/button'

import { SubmitHandler, useForm } from 'react-hook-form'
import { IRegisteredProfessionalInfo } from './interface'
import Select from 'src/components/input-select'
import {
  crmKindsOptions,
  schoolingOption,
  specialitysOption,
  stateOption,
  workAreaOption
} from 'src/constants/options'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'src/store/rootReducer'
import InputDate from 'src/components/input-date'
import {
  getRegisteredInfo,
  updateRegisteredProfessionalInfo
} from 'src/store/registered-info/actions'
import { showErrorToast, showSuccessToast } from 'src/utils/toast'
import { ERROR, FORMS_MIN_YEAR } from 'src/constants'

export const RegisteredProfessionalInfo = ({
  inputChange,
  selectChange,
  values,
  cpf
}: IRegisteredProfessionalInfo) => {
  const dispatch = useDispatch()
  const token = useSelector((state: ApplicationState) => state.userReducer.auth)
  const solicitation: any = useSelector(
    (state: ApplicationState) => state.solicitationDetailReducer.solicitation
  )

  const { register, handleSubmit } = useForm()

  const colSpan1 = Chakra.useBreakpointValue({
    base: 3,
    xl: 3,
    lg: 3,
    md: 3,
    sm: 6
  })

  const colSpan2 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 6
  })

  const onSubmit: SubmitHandler<IRegisteredProfessionalInfo> = () => {
    const {
      registeredHospitals,
      crmKind,
      crmNumber,
      crmState,
      mainSpecialty,
      rqE1,
      actuationArea1,
      secoundSpecialty,
      rqE2,
      actuationArea2,
      educationLevel,
      completionDate,
      memberTeamHospitals,
      interestResearch,
      teamOrBossName,
      crmBossNumber,
      reason,
      descriptionInterest
    } = values

    const educationCompletionDate = new Date(values.completionDate)
    const today = new Date()

    if (educationCompletionDate >= today) {
      showErrorToast(
        ERROR,
        'Data de conclusão não pode ser maior que a data atual.'
      )
      return
    } else if (
      new Date(educationCompletionDate).getFullYear() < FORMS_MIN_YEAR
    ) {
      showErrorToast(ERROR, 'Data de conclusão inválida.')
      return
    }

    const onSuccessCallback = () => {
      if (token && token.layerSevenToken) {
        dispatch(
          getRegisteredInfo(
            solicitation.cpfHealthProfessional,
            token.layerSevenToken
          )
        )
      }
      return showSuccessToast('', 'Dados atualizados com sucesso.')
    }

    if (token && token.layerSevenToken) {
      dispatch(
        updateRegisteredProfessionalInfo(
          {
            cpf,
            registeredHospitals,
            crmKind,
            crmNumber,
            crmState,
            mainSpecialty,
            rqE1,
            actuationArea1,
            secoundSpecialty,
            rqE2,
            actuationArea2,
            educationLevel,
            completionDate,
            memberTeamHospitals: memberTeamHospitals.toString(),
            interestResearch: interestResearch.toString(),
            teamOrBossName,
            crmBossNumber,
            reason,
            descriptionInterest
          },
          token.layerSevenToken,
          onSuccessCallback
        )
      )
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.Container>
          <Chakra.Grid templateColumns="repeat(3, 1fr)" gap={3}>
            <Chakra.GridItem colSpan={colSpan1} display="flex">
              <Chakra.Box mr="10%">
                <S.SessionTitle>Hospitais em cadastramento:</S.SessionTitle>
                <Chakra.Box mt={2}>
                  {values.registeredHospitals ? (
                    values.registeredHospitals &&
                    values.registeredHospitals?.map((hospital: any) => {
                      return (
                        <S.ListItem key={hospital.name}>
                          {hospital.name}
                          <I.TimeIcon size="28px" />
                        </S.ListItem>
                      )
                    })
                  ) : (
                    <S.Text>Não há hospitais em cadastramento</S.Text>
                  )}
                </Chakra.Box>
              </Chakra.Box>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan1} mt={8}>
              <S.SessionTitle>Dados profissionais:</S.SessionTitle>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan2}>
              <Chakra.FormControl>
                <S.InputLabel>Tipo de conselho:</S.InputLabel>
                <Select
                  disabled={false}
                  name="crmKind"
                  onChange={selectChange}
                  value={values.crmKind}
                >
                  <>
                    <option value="" disabled selected hidden>
                      Selecione
                    </option>
                    {crmKindsOptions}
                  </>
                </Select>
              </Chakra.FormControl>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan2}>
              <Chakra.FormControl>
                <S.InputLabel>Número do Conselho:</S.InputLabel>
                <CustomTextField
                  register={register('values.crmNumber')}
                  type="text"
                  name="crmNumber"
                  maxLength={9}
                  onChange={inputChange}
                  value={values.crmNumber}
                />
              </Chakra.FormControl>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan2}>
              <Chakra.FormControl>
                <S.InputLabel>UF do Conselho:</S.InputLabel>
                <Select
                  disabled={false}
                  name="crmState"
                  onChange={selectChange}
                  value={values.crmState}
                >
                  <>
                    <option value="" disabled selected hidden>
                      Selecione
                    </option>
                    {stateOption}
                  </>
                </Select>
              </Chakra.FormControl>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan2}>
              <Chakra.FormControl>
                <S.InputLabel>Especialidade principal:</S.InputLabel>
                <Select
                  disabled={false}
                  data-testid="mainSpecialty"
                  name="mainSpecialty"
                  onChange={selectChange}
                  value={values.mainSpecialty}
                >
                  <>
                    <option value="" disabled selected hidden>
                      Selecione
                    </option>
                    {specialitysOption}
                  </>
                </Select>
              </Chakra.FormControl>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan2}>
              <Chakra.FormControl>
                <S.InputLabel>RQE principal:</S.InputLabel>
                <CustomTextField
                  register={register('values.rqE1')}
                  type="text"
                  name="rqE1"
                  onChange={inputChange}
                  value={values.rqE1}
                />
              </Chakra.FormControl>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan2}>
              <Chakra.FormControl>
                <S.InputLabel>Área de atuação principal:</S.InputLabel>
                <Select
                  disabled={false}
                  data-testid="actuationArea1"
                  onChange={selectChange}
                  value={values.actuationArea1}
                  name="actuationArea1"
                >
                  <>
                    <option value="" disabled selected hidden>
                      Selecione
                    </option>
                    {workAreaOption}
                  </>
                </Select>
              </Chakra.FormControl>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan2}>
              <Chakra.FormControl>
                <S.InputLabel>Especialidade secundária:</S.InputLabel>
                <Select
                  disabled={false}
                  data-testid="secoundSpecialty"
                  onChange={selectChange}
                  value={values.secoundSpecialty}
                  name="secoundSpecialty"
                >
                  <>
                    <option value="" disabled selected hidden>
                      Selecione
                    </option>
                    {specialitysOption}
                  </>
                </Select>
              </Chakra.FormControl>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan2}>
              <Chakra.FormControl>
                <S.InputLabel>RQE secundário:</S.InputLabel>
                <CustomTextField
                  register={register('rqE2')}
                  type="text"
                  name="rqE2"
                  onChange={inputChange}
                  value={values.rqE2}
                />
              </Chakra.FormControl>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan2}>
              <Chakra.FormControl>
                <S.InputLabel>Área de atuação secundária:</S.InputLabel>
                <Select
                  disabled={false}
                  data-testid="actuationArea2"
                  onChange={selectChange}
                  value={values.actuationArea2}
                  name="actuationArea2"
                >
                  <>
                    <option value="" disabled selected hidden>
                      Selecione
                    </option>
                    {workAreaOption}
                  </>
                </Select>
              </Chakra.FormControl>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan2}>
              <Chakra.FormControl>
                <S.InputLabel>Nível de escolaridade:</S.InputLabel>
                <Select
                  disabled={false}
                  data-testid="educationLevel"
                  onChange={selectChange}
                  value={values.educationLevel}
                  name="educationLevel"
                >
                  <>
                    <option value="" disabled selected hidden>
                      Selecione
                    </option>
                    {schoolingOption}
                  </>
                </Select>
              </Chakra.FormControl>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan2}>
              <Chakra.FormControl>
                <S.InputLabel>Data de conclusão:</S.InputLabel>
                <InputDate
                  name="completionDate"
                  onChange={inputChange}
                  value={values.completionDate}
                />
              </Chakra.FormControl>
            </Chakra.GridItem>

            <Chakra.GridItem colSpan={colSpan1} mt={8}>
              <S.SessionTitle>Dados adicionais:</S.SessionTitle>
            </Chakra.GridItem>

            <Chakra.GridItem colSpan={colSpan1} display="flex">
              <Chakra.Box mr={10}>
                <S.InputLabel>Membro de equipe em algum hospital:</S.InputLabel>
                <S.Text display="flex">
                  {values.memberTeamHospitals == true ||
                  values.memberTeamHospitals === 'true' ? (
                    <>
                      <I.CheckIcon size={28} /> Sim
                    </>
                  ) : (
                    <>
                      <I.RejectIcon size="28px" /> Não
                    </>
                  )}
                </S.Text>
              </Chakra.Box>
              <Chakra.Box>
                <S.InputLabel>
                  Interesse em desenvolver projetos de pesquisa:
                </S.InputLabel>
                <S.Text display="flex">
                  {values.interestResearch == true ||
                  values.interestResearch === 'true' ? (
                    <>
                      <I.CheckIcon size={28} /> Sim
                    </>
                  ) : (
                    <>
                      <I.RejectIcon size="28px" /> Não
                    </>
                  )}
                </S.Text>
              </Chakra.Box>
            </Chakra.GridItem>

            <Chakra.GridItem colSpan={colSpan1}>
              <Chakra.FormControl>
                <S.InputLabel>
                  Nome da(s) equipe(s) / chefe(s) da(s) equipe(s):
                </S.InputLabel>
                <CustomTextField
                  register={register('teamOrBossName')}
                  name="teamOrBossName"
                  type="text"
                  onChange={inputChange}
                  value={values.teamOrBossName}
                />
              </Chakra.FormControl>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan1}>
              <Chakra.FormControl>
                <S.InputLabel>CRM/CRO do chefe da(s) equipe(s):</S.InputLabel>
                <CustomTextField
                  register={register('crmBossNumber')}
                  name="crmBossNumber"
                  type="text"
                  onChange={inputChange}
                  value={values.crmBossNumber}
                />
              </Chakra.FormControl>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan1}>
              <Chakra.FormControl>
                <S.InputLabel>
                  Motivo do cadastro no(s) hospital(is) da Rede Americas:
                </S.InputLabel>
                <CustomTextField
                  register={register('reason')}
                  name="reason"
                  type="text"
                  onChange={inputChange}
                  value={values.reason}
                />
              </Chakra.FormControl>
            </Chakra.GridItem>
            <Chakra.GridItem colSpan={colSpan1}>
              <Chakra.FormControl>
                <S.InputLabel>Descrição adicional:</S.InputLabel>
                <CustomTextField
                  register={register('interestDescription')}
                  name="interestDescription"
                  type="text"
                  onChange={inputChange}
                  value={values.descriptionInterest}
                />
              </Chakra.FormControl>
            </Chakra.GridItem>
          </Chakra.Grid>
          <Chakra.Grid templateColumns="repeat(4, 1fr)" mt={4}>
            <Chakra.GridItem colStart={4}>
              <Button type="submit" variant="normal">
                Salvar alterações
              </Button>
            </Chakra.GridItem>
          </Chakra.Grid>
        </S.Container>
      </form>
    </>
  )
}

export default RegisteredProfessionalInfo
