import { PersonalInfosIconProps } from './interface'

const PersonalInfosIcon = ({ fill }: PersonalInfosIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.719"
    height="36.124"
    viewBox="0 0 32.719 36.124"
  >
    <g
      id="Informações_Pessoais"
      data-name="Informações Pessoais"
      transform="translate(0.4 0.4)"
    >
      <path
        id="Path_3102"
        data-name="Path 3102"
        d="M540.279,729.745a8.831,8.831,0,0,0,2.9-6.565,8.912,8.912,0,1,0-17.823,0,8.829,8.829,0,0,0,2.9,6.565c-5.984,2.03-9.948,6.891-9.948,12.305a5.258,5.258,0,0,0,1.69,3.88c3.486,3.392,11.71,3.655,14.147,3.655.321,0,.528,0,.6-.007,1.653.039,10.087.031,13.739-3.514a5.43,5.43,0,0,0,1.738-4.015c0-5.414-3.965-10.275-9.949-12.305m-12.293-6.565a6.282,6.282,0,1,1,9.193,5.546,8.6,8.6,0,0,1-5.532.154,6.263,6.263,0,0,1-3.661-5.7m18.625,20.945h0c-2.881,2.8-10.92,2.768-11.876,2.754-2.489.071-10.206-.31-12.853-2.884a2.592,2.592,0,0,1-.873-1.947c0-5,4.423-9.345,10.536-10.371a18.41,18.41,0,0,1,5.515.015c6.078,1.046,10.469,5.373,10.469,10.356a2.748,2.748,0,0,1-.917,2.076"
        transform="translate(-518.31 -714.269)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.8"
      />
    </g>
  </svg>
)

export default PersonalInfosIcon
