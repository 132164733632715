import { IThunkAction } from '../shared/types'
import { IPersonalInfoStates, PersonalInfoTypes } from './types'

const initialState: IPersonalInfoStates = {
  error: undefined,
  personalData: {}
}

export default function personalInfoReducer(
  state = initialState,
  action: IThunkAction
) {
  switch (action.type) {
    case PersonalInfoTypes.GET_PERSONAL_INFO_SUCCESS: {
      return {
        ...state,
        personalData: action.payload,
        error: null
      }
    }

    default:
      return state
  }
}
