import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import urls from 'src/constants/api'
import { store } from 'src/store'

interface IApi {
  registrationApi: AxiosInstance
  getDocument(token: string): Promise<AxiosResponse>
  saveCompetencyMap(data: any, token: string): Promise<AxiosResponse>
  updateCompetencyMap(data: any, token: string): Promise<AxiosResponse>
}

export default (): IApi => {
  const registrationApi = axios.create({
    baseURL: urls.registrationAPI
  })

  const amilDocumentsApi = axios.create({
    baseURL: urls.amilAPI
  })

  registrationApi.interceptors.request.use(
    async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      request.headers.Authorization = `Bearer ${
        store.getState().userReducer.auth.accessToken
      }`
      return request
    }
  )

  return {
    registrationApi: registrationApi,

    getDocument: async () => {
      const headers = {
        Accept: 'application/vnd.amil.gerdoc.site.v1+json'
      }
      return amilDocumentsApi.get('/documentos/lista/chave/mapa_competencia', {
        headers: headers
      })
    },

    saveCompetencyMap: (data: any, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi.post(`/Files/competency-map`, data, {
        headers
      })
    },

    updateCompetencyMap: (data: any, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi.put(`/Files/competency-map`, data, {
        headers
      })
    }
  }
}
