import { MessageIconProps } from './interface'

const MessagesIcon = ({ fill, stroke, width }: MessageIconProps) => (
  <svg
    id="Mensagens"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height="auto"
    viewBox="0 0 42 46.835"
  >
    <g id="Comentar" transform="translate(5 5)">
      <path
        id="Path_34611"
        data-name="Path 34611"
        d="M572.433,1277.786a9.472,9.472,0,0,1-4.928-1.378l-5.567,1.075,1.879-5.276a7.613,7.613,0,0,1-.682-3.343v-.13a9.3,9.3,0,1,1,9.307,9.052Z"
        transform="translate(-555.439 -1252.17)"
        fill={fill}
        stroke={stroke}
        strokeWidth="1.5"
        fillRule="evenodd"
      />
      <rect
        id="Rectangle_7671"
        data-name="Rectangle 7671"
        width="33"
        height="33"
        fill="none"
      />
    </g>
    <g
      id="Group_43964"
      data-name="Group 43964"
      transform="translate(20 22.885)"
    >
      <path
        id="Path_205"
        data-name="Path 205"
        d="M7.5,0A7.5,7.5,0,1,1,0,7.5,7.5,7.5,0,0,1,7.5,0Z"
        transform="translate(0 0.95)"
        fill="#d0021b"
      />
      <text
        id="_2"
        data-name="2"
        transform="translate(3 3)"
        fill="#fff"
        fontSize="12"
        fontFamily="SoletoLight, Soleto"
        fontWeight="400"
        letterSpacing="-0.013em"
      >
        <tspan x="0.984" y="9">
          2
        </tspan>
      </text>
    </g>
  </svg>
)

export default MessagesIcon
