import { AxiosResponse } from 'axios'
import { workflowAPI } from '../interceptors'

interface ISolicitations {
  getSolicitations(
    token: string,
    pageNumber: number,
    pageSize: number,
    filter?: string,
    hospitals?: number[],
    status?: number[],
    onlyMyAnalyzes?: boolean
  ): Promise<AxiosResponse>
  getFilters(token: string): Promise<AxiosResponse>
}

export default (): ISolicitations => {
  return {
    getSolicitations: (
      token: string,
      pageNumber: number,
      pageSize: number,
      filter: string,
      hospitals: number[],
      status: number[],
      onlyMyAnalyzes: boolean
    ): Promise<AxiosResponse> => {
      const fullUrl = new URL('/Workflow/solicitations', window.location.origin)

      pageNumber != undefined
        ? fullUrl.searchParams.append('PageNumber', pageNumber.toString())
        : null

      pageSize != undefined
        ? fullUrl.searchParams.append('PageSize', pageSize.toString())
        : null

      filter != undefined && filter != ''
        ? fullUrl.searchParams.append('Filter', filter)
        : null

      hospitals != undefined && hospitals.length > 0
        ? hospitals.map((hospital) => {
            fullUrl.searchParams.append('Hospitals', hospital.toString())
          })
        : null

      status != undefined && status.length > 0
        ? status.map((item) => {
            fullUrl.searchParams.append('Status', item.toString())
          })
        : null

      onlyMyAnalyzes != undefined
        ? fullUrl.searchParams.append(
            'OnlyMyAnalyzes',
            onlyMyAnalyzes.toString()
          )
        : null

      const url = fullUrl.pathname + fullUrl.search

      const headers = {
        'X-Authorization': `${token}`
      }

      return workflowAPI().get(url, { headers })
    },
    getFilters: (token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return workflowAPI().get(`/Workflow/filter`, { headers })
    }
  }
}
