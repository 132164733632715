import styled, { css } from 'styled-components'
import {
  THEME_PURPLE,
  THEME_GREY,
  ACCESSIBILITY_SUCCESS,
  ACCESSIBILITY_ERROR
} from 'src/styles/colors'
import * as I from 'src/assets/icons'
import * as Chakra from '@chakra-ui/react'
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineInfoCircle
} from 'react-icons/ai'

export const statusColors: any = {
  Nova: ACCESSIBILITY_SUCCESS,
  'Em Análise': THEME_PURPLE,
  Rejeitada: ACCESSIBILITY_ERROR,
  Rejeitado: ACCESSIBILITY_ERROR,
  Pendência: THEME_PURPLE,
  Aprovada: ACCESSIBILITY_SUCCESS,
  Aprovado: ACCESSIBILITY_SUCCESS,
  Bloqueado: ACCESSIBILITY_ERROR,
  Cancelada: ACCESSIBILITY_ERROR,
  Cancelado: ACCESSIBILITY_ERROR,
  'Aguardando Aprovação do Diretor': THEME_PURPLE
}

const arrowIconStyle = css`
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 5px;
`

export const ArrowDown = styled(AiOutlineArrowDown)`
  ${arrowIconStyle};
`

export const ArrowUp = styled(AiOutlineArrowUp)`
  ${arrowIconStyle};
`

export const ArrowInvisible = styled(AiOutlineArrowUp)`
  display: inline-block;
  margin-left: 5px;
  visibility: hidden;
`

export const Table = styled.table`
  border: 1px solid #d1d3d4;
  width: 100%;
`

export const THead = styled.thead`
  color: ${THEME_PURPLE};
`

export const Th = styled.th`
  background-color: #fff;
  padding: 8px 10px;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
  text-transform: uppercase;
`

export const Tr = styled.tr`
  border-top: 1px solid #d1d3d4;
  border-bottom: 1px solid #d1d3d4;
  cursor: pointer;
`

export const Td = styled.td`
  color: ${THEME_GREY};
  padding: 8px 10px;
`

export const TdHospital = styled.td`
  align-items: center;
  color: ${THEME_GREY};
  display: flex;
`

export const Tooltip = styled.div`
  cursor: pointer;
`
export const TooltipBox = styled.div`
  border-radius: 4px;
  display: none;
`

export const TooltipCard = styled(Chakra.Tooltip)`
  border: 1px solid ${THEME_GREY};
  border-radius: 4px;
  color: ${THEME_PURPLE};
  background-color: #fff;
  display: block;
  width: 240px;
  padding: 8px;
`

export const HospitalName = styled.div`
  font-size: 16px;
  font-weight: bold;
`

export const HospitalStatus = styled.div<{ status: string }>`
  font-size: 14px;
  margin-bottom: 10px;
  color: ${({ status }) => statusColors[status]};
`

export const InvisibleIcon = styled.div`
  visibility: hidden;
  display: none;
`

export const HospitalIcon = styled(I.HospitalIcon)`
  height: 30px;
  width: 30px;
`

export const Hospital = styled.p`
  position: relative;
  left: 12px;
`

export const StatusBox = styled(Chakra.Flex)<{ status: string }>`
  border: 1.5px solid ${({ status }) => statusColors[status]};
  border-radius: 5px;
  align-items: center;
  padding: 4px;
  justify-content: space-between;

  color: ${({ status }) => statusColors[status]};
`

export const StatusText = styled(Chakra.Text)`
  font-family: 'SoletoMedium';
  font-size: 14px;
  margin-right: 4px;
  padding: 4px;
`

export const StatusIcon = styled(AiOutlineInfoCircle)<{ status: string }>`
  font-size: 18px;
  svg {
    stroke: ${({ status }) => statusColors[status]};
  }
`
