import { ReactNode } from 'react'

interface WarningMessageFlagProps {
  children: ReactNode
}

export function VerifyWarningMessageFlag({
  children
}: WarningMessageFlagProps) {
  return <>{window._flags_.WARNING_MESSAGE_ENABLED ? children : null}</>
}
