import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'

export const Heading = styled(Chakra.Heading)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0 0 0;
  flex-wrap: wrap;
  font-size: 36px;
  color: ${THEME_PURPLE};
  text-align: left;
  @media (max-width: 900px) {
    font-size: 32px;
  }
`
export const Icon = styled.div`
  max-width: 18%;
  margin-right: 10px;
`

export const Title = styled.div`
  max-width: 82%;
`

export const Subtitle = styled(Chakra.Text)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
  color: ${THEME_GREY};
`
