import { AxiosResponse } from 'axios'
import { workflowAPI } from '../interceptors'

interface IApi {
  getHospitals(token: string): Promise<AxiosResponse>
}

export default (): IApi => {
  return {
    getHospitals: (token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return workflowAPI().get('/Workflow/hospitals', {
        headers
      })
    }
  }
}
