import { hideSpinner, showSpinner } from 'src/store/spinner/actions'
import UserService from 'src/services/user'
import User from 'src/services/create-account'
import {
  IResetPasswordPayload,
  ISignInParams,
  IUserClaim,
  UserStates
} from './types'
import * as storage from 'src/utils/storage'
import ROUTING_PATHS from '../../routes/paths'
import { AxiosResponse } from 'axios'
import { UserProfileEnum } from './types'
import { showErrorToast } from 'src/utils/toast'
import { capitalizeFirstLetter } from 'src/utils'
import { CPF_KEY_RESET_PASSWORD } from './constants'
import {
  getRegistrationStepSuccess,
  getNextRegistrationStepRoute,
  createRegistrationStep,
  clearRegistrationStep
} from 'src/store/registration-step/actions'
import {
  clearProfessinalInfo,
  saveProfessionalInfoSuccess
} from '../professional-information/actions'
import {
  getLayerSevenTokenSuccess,
  getToken,
  handleLayerSevenRequestError
} from '../layer-seven/actions'
import {
  clearAdditionalInfo,
  getAdditionalInfoSuccess
} from '../additional-info/actions'
import { clearCompetencyMap } from '../competency-map/actions'
import { clearDocumentUpload } from '../documents-upload/actions'
import { clearDownloadFiles } from '../files-download/actions'
import { clearFilesList } from '../files-list/actions'
import { clearHospitalsList } from '../hospitals-list/actions'
import { clearMassRegistration } from '../mass-registration/actions'
import { hideModal } from '../message-modal/actions'
import { ERROR, MSG_SYSTEM_UNAVAILABLE } from 'src/constants'
import { ApplicationState } from './../rootReducer'

export const clearError = () => ({
  type: UserStates.CLEAR_ERROR
})

export const signIn =
  (
    credentials: ISignInParams,
    historySignInScreen: any,
    tokenLayerSeven = '',
    onSuccessCallback: any = null
  ) =>
  async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(showSpinner())
    let validLayerSevenToken = ''
    const stateAuth = getState().userReducer.auth

    if (tokenLayerSeven.length < 1) {
      if (stateAuth && stateAuth.layerSevenToken) {
        validLayerSevenToken = stateAuth.layerSevenToken
      } else {
        validLayerSevenToken = await getToken()

        if (validLayerSevenToken.length < 1) {
          showErrorToast(ERROR, MSG_SYSTEM_UNAVAILABLE)
          dispatch(hideSpinner())
          return
        } else {
          dispatch(getLayerSevenTokenSuccess(validLayerSevenToken))
        }
      }
    } else {
      validLayerSevenToken = tokenLayerSeven
    }

    UserService()
      .signInByCpfAndPassword(credentials, validLayerSevenToken)
      .then(async (res) => {
        const initialUserState = getUserReducerFromLoginPayload(res.data)
        storage.saveUserToStorage(initialUserState)
        dispatch(signInSuccess(initialUserState))
        dispatch(
          getUserLoggedIn(
            initialUserState.user.personalData.cpf,
            validLayerSevenToken
          )
        )
        dispatch(setCounterTokenRetrials(0))

        const routeByProfile = await getRouteByUserProfile(
          validLayerSevenToken,
          initialUserState.auth.isActive,
          initialUserState.auth.userProfile,
          initialUserState.user.personalData.cpf,
          dispatch
        )
        if (historySignInScreen) {
          if (routeByProfile.length > 0) {
            historySignInScreen.push(routeByProfile)
          }
        }

        if (onSuccessCallback) onSuccessCallback()
      })
      .catch((error) => {
        if (error.request && error.request.response && error.request.status) {
          if (error.request.status === 400) {
            const errors: string[] = JSON.parse(error.request.response).errors
              .messages

            if (errors && errors.length > 0) {
              const msg = errors[0]
              showErrorToast('Erro de acesso', capitalizeFirstLetter(msg))
            }
          } else if (error.request.status === 401) {
            dispatch(
              handleLayerSevenRequestError(error, (newToken: string) =>
                dispatch(
                  signIn(
                    credentials,
                    historySignInScreen,
                    newToken,
                    onSuccessCallback
                  )
                )
              )
            )
          } else {
            dispatch(setUserFail(JSON.parse(error.request.response).errors))
          }
        }
      })
      .finally(() => {
        dispatch(hideSpinner())
      })
  }

export const saveAccountData = (data: any) => ({
  payload: data,
  type: UserStates.SAVE_ACCOUNT_DATA
})

export const signInSuccess = (user: any) => ({
  payload: user,
  type: UserStates.SIGNIN_SUCCESS
})

export const setUserFail = (error: string) => ({
  payload: error,
  type: UserStates.USER_ERROR
})

export const signOut =
  () => (dispatch: any, getState: () => ApplicationState) => {
    dispatch(clearAdditionalInfo())
    dispatch(clearProfessinalInfo())
    dispatch(clearCompetencyMap())
    dispatch(clearDocumentUpload())
    dispatch(clearDownloadFiles())
    dispatch(clearFilesList())
    dispatch(clearHospitalsList())
    dispatch(clearMassRegistration())
    dispatch(hideModal())
    dispatch(clearRegistrationStep())
    const accessToken = getState().userReducer.auth?.accessToken
    if (accessToken) {
      UserService().signOut(accessToken)
    }
    dispatch(signOutSuccess())
    storage.clearStorage()
  }

export const signOutSuccess = () => ({
  type: UserStates.SIGNOUT
})

export const register =
  (data: any, onSuccessCallback: any = null) =>
  async (dispatch: any) => {
    const { cpf, password } = data

    dispatch(showSpinner())
    let validLayerSevenToken = ''
    validLayerSevenToken = await getToken()

    if (validLayerSevenToken.length < 1) {
      showErrorToast(ERROR, MSG_SYSTEM_UNAVAILABLE)
      dispatch(hideSpinner())
      return
    } else {
      dispatch(getLayerSevenTokenSuccess(validLayerSevenToken))
    }

    User()
      .createAccount(data, validLayerSevenToken)
      .then(() => {
        dispatch(
          signIn({ cpf, password }, null, '', () => {
            dispatch(createRegistrationStep(cpf, validLayerSevenToken))

            if (onSuccessCallback) onSuccessCallback()
          })
        )
        dispatch(saveAccountData(data))
      })
      .catch((error) => {
        dispatch(
          handleLayerSevenRequestError(error, () => dispatch(register(data)))
        )

        if (error.request && error.request.response)
          dispatch(
            setUserFail(JSON.parse(error.request.response).errors.messages)
          )
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const updateUser =
  (data: any, token: string, onSuccessCallback: any = null) =>
  async (dispatch: any) => {
    dispatch(showSpinner())

    UserService()
      .updateUser(data, token)
      .then(() => {
        dispatch(saveAccountData(data))

        if (onSuccessCallback) onSuccessCallback()
      })
      .catch((error) => {
        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(updateUser(data, newToken))
          )
        )

        if (error.request && error.request.response)
          dispatch(
            setUserFail(JSON.parse(error.request.response).errors.messages)
          )
      })
      .finally(() => dispatch(hideSpinner()))
  }

const getUserReducerFromLoginPayload = (payload: any): any => {
  const { accessToken, expiresIn, refreshToken, userProfile, isActive } =
    payload
  const email = payload.userToken.email
  let cpf = ''
  payload.userToken.claims.forEach((claim: IUserClaim) => {
    if (claim.type === 'uid') {
      cpf = claim.value
    }
  })
  return {
    auth: {
      accessToken,
      expiresIn,
      refreshToken,
      userProfile,
      isActive
    },
    user: {
      personalData: {
        cpf,
        email
      }
    }
  }
}

export const resetPassword =
  (
    payload: IResetPasswordPayload,
    token: string,
    onSuccessCallback: any = null,
    onErrorCallback: any = null
  ) =>
  (dispatch: any) => {
    UserService()
      .resetPassword(payload, token)
      .then(() => {
        dispatch(resetPasswordSuccess())

        if (onSuccessCallback) onSuccessCallback()
      })
      .catch((error: any) => {
        if (onErrorCallback) onErrorCallback()

        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(resetPassword(payload, newToken))
          )
        )
      })
  }

export const resetPasswordSuccess = () => ({
  payload: null,
  type: UserStates.RESET_PASSWORD_SUCCESS
})

export const getRouteByUserProfile = async (
  token: string,
  isActive = true,
  userProfile = UserProfileEnum.DOCTOR,
  cpf = '',
  dispatch: any = null
): Promise<string> => {
  if (isActive === false) {
    storage.setLocalStorage(CPF_KEY_RESET_PASSWORD, cpf)
    return ROUTING_PATHS.Reset_Password
  }

  switch (userProfile) {
    case UserProfileEnum.DIRECTOR:
      return ROUTING_PATHS.Home

    case UserProfileEnum.RELATIONSHIP_MANAGER:
      return ROUTING_PATHS.Home

    case UserProfileEnum.DOCTOR: {
      return await UserService()
        .getUserRegistrationStep(cpf, token)
        .then((res: AxiosResponse) => {
          const step = res.data.registryStep

          if (step !== undefined && dispatch) {
            // When user is a doctor
            dispatch(getRegistrationStepSuccess(step))
            if (step < 6) {
              // Doctor has not finished registration
              return getNextRegistrationStepRoute(step)
            }
          }
          return ROUTING_PATHS.Dashboard
        })
        .catch(() => ROUTING_PATHS.SignUp_PersonalInfo)
    }

    default:
      return ROUTING_PATHS.Root
  }
}

export const getUserLoggedIn =
  (cpf: string, token: string) => (dispatch: any) => {
    UserService()
      .getUserLoggedIn(cpf, token)
      .then((res: AxiosResponse) => {
        if (res.data.personalData)
          dispatch(getUserLoggedInSuccess(res.data.personalData))
        if (res.data.professionalData)
          dispatch(saveProfessionalInfoSuccess(res.data.professionalData))
        if (res.data.additionalData)
          dispatch(getAdditionalInfoSuccess(res.data.additionalData))
      })
      .catch((error) => {
        if (error.request && error.request.response)
          dispatch(setUserFail(JSON.parse(error.request.response).errors))

        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(getUserLoggedIn(cpf, newToken))
          )
        )
      })
  }

export const getUserLoggedInSuccess = (payload: any) => ({
  payload,
  type: UserStates.GET_USER_LOGGED_IN_SUCCESS
})

export const incrementCounterTokenRetrials = () => ({
  type: UserStates.INCREMENT_COUNTER_TOKEN_RETRIALS
})

export const setCounterTokenRetrials = (value: number) => ({
  payload: value,
  type: UserStates.SET_COUNTER_TOKEN_RETRIALS
})
