interface Props {
  size?: string
  color?: string
}

const SearchIcon = ({ size, color }: Props) => (
  <svg
    id="Busca"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 42 42"
  >
    <g id="Group_6545" data-name="Group 6545" transform="translate(8 7)">
      <circle
        id="Ellipse_180"
        data-name="Ellipse 180"
        cx="12"
        cy="12"
        r="12"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <line
        id="Line_380"
        data-name="Line 380"
        x1="6.34"
        y1="6.34"
        transform="translate(20.417 20.629)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <rect
      id="Rectangle_3559"
      data-name="Rectangle 3559"
      width={size}
      height={size}
      fill="none"
    />
  </svg>
)

export default SearchIcon
