import { getSolicitations } from './types'

const initialState = {
  filters: {
    hospitals: [],
    status: []
  },
  solicitations: [],
  error: null
}

export default function solicitationsList(state = initialState, action: any) {
  switch (action.type) {
    case getSolicitations.GET_SOLICITATION_SUCCESS:
      return {
        ...state,
        solicitations: action.payload.solicitations,
        hasNextPage: action.payload.hasNextPage,
        error: null
      }

    case getSolicitations.GET_SOLICITATION_ERROR:
      return {
        ...state,
        solicitations: [],
        hasNextPage: false,
        error: action.payload.message
      }

    case getSolicitations.GET_FILTER_SUCCESS:
      return {
        ...state,
        filters: {
          hospitals: action.payload.hospitals,
          status: action.payload.status
        },
        error: null
      }

    case getSolicitations.GET_FILTER_ERROR:
      return {
        ...state,
        filters: {},
        error: action.payload.message
      }

    default:
      return state
  }
}
