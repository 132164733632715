import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'

export const Link = styled.a`
  color: ${THEME_PURPLE};
  text-decoration: underline;
`

export const Form = styled.form`
  display: contents;
`

export const InputField = styled(Chakra.Input)`
  border: 1px solid ${THEME_GREY};
  height: 46px;
`

export const TextareaField = styled(Chakra.Textarea)`
  background-color: #fff;
  border: 1px solid ${THEME_GREY};
  height: 120px;
`
