import { ListType } from './types'
import * as S from './styles'

const List = ({ documents, search }: ListType) => {
  return (
    <S.Ul>
      {documents?.documentos
        .filter((documents) =>
          documents.descricao.toLowerCase().includes(search.toLowerCase())
        )
        .map((document): any => {
          const urlToDownload = `${window._env_.REACT_APP_GALLERY_AMIL}${document.link}`
          return (
            <S.Item key={document.id}>
              <S.Download href={urlToDownload}>
                <S.PdfIcon />
                <S.ButtonTitle>{document.descricao}</S.ButtonTitle>
              </S.Download>
            </S.Item>
          )
        })}
    </S.Ul>
  )
}

export default List
