import { ReactNode } from 'react'

interface MenuItemProps {
  children: ReactNode
  flag: boolean
}

export function VerifyMenuItemFlag({ children, flag }: MenuItemProps) {
  return <>{flag ? children : null}</>
}
