import { hideSpinner, showSpinner } from 'src/store/spinner/actions'
import Service from 'src/services/user'
import { getToken } from '../layer-seven/actions'

export const forgotPassword =
  (cpf: string, token: string, onSuccessCallback: any = null) =>
  async (dispatch: any) => {
    dispatch(showSpinner())
    const validLayerSevenToken = await getToken()

    Service()
      .forgotPassword(cpf, token, validLayerSevenToken)
      .then((response) => {
        if (response.status === 204) {
          onSuccessCallback()
        }
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const forgotPasswordWithoutRecaptcha =
  (cpf: string, onSuccessCallback: any = null) =>
  async (dispatch: any) => {
    dispatch(showSpinner())
    const validLayerSevenToken = await getToken()

    Service()
      .forgotPasswordWithoutRecaptcha(cpf, validLayerSevenToken)
      .then((response) => {
        if (response.status === 204) {
          onSuccessCallback()
        }
      })
      .finally(() => dispatch(hideSpinner()))
  }
