export const enum ProfessionalInfoTypes {
  GET_PROFESSIONAL_INFO_SUCCESS = '@professional-info/GET_PROFESSIONAL_INFO',
  GET_PROFESSIONAL_INFO_ERROR = '@professional-info/GET_PROFESSIONAL_INFO_ERROR',
  SAVE_PROFESSIONAL_INFO = '@professional-info/SAVE_PROFESSIONAL_INFO',
  UPDATE_PROFESSIONAL_INFO = '@professional-info/UPDATE_PROFESSIONAL_INFO',
  CLEAR_PROFESSIONAL_INFO = '@professional-info/CLEAR_PROFESSIONAL_INFO'
}

export interface IProfessionalData {
  cpf?: string
  mainSpecialty?: string
  rqE1?: string
  actuationArea1?: string
  secoundSpecialty?: string
  rqE2?: string
  actuationArea2?: string
  educationLevel?: string
  completionDate?: string
  interestResearch?: string
  accreditationApplication: {
    cpf?: string
    selectedHospitals: IHospitalInfo[]
    memberTeamHospitals?: string
    interestResearch?: boolean
    reasonForRegistering?: string
    teamNameTeamLeader?: string
    crmTeamLeader?: string
    descriptionInterest?: string
  }
  clinicsInformation?: IClinicInfo[]
}

export interface IClinicInfo {
  id?: string
  createdBy?: string
  createdDate?: Date
  lastModifiedBy?: string
  lastModifiedDate?: Date
  cnpj: string
  name: string
  address: string
  number: string
  neighborhood: string
  city: string
  state: string
  postalCode: string
  clinicPhoneNumber: string
  secretaryName: string
  secretaryEmail: string
}

export interface IHospitalInfo {
  id: string
  name: string
  sapId?: string
}

export interface IProfessionalInformationStates {
  error?: any
  professionalData?: IProfessionalData
}
