import { THEME_GREY } from './../../styles/colors'

const ConsultDocumentsIcon = () => (
  <svg
    id="Icone_Consultar_documentos"
    data-name="Icone / Consultar documentos"
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g
      id="Grupo_44136"
      data-name="Grupo 44136"
      transform="translate(-79.926 -1280.057)"
    >
      <path
        id="Caminho_34880"
        data-name="Caminho 34880"
        d="M90.94,1291.434"
        fill="none"
        stroke={THEME_GREY}
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
    <g
      id="Ellipse_54"
      data-name="Ellipse 54"
      fill="none"
      stroke={THEME_GREY}
      strokeWidth="0.5"
    >
      <circle cx="21" cy="21" r="21" stroke="none" />
      <circle cx="21" cy="21" r="20.75" fill="none" />
    </g>
    <rect
      id="Retângulo_7671"
      data-name="Retângulo 7671"
      width="42"
      height="42"
      fill="none"
    />
    <g
      id="Grupo_44412"
      data-name="Grupo 44412"
      transform="translate(-2.147 -0.859)"
    >
      <g
        id="Grupo_44169"
        data-name="Grupo 44169"
        transform="translate(-190.445 -1180.53)"
      >
        <g
          id="Grupo_44168"
          data-name="Grupo 44168"
          transform="translate(201.206 1189.389)"
        >
          <line
            id="Linha_1712"
            data-name="Linha 1712"
            x1="10.93"
            transform="translate(3.934 10.108)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <line
            id="Linha_1713"
            data-name="Linha 1713"
            x1="10.93"
            transform="translate(3.934 7.664)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Caminho_34904"
            data-name="Caminho 34904"
            d="M214.338,1189.389h-4.692a1.469,1.469,0,0,0-1.469,1.469v1.88a1.1,1.1,0,0,0,1.1,1.1h.017a1.124,1.124,0,0,0,1.025-.709,1.822,1.822,0,0,1,3.355,0,1.122,1.122,0,0,0,1.025.709h.017a1.1,1.1,0,0,0,1.1-1.1v-1.88A1.468,1.468,0,0,0,214.338,1189.389Z"
            transform="translate(-202.671 -1189.389)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Caminho_34905"
            data-name="Caminho 34905"
            d="M219.487,1191.474h1.143a.742.742,0,0,1,.742.743v16.078a5.007,5.007,0,0,1-5.007,5.009H203.731a.743.743,0,0,1-.743-.743v-1.143"
            transform="translate(-201.013 -1189.163)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Caminho_34906"
            data-name="Caminho 34906"
            d="M205.848,1189.389h-3.9a.74.74,0,0,0-.738.742v20.244a.74.74,0,0,0,.738.742h12.641a1.92,1.92,0,0,0,1.915-1.924h0a.741.741,0,0,1,.739-.742h0a1.92,1.92,0,0,0,1.915-1.924v-16.4a.74.74,0,0,0-.739-.742h-3.9"
            transform="translate(-201.206 -1189.389)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Caminho_34907"
            data-name="Caminho 34907"
            d="M217.617,1203.6v1.111a.949.949,0,0,1-.949.95h-9.031a.95.95,0,0,1-.95-.95V1203.6"
            transform="translate(-202.754 -1191.254)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </g>
      <g
        id="Grupo_44411"
        data-name="Grupo 44411"
        transform="translate(-325 -728.02)"
      >
        <g
          id="Grupo_6519"
          data-name="Grupo 6519"
          transform="translate(341 749.02)"
        >
          <path
            id="Caminho_3139"
            data-name="Caminho 3139"
            d="M1,11.1S4.551,4,10.767,4s9.767,7.1,9.767,7.1-3.551,7.1-9.767,7.1S1,11.1,1,11.1Z"
            transform="translate(-1 -4)"
            fill="#fff"
            stroke={THEME_GREY}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <circle
            id="Elipse_121"
            data-name="Elipse 121"
            cx="2.689"
            cy="2.689"
            r="2.689"
            transform="translate(7.012 4.705)"
            fill="#fff"
            stroke={THEME_GREY}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default ConsultDocumentsIcon
