import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'

export const IconWrapper = styled.div`
  max-width: 10%;
  margin: 0 auto;
`
export const Title = styled(Chakra.Text)`
  color: ${THEME_PURPLE};
  font-size: 36px;
  text-align: center;
  margin-top: 20px;
  line-height: 36px;
`

export const DescriptionText = styled(Chakra.Text)`
  color: ${THEME_GREY};
  font-size: 18px;
  margin-bottom: 18px;
  text-align: center;
  line-height: 32px;
`
