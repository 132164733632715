import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'

export const Container = styled(Chakra.Container)`
  max-width: 90%;
  margin-bottom: 5vh;
  margin-left: 7vw;
  margin-right: 120px;
`

export const PasswordMatchError = styled(Chakra.Box)`
  color: red;
  font-size: 16px;
`
