import { Spinner } from './constants'

const initialState = {
  loading: false
}

export default function spinner(state = initialState, action: any) {
  switch (action.type) {
    case Spinner.SHOW_SPINNER:
      return {
        ...state,
        loading: true
      }
    case Spinner.HIDE_SPINNER:
      return {
        ...state,
        loading: false
      }
    default:
      return initialState
  }
}
