import { applyMiddleware, createStore, Store } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from '../rootReducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userReducer', 'personalInfoReducer', 'solicitationDetailReducer']
}

const persistReducers = persistReducer(persistConfig, rootReducer)

export default function configureStore(preloadedState: any) {
  const composeEnhancers =
    (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    composeWithDevTools

  const middlewareEnhancer = applyMiddleware(thunk)

  const composedEnhancers = composeEnhancers(middlewareEnhancer)

  const store: Store = createStore(
    persistReducers,
    preloadedState,
    composedEnhancers
  )

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../rootReducer', () => store.replaceReducer(rootReducer))
  }

  return store
}
