import * as Chakra from '@chakra-ui/react'
import * as I from 'src/assets/icons'
import CreateAccountForm from './components/form'
import HeadingText from 'src/components/signup/heading-text'
import Container from 'src/components/container'
import { THEME_PURPLE } from 'src/styles/colors'

const CreateAccount = () => {
  return (
    <Container value={5.2}>
      <Chakra.Grid templateColumns="repeat(6, 1fr)" gap={3}>
        <Chakra.GridItem colSpan={6}>
          <HeadingText
            icon={<I.CreateAccountIcon stroke={THEME_PURPLE} />}
            title="Crie sua conta"
            subtitle="Crie seu usuário para fazer um cadastro ao
    Portal da rede. Com ele você poderá ter
      acesso a informações básicas como acesso a ebooks, cadastro em
      palestras, dentro outras opções"
          />
        </Chakra.GridItem>
      </Chakra.Grid>
      <CreateAccountForm />
    </Container>
  )
}

export default CreateAccount
