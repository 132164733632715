import { IThunkAction } from '../shared/types'
import { IUserStateTypes, UserStates } from './types'

const initialState: IUserStateTypes = {
  error: undefined,
  user: undefined,
  auth: {
    refreshToken: ''
  }
}

const userReducer = (
  state: IUserStateTypes = initialState,
  action: IThunkAction
): IUserStateTypes => {
  switch (action.type) {
    case UserStates.SIGNIN_SUCCESS: {
      const userData = action.payload

      return {
        ...state,
        error: undefined,
        user: {
          ...state.user,
          personalData: {
            ...state.user?.personalData,
            email: userData.user.personalData.email,
            cpf: userData.user.personalData.cpf
          }
        },
        auth: {
          ...state.auth,
          accessToken: userData.auth.accessToken,
          expiresIn: userData.auth.expiresIn,
          refreshToken: userData.auth.refreshToken || '',
          userProfile: userData.auth.userProfile,
          isActive: userData.auth.isActive
        }
      }
    }
    case UserStates.USER_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case UserStates.SIGNOUT: {
      return initialState
    }

    case UserStates.CLEAR_ERROR: {
      return {
        ...state,
        error: undefined
      }
    }

    case UserStates.SAVE_ACCOUNT_DATA: {
      return {
        ...state,
        error: undefined,
        user: {
          personalData: {
            email: action.payload.email,
            fullName: action.payload.fullName,
            socialName: action.payload.socialName,
            cpf: action.payload.cpf,
            crmKind: action.payload.crmKind,
            crmNumber: action.payload.crmNumber,
            crmState: action.payload.crmState,
            speciality: action.payload.speciality,
            celPhone: action.payload.celPhone,
            allowReceiveInformations: action.payload.allowReceiveInformations,
            acceptedTermsPrivacyUsePortal:
              action.payload.acceptedTermsPrivacyUsePortal
          }
        }
      }
    }

    case UserStates.SAVE_PERSONAL_INFO: {
      return {
        ...state,
        error: undefined,
        user: {
          ...state.user,
          personalData: {
            ...state.user?.personalData,
            rg: action.payload.rg,
            rgExpeditor: action.payload.rgExpeditor,
            emissionDate: action.payload.emissionDate,
            placeOfBirth: action.payload.placeOfBirth,
            citizenship: action.payload.citizenship,
            dateBirth: action.payload.dateBirth,
            gender: action.payload.gender,
            maritalStatus: action.payload.maritalStatus,
            partnerName: action.payload.partnerName,
            cnpj: action.payload.cnpj,
            clinicPhone: action.payload.clinicPhone,
            secretaryName: action.payload.secretaryName,
            secretaryEmail: action.payload.secretaryEmail
          }
        }
      }
    }

    case UserStates.GET_USER_LOGGED_IN_SUCCESS:
      return {
        ...state,
        user: {
          personalData: action.payload
        }
      }

    case UserStates.RESET_PASSWORD_SUCCESS:
      return {
        ...initialState
      }

    case UserStates.GET_LAYER7_TOKEN_SUCCESS:
      return {
        ...state,
        auth: {
          ...state.auth,
          layerSevenToken: action.payload
        }
      }

    case UserStates.GET_LAYER7_TOKEN_ERROR:
      return {
        ...state,
        auth: {
          ...state.auth,
          layerSevenToken: undefined
        }
      }

    case UserStates.INCREMENT_COUNTER_TOKEN_RETRIALS:
      return {
        ...state,
        auth: {
          ...state.auth,
          tokenRetrialsCounter:
            state.auth && state.auth.tokenRetrialsCounter
              ? state.auth.tokenRetrialsCounter + 1
              : 1
        }
      }

    case UserStates.SET_COUNTER_TOKEN_RETRIALS:
      return {
        ...state,
        auth: {
          ...state.auth,
          tokenRetrialsCounter: Number(action.payload)
        }
      }

    default:
      return state
  }
}

export default userReducer
