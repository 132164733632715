import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as S from './styles'
import * as Chakra from '@chakra-ui/react'
import { Link, useHistory } from 'react-router-dom'
import { onSubmitProps } from '../../interface'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginSchema } from 'src/utils/masks-validations/password-validation'
import { signIn, signOut } from 'src/store/login/actions'
import ROUTING_PATHS from 'src/routes/paths'
import CustomTextField from 'src/components/text-field/index'
import PasswordField from 'src/components/password-field'
import Button from 'src/components/button/index'
import { VerifyForgotPasswordFlag } from 'src/components/flags/forgot-password'

const LoginForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginSchema)
  })

  const [rememberMe, setRememberMe] = useState<boolean>(false)
  const [cpf, setCpf] = useState<string>('')

  useEffect(() => {
    dispatch(signOut())
  }, [dispatch])

  const onSubmit = (data: onSubmitProps) => {
    const { cpf, password } = data
    dispatch(signIn({ cpf, password }, history))
  }

  const onChangeCpf = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    const sanitizedCpf = value.replaceAll('.', '').replaceAll('-', '')
    setCpf(sanitizedCpf)
    setValue('cpf', sanitizedCpf)
  }

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue('password', value)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Chakra.Grid>
        <Chakra.FormControl
          isInvalid={!!errors?.cpf}
          errortext={errors?.cpf?.message}
          isRequired
        >
          <Chakra.InputGroup style={{ display: 'block' }}>
            <CustomTextField
              type="text"
              register={register('cpf')}
              variant="flushed"
              placeholder="Digite seu cpf"
              value={cpf}
              onChange={onChangeCpf}
            />
            <Chakra.FormErrorMessage>
              {errors.cpf?.message}
            </Chakra.FormErrorMessage>
          </Chakra.InputGroup>
        </Chakra.FormControl>
      </Chakra.Grid>
      <S.Grid height="35px" />
      <Chakra.Grid>
        <PasswordField
          register={register('password')}
          variant="flushed"
          placeholder="Digite sua senha"
          onChange={onChangePassword}
        />
      </Chakra.Grid>
      <Chakra.Grid display="none">
        <Chakra.Checkbox
          mt="2vh"
          isChecked={rememberMe}
          onChange={(e: any) => setRememberMe(e.target.checked)}
        >
          Lembrar-me
        </Chakra.Checkbox>
      </Chakra.Grid>
      <S.Grid height="15px" />
      <VerifyForgotPasswordFlag>
        <Chakra.Grid>
          <S.ForgotPassword to={ROUTING_PATHS.Forgot_Password}>
            Esqueci minha senha
          </S.ForgotPassword>
        </Chakra.Grid>
      </VerifyForgotPasswordFlag>
      <S.Grid height="35px" />
      <Chakra.Grid>
        <Button variant="normal" type="submit">
          Login
        </Button>
      </Chakra.Grid>
      <S.Grid height="16px" />
      <Chakra.Grid>
        <Link to={ROUTING_PATHS.SignUp_CreateAccount}>
          <Button variant="normal">Primeiro acesso</Button>
        </Link>
      </Chakra.Grid>
    </form>
  )
}

export default LoginForm
