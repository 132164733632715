import { UserStates } from '../login/types'
import Service from 'src/services/layer-seven'
import { incrementCounterTokenRetrials } from '../login/actions'
import { ApplicationState } from 'src/store/rootReducer'
import { MAXIMUM_TOKEN_RETRIALS, MSG_ERROR_TOKEN_RETRIALS } from 'src/constants'
import { signOut } from 'src/store/login/actions'
import { showErrorToast } from 'src/utils/toast'

export const getLayerSevenToken =
  (onSuccessCallbackDispatch: any = null) =>
  async (dispatch: any) => {
    const token = await getToken()

    if (token && token.length > 0) {
      dispatch(getLayerSevenTokenSuccess(token))
      if (onSuccessCallbackDispatch) onSuccessCallbackDispatch(token)
    } else {
      console.error('Error in the action to get Layer7 token')
    }
  }

export const getLayerSevenTokenSuccess = (token: any) => ({
  payload: token,
  type: UserStates.GET_LAYER7_TOKEN_SUCCESS
})

export const getLayerSevenTokenError = (user: any) => ({
  payload: user,
  type: UserStates.GET_LAYER7_TOKEN_ERROR
})

export const handleLayerSevenRequestError =
  (error: any, tryAgainActionCallback: any = null) =>
  (dispatch: any, getState: () => ApplicationState) => {
    const auth = getState().userReducer.auth

    if (auth && auth.tokenRetrialsCounter !== undefined) {
      if (auth.tokenRetrialsCounter >= MAXIMUM_TOKEN_RETRIALS) {
        showErrorToast('Erro', MSG_ERROR_TOKEN_RETRIALS)
        dispatch(signOut())
        return
      }
    }

    if (error.request && error.request.status) {
      if (error.request.status === 401) {
        dispatch(incrementCounterTokenRetrials())
        dispatch(getLayerSevenToken(tryAgainActionCallback))
      }
    }
  }

export const getToken = async () => {
  try {
    const res = await Service().getLayerSevenToken()
    return res.data.access_Token
  } catch (error: any) {
    return ''
  }
}
