import * as S from './styles'
import { statusDescription } from 'src/pages/solicitation/components/table'

const TableDetails = ({ solicitationDetails }: any) => {
  return (
    <S.RequestContainer>
      {/* <Chakra.Flex alignItems="flex-start">
        <S.RoundIcon>
          <BsCircleFill />
        </S.RoundIcon>
        <Chakra.Text fontSize="14px" color={THEME_GREY} mb={4}>
          Análise * - Solicitação em análise em outras duas unidades: Hospital
          Samaritano e Hospital Maternidade Metropolitano. Para alterar os
          hospitais da análise clique em adicionar ou remover hospitais.
        </Chakra.Text>
      </Chakra.Flex> */}
      <S.TableContainer>
        <S.Table>
          <S.THead>
            <tr>
              <S.Th>NOME DO SOLICITANTE</S.Th>
              <S.Th>CONSELHO</S.Th>
              <S.Th>DATA SUBMISSÃO</S.Th>
              <S.Th>HOSPITAL</S.Th>
              <S.Th>STATUS</S.Th>
            </tr>
          </S.THead>
          <S.TBody>
            <S.Tr>
              <S.Td>{solicitationDetails.applicantName}</S.Td>
              <S.Td>
                {`${solicitationDetails.councilType} ${solicitationDetails.councilNumber}-${solicitationDetails.councilState}`}
              </S.Td>
              <S.Td>{solicitationDetails.submissionDate}</S.Td>
              {solicitationDetails.hospitals &&
              solicitationDetails.hospitals.length > 1 ? (
                <>
                  <S.TdHospital>
                    <S.TooltipCard
                      label={solicitationDetails.hospitalsStatus.map(
                        (hospital: any) => (
                          <span key={hospital}>
                            <S.HospitalName> {hospital.name} </S.HospitalName>
                            <S.HospitalStatus status={hospital.status}>
                              {hospital.status}
                            </S.HospitalStatus>
                          </span>
                        )
                      )}
                    >
                      <span>
                        <S.HospitalIcon
                          hospitals={solicitationDetails.hospitals.length}
                        />
                      </span>
                    </S.TooltipCard>

                    <S.Hospital>{solicitationDetails.hospitals[0]}</S.Hospital>
                  </S.TdHospital>
                </>
              ) : (
                <S.Td>{solicitationDetails.hospitals}</S.Td>
              )}
              <S.Td>
                <S.StatusBox status={solicitationDetails.status}>
                  <S.StatusText alignItems="center">
                    {solicitationDetails.status}
                  </S.StatusText>
                  <S.TooltipCard
                    label={statusDescription(solicitationDetails.status)}
                  >
                    <span>
                      <S.StatusIcon status={solicitationDetails.status} />
                    </span>
                  </S.TooltipCard>
                </S.StatusBox>
              </S.Td>
            </S.Tr>
          </S.TBody>
        </S.Table>
      </S.TableContainer>
    </S.RequestContainer>
  )
}

export default TableDetails
