export const cnpjMask = (value: string) => {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const validateCnpj = (cnpj: string): boolean => {
  if (!cnpj) return false
  cnpj = cnpj.replace(/[^\d]+/g, '')
  if (cnpj == '') return false
  if (cnpj.length != 14) return false
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false
  let cnpjLenght = cnpj.length - 2
  let cnpjNumbers = cnpj.substring(0, cnpjLenght)
  const digits = cnpj.substring(cnpjLenght)
  let sum = 0
  let after = cnpjLenght - 7
  for (let i = cnpjLenght; i >= 1; i--) {
    sum += parseInt(cnpjNumbers.charAt(cnpjLenght - i)) * after--
    if (after < 2) after = 9
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (result !== parseInt(digits.charAt(0))) return false
  cnpjLenght = cnpjLenght + 1
  cnpjNumbers = cnpj.substring(0, cnpjLenght)
  sum = 0
  after = cnpjLenght - 7
  for (let i = cnpjLenght; i >= 1; i--) {
    sum += parseInt(cnpjNumbers.charAt(cnpjLenght - i)) * after--
    if (after < 2) after = 9
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (result !== parseInt(digits.charAt(1))) return false
  return true
}
