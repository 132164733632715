const CheckIcon = ({ size, color }: any) => (
  <svg
    id="Check"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 42 42"
  >
    <g
      id="Group_6547"
      data-name="Group 6547"
      transform="translate(-382.104 -923.436)"
    >
      <path
        id="Path_3039"
        data-name="Path 3039"
        d="M29.362,68.45a1.452,1.452,0,0,0-2.107,0L9.4,86.974l-6.861-7.12a1.452,1.452,0,0,0-2.107,0,1.587,1.587,0,0,0,0,2.186l7.915,8.213a1.452,1.452,0,0,0,2.107,0l18.9-19.616A1.588,1.588,0,0,0,29.362,68.45Z"
        transform="translate(388.104 864.438)"
        fill={color}
        stroke={color}
        strokeWidth="1"
      />
    </g>
    <rect
      id="Rectangle_3561"
      data-name="Rectangle 3561"
      width={size}
      height={size}
      fill="none"
    />
  </svg>
)

export default CheckIcon
