import * as S from './styles'
import { ProgressBarProps } from './interface'

const ProgressBar = ({ colorScheme, value, bgColor }: ProgressBarProps) => {
  return (
    <S.ProgressBar
      bgColor={bgColor}
      colorScheme={colorScheme}
      value={value}
      data-testid="progressBar"
    />
  )
}

export default ProgressBar
