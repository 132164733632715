import { hideSpinner, showSpinner } from '../../store/spinner/actions'
import Service from '../../services/user'
import { setUserFail } from '../login/actions'
import { updateRegistrationStep } from '../registration-step/actions'
import { UserStates } from '../login/types'
import { RegistrationStep } from '../registration-step/types'
import { handleLayerSevenRequestError } from '../layer-seven/actions'
import { PersonalInfoTypes } from './types'
import { AxiosResponse } from 'axios'

export const savePersonalInfoSuccess = (data: any) => ({
  payload: data,
  type: UserStates.SAVE_PERSONAL_INFO
})

export const getPersonalInfoSuccess = (data: any) => ({
  payload: data,
  type: PersonalInfoTypes.GET_PERSONAL_INFO_SUCCESS
})

export const getPersonalInfoError = (error: any) => ({
  payload: error,
  type: PersonalInfoTypes.GET_PERSONAL_INFO_ERROR
})

export const getPersonalInfo =
  (cpf: string, token: string) => (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .getPersonalInfo(cpf, token)
      .then((res: AxiosResponse) => {
        dispatch(getPersonalInfoSuccess(res.data))
      })
      .catch((error: any) => {
        if (error.request && error.request.response)
          dispatch(
            getPersonalInfoError(JSON.parse(error.request.response).errors)
          )
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const savePersonalInfo =
  (data: any, token: string, onSuccessCallback: any = null) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .savePersonalInfo(data, token)
      .then(() => {
        dispatch(savePersonalInfoSuccess(data))
        dispatch(
          updateRegistrationStep(data.cpf, RegistrationStep.PersonalInfo, token)
        )
        if (onSuccessCallback) onSuccessCallback()
      })
      .catch((error) => {
        if (error.request && error.request.response)
          dispatch(setUserFail(JSON.parse(error.request.response).errors))
        dispatch(hideSpinner())
        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(savePersonalInfo(data, newToken, onSuccessCallback))
          )
        )
      })
      .finally(() => dispatch(hideSpinner()))
  }
