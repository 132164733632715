import { Thead, Flex, Center } from '@chakra-ui/react'
import {
  ACCESSIBILITY_ERROR,
  ACCESSIBILITY_SUCCESS,
  THEME_PURPLE
} from 'src/styles/colors'
import styled from 'styled-components'
import { CSVLink } from 'react-csv'

export const TableHead = styled(Thead)`
  background-color: ${ACCESSIBILITY_ERROR};
  tr,
  th {
    color: white !important;
  }import { THEME_PURPLE } from './../../../../styles/colors';

`

export const SectionTitle = styled(Flex)`
  font-weight: 800;
  margin-top: 12px;
  margin-bottom: 12px;
`

export const ButtonDownloadCsv = styled(CSVLink)`
  background-color: white;
  border-radius: var(--chakra-radii-md);
  color: ${THEME_PURPLE};
  border: 1px px solid ${THEME_PURPLE};
  padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--chakra-fontWeights-semibold);
  font-size: 20px;
  font-family: inherit;
  margin-right: 16px;
  border: 1px solid ${THEME_PURPLE};
`

export const SuccessContainer = styled(Center)`
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  color: ${ACCESSIBILITY_SUCCESS};
`
