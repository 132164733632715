const productionPath = 'oneTrust_production/scripttemplates/otSDKStub.js'
const testPath = 'oneTrust_test/scripttemplates/otSDKStub.js'
const dataDomainScript = 'e75c9951-1eef-4263-a2b2-89f9ce64d4fd'

export const setPrivacyCookieByEnv = (env: string) => {
  const enviroment = env.toLowerCase()
  let path
  let dataDomain = dataDomainScript
  if (
    enviroment === 'dev' ||
    enviroment === 'develop' ||
    enviroment === 'development'
  ) {
    path = testPath
    dataDomain += '-test'
  } else if (
    enviroment === 'qa' ||
    enviroment === 'test' ||
    enviroment === 'homolog'
  ) {
    path = testPath
    dataDomain += '-test'
  } else {
    //Prod
    path = productionPath
  }

  const referenceElement = document.getElementById('onetrust')
  if (referenceElement) {
    const scriptElement = document.createElement('script')
    scriptElement.setAttribute('src', path)
    scriptElement.setAttribute('type', 'text/javascript')
    scriptElement.setAttribute('charset', 'UTF-8')
    scriptElement.setAttribute('data-domain-script', dataDomain)

    const optanonFunctionScriptElement = document.createElement('script')
    optanonFunctionScriptElement.setAttribute('type', 'text/javascript')
    optanonFunctionScriptElement.text = 'function OptanonWrapper() { }'

    referenceElement.parentElement?.insertBefore(
      scriptElement,
      referenceElement
    )

    referenceElement.parentElement?.insertBefore(
      optanonFunctionScriptElement,
      referenceElement
    )

    referenceElement.remove()
  }
}
