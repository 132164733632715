const AddIcon = () => (
  <svg
    id="Adicionar"
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g
      id="Group_6540"
      data-name="Group 6540"
      transform="translate(-665.746 -829.313)"
    >
      <path
        id="Path_175"
        data-name="Path 175"
        d="M16.3,1.4A14.9,14.9,0,1,0,31.2,16.3,14.916,14.916,0,0,0,16.3,1.4Z"
        transform="translate(670.343 833.91)"
        fill="transparent"
        stroke="#fff"
        strokeWidth="2"
      />
      <path
        id="Path_176"
        data-name="Path 176"
        d="M140.916,242.67H128.861c-.475,0-.861-.6-.861-1.335s.386-1.335.861-1.335h12.055c.475,0,.861.6.861,1.335S141.391,242.67,140.916,242.67Z"
        transform="translate(551.757 608.878)"
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.5"
      />
      <path
        id="Path_177"
        data-name="Path 177"
        d="M241.335,141.777c-.737,0-1.335-.386-1.335-.861V128.861c0-.475.6-.861,1.335-.861s1.335.386,1.335.861v12.055C242.67,141.391,242.072,141.777,241.335,141.777Z"
        transform="translate(445.311 715.324)"
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.5"
      />
    </g>
    <rect
      id="Rectangle_3554"
      data-name="Rectangle 3554"
      width="42"
      height="42"
      fill="none"
    />
  </svg>
)

export default AddIcon
