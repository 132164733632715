import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { THEME_PURPLE, THEME_GREY, THEME_WHITE } from '../../styles/colors'
import {
  HomeBanner,
  HomeBanner2,
  HomeBanner3,
  HomeBannerMobile,
  HomeBannerMobile2,
  HomeBannerMobile3
} from 'src/assets/img'

const bgImgArray = [
  { image: HomeBanner },
  { image: HomeBanner2 },
  { image: HomeBanner3 }
]

const bgMobileImgArray = [
  { image: HomeBannerMobile },
  { image: HomeBannerMobile2 },
  { image: HomeBannerMobile3 }
]

const getRandomImg = () => {
  if (window.innerWidth > 450) {
    const index = Math.floor(Math.random() * (bgImgArray.length - 0))
    const newRandomImg = bgImgArray[index]
    return newRandomImg
  } else {
    const index = Math.floor(Math.random() * (bgMobileImgArray.length - 0))
    const newRandomImg = bgMobileImgArray[index]
    return newRandomImg
  }
}

const randomImg = getRandomImg()

export const Container = styled.div`
  background: url(${randomImg.image}) no-repeat center center;
`
export const ContainerFlex = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 5vw 0;

  @media (max-width: 800px) {
    align-items: center;
    flex-direction: column;
    padding: 10vw 0;
  }
  @media (min-width: 1440px) {
    width: 100%;
  }
  @media (min-width: 1920px) {
    width: 60%;
  }
`
export const TitleContainer = styled(Chakra.Container)`
  background-color: ${THEME_WHITE};
  opacity: 90%;
  border-radius: 14px;
  padding: 20px;
  width: 40%;
  max-width: 400px;
  text-align: right;
  height: 50%;
  align-self: end;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 80px;
  }
`
export const WelcomeTitle = styled.h1`
  color: ${THEME_PURPLE};
  font-family: SoletoBold;
  font-size: 26px;
  font-weight: bolder;
  margin-bottom: 24px;

  @media (max-width: 800px) {
    width: 100%;
    margin: 0;
    padding: 10px;
    text-align: center;
    font-size: 22px;
  }
`
export const WelcomeSubtitle = styled(Chakra.Text)`
  font-size: 16px;
  font-weight: 700;
  color: ${THEME_GREY};

  @media (max-width: 800px) {
    text-align: center;
  }
`

export const LoginContainer = styled(Chakra.Container)`
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 400px;
  background-color: ${THEME_WHITE};
  opacity: 90%;
  border-radius: 14px;
  padding: 40px;
`

export const LoginTitleText = styled(Chakra.Text)`
  text-align: left;
  font-weight: 600;
  font-size: 34px;
  color: ${THEME_PURPLE};
  margin-bottom: 47px;
`

export const LogoBar = styled.div`
  width: 100%;
  background: ${THEME_WHITE};
  padding: 24px 0;
`
export const LogoBarAlign = styled.div`
  width: 85%;
  margin: 0px auto;
  img {
    padding: 0 10px;
    max-width: 150px;
    filter: grayscale(100%);
    transition: 0.2s linear;
  }
  img:hover {
    filter: grayscale(0%);
  }
`

export const LogoTitle = styled(Chakra.Text)`
  text-align: left;
  font-weight: 600;
  font-size: 20px;
  color: ${THEME_GREY};
`
