import { StrictMode } from 'react'
import { render } from 'react-dom'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ChakraProvider } from '@chakra-ui/react'
import { CookiesProvider } from 'react-cookie'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { store, persistor } from 'src/store'
import theme from './theme'

const renderApp = () =>
  render(
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ChakraProvider theme={theme}>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </ChakraProvider>
        </PersistGate>
      </Provider>
    </StrictMode>,
    document.getElementById('root')
  )

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp)
}

renderApp()
reportWebVitals()
