import { ReactNode } from 'react'

interface ForgotPasswordFlagProps {
  children: ReactNode
}

export function VerifyForgotPasswordFlag({
  children
}: ForgotPasswordFlagProps) {
  return <>{window._flags_.FORGOT_PASSWORD_ENABLED ? children : null}</>
}
