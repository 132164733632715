import { useState } from 'react'
import CustomTextField from '../text-field'
import * as Chakra from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginSchema } from '../../utils/masks-validations/password-validation'
import { BiShow, BiHide } from 'react-icons/bi'
import { IPasswordFieldProps } from './interface'
import * as S from './style'
import { THEME_MEDIUM_GREY } from 'src/styles/colors'

const PasswordField = ({
  align,
  placeholder,
  register,
  variant,
  value,
  onChange,
  isInvalid,
  showRequirements,
  disabled,
  isRequired = false
}: IPasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const {
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginSchema)
  })
  return (
    <Chakra.VStack align={align || 'flex-start'}>
      <Chakra.FormControl
        isInvalid={!!errors?.password}
        errortext={errors?.password?.message}
        isRequired={isRequired}
      >
        <Chakra.InputGroup>
          <CustomTextField
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder}
            register={register}
            variant={variant}
            value={value}
            onChange={onChange}
            isInvalid={isInvalid}
            isDisabled={disabled}
            required={isRequired}
          />
          <Chakra.FormErrorMessage>
            {errors.password?.message}
          </Chakra.FormErrorMessage>

          <Chakra.InputRightElement>
            {!showPassword ? (
              <Chakra.IconButton
                mt="7px"
                mr="3px"
                variant="ghost"
                aria-label="Show password"
                onClick={() => {
                  setShowPassword(true)
                }}
              >
                <BiHide color={THEME_MEDIUM_GREY} size={24} />
              </Chakra.IconButton>
            ) : (
              <Chakra.IconButton
                mt="7px"
                mr="3px"
                variant="ghost"
                aria-label="Hide password"
                onClick={() => {
                  setShowPassword(false)
                }}
              >
                <BiShow color={THEME_MEDIUM_GREY} size={24} />
              </Chakra.IconButton>
            )}
          </Chakra.InputRightElement>
        </Chakra.InputGroup>
      </Chakra.FormControl>
      {showRequirements && (
        <S.PasswordRequirements align="flex-start">
          <S.Requeriments>* Mínimo 8 caracteres</S.Requeriments>
          <S.Requeriments>* Um caractere maiúsculo</S.Requeriments>
          <S.Requeriments>* Um caractere minúsculo</S.Requeriments>
          <S.Requeriments>* Um número</S.Requeriments>
          <S.Requeriments>* Um símbolo/caractere especial</S.Requeriments>
        </S.PasswordRequirements>
      )}
    </Chakra.VStack>
  )
}

export default PasswordField
