import * as Chakra from '@chakra-ui/react'
import * as S from './style'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'src/store/rootReducer'
import { useEffect, useState } from 'react'
import * as storage from '../../../utils/storage'
import { CPF_KEY_RESET_PASSWORD } from 'src/store/login/constants'
import ROUTING_PATHS from 'src/routes/paths'
import { Link } from 'react-router-dom'
import { signOut } from 'src/store/login/actions'
import { THEME_PURPLE, THEME_WHITE } from './../../../styles/colors'

const UserLoggedIn = () => {
  const dispatch = useDispatch()
  const userState = useSelector(
    (state: ApplicationState) => state.userReducer.user
  )

  const [userNameShown, setUserNameShown] = useState<string>('')

  const getFirstAndLastNames = (completeName: string) => {
    if (!completeName || completeName.length < 1) return `Desconhecido`
    const names = completeName.split(' ')
    if (names.length === 1) return names[0]
    return `${names[0]} ${names[names.length - 1]}`
  }

  useEffect(() => {
    if (userState && userState.personalData) {
      if (
        userState.personalData.socialName &&
        userState.personalData.socialName.length > 0
      ) {
        setUserNameShown(userState.personalData.socialName)
      } else if (
        userState.personalData.fullName &&
        userState.personalData.fullName.length > 0
      ) {
        const nameToBeShown = getFirstAndLastNames(
          userState.personalData.fullName
        )
        setUserNameShown(nameToBeShown)
      }
    }
  }, [userState])

  const saveCpfToLocalStorage = () => {
    if (userState && userState.personalData && userState.personalData.cpf) {
      storage.setLocalStorage(
        CPF_KEY_RESET_PASSWORD,
        userState.personalData.cpf
      )
    }
  }

  const signUserOut = () => {
    dispatch(signOut())
  }

  return (
    <>
      <Chakra.HStack spacing={5}>
        <S.Avatar
          name={userNameShown}
          bg={THEME_PURPLE}
          color={THEME_WHITE}
          // src='' //Implement when we have an user picture
        >
          {/* <Chakra.AvatarBadge
            bg={THEME_GREEN}
            boxSize="1.25em"
            borderWidth={0}
            color="white"
            marginBottom={9}
          >
            +
          </Chakra.AvatarBadge> */}
        </S.Avatar>
        <S.DataContainer spacing={0}>
          <Chakra.HStack>
            <S.UserText>Usuário:</S.UserText>
            <S.UserName>{userNameShown}</S.UserName>
          </Chakra.HStack>
          <S.SubText>
            <Link
              onClick={() => saveCpfToLocalStorage()}
              to={ROUTING_PATHS.Reset_Password}
            >
              Alterar senha
            </Link>
            {' | '}
            <Link onClick={() => signUserOut()} to={ROUTING_PATHS.Root}>
              Sair
            </Link>
          </S.SubText>
        </S.DataContainer>
      </Chakra.HStack>
    </>
  )
}

export default UserLoggedIn
