import * as Chakra from '@chakra-ui/react'
import InfoHeader from 'src/components/signup/info-header'
import { Link } from 'react-router-dom'
import * as I from 'src/assets/icons'
import * as S from './style'
import ROUTING_PATHS from 'src/routes/paths/index'
import { NUCLEO_ATENDIMENTO_AMERICAS_MED } from 'src/constants'
import Button from 'src/components/button/index'

const SignUpCompleteError = () => {
  return (
    <>
      <InfoHeader value={100} />
      <S.Container>
        <S.ContainerText>
          <S.IconWrapper>
            <I.AttentionIcon />
          </S.IconWrapper>
          <S.TitleText>Não foi possível concluir seu cadastro.</S.TitleText>
          <S.DescriptionText data-testid="errorDescription">
            Ocorreu uma indisponibilidade em nosso sistema e não foi possível
            concluir seu cadastro, porém seus dados foram salvos em nosso
            sistema. Por favor, tente entrar no portal mais tarde para terminar
            seu cadastro. Em caso de dúvidas, entre em contato com: <br />
            <a
              data-testid="site"
              target="blank"
              href={`${NUCLEO_ATENDIMENTO_AMERICAS_MED}`}
            >
              {'Núcleo de Atendimento Médico'}
            </a>
          </S.DescriptionText>
          <Chakra.GridItem w="150px" m="0 auto">
            <Link to={ROUTING_PATHS.SignUp_AdditionalInfo}>
              <Button variant="normal">Voltar</Button>
            </Link>
          </Chakra.GridItem>
        </S.ContainerText>
      </S.Container>
    </>
  )
}

export default SignUpCompleteError
