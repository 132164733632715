import {
  ACCESSIBILITY_ERROR,
  THEME_GREY,
  THEME_MEDIUM_GREY,
  THEME_PURPLE
} from 'src/styles/colors'
import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'

export const Input = styled(Chakra.Input)`
  border: ${(props) => (props.variant == 'flushed' ? '0px' : '1px solid')};
  border-bottom: 1px solid;
  border-color: ${(props) =>
    props.isInvalid ? ACCESSIBILITY_ERROR : THEME_GREY};
  border-radius: ${(props) => (props.variant == 'flushed' ? '0px' : '5px')};
  height: 46px;
  width: 100%;
  min-width: 0px;
  position: relative;
  padding: 0 10px;
  background-color: white;
  color: ${(props) => (props.value ? THEME_GREY : THEME_MEDIUM_GREY)};

  :hover {
    color: ${THEME_GREY};
  }

  &:focus {
    border: ${(props) => (props.variant == 'flushed' ? '0px' : '2px solid')};
    border-bottom: 2px solid;
    border-color: ${(props) =>
      props.isInvalid ? ACCESSIBILITY_ERROR : THEME_PURPLE};
    outline-style: none;
    box-shadow: none;
    background-color: white;
    color: ${THEME_GREY};
  }

  &:disabled {
    opacity: 0.4;
    border-color: ${THEME_GREY};
    cursor: not-allowed;
    color: ${THEME_GREY};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
`
