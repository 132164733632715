import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import * as Chakra from '@chakra-ui/react'

import * as I from 'src/assets/icons'
import * as S from './styles'
import InputFilter from 'src/components/filter'
import Button from 'src/components/button'
import List from './components/list'
import {
  fetchDocument,
  saveCompetencyMap,
  updateCompetencyMap
} from 'src/store/competency-map/actions'
import HeadingText from 'src/components/signup/heading-text'
import Container from 'src/components/container'
import DocumentsUpload from 'src/components/documents-upload'
import ROUTING_PATHS from 'src/routes/paths'
import { ApplicationState } from 'src/store/rootReducer'
import { updateRegistrationStep } from 'src/store/registration-step/actions'
import { RegistrationStep } from 'src/store/registration-step/types'
import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'
import { ScrollToTop } from 'src/utils'

const CompetencyMap = () => {
  const dispatch = useDispatch()
  const userState = useSelector((state: ApplicationState) => state.userReducer)
  const document = useSelector(
    (state: any) => state.competencyMapReducer.documentType
  )
  const documentsUploadState = useSelector(
    (state: ApplicationState) => state.documentsUploadReducer
  )
  const token = useSelector((state: ApplicationState) => state.userReducer.auth)
  const uploadDoc = useSelector(
    (state: ApplicationState) => state.documentsUploadReducer
  )

  const [values, setValues] = useState({ competencyFilter: '' })

  useEffect(() => {
    if (token && token.layerSevenToken) {
      dispatch(fetchDocument(token.layerSevenToken))
    }
  }, [dispatch])

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
  }

  const handleSubmit = () => {
    const codes: any[] = []
    documentsUploadState.uploadedFileCodes.forEach((file) => {
      if (file.code) codes.push(file.code)
    })
    if (documentsUploadState.data.files) {
      documentsUploadState.data.files.forEach((file: any) => {
        if (file.code) codes.push(file.code)
      })
    }

    if (
      userState.user &&
      userState.user.personalData &&
      userState.user.personalData.cpf &&
      token &&
      token.layerSevenToken
    ) {
      const cpf = userState.user.personalData.cpf
      const data = { cpf, codes }
      if (token && token.layerSevenToken) {
        if (documentsUploadState.data?.files?.length > 0) {
          dispatch(updateCompetencyMap(data, token.layerSevenToken))
          return
        }

        dispatch(saveCompetencyMap(data, token.layerSevenToken))
      }

      dispatch(
        updateRegistrationStep(
          userState.user?.personalData?.cpf,
          RegistrationStep.CompetencyMap,
          token.layerSevenToken
        )
      )
    }
  }

  const withDocument =
    uploadDoc.uploadedFileCodes.length > 0 || uploadDoc.data?.files?.length > 0
  return (
    <>
      <ScrollToTop />
      <Container value={60}>
        <Chakra.Grid gap={3}>
          <Chakra.GridItem colSpan={6}>
            <HeadingText
              icon={<I.CompetencyIcon stroke={THEME_PURPLE} />}
              title="Mapa de Competências Técnicas"
              subtitle="Por favor, escolha uma ficha de competências para preenchimento de
            acordo com sua especialidade."
            />
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={6}>
            <Chakra.Text fontSize="18px" color={THEME_GREY} fontWeight="bold">
              Ficha de competência clínica
            </Chakra.Text>
            <InputFilter
              name={'competencyFilter'}
              value={values.competencyFilter}
              placeholder={
                'Digite sua especialidade para encontrar seu mapa de competências'
              }
              onChange={inputChange}
            />
          </Chakra.GridItem>
        </Chakra.Grid>

        <List documents={document[0]} search={values.competencyFilter} />

        <S.Hr />

        <Chakra.Grid gap={3}>
          <Chakra.GridItem colSpan={6}>
            <DocumentsUpload
              acceptedMimeTypes="image/jpeg, image/jpg, image/png, application/pdf"
              subTitle="Após o Download e preenchimento da sua ficha(s) de competências faça o Upload do arquivo preenchido aqui."
              title="Upload do(s) Mapas de Competência"
              isUploadPage={false}
            />
          </Chakra.GridItem>
        </Chakra.Grid>

        <Chakra.Grid>
          <Chakra.GridItem>
            <S.Message p={4} color="black">
              A renovação das competências concedidas será avaliada anualmente
              pela diretoria médica do hospital de acordo com critérios
              previamente estabelecidos.
            </S.Message>
          </Chakra.GridItem>
        </Chakra.Grid>
        <Chakra.Grid gap={4}>
          <Chakra.GridItem colSpan={5}>
            <Chakra.Divider
              borderColor="secondary"
              marginTop="5vh"
              marginBottom="5vh"
            />
          </Chakra.GridItem>

          <Chakra.GridItem colStart={4}>
            <Link to={ROUTING_PATHS.SignUp_ProfessionalInfo}>
              <Button variant="outlined">Voltar</Button>
            </Link>
          </Chakra.GridItem>
          <Chakra.GridItem colStart={5} colSpan={1}>
            <Link to={ROUTING_PATHS.SignUp_UploadDocuments}>
              <Button
                disabled={!withDocument}
                variant="normal"
                type="button"
                onClick={handleSubmit}
              >
                Avançar
              </Button>
            </Link>
          </Chakra.GridItem>
        </Chakra.Grid>
      </Container>
    </>
  )
}

export default CompetencyMap
