import * as S from './styles'
import { ICustomTextFieldProps } from './interface'

const CustomTextField = (props: ICustomTextFieldProps) => {
  return (
    <S.Input
      required={props.required}
      type={props.type}
      variant={props.variant}
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      isInvalid={props.isInvalid}
      isDisabled={props.isDisabled}
      {...props.register}
      {...props}
    />
  )
}

export default CustomTextField
