import { useEffect } from 'react'
import * as Chakra from '@chakra-ui/react'
import InfoHeader from 'src/components/signup/info-header'
import { Link } from 'react-router-dom'
import * as I from 'src/assets/icons'
import { useDispatch } from 'react-redux'
import * as S from './style'
import { signOut } from 'src/store/login/actions'
import ROUTING_PATHS from 'src/routes/paths/index'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'src/store/rootReducer'
import Button from 'src/components/button/index'
import { updateRegistrationStep } from 'src/store/registration-step/actions'
import { RegistrationStep } from 'src/store/registration-step/types'
import { ACCESSIBILITY_SUCCESS } from 'src/styles/colors'

const SignUpCompleteSuccess = () => {
  const dispatch = useDispatch()

  const userState = useSelector((state: ApplicationState) => state.userReducer)

  const token = useSelector((state: ApplicationState) => state.userReducer.auth)

  useEffect(() => {
    if (
      userState.user &&
      userState.user.personalData &&
      userState.user.personalData.cpf &&
      userState.user.personalData.cpf.length > 0 &&
      token &&
      token.layerSevenToken
    ) {
      dispatch(
        updateRegistrationStep(
          userState.user.personalData.cpf,
          RegistrationStep.Completed,
          token.layerSevenToken
        )
      )
    }
    setTimeout(() => dispatch(signOut()), 10000)
  }, [])

  return (
    <>
      <InfoHeader value={100} />
      <S.Container>
        <S.ContainerText>
          <S.IconWrapper>
            <I.CheckIcon size={42} color={ACCESSIBILITY_SUCCESS} />
          </S.IconWrapper>
          <S.TitleText>Solicitação enviada!</S.TitleText>
          <S.DescriptionText data-testid="successDescription">
            Estou ciente que esta solicitação é a apresentação preliminar de
            documentos para cadastro ao corpo clínico da Rede Americas e que o
            cadastro dependerá dos critérios estabelecidos pelas comissões de
            cadastro de cada um dos Hospitais.
          </S.DescriptionText>
          <Chakra.GridItem w="180px" m="0 auto">
            <Link to={ROUTING_PATHS.Root}>
              <Button variant="normal">Ir para o login </Button>
            </Link>
          </Chakra.GridItem>
        </S.ContainerText>
      </S.Container>
    </>
  )
}

export default SignUpCompleteSuccess
