import { AxiosResponse } from 'axios'
import { authApi } from '../interceptors'

interface IApi {
  createAccount(data: any, token: string): Promise<AxiosResponse>
}

export default (): IApi => {
  return {
    createAccount: (data: any, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return authApi().post(`/Auth/register`, data, { headers })
    }
  }
}
