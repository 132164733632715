import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { THEME_GREEN, THEME_GREY, THEME_WHITE } from 'src/styles/colors'

export const Container = styled(Chakra.Container)`
  max-width: 90%;
  margin-bottom: 5vh;
`

export const FavoriteContainer = styled(Chakra.Container)`
  box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.3);
  width: 100%;
  padding: 12px;
  background-color: ${THEME_WHITE};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  margin-top: 25px;
  @media screen and (max-width: 750px) {
    margin: 0 0 25px 0;
    max-width: 100%;
  }
`
export const FavoriteContainerText = styled(Chakra.Text)`
  font-size: 12px;
  font-weight: bold;
  margin: 0 12px 0 2px;
`

// export const AlertList = styled(Chakra.Box)`
//   &::-webkit-scrollbar {
//     width: '4px';
//   }
//   &::-webkit-scrollbar-track {
//     width: '6px';
//   }
//   &::-webkit-scrollbar-thumb {
//     background: '#8ccef0';
//     border-radius: 24px;
//   }
// `

export const ButtonsContainer = styled(Chakra.Box)`
  display: flex;
  gap: 18px;
  justify-content: center;
`

export const CardButton = styled(Chakra.Box)`
  box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.3);
  padding: 20px 14px;
  background-color: ${THEME_WHITE};
  border-radius: 5px;
  width: 40%;
  cursor: pointer;

  &:active {
    border: 1.5px solid ${THEME_GREEN};
  }
`
export const CardTitle = styled(Chakra.Text)`
  color: ${THEME_GREY};
  font-size: 18px;
  font-weight: 600;
`
export const CardText = styled(Chakra.Text)`
  font-family: 'SoletoLight';
  color: ${THEME_GREY};
  font-size: 18px;
  padding: 16px;
`

export const ContainerTitle = styled(Chakra.Flex)`
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
`
