import { useEffect, useState } from 'react'
import * as Chakra from '@chakra-ui/react'
import {
  FormLabel,
  Grid,
  GridItem,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import Button from 'src/components/button'
import UploadChecklist from './checklist/index'
import * as I from 'src/assets/icons'
import { IUploadInformationRequest } from 'src/store/documents-upload/types'
import { LinkIcon } from '@chakra-ui/icons'
import DocumentsUpload from 'src/components/documents-upload'
import HeadingText from 'src/components/signup/heading-text'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'src/store/rootReducer'
import {
  saveUserPersonalDocuments,
  updateUserPersonalDocuments
} from 'src/store/documents-upload/actions'
import { useHistory } from 'react-router'
import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'
import CustomTextField from 'src/components/text-field'
import { showErrorToast } from 'src/utils/toast'
import ROUTING_PATHS from 'src/routes/paths'
import Container from 'src/components/container'
import { ScrollToTop } from 'src/utils'
import { getProfessionalInfo } from 'src/store/professional-information/actions'

const DocumentsUploadForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const profissionalInfoState = useSelector(
    (state: ApplicationState) => state.professionalInfoReducer
  )

  const [curriculum, setCurriculum] = useState<string>('')
  const [linkedin, setLinkedin] = useState<string>('')

  const documentsUploadState = useSelector(
    (state: ApplicationState) => state.documentsUploadReducer
  )
  const userState = useSelector((state: ApplicationState) => state.userReducer)

  const token = useSelector((state: ApplicationState) => state.userReducer.auth)

  useEffect(() => {
    if (
      userState.user &&
      userState.user.personalData &&
      userState.user.personalData.cpf
    ) {
      if (token && token.layerSevenToken) {
        dispatch(
          getProfessionalInfo(
            userState.user.personalData.cpf,
            token.layerSevenToken
          )
        )
      }
    }
  }, [dispatch, userState.user])

  const submitData = () => {
    const fileCodes: any[] = []
    documentsUploadState.uploadedFileCodes.forEach((file) => {
      if (file.code) fileCodes.push(file.code)
    })
    if (documentsUploadState.data.files) {
      documentsUploadState.data.files.forEach((file: any) => {
        if (file.code) fileCodes.push(file.code)
      })
    }

    if (
      userState.user &&
      userState.user.personalData &&
      userState.user.personalData.cpf
    ) {
      const data: IUploadInformationRequest = {
        requiredChecklist:
          documentsUploadState.uploadInformationData.requiredChecklist,
        optionalChecklist:
          documentsUploadState.uploadInformationData.optionalChecklist,
        fileCodes,
        curriculum,
        linkedin,
        cpf: userState.user.personalData.cpf
      }
      if (token && token.layerSevenToken) {
        if (documentsUploadState.data?.files?.length > 0) {
          dispatch(
            updateUserPersonalDocuments(data, history, token.layerSevenToken)
          )
          return
        }

        dispatch(
          saveUserPersonalDocuments(data, history, token.layerSevenToken)
        )
      }
    }
  }

  const validateDocumentsAndSubmit = () => {
    const { uploadInformationData, uploadedFileCodes } = documentsUploadState

    const required: number[] = [0, 1, 2, 5, 6, 8]

    if (
      profissionalInfoState.professionalData?.accreditationApplication
        ?.memberTeamHospitals === 'true'
    ) {
      required.push(7)
    }
    const requiredChecks = required.every((check) =>
      uploadInformationData.requiredChecklist.includes(check)
    )
    const fileCodesLength = uploadedFileCodes.length
    const informationDataLength = uploadInformationData.requiredChecklist.length

    if (
      requiredChecks &&
      fileCodesLength >= 6 &&
      (informationDataLength === fileCodesLength ||
        fileCodesLength > informationDataLength)
    ) {
      submitData()
    } else if (
      profissionalInfoState.professionalData?.accreditationApplication
        ?.memberTeamHospitals === 'true'
    ) {
      showErrorToast(
        'Documentos obrigatórios!',
        'Os seguintes documentos são obrigatórios:    Registro profissional (CRM, CRO, CREFITO, CRP, CREF, CFN)  - Diploma médico (frente e verso do documento)  - Certidão ético-profissional atualizada  - Carteira de vacinação ou termo de recusa  - Carta de inclusão em equipe, termos de uso dos hospitais Americas e Foto de crachá.  Revise as informações e tente novamente.'
      )
    } else if (
      profissionalInfoState.professionalData?.accreditationApplication
        ?.memberTeamHospitals === 'false'
    ) {
      showErrorToast(
        'Documentos obrigatórios!',
        'Os seguintes documentos são obrigatórios:    Registro profissional (CRM, CRO, CREFITO, CRP, CREF, CFN)  - Diploma médico (frente e verso do documento)  - Certidão ético-profissional atualizada,  Carteira de vacinação ou termo de recusa, termos de uso dos hospitais Americas e Foto de crachá.  Revise as informações e tente novamente.'
      )
    } else {
      showErrorToast(
        'Documentos obrigatórios!',
        'Os seguintes documentos são obrigatórios:    Registro profissional (CRM, CRO, CREFITO, CRP, CREF, CFN)  - Diploma médico (frente e verso do documento)  - Certidão ético-profissional atualizada,  Carteira de vacinação ou termo de recusa, termos de uso dos hospitais Americas e Foto de crachá.  Revise as informações e tente novamente.'
      )
    }
  }

  return (
    <>
      <ScrollToTop />
      <Container value={80}>
        <Grid templateColumns="repeat(6, 1fr)">
          <GridItem colSpan={6}>
            <HeadingText
              icon={<I.PersonalInfosIcon fill={THEME_PURPLE} />}
              title="Envio de Documentos"
              subtitle="Confirme abaixo quais documentos você está enviando."
            />
          </GridItem>
        </Grid>

        <UploadChecklist />

        <Grid templateColumns="repeat(6, 1fr)" gap={5}>
          <GridItem colSpan={3}>
            <FormLabel>Link do currículo lattes (opcional)</FormLabel>
            <InputGroup>
              <CustomTextField
                type="link"
                name="lattes"
                data-testid="lattes-input"
                value={curriculum}
                onChange={(event: any) => setCurriculum(event.target.value)}
              />
              <InputRightElement>
                <LinkIcon color={THEME_GREY} />
              </InputRightElement>
            </InputGroup>
          </GridItem>
          <GridItem colSpan={3}>
            <FormLabel>Perfil do Linkedin (opcional)</FormLabel>
            <InputGroup>
              <CustomTextField
                type="link"
                name="linkedin"
                data-testid="linkedin-input"
                value={linkedin}
                maxLength={100}
                onChange={(event: any) => setLinkedin(event.target.value)}
              />
              <InputRightElement>
                <LinkIcon color={THEME_GREY} />
              </InputRightElement>
            </InputGroup>
          </GridItem>
        </Grid>

        <DocumentsUpload
          acceptedMimeTypes="image/jpeg, image/jpg, image/png, application/pdf"
          subTitle="Adicione os arquivos que você confirmou acima para envio."
          title="Upload de arquivos"
          isUploadPage={true}
        />

        <Chakra.GridItem colSpan={6}>
          <Chakra.Divider
            borderColor="secondary"
            marginTop="5vh"
            marginBottom="5vh"
          />
        </Chakra.GridItem>
        <Grid gap={4}>
          <Chakra.GridItem colStart={4}>
            <Link to={ROUTING_PATHS.SignUp_CompetencyMap}>
              <Button variant="outlined">Voltar</Button>
            </Link>
          </Chakra.GridItem>
          <GridItem colStart={5} colSpan={1}>
            <Button variant="normal" onClick={validateDocumentsAndSubmit}>
              Avançar
            </Button>
          </GridItem>
        </Grid>
      </Container>
    </>
  )
}

export default DocumentsUploadForm
