export const enum PersonalInfoTypes {
  GET_PERSONAL_INFO_SUCCESS = '@personal-info/GET_PERSONAL_INFO',
  GET_PERSONAL_INFO_ERROR = '@personal-info/GET_PERSONAL_INFO_ERROR'
}

export interface IPersonalData {
  rg?: string
  rgExpeditor?: string
  emissionDate?: string
  placeOfBirth?: string
  citizenship?: string
  dateBirth?: string
  gender?: string
  cnpj?: string
  maritalStatus?: string
  partnerName?: string
  clinicPhone?: string
  secretaryName?: string
  secretaryEmail?: string
}

export interface IPersonalInfoStates {
  error?: any
  personalData?: IPersonalData
}
