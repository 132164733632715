import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  THEME_PURPLE,
  ACCESSIBILITY_ERROR,
  THEME_GREY
} from 'src/styles/colors'

export const Container = styled(Chakra.Container)`
  max-width: 90%;
`
export const ForgotPassword = styled(Link)`
  color: ${THEME_PURPLE};
  text-decoration: underline;
`

export const ErrorLabel = styled.p`
  color: ${(props) =>
    props.color === 'danger' ? ACCESSIBILITY_ERROR : THEME_GREY};
  font-weight: 600;
  font-size: 14px;
`

export const Recaptcha = styled(ReCAPTCHA)`
  display: flex;
  justify-content: center;
`
