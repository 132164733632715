import axios, { AxiosInstance, AxiosResponse } from 'axios'
import urls from '../../constants/api'

interface IApi {
  bff: AxiosInstance
  getLayerSevenToken: () => Promise<AxiosResponse<any>>
}

export default (): IApi => {
  const bff = axios.create({ baseURL: urls.bffUrl })

  return {
    bff,

    getLayerSevenToken: (): Promise<AxiosResponse<any>> => {
      return bff.post('/oauth/token')
    }
  }
}
