export const COUNTRIES = [
  'Brasil',
  'Afeganistão',
  'África do Sul',
  'Albânia',
  'Alemanha',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antilhas Holandesas',
  'Antárctida',
  'Antígua e Barbuda',
  'Argentina',
  'Argélia',
  'Armênia',
  'Aruba',
  'Arábia Saudita',
  'Austrália',
  'Áustria',
  'Azerbaijão',
  'Bahamas',
  'Bahrein',
  'Bangladesh',
  'Barbados',
  'Belize',
  'Benim',
  'Bermudas',
  'Bielorrússia',
  'Bolívia',
  'Botswana',
  'Brunei',
  'Bulgária',
  'Burkina Faso',
  'Burundi',
  'Butão',
  'Bélgica',
  'Bósnia e Herzegovina',
  'Cabo Verde',
  'Camarões',
  'Camboja',
  'Canadá',
  'Catar',
  'Cazaquistão',
  'Chade',
  'Chile',
  'China',
  'Chipre',
  'Colômbia',
  'Comores',
  'Coreia do Norte',
  'Coreia do Sul',
  'Costa do Marfim',
  'Costa Rica',
  'Croácia',
  'Cuba',
  'Dinamarca',
  'Djibouti',
  'Dominica',
  'Egito',
  'El Salvador',
  'Emirados Árabes Unidos',
  'Equador',
  'Eritreia',
  'Escócia',
  'Eslováquia',
  'Eslovênia',
  'Espanha',
  'Estados Federados da Micronésia',
  'Estados Unidos',
  'Estônia',
  'Etiópia',
  'Fiji',
  'Filipinas',
  'Finlândia',
  'França',
  'Gabão',
  'Gana',
  'Geórgia',
  'Gibraltar',
  'Granada',
  'Gronelândia',
  'Grécia',
  'Guadalupe',
  'Guam',
  'Guatemala',
  'Guernesei',
  'Guiana',
  'Guiana Francesa',
  'Guiné',
  'Guiné Equatorial',
  'Guiné-Bissau',
  'Gâmbia',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungria',
  'Ilha Bouvet',
  'Ilha de Man',
  'Ilha do Natal',
  'Ilha Heard e Ilhas McDonald',
  'Ilha Norfolk',
  'Ilhas Cayman',
  'Ilhas Cocos (Keeling)',
  'Ilhas Cook',
  'Ilhas Feroé',
  'Ilhas Geórgia do Sul e Sandwich do Sul',
  'Ilhas Malvinas',
  'Ilhas Marshall',
  'Ilhas Menores Distantes dos Estados Unidos',
  'Ilhas Salomão',
  'Ilhas Virgens Americanas',
  'Ilhas Virgens Britânicas',
  'Ilhas Åland',
  'Indonésia',
  'Inglaterra',
  'Índia',
  'Iraque',
  'Irlanda do Norte',
  'Irlanda',
  'Irã',
  'Islândia',
  'Israel',
  'Itália',
  'Iêmen',
  'Jamaica',
  'Japão',
  'Jersey',
  'Jordânia',
  'Kiribati',
  'Kuwait',
  'Laos',
  'Lesoto',
  'Letônia',
  'Libéria',
  'Liechtenstein',
  'Lituânia',
  'Luxemburgo',
  'Líbano',
  'Líbia',
  'Macau',
  'Macedônia',
  'Madagáscar',
  'Malawi',
  'Maldivas',
  'Mali',
  'Malta',
  'Malásia',
  'Marianas Setentrionais',
  'Marrocos',
  'Martinica',
  'Mauritânia',
  'Maurícia',
  'Mayotte',
  'Moldávia',
  'Mongólia',
  'Montenegro',
  'Montserrat',
  'Moçambique',
  'Myanmar',
  'México',
  'Mônaco',
  'Namíbia',
  'Nauru',
  'Nepal',
  'Nicarágua',
  'Nigéria',
  'Niue',
  'Noruega',
  'Nova Caledônia',
  'Nova Zelândia',
  'Níger',
  'Omã',
  'Palau',
  'Palestina',
  'Panamá',
  'Papua-Nova Guiné',
  'Paquistão',
  'Paraguai',
  'País de Gales',
  'Países Baixos',
  'Peru',
  'Pitcairn',
  'Polinésia Francesa',
  'Polônia',
  'Porto Rico',
  'Portugal',
  'Quirguistão',
  'Quênia',
  'Reino Unido',
  'República Centro-Africana',
  'República Checa',
  'República Democrática do Congo',
  'República do Congo',
  'República Dominicana',
  'Reunião',
  'Romênia',
  'Ruanda',
  'Rússia',
  'Saara Ocidental',
  'Saint Martin',
  'Saint-Barthélemy',
  'Saint-Pierre e Miquelon',
  'Samoa Americana',
  'Samoa',
  'Santa Helena, Ascensão e Tristão da Cunha',
  'Santa Lúcia',
  'Senegal',
  'Serra Leoa',
  'Seychelles',
  'Singapura',
  'Somália',
  'Sri Lanka',
  'Suazilândia',
  'Sudão',
  'Suriname',
  'Suécia',
  'Suíça',
  'Svalbard e Jan Mayen',
  'São Cristóvão e Nevis',
  'São Marino',
  'São Tomé e Príncipe',
  'São Vicente e Granadinas',
  'Sérvia',
  'Síria',
  'Tadjiquistão',
  'Tailândia',
  'Taiwan',
  'Tanzânia',
  'Terras Austrais e Antárticas Francesas',
  'Território Britânico do Oceano Índico',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Toquelau',
  'Trinidad e Tobago',
  'Tunísia',
  'Turcas e Caicos',
  'Turquemenistão',
  'Turquia',
  'Tuvalu',
  'Ucrânia',
  'Uganda',
  'Uruguai',
  'Uzbequistão',
  'Vanuatu',
  'Vaticano',
  'Venezuela',
  'Vietname',
  'Wallis e Futuna',
  'Zimbabwe',
  'Zâmbia'
]

export const STATES = [
  'Acre',
  'Alagoas',
  'Amapá',
  'Amazonas',
  'Bahia',
  'Ceará',
  'Distrito Federal',
  'Espírito Santo',
  'Goiás',
  'Maranhão',
  'Mato Grosso',
  'Mato Grosso do Sul',
  'Minas Gerais',
  'Pará',
  'Paraíba',
  'Paraná',
  'Pernambuco',
  'Piauí',
  'Rio de Janeiro',
  'Rio Grande do Norte',
  'Rio Grande do Sul',
  'Rondônia',
  'Roraima',
  'São Paulo',
  'Santa Catarina',
  'Sergipe',
  'Tocantins',
  'Estrangeiro'
]

export const SPECIALITIES = [
  'Acupuntura',
  'Alergia e Imunologia',
  'Anestesiologia',
  'Angiologia',
  'Cancerologia',
  'Cardiologia',
  'Cirurgia Bucomaxilofacial',
  'Cirurgia Cardiovascular',
  'Cirurgia da Mão',
  'Cirurgia de Cabeça e Pescoço',
  'Cirurgia do Aparelho Digestivo',
  'Cirurgia Geral',
  'Cirurgia Pediátrica',
  'Cirurgia Plástica',
  'Cirurgia Torácica',
  'Cirurgia Vascular',
  'Clínica Médica',
  'Coloproctologia',
  'Dentista',
  'Dermatologia',
  'Educação Física',
  'Endocrinologia e Metabologia',
  'Endoscopia',
  'Fisioterapia',
  'Gastroenterologia',
  'Genética Médica',
  'Geriatria',
  'Ginecologia e Obstetrícia',
  'Hematologia e Hemoterapia',
  'Homeopatia',
  'Infectologia',
  'Mastologia',
  'Medicina de Família e Comunidade',
  'Medicina do Trabalho',
  'Medicina de Tráfego',
  'Medicina Esportiva',
  'Medicina Física e Reabilitação',
  'Medicina Intensiva',
  'Medicina Legal e Perícia Médica',
  'Medicina Nuclear',
  'Medicina Preventiva e Social',
  'Médico Generalista',
  'Nefrologia',
  'Neurocirurgia',
  'Neurologia',
  'Nutrição',
  'Nutrologia',
  'Obstetra',
  'Oftalmologia',
  'Ortopedia e Traumatologia',
  'Otorrinolaringologia',
  'Patologia',
  'Patologia Clínica/Medicina Laboratorial',
  'Pediatria',
  'Pneumologia',
  'Psicologia',
  'Psiquiatria',
  'Radiologia e Diagnóstico por Imagem',
  'Radioterapia',
  'Reumatologia',
  'Terapia Ocupacional',
  'Urologia'
]

export const CRM_KINDS = ['CRM', 'CRO', 'CREFITO', 'CRP', 'CREF', 'CFN']

export const WORK_AREAS = [
  'Administração em Saúde',
  'Alergia e Imunologia Pediátrica',
  'Angiorradiologia e Cirurgia Endovascular',
  'Atendimento ao queimado',
  'Cardiologia Pediátrica',
  'Cirurgia Crânio-Maxilo-Facial',
  'Cirurgia do Trauma',
  'Cirurgia Videolaparoscópica',
  'Citopatologia',
  'Densitometria Óssea',
  'Dor',
  'Ecocardiografia',
  'Ecografia Vascular com Doppler',
  'Eletrofisiologia Clínica Invasiva',
  'Endocrinologia Pediátrica',
  'Endoscopia Digestiva',
  'Endoscopia Ginecológica',
  'Endoscopia Respiratória',
  'Ergometria',
  'Foniatria',
  'Gastroenterologia Pediátrica',
  'Hansenologia',
  'Hematologia e Hemoterapia Pediátrica',
  'Hemodinâmica e Cardiologia Intervencionista',
  'Hepatologia',
  'Infectologia Hospitalar',
  'Infectologia Pediátrica',
  'Mamografia',
  'Medicina de Urgência',
  'Medicina do Adolescente',
  'Medicina do Sono',
  'Medicina Fetal',
  'Medicina Intensiva Pediátrica',
  'Medicina Paliativa',
  'Medicina Tropical',
  'Nefrologia Pediátrica',
  'Neonatologia',
  'Neurofisiologia Clínica',
  'Neurologia Pediátrica',
  'Neurorradiologia',
  'Nutrição Parenteral e Enteral',
  'Nutrição Parenteral e Enteral Pediátrica',
  'Nutrologia Pediátrica',
  'Pneumologia Pediátrica',
  'Psicogeriatria',
  'Psicoterapia',
  'Psiquiatria da Infância e Adolescência',
  'Psiquiatria Forense',
  'Radiologia Intervencionista e Angiorradiologia',
  'Reumatologia Pediátrica',
  'Sexologia',
  'Transplante de Medula Óssea',
  'Ultrassonografia em Ginecologia e Obstetrícia'
]

export const SCHOOLING_LEVELS = [
  'Ensino Superior',
  'Mestrado',
  'Doutorado',
  'Pós-doutorado'
]

export enum Genders {
  MALE = 'Masculino',
  FEMALE = 'Feminino',
  NOT_INFORMED = 'Prefiro não informar'
}

export enum MaritalState {
  MARRIED = 'Casado(a)',
  SINGLE = 'Solteiro(a)',
  STABLE_UNION = 'União Estável',
  DIVORCED = 'Divorciado(a)',
  WIDOW = 'Viúvo(a)'
}

export enum Specilizations {
  CARDIO = 'Cardiologia',
  ENDOCRINO = 'Endocrinologia'
}

export const EMAIL_AMERICAS_MED = 'email@americasmed.com.br'
export const NUCLEO_ATENDIMENTO_AMERICAS_MED =
  'https://www.americasmed.com.br/area-medica/contato/nucleo-de-atendimento-medico'
export const ADULT_AGE = 18

export const FORMS_MIN_YEAR = 1900
export const TIMEOUT_AFTER_RESET_PASSWORD = 3000
export const UNKOWN_ERROR = 'Erro desconhecido'
export const ERROR = 'Erro'
export const MSG_SYSTEM_UNAVAILABLE =
  'Ops! Ocorreu alguma indisponibilidade momentânea, por favor tente novamente em instantes.'

export const ENVIRONMENT = window._env_.REACT_APP_ENVIRONMENT || 'Dev'

export enum MassRegistrationOrigin {
  EVENT = 1,
  RELATIONSHIP_TEAM = 2,
  INDICATION_DIRECTOR = 3
}

export const MAXIMUM_TOKEN_RETRIALS = 50
export const MSG_ERROR_TOKEN_RETRIALS = 'Erro inesperado. Faça login novamente.'
