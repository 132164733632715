import { THEME_GREY } from '../../styles/colors'

const CreateMassRegistrationIcon = () => (
  <svg
    id="Icone_Criar_acesso_médico_2"
    data-name="Icone / Criar acesso médico 2"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Retângulo_7766"
          data-name="Retângulo 7766"
          width="18.389"
          height="21.561"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Grupo_44410" data-name="Grupo 44410" transform="translate(12 10)">
      <path
        id="Caminho_35006"
        data-name="Caminho 35006"
        d="M14.627,18.594l-2.573,2.192L9.6,18.594"
        transform="translate(-3.012 -5.834)"
        fill="none"
        stroke={THEME_GREY}
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <g id="Grupo_44408" data-name="Grupo 44408" transform="translate(0 0)">
        <g id="Grupo_44407" data-name="Grupo 44407" clipPath="url(#clip-path)">
          <path
            id="Caminho_35007"
            data-name="Caminho 35007"
            d="M13.4,12h0a3.411,3.411,0,0,0,2.838-1.625A9.879,9.879,0,0,0,17.642,4.53,4.108,4.108,0,0,0,13.4.5"
            transform="translate(-4.203 -0.157)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Caminho_35008"
            data-name="Caminho 35008"
            d="M11.454,12h0a3.411,3.411,0,0,1-2.838-1.625A9.879,9.879,0,0,1,7.208,4.53,4.108,4.108,0,0,1,11.449.5"
            transform="translate(-2.262 -0.157)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Caminho_35009"
            data-name="Caminho 35009"
            d="M7.385,16.268c0,.137,0,.267-.008.391a2.443,2.443,0,0,1-1.922,2.2l-2.072.512A3.273,3.273,0,0,0,1.224,21.4a3.315,3.315,0,0,0-.176.775L.562,26.322h5.67"
            transform="translate(-0.176 -5.104)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Caminho_35010"
            data-name="Caminho 35010"
            d="M16.293,16.268c0,.137,0,.267.008.391a2.442,2.442,0,0,0,1.922,2.2l2.071.512A3.275,3.275,0,0,1,22.454,21.4a3.314,3.314,0,0,1,.176.775l.486,4.144H17.543"
            transform="translate(-5.112 -5.104)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Caminho_35011"
            data-name="Caminho 35011"
            d="M7.5,4.167a1.842,1.842,0,0,0,.913,1.08,4.042,4.042,0,0,0,1.622.33,2.828,2.828,0,0,0,2.46-1.369.344.344,0,0,0-.593-.347,2.128,2.128,0,0,1-1.867,1.029,3.49,3.49,0,0,1-1.289-.244,1.309,1.309,0,0,1-.653-.826A.345.345,0,0,0,7.619,3.7a.352.352,0,0,0-.123.469"
            transform="translate(-2.339 -1.146)"
            fill={THEME_GREY}
          />
          <path
            id="Caminho_35012"
            data-name="Caminho 35012"
            d="M13.249,3.606a1.686,1.686,0,0,0,.738.618,4.561,4.561,0,0,0,.981.266,4.647,4.647,0,0,0,2.077-.071.344.344,0,0,0-.183-.662,3.807,3.807,0,0,1-1.65.082,1.982,1.982,0,0,1-1.372-.579.352.352,0,0,0-.469-.123.346.346,0,0,0-.123.469"
            transform="translate(-4.141 -0.97)"
            fill={THEME_GREY}
          />
          <path
            id="Caminho_35013"
            data-name="Caminho 35013"
            d="M5.451,24.459c.113-1.323.53-4.264.53-4.264"
            transform="translate(-1.71 -6.337)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Caminho_35014"
            data-name="Caminho 35014"
            d="M19.654,26.1l-.04,0a.1.1,0,0,0-.11.089.1.1,0,0,0,.095.106l.109.008a.171.171,0,0,0,.17-.1c.138-.313.456-1.222-.362-1.493s-1.195.618-1.3.94a.15.15,0,0,0,.065.176l.09.058a.11.11,0,0,0,.145-.025.093.093,0,0,0-.024-.134l-.032-.022"
            transform="translate(-5.712 -7.736)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Caminho_35015"
            data-name="Caminho 35015"
            d="M20.8,20.336a9.114,9.114,0,0,1-.594,2.537,5.4,5.4,0,0,1-.281.581"
            transform="translate(-6.253 -6.381)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Caminho_35016"
            data-name="Caminho 35016"
            d="M5.7,26.625a.791.791,0,1,1-1.112-.132A.791.791,0,0,1,5.7,26.625Z"
            transform="translate(-1.347 -8.259)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <line
            id="Linha_1762"
            data-name="Linha 1762"
            y2="4.117"
            transform="translate(9.042 14.952)"
            fill="none"
            stroke={THEME_GREY}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </g>
    </g>
    <g
      id="Ellipse_54"
      data-name="Ellipse 54"
      fill="none"
      stroke={THEME_GREY}
      strokeWidth="0.5"
    >
      <circle cx="21" cy="21" r="21" stroke="none" />
      <circle cx="21" cy="21" r="20.75" fill="none" />
    </g>
  </svg>
)

export default CreateMassRegistrationIcon
