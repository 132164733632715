import styled from 'styled-components'
import { THEME_PURPLE } from 'src/styles/colors'

export const LoaderContainer = styled.div`
  margin: 60px 0;
  height: 40px;
  width: 40px;
  background: transparent;
  border: solid 6px transparent;
  border-bottom-color: ${THEME_PURPLE};
  border-radius: 50%;
  animation: 1s linear infinite spinner;
`
