import styled from 'styled-components'
import { Text } from '@chakra-ui/layout'
import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TitleText = styled(Text)`
  text-align: center;
  font-size: 36px;
  color: ${THEME_PURPLE};
  font-family: SoletoBold;
  margin-bottom: 32px;
`
export const SubTitle = styled(Text)`
  font-size: 18px;
  color: ${THEME_GREY};
  font-family: SoletoRegular;
  text-align: center;
`
