import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Chakra from '@chakra-ui/react'
import * as ChakraIcons from '@chakra-ui/icons'
import {
  ACCESSIBILITY_ERROR,
  ACCESSIBILITY_SUCCESS,
  THEME_GREY
} from 'src/styles/colors'
import { FileStatusType, IFileStatusProps } from './interfaces'
import {
  saveUploadedFileCode,
  deleteUploadedFileCode,
  removeOnlyFileStatus,
  deleteSolicitationFileInUploadComponent
} from 'src/store/documents-upload/actions'
import Service from 'src/services/documents-upload'
import * as S from './style'
import {
  MAX_FILE_SIZE_MB,
  DEFAULT_SUCCESS_TEXT,
  DEFAULT_ERROR_TEXT,
  MAX_SIZE_ERROR_TEXT,
  MAX_FILE_NAME_LENGTH_ON_STATUS,
  EXTENSION_ERROR_TEXT
} from '../constants'
import { ApplicationState } from 'src/store/rootReducer'
import { useParams } from 'react-router-dom'

const FileStatus = ({
  fileStatusKey,
  file,
  fileDetails,
  cpf,
  acceptedExtensions,
  isModalReject,
  isUploadSolicitation
}: IFileStatusProps) => {
  const dispatch = useDispatch()
  const { id, technicalEvaluationId } = useParams<any>()

  const documentsUploadState = useSelector(
    (state: ApplicationState) => state.documentsUploadReducer
  )

  const token = useSelector((state: ApplicationState) => state.userReducer.auth)

  const [showStatus] = useState<boolean>(true)
  const [currentStatus, setCurrentStatus] = useState<FileStatusType>(
    FileStatusType.LOADING
  )
  const [successText, setSuccessText] = useState<string>(DEFAULT_SUCCESS_TEXT)
  const [errorText, setErrorText] = useState<string>(DEFAULT_ERROR_TEXT)

  useEffect(() => {
    if (file.type) {
      if (!isFileSizeAllowed(file)) {
        setErrorState(MAX_SIZE_ERROR_TEXT)
        return
      }

      if (!isFileExtensionAllowed(file)) {
        setErrorState(
          EXTENSION_ERROR_TEXT +
            acceptedExtensions.replaceAll(',.', ', ').replaceAll('.', '')
        )
        return
      }

      const alreaddyAddedFile = isFileAlreadyAdded()
      if (alreaddyAddedFile) {
        if (alreaddyAddedFile.code) setSuccessState(DEFAULT_SUCCESS_TEXT)
        else setErrorState(DEFAULT_ERROR_TEXT)
        return
      }

      uploadFile()
    } else {
      setCurrentStatus(FileStatusType.SUCCESS)
      dispatch(
        saveUploadedFileCode({
          code: file.code,
          key: fileStatusKey
        })
      )
    }
  }, [])

  const uploadFile = () => {
    setCurrentStatus(FileStatusType.LOADING)

    if (token && token.layerSevenToken && isModalReject) {
      Service()
        .uploadFileToStorageReject(fileDetails, token.layerSevenToken)
        .then((res) => {
          dispatch(
            saveUploadedFileCode({
              code: res.data.code,
              key: fileStatusKey
            })
          )
          setSuccessState(DEFAULT_SUCCESS_TEXT)
        })
        .catch(() => {
          dispatch(
            saveUploadedFileCode({
              key: fileStatusKey
            })
          )
          setErrorState(DEFAULT_ERROR_TEXT)
        })
    }

    if (token && token.layerSevenToken && isUploadSolicitation) {
      Service()
        .uploadFileToSolicitation(
          fileDetails,
          token.layerSevenToken,
          id,
          technicalEvaluationId
        )
        .then((res) => {
          dispatch(
            saveUploadedFileCode({
              code: res.data.code,
              key: fileStatusKey
            })
          )
          setSuccessState(DEFAULT_SUCCESS_TEXT)
        })
        .catch(() => {
          dispatch(
            saveUploadedFileCode({
              key: fileStatusKey
            })
          )
          setErrorState(DEFAULT_ERROR_TEXT)
        })
    }

    if (
      token &&
      token.layerSevenToken &&
      !isModalReject &&
      !isUploadSolicitation
    ) {
      Service()
        .uploadFileToStorage(fileDetails, token.layerSevenToken)
        .then((res) => {
          dispatch(
            saveUploadedFileCode({
              code: res.data.code,
              key: fileStatusKey
            })
          )
          setSuccessState(DEFAULT_SUCCESS_TEXT)
        })
        .catch(() => {
          dispatch(
            saveUploadedFileCode({
              key: fileStatusKey
            })
          )
          setErrorState(DEFAULT_ERROR_TEXT)
        })
    }
  }

  const isFileAlreadyAdded = () => {
    return documentsUploadState.uploadedFileCodes.find(
      (fileStatus) => fileStatus.key === fileStatusKey
    )
  }

  const isFileSizeAllowed = (fileToUpload: File) => {
    // File size in MB
    return fileToUpload.size / 1024 / 1024 <= MAX_FILE_SIZE_MB
  }

  const isFileExtensionAllowed = (fileToUpload: File) => {
    const acceptedExtensionsArray = acceptedExtensions
      .replaceAll('.', '')
      .split(',')

    const fileExtension = fileToUpload.type.split('/')[1]

    return acceptedExtensionsArray.includes(fileExtension)
  }

  const setErrorState = (text: string) => {
    setCurrentStatus(FileStatusType.ERROR)
    setErrorText(text)
  }

  const setSuccessState = (text: string) => {
    setCurrentStatus(FileStatusType.SUCCESS)
    setSuccessText(text)
  }

  const getIconByStatus = (): JSX.Element | null => {
    switch (currentStatus) {
      case FileStatusType.LOADING:
        return (
          <S.FileStatusProgress
            colorScheme="green"
            isIndeterminate
            isAnimated
          />
        )
      case FileStatusType.SUCCESS:
        return <ChakraIcons.CheckIcon color={ACCESSIBILITY_SUCCESS} />
      case FileStatusType.ERROR:
        return (
          <ChakraIcons.CloseIcon fontSize={13} color={ACCESSIBILITY_ERROR} />
        )
    }
  }

  const getBottomTextByStatus = (): JSX.Element | null => {
    switch (currentStatus) {
      case FileStatusType.LOADING:
        return null
      case FileStatusType.SUCCESS:
        return <>{successText}</>
      case FileStatusType.ERROR:
        return <>{errorText}</>
    }
  }

  const getTextColorByStatus = (): string => {
    switch (currentStatus) {
      case FileStatusType.LOADING:
        return THEME_GREY
      case FileStatusType.SUCCESS:
        return ACCESSIBILITY_SUCCESS
      case FileStatusType.ERROR:
        return ACCESSIBILITY_ERROR
    }
  }

  const getBottomStatus = () => {
    return (
      <Chakra.HStack color={getTextColorByStatus()} paddingLeft="2px">
        <>{getIconByStatus()}</>
        <Chakra.Box w="1px" />
        <>{getBottomTextByStatus()}</>
      </Chakra.HStack>
    )
  }

  const getTextLimitedByLength = (text: string, length: number) => {
    if (text.length > length) return text.substring(0, length - 5) + '(...)'
    return text.split('.')[0]
  }

  const deleteFile = () => {
    if (currentStatus === FileStatusType.LOADING) {
      return
    } else if (currentStatus === FileStatusType.SUCCESS) {
      const fileCode = documentsUploadState.uploadedFileCodes.find(
        (file) => file.key === fileStatusKey
      )?.code
      if (fileCode) {
        if (token && token.layerSevenToken) {
          if (isUploadSolicitation || isModalReject) {
            dispatch(
              deleteSolicitationFileInUploadComponent(
                fileCode,
                token.layerSevenToken,
                fileStatusKey
              )
            )
            return
          }
          dispatch(
            deleteUploadedFileCode(
              cpf,
              fileCode,
              fileStatusKey,
              token.layerSevenToken
            )
          )
        }
      }
    } else {
      dispatch(removeOnlyFileStatus(fileStatusKey))
    }
  }

  return (
    <Chakra.Grid key={fileStatusKey} pb={4}>
      <Chakra.GridItem>
        <Chakra.HStack spacing="-2px">
          {file.type ? (
            <S.FileStatusTextField>{`${getTextLimitedByLength(
              fileDetails.file.name,
              MAX_FILE_NAME_LENGTH_ON_STATUS
            )}.${fileDetails.file.type.split('/')[1]}`}</S.FileStatusTextField>
          ) : (
            <S.FileStatusTextField>{`${getTextLimitedByLength(
              fileDetails.file.name,
              MAX_FILE_NAME_LENGTH_ON_STATUS
            )}`}</S.FileStatusTextField>
          )}
          <S.FileStatusButton
            w="auto"
            onClick={() => {
              deleteFile()
            }}
          >
            Excluir
          </S.FileStatusButton>
        </Chakra.HStack>
      </Chakra.GridItem>

      {showStatus && <>{getBottomStatus()}</>}
    </Chakra.Grid>
  )
}

export default FileStatus
