import { useState } from 'react'
import * as Chakra from '@chakra-ui/react'
import * as S from './styles'
import Header from 'src/components/header'
import LoginForm from './components/form'
import Carousel from 'src/components/carousel'
import { THEME_GREY } from 'src/styles/colors'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import {
  logo1,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo10,
  logo12,
  logo13,
  logo14,
  logo16,
  logo17,
  logo18,
  logo19
} from 'src/assets/img'
import { v4 as uidv4 } from 'uuid'

const Login = () => {
  const [activeSlide, setActiveSlide] = useState(0)
  const imgLogos = [
    logo1,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo10,
    logo12,
    logo13,
    logo14,
    logo16,
    logo17,
    logo18,
    logo19
  ]

  const renderCarousel = () => {
    return (
      <Carousel
        updateOnItemClick
        backwardBtnProps={{
          children: <IoIosArrowBack color={THEME_GREY} size={30} />,
          style: { fontSize: '20px' }
        }}
        forwardBtnProps={{
          children: <IoIosArrowForward color={THEME_GREY} size={30} />,
          style: { fontSize: '20px' }
        }}
        itemsToShow={14}
        itemsToScroll={14}
        onRequestChange={setActiveSlide}
        activeSlideIndex={activeSlide}
        speed={20000}
      >
        {imgLogos.map((img) => {
          return <img src={img} id={`img-${uidv4()}`} key={`img-${uidv4()}`} />
        })}
      </Carousel>
    )
  }
  return (
    <>
      <Header />
      <S.Container>
        <S.ContainerFlex>
          <S.TitleContainer>
            <S.WelcomeTitle>
              Bem-vindo(a) ao <br /> Portal do Médico,
            </S.WelcomeTitle>
            <S.WelcomeSubtitle>
              O espaço criado especialmente para você, médico(a) da Rede
              Americas.
            </S.WelcomeSubtitle>
          </S.TitleContainer>
          <S.LoginContainer>
            <Chakra.Grid>
              <Chakra.Grid>
                <S.LoginTitleText>Login</S.LoginTitleText>
              </Chakra.Grid>
              <Chakra.Grid>
                <LoginForm />
              </Chakra.Grid>
            </Chakra.Grid>
          </S.LoginContainer>
        </S.ContainerFlex>
        <S.LogoBar>
          <S.LogoBarAlign>
            <S.LogoTitle>Nossas unidades</S.LogoTitle>
            {renderCarousel()}
          </S.LogoBarAlign>
        </S.LogoBar>
      </S.Container>
    </>
  )
}

export default Login
