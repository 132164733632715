import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Chakra from '@chakra-ui/react'
import * as S from './styles'
import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'
import { RequiredDocumentsType } from 'src/store/documents-upload/types'
import { OptionalDocumentsType } from 'src/store/documents-upload/types'
import * as documentsActions from 'src/store/documents-upload/actions'
import { ApplicationState } from 'src/store/rootReducer'
import Service from '../../../../services/files'

const UploadChecklist = () => {
  const dispatch = useDispatch()
  const profissionalInfoState = useSelector(
    (state: ApplicationState) => state.professionalInfoReducer
  )
  const uploadInformationData = useSelector(
    (state: ApplicationState) =>
      state.documentsUploadReducer.uploadInformationData
  )

  const addRequiredDocument = (document: RequiredDocumentsType) => {
    dispatch(documentsActions.addRequiredCheck(document))
  }

  const addOptionalDocument = (document: OptionalDocumentsType) => {
    dispatch(documentsActions.addOptionalCheck(document))
  }

  const removeRequiredDocument = (document: RequiredDocumentsType) => {
    dispatch(documentsActions.removeRequiredCheck(document))
  }

  const removeAdditionalDocument = (document: OptionalDocumentsType) => {
    dispatch(documentsActions.removeOptionalCheck(document))
  }

  const colSpan1 = Chakra.useBreakpointValue({
    base: 3,
    xl: 3,
    lg: 3,
    md: 6,
    sm: 6
  })

  const checkboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    document: RequiredDocumentsType
  ) => {
    const { checked } = e.target

    if (checked) {
      addRequiredDocument(document)
    } else {
      removeRequiredDocument(document)
    }
  }

  const optionalDocumentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    document: OptionalDocumentsType
  ) => {
    const { checked } = e.target

    if (checked) {
      addOptionalDocument(document)
    } else {
      removeAdditionalDocument(document)
    }
  }

  const [vaccineTerm, setVaccineTerm] = useState('')
  const [hospitalTerm, setHospitalTerm] = useState('')

  useEffect(() => {
    getTermVaccine()
    getHospitalTerm()
  }, [])

  const getTermVaccine = () => {
    Service()
      .getVaccineTerm()
      .then((response: any) => {
        const relativeUrlTerm =
          response.data?.tipoDocumentoRaiz?.filhos[0]?.documentos[0]?.link
        if (relativeUrlTerm) {
          setVaccineTerm(relativeUrlTerm)
        } else {
          setVaccineTerm('')
        }
      })
  }

  const getHospitalTerm = () => {
    Service()
      .getHospitalTerm()
      .then((response: any) => {
        const relativeUrlTerm =
          response.data?.tipoDocumentoRaiz?.filhos[0]?.documentos[0]?.link
        if (relativeUrlTerm) {
          setHospitalTerm(relativeUrlTerm)
        } else {
          setHospitalTerm('')
        }
      })
  }

  return (
    <Chakra.Grid templateColumns="repeat(6, 1fr)" gap={5}>
      <S.ChecklistGridItem p={12} colSpan={colSpan1}>
        <Chakra.Heading as="h2" size="md" color={THEME_PURPLE}>
          Documentação obrigatória
        </Chakra.Heading>
        <Chakra.Divider borderColor={THEME_GREY} p={1} mb={4} w="100%" />
        <Chakra.VStack spacing={4} alignItems="flex-start">
          <Chakra.CheckboxGroup colorScheme="purple">
            <Chakra.Checkbox
              isChecked={uploadInformationData.requiredChecklist.includes(0)}
              onChange={(e) => {
                checkboxChange(e, RequiredDocumentsType.CRM_REGISTER)
              }}
            >
              <S.ItemText>
                *Registro profissional (CRM, CRO, CREFITO, CRP, CREF, CFN)
              </S.ItemText>
            </Chakra.Checkbox>
            <Chakra.Checkbox
              isChecked={uploadInformationData.requiredChecklist.includes(1)}
              onChange={(e) => checkboxChange(e, RequiredDocumentsType.DEGREE)}
            >
              <S.ItemText>
                *Diploma médico (frente e verso do documento)
              </S.ItemText>
            </Chakra.Checkbox>
            <Chakra.Checkbox
              isChecked={uploadInformationData.requiredChecklist.includes(2)}
              onChange={(e) =>
                checkboxChange(e, RequiredDocumentsType.ETHICS_CERTIFICATE)
              }
            >
              <S.ItemText>*Certidão ético-profissional atualizada</S.ItemText>
            </Chakra.Checkbox>
            <Chakra.Checkbox
              isChecked={uploadInformationData.requiredChecklist.includes(3)}
              onChange={(e) =>
                checkboxChange(e, RequiredDocumentsType.SPECIALIST_TITLE)
              }
            >
              <S.ItemText>
                *Título de especialista (frente e verso do documento)
              </S.ItemText>
              <Chakra.Text fontSize="12px" color={THEME_GREY}>
                Reconhecido pela AMB ou sociedades correspondentes
              </Chakra.Text>
            </Chakra.Checkbox>
            <Chakra.Checkbox
              isChecked={uploadInformationData.requiredChecklist.includes(4)}
              onChange={(e) =>
                checkboxChange(e, RequiredDocumentsType.RESIDENCY_CERTIFICATE)
              }
            >
              <S.ItemText>
                *Cert. de conclusão de res. médica (frente e verso do documento)
              </S.ItemText>
              <Chakra.Text fontSize="12px" color={THEME_GREY}>
                Reconhecido pelo MEC ou CNRM
              </Chakra.Text>
            </Chakra.Checkbox>
            <Chakra.Checkbox
              isChecked={uploadInformationData.requiredChecklist.includes(5)}
              onChange={(e) =>
                checkboxChange(e, RequiredDocumentsType.VACCINATION_CARD)
              }
            >
              <S.ItemText>
                *Carteira de vacinação ou termo de recusa{' '}
                <S.Link
                  target="_blank"
                  href={`${window._env_.REACT_APP_GALLERY_AMIL}${vaccineTerm}`}
                >
                  (Faça o download aqui)
                </S.Link>
              </S.ItemText>
            </Chakra.Checkbox>

            <Chakra.Checkbox
              isChecked={uploadInformationData.requiredChecklist.includes(6)}
              onChange={(e) =>
                checkboxChange(e, RequiredDocumentsType.HOSPITAL_TERM)
              }
            >
              <S.ItemText>
                *Termos de uso dos hospitais Americas{' '}
                <S.Link
                  target="_blank"
                  href={`${window._env_.REACT_APP_GALLERY_AMIL}${hospitalTerm}`}
                >
                  (Faça o download aqui)
                </S.Link>
              </S.ItemText>
            </Chakra.Checkbox>

            {profissionalInfoState.professionalData?.accreditationApplication
              ?.memberTeamHospitals === 'true' ? (
              <Chakra.Checkbox
                isChecked={uploadInformationData.requiredChecklist.includes(7)}
                onChange={(e) =>
                  checkboxChange(e, RequiredDocumentsType.TEAM_LETTER)
                }
              >
                <S.ItemText>*Carta de inclusão em equipe</S.ItemText>
              </Chakra.Checkbox>
            ) : null}
            <Chakra.Checkbox
              isChecked={uploadInformationData.requiredChecklist.includes(8)}
              onChange={(e) =>
                checkboxChange(e, RequiredDocumentsType.BADGE_PHOTO)
              }
            >
              <S.ItemText>*Foto de crachá</S.ItemText>
            </Chakra.Checkbox>
          </Chakra.CheckboxGroup>
        </Chakra.VStack>
      </S.ChecklistGridItem>

      <S.ChecklistGridItem p={12} colSpan={colSpan1}>
        <Chakra.Heading as="h2" size="md" color={THEME_PURPLE}>
          Documentação adicional (Opcional)
        </Chakra.Heading>
        <Chakra.Divider borderColor={THEME_GREY} p={1} mb={4} w="100%" />
        <Chakra.VStack spacing={4} alignItems="flex-start">
          <Chakra.CheckboxGroup colorScheme="purple">
            {profissionalInfoState.professionalData?.accreditationApplication
              ?.memberTeamHospitals === 'true' ? null : (
              <Chakra.Checkbox
                onChange={(e) =>
                  optionalDocumentChange(e, OptionalDocumentsType.TEAM_LETTER)
                }
              >
                <S.ItemText>Carta de inclusão em equipe</S.ItemText>
              </Chakra.Checkbox>
            )}
            <Chakra.Checkbox
              onChange={(e) =>
                optionalDocumentChange(
                  e,
                  OptionalDocumentsType.MANDATORY_COURSE
                )
              }
            >
              <S.ItemText>Curso obrigatório</S.ItemText>
            </Chakra.Checkbox>
            <Chakra.Checkbox
              onChange={(e) =>
                optionalDocumentChange(
                  e,
                  OptionalDocumentsType.PROOF_COMPETENCY
                )
              }
            >
              <S.ItemText>Comprovação competência</S.ItemText>
            </Chakra.Checkbox>
          </Chakra.CheckboxGroup>
        </Chakra.VStack>
      </S.ChecklistGridItem>
    </Chakra.Grid>
  )
}

export default UploadChecklist
