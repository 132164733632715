export const enum AdditionalInfo {
  ADDITIONAL_INFO_ERROR = '@additionalInfo/ADDITIONAL_INFO_ERROR',
  ADDITIONAL_INFO_SUCCESS = '@additionalInfo/ADDITIONAL_INFO_SUCCESS',
  GET_ADDITIONAL_INFO_SUCCESS = '@additionalInfo/GET_ADDITIONAL_INFO_SUCCESS',
  GET_ADDITIONAL_INFO_ERROR = '@additionalInfo/GET_ADDITIONAL_INFO_ERROR',
  CLEAR_ADDITIONAL_INFO = '@additionalInfo/CLEAR_ADDITIONAL_INFO'
}

export interface IAdditionalData {
  cpf?: string
  homeAddress?: string
  zipCode?: string
  number?: string
  district?: string
  city?: string
  uf?: string
  complement?: string
  phoneNumber?: string
  soccerTeam?: string
  observationHobbies?: string
  enjoyWine?: string
  musicalGenre?: string
}

export interface IAdditionalInfoStates {
  additionalInfo: IAdditionalData
  success?: any
  message?: any
  error?: any
}
