import Service from '../../services/mass-registration'
import { IMassRegistrationResult, MassRegistrationTypes } from './types'
import { AxiosResponse } from 'axios'
import { hideSpinner, showSpinner } from '../spinner/actions'
import { showErrorToast } from 'src/utils/toast'
import { MassRegistrationOrigin } from 'src/constants'
import { handleLayerSevenRequestError } from '../layer-seven/actions'
import { ERROR } from 'src/constants'

export const getCsvTemplate = (token: string) => {
  return Service()
    .getCsvTemplate(token)
    .then((res: AxiosResponse) => {
      const result = res.data
      return result
    })
    .catch((error: any) => {
      console.error('Action getCsvTemplate: ', error.message)
    })
}

export const uploadCsv =
  (file: File, token: string, originValue: MassRegistrationOrigin) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .uploadCsv(file, token, originValue)
      .then((res: AxiosResponse) => {
        const result = res.data
        dispatch(hideSpinner())
        dispatch(uploadCsvSuccess(result))
      })
      .catch((error: any) => {
        showErrorToast(ERROR, 'Falha ao enviar planilha.')
        dispatch(hideSpinner())
        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(uploadCsv(file, newToken, originValue))
          )
        )
      })
  }

export const uploadCsvSuccess = (result: IMassRegistrationResult[]) => ({
  type: MassRegistrationTypes.ON_UPLOAD_SUCCESS,
  payload: result
})

export const uploadCsvError = (error: any) => ({
  type: MassRegistrationTypes.ON_UPLOAD_ERROR,
  payload: error
})

export const clearMassRegistration = () => ({
  type: MassRegistrationTypes.CLEAR_MASS_REGISTRATION
})
