import { ReactNode } from 'react'

interface FavoriteButtonFlagProps {
  children: ReactNode
}

export function VerifyFavoriteButtonFlag({
  children
}: FavoriteButtonFlagProps) {
  return <>{window._flags_.FAVORITE_BUTTON_ENABLED ? children : null}</>
}
