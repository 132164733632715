import * as Chakra from '@chakra-ui/modal'

import * as S from './styles'
import * as I from 'src/assets/icons'

import { GrCircleInformation } from 'react-icons/gr'
import Button from 'src/components/button'

import { ModalProps } from './interface'
import { MessageModalType } from 'src/store/message-modal/types'
import { ACCESSIBILITY_SUCCESS, THEME_PURPLE } from 'src/styles/colors'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { BsUpload } from 'react-icons/bs'

const MessageModal = ({
  isOpen,
  onClose,
  title,
  children,
  type,
  size,
  titleButton,
  isCentered,
  scrollBehavior,
  onClickButton
}: ModalProps) => {
  const getIconByType = () => {
    switch (type) {
      case MessageModalType.Error:
        return <I.AttentionIcon />
      case MessageModalType.Success:
        return <I.CheckIcon color={ACCESSIBILITY_SUCCESS} size={35} />
      case MessageModalType.Information:
        return <GrCircleInformation />
      case MessageModalType.History:
        return <AiOutlineClockCircle size="28px" color={THEME_PURPLE} />
      case MessageModalType.Upload:
        return <BsUpload size="28px" color={THEME_PURPLE} />
    }
  }

  return (
    <Chakra.Modal
      isOpen={isOpen !== undefined || isOpen === true}
      onClose={onClose}
      size={size}
      data-testid="errormodal"
      isCentered={isCentered}
      scrollBehavior={scrollBehavior}
    >
      <Chakra.ModalOverlay />
      <Chakra.ModalContent paddingY={8} paddingX={2} alignItems="center">
        <Chakra.ModalHeader>
          <S.IconWrapper>{getIconByType()}</S.IconWrapper>
          <S.Title>{title}</S.Title>
        </Chakra.ModalHeader>
        <Chakra.ModalCloseButton />

        <Chakra.ModalBody
          w="100%"
          css={{
            'scrollbar-width': 'thin',

            '&::-webkit-scrollbar': {
              width: '10px',
              height: '10px'
            },

            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1'
            },

            '&::-webkit-scrollbar-thumb': {
              background: THEME_PURPLE,
              borderRadius: '24px'
            }
          }}
        >
          <S.DescriptionText>{children}</S.DescriptionText>
        </Chakra.ModalBody>

        <Chakra.ModalFooter>
          {titleButton !== '' && (
            <Button
              variant="normal"
              type="submit"
              onClick={() => {
                onClose()
                if (onClickButton) onClickButton()
              }}
            >
              {titleButton}
            </Button>
          )}
        </Chakra.ModalFooter>
      </Chakra.ModalContent>
    </Chakra.Modal>
  )
}

export default MessageModal
