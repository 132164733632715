import { useState } from 'react'
import InputFilter from 'src/components/filter'
import * as Chakra from '@chakra-ui/react'
import * as S from './styles'
import { THEME_PURPLE } from 'src/styles/colors'
import Button from 'src/components/button'
import { Filter, FilterType, hospitals, status } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { getSolicitationList } from 'src/store/solicitation-page/actions'
import { UserProfileEnum } from 'src/store/login/types'
import { ApplicationState } from 'src/store/rootReducer'

const FilterModal = ({
  setFilter,
  filters,
  setFilterValues,
  setPageNumber
}: FilterType) => {
  const dispatch = useDispatch()

  const auth = useSelector((state: ApplicationState) => state.userReducer.auth)
  const userProfileId = Number(auth?.userProfile)
  const nameProfile = UserProfileEnum[userProfileId]

  const statusList = filters.status
  const hospitalsList = filters.hospitals

  const [values, setValues] = useState<Filter>({
    filter: '',
    hospitalsId: [],
    statusId: [],
    myAnalysis: false
  })

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setValues({
      ...values,
      filter: value
    })
  }

  const checkboxChange = (
    itemId: number,
    itemName: 'hospitalsId' | 'statusId',
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target
    if (checked) {
      const newCheckboxArray = [...values[itemName], itemId]

      setValues({
        ...values,
        [itemName]: newCheckboxArray
      })
    } else {
      const newCheckboxArray = values[itemName].filter(
        (value) => value != itemId
      )

      setValues({
        ...values,
        [itemName]: newCheckboxArray
      })
    }
  }

  const analysisChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target
    if (e.target.checked) {
      setValues({
        ...values,
        [name]: e.target.checked
      })
    }
  }

  const submitFilter = () => {
    setPageNumber(1)
    setFilterValues(values)
    setFilter(false)
  }

  return (
    <S.Box maxW="sm">
      <S.ModalBox>
        <S.BoxTitle fontSize="18px" fontWeight="bold">
          Filtros
        </S.BoxTitle>
        <InputFilter
          name="filter"
          value={values.filter}
          placeholder="Pesquisar"
          onChange={inputChange}
        />
        <Chakra.Text fontSize="sm">Pesquise por: Nome, CPF ou CRM</Chakra.Text>

        {nameProfile === 'DIRECTOR' ? null : (
          <S.MyAnalysis>
            <Chakra.Checkbox
              color={THEME_PURPLE}
              onChange={analysisChange}
              name="myAnalysis"
            >
              Somente minhas solicitações
            </Chakra.Checkbox>
          </S.MyAnalysis>
        )}

        <S.BoxTitle fontSize="18px" fontWeight="bold">
          Unidades:
        </S.BoxTitle>

        <Chakra.Stack>
          {hospitalsList.map((hospital: hospitals) => (
            <Chakra.Checkbox
              color={THEME_PURPLE}
              key={hospital.sapId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                checkboxChange(hospital.sapId, 'hospitalsId', event)
              }
            >
              {hospital.name}
            </Chakra.Checkbox>
          ))}
        </Chakra.Stack>

        <S.BoxTitle fontSize="18px" fontWeight="bold">
          Status da solicitação:
        </S.BoxTitle>

        <S.WrapperCheckbox columns={3} spacingY="10px">
          {statusList.map((status: status) => (
            <S.Checkbox
              size="sm"
              key={status.id}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                checkboxChange(status.id, 'statusId', event)
              }
            >
              {status.name}
            </S.Checkbox>
          ))}
        </S.WrapperCheckbox>

        <S.ButtonContainer>
          <Button
            maxW="400"
            onClick={submitFilter}
            variant="normal"
            type="button"
          >
            Aplicar filtros
          </Button>
        </S.ButtonContainer>
      </S.ModalBox>
    </S.Box>
  )
}

export default FilterModal
