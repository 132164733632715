import { AxiosResponse } from 'axios'
import Service from '../../services/user'
import ROUTING_PATHS from '../../routes/paths'
import {
  RegistrationStep,
  RegistrationStepTypes
} from '../registration-step/types'
import { handleLayerSevenRequestError } from '../layer-seven/actions'

export const getRegistrationStepSuccess = (data: any) => ({
  payload: data,
  type: RegistrationStepTypes.GET_REGISTRATION_STEP_SUCCESS
})

export const createRegistrationStep =
  (
    cpf: string,
    token: string,
    step: RegistrationStep = RegistrationStep.NewAccount
  ) =>
  (dispatch: any) => {
    Service()
      .createUserRegistrationStep(cpf, step, token)
      .then(() => {
        dispatch(createRegistrationStepSuccess(step))
      })
      .catch((error) => {
        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(createRegistrationStep(cpf, newToken, step))
          )
        )
      })
  }

export const createRegistrationStepSuccess = (data: any) => ({
  payload: data,
  type: RegistrationStepTypes.CREATE_REGISTRATION_STEP_SUCCESS
})

export const updateRegistrationStep =
  (cpf: string, step: RegistrationStep, token: string) => (dispatch: any) => {
    if (!token || token.length < 1) return

    Service()
      .updateUserRegistrationStep(cpf, step, token)
      .then((res: AxiosResponse) => {
        dispatch(updateRegistrationStepSuccess(res.data))
      })
      .catch((error) => {
        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(updateRegistrationStep(cpf, step, newToken))
          )
        )
      })
  }

export const updateRegistrationStepSuccess = (data: any) => ({
  payload: data,
  type: RegistrationStepTypes.UPDATE_REGISTRATION_STEP_SUCCESS
})

export const getNextRegistrationStepRoute = (step: RegistrationStep) => {
  switch (step) {
    case RegistrationStep.NewAccount:
      return ROUTING_PATHS.SignUp_PersonalInfo
    case RegistrationStep.PersonalInfo:
      return ROUTING_PATHS.SignUp_ProfessionalInfo
    case RegistrationStep.ProfessionalInfo:
      return ROUTING_PATHS.SignUp_CompetencyMap
    case RegistrationStep.CompetencyMap:
      return ROUTING_PATHS.SignUp_UploadDocuments
    case RegistrationStep.UploadDocument:
      return ROUTING_PATHS.SignUp_AdditionalInfo
    default:
      return ROUTING_PATHS.SignUp_PersonalInfo
  }
}

export const clearRegistrationStep = () => ({
  type: RegistrationStepTypes.CLEAR_REGISTRATION_STEP
})
