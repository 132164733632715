import * as S from './styles'

const Spinner = () => {
  return (
    <S.Container data-testid="spinner">
      <S.Spinner />
    </S.Container>
  )
}

export default Spinner
