import * as Chakra from '@chakra-ui/react'
import styled from 'styled-components'
import {
  ACCESSIBILITY_SUCCESS,
  THEME_GREY,
  THEME_PURPLE
} from './../../styles/colors'
import { Button } from '@chakra-ui/react'

export const Container = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  @media (min-width: 200px) and (max-width: 900px) {
    flex-direction: column;
  }
`
export const Upload = styled.div`
  width: 45%;
  margin: 5vh 0;
  @media (max-width: 900px) {
    width: 100%;
  }
`
export const Status = styled.div`
  width: 45%;
  margin: 5vh 0;
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const DropzoneContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 16px;
  border-width: 2px;
  border-radius: 5px;
  border-style: dashed;
  color: black;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 250px;
  width: 100%;
  min-width: 250px;
  box-shadow: 0px 0px 10px #dedede;
  cursor: pointer;
  &:hover {
    border-color: ${THEME_PURPLE} !important;
  }
`

export const WarningContainerFileCount = styled(Chakra.Container)`
  border-style: solid;
  border-width: 1px;
  border-color: ${THEME_PURPLE};
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 32px;
  min-height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const WarningText = styled.div`
  color: ${THEME_GREY};
  margin-left: 8px;
`

export const FileStatusTextField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 16px;
  border-width: 2px;
  border-radius: 5px 0px 0px 5px;
  border-style: solid;
  border-color: #c4c4c4;
  color: black;
  transition: border 0.24s ease-in-out;
  height: 46px;
  width: 83%;
  min-width: 200px;
  font-size: 14px;
  overflow-x: hidden;
  overflow-y: hidden;
`

export const FileStatusButton = styled(Button)`
  border-width: 2px;
  border-radius: 0px 5px 5px 0px;
  border-style: solid;
  color: black;
  transition: border 0.24s ease-in-out;
  height: 46px;
  width: 17%;
  min-width: 70px;
  margin-left: -5px;
  border-color: #c4c4c4;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: hidden;
`

export const FileStatus = styled.div`
  color: ${ACCESSIBILITY_SUCCESS};
`
