import {
  DocumentUploadStateType,
  IFileCodeUploaded,
  IUploadInformationRequest,
  OptionalDocumentsType,
  RequiredDocumentsType
} from './types'
import Service from '../../services/documents-upload'
import { hideSpinner, showSpinner } from 'src/store/spinner/actions'
import ROUTING_PATHS from 'src/routes/paths'
import { RegistrationStep } from '../registration-step/types'
import { updateRegistrationStep } from '../registration-step/actions'
import { handleLayerSevenRequestError } from '../layer-seven/actions'
import { getSolicitationDocuments } from '../solicitation-details/actions'

export const addFileStatus = (component: JSX.Element) => (dispatch: any) =>
  dispatch({
    payload: component,
    type: DocumentUploadStateType.ADD_FILE_STATUS
  })

export const removeOnlyFileStatus = (key: string) => (dispatch: any) =>
  dispatch({
    payload: key,
    type: DocumentUploadStateType.REMOVE_FILE_STATUS
  })

export const clearFileStatus = () => (dispatch: any) =>
  dispatch({
    type: DocumentUploadStateType.CLEAR_FILE_STATUS
  })

const getPersonalFile = (sucess: any) => ({
  type: DocumentUploadStateType.GET_FILE_UPLOADED,
  payload: sucess
})

const getPersonalFileError = (error: any) => ({
  type: DocumentUploadStateType.GET_FILE_UPLOADED_ERROR,
  payload: error
})

const getCompetencyMap = (sucess: any) => ({
  type: DocumentUploadStateType.GET_COMPETENCY_MAP,
  payload: sucess
})

const getCompetencyMapError = (error: any) => ({
  type: DocumentUploadStateType.GET_COMPETENCY_MAP_ERROR,
  payload: error
})

export const getUploadedFile =
  (cpf: string, token: string, onCreateFile: (file: any) => void) =>
  (dispatch: any) => {
    Service()
      .getPersonalFile(cpf, token)
      .then((response: any) => {
        dispatch(getPersonalFile(response.data))
        dispatch(clearFileStatus())
        const data = response.data

        if (data.requiredPersonalDocuments) {
          data.requiredPersonalDocuments.map((requiredItem: any) => {
            dispatch(addRequiredCheck(requiredItem.type))
          })
        }
        if (data.files.length > 0) {
          data.files.map((file: any) => {
            onCreateFile(file)
          })
        }
      })
      .catch((error: any) => {
        dispatch(getPersonalFileError(error))
      })
  }

export const getUploadedCompetencyMap =
  (cpf: string, token: string, onCreateFile: (file: any) => void) =>
  (dispatch: any) => {
    Service()
      .getCompetencyMap(cpf, token)
      .then((response: any) => {
        dispatch(getCompetencyMap(response.data))
        dispatch(clearFileStatus())
        const data = response.data
        if (data.files.length > 0) {
          data.files.map((file: any) => {
            onCreateFile(file)
          })
        }
      })
      .catch((error: any) => {
        dispatch(getCompetencyMapError(error))
      })
  }

export const saveUploadedFileCode =
  (fileCodeKey: IFileCodeUploaded) => (dispatch: any) =>
    dispatch({
      payload: fileCodeKey,
      type: DocumentUploadStateType.SAVE_UPLOADED_FILE_CODE
    })

export const deleteUploadedFileSuccess = (data: any) => ({
  type: DocumentUploadStateType.DELETE_SOLICITATION_FILE_SUCCESS,
  payload: data
})
export const deleteUploadedFileError = (error: any) => ({
  type: DocumentUploadStateType.DELETE_SOLICITATION_FILE_ERROR,
  payload: error
})

export const deleteUploadedFileCode =
  (cpf: string, fileCode: string, fileKey: string, token: string) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .deleteFileFromStorage(cpf, fileCode, token)
      .then(() => {
        dispatch({
          payload: { code: fileCode, key: fileKey },
          type: DocumentUploadStateType.DELETE_UPLOADED_FILE_CODE_SUCCESS
        })
      })
      .catch((error) => {
        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(deleteUploadedFileCode(cpf, fileCode, fileKey, newToken))
          )
        )
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const deleteSolicitationFile =
  (code: string, token: string, cpf: string, technicalEvaluationId?: number) =>
  (dispatch: any) => {
    Service()
      .deleteUploadedFile(code, token)
      .then(() => {
        dispatch(deleteUploadedFileSuccess({}))
        if (technicalEvaluationId) {
          dispatch(
            getSolicitationDocuments(token, cpf, Number(technicalEvaluationId))
          )
        } else {
          dispatch(getSolicitationDocuments(token, cpf))
        }
      })
      .catch((error) => {
        dispatch(deleteUploadedFileError(error))
      })
  }
export const deleteSolicitationFileInUploadComponent =
  (code: string, token: string, fileKey: string) => (dispatch: any) => {
    Service()
      .deleteUploadedFile(code, token)
      .then(() => {
        dispatch(deleteUploadedFileSuccess({}))
        dispatch({
          payload: { code, key: fileKey },
          type: DocumentUploadStateType.DELETE_UPLOADED_FILE_CODE_SUCCESS
        })
      })
      .catch((error) => {
        dispatch(deleteUploadedFileError(error))
      })
  }

export const clearUploadedFileCodes = () => (dispatch: any) =>
  dispatch({
    type: DocumentUploadStateType.CLEAR_UPLOADED_FILE_CODES
  })

export const saveUserPersonalDocuments =
  (data: IUploadInformationRequest, history: any, token: string) =>
  (dispatch: any) => {
    dispatch({
      type: DocumentUploadStateType.UPLOAD_INFORMATION_REQUEST
    })
    dispatch(showSpinner())
    Service()
      .saveUserPersonalDocuments(data, token)
      .then(() => {
        dispatch(saveUserPersonalDocumentsSuccess(data))
        dispatch(
          updateRegistrationStep(
            data.cpf,
            RegistrationStep.UploadDocument,
            token
          )
        )
        if (history) history.push(ROUTING_PATHS.SignUp_AdditionalInfo)
      })
      .catch((error: Error) => {
        dispatch(saveUserPersonalDocumentsError(error))

        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(saveUserPersonalDocuments(data, history, newToken))
          )
        )
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const updateUserPersonalDocuments =
  (data: IUploadInformationRequest, history: any, token: string) =>
  (dispatch: any) => {
    dispatch({
      type: DocumentUploadStateType.UPLOAD_INFORMATION_REQUEST
    })
    dispatch(showSpinner())
    Service()
      .updateUserPersonalDocuments(data, token)
      .then(() => {
        dispatch(saveUserPersonalDocumentsSuccess(data))
        dispatch(
          updateRegistrationStep(
            data.cpf,
            RegistrationStep.UploadDocument,
            token
          )
        )
        if (history) history.push(ROUTING_PATHS.SignUp_AdditionalInfo)
      })
      .catch((error: Error) => {
        dispatch(saveUserPersonalDocumentsError(error))

        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(saveUserPersonalDocuments(data, history, newToken))
          )
        )
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const saveUserPersonalDocumentsSuccess =
  (data: IUploadInformationRequest) => (dispatch: any) =>
    dispatch({
      payload: data,
      type: DocumentUploadStateType.UPLOAD_INFORMATION_SUCCESS
    })

export const saveUserPersonalDocumentsError = (error: any) => (dispatch: any) =>
  dispatch({
    payload: error,
    type: DocumentUploadStateType.UPLOAD_INFORMATION_ERROR
  })

export const addRequiredCheck = (documentType: RequiredDocumentsType) => ({
  payload: documentType,
  type: DocumentUploadStateType.ADD_REQUIRED_CHECK
})

export const removeRequiredCheck = (documentType: RequiredDocumentsType) => ({
  payload: documentType,
  type: DocumentUploadStateType.REMOVE_REQUIRED_CHECK
})

export const addOptionalCheck =
  (check: OptionalDocumentsType) => (dispatch: any) =>
    dispatch({
      payload: check,
      type: DocumentUploadStateType.ADD_OPTIONAL_CHECK
    })

export const removeOptionalCheck =
  (check: OptionalDocumentsType) => (dispatch: any) =>
    dispatch({
      payload: check,
      type: DocumentUploadStateType.REMOVE_OPTIONAL_CHECK
    })

export const clearDocumentUpload = () => ({
  type: DocumentUploadStateType.CLEAR_DOCUMENT_UPLOAD
})
