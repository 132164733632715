import * as Chakra from '@chakra-ui/react'
import InfoHeader from '../../../components/signup/info-header'
import { Link } from 'react-router-dom'
import * as I from '../../../assets/icons'
import * as S from './style'
import ROUTING_PATHS from 'src/routes/paths'
import Button from 'src/components/button'
import { ACCESSIBILITY_SUCCESS } from 'src/styles/colors'

const SignUpSuccess = () => {
  const colSpan1 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 6
  })
  const colStart1 = Chakra.useBreakpointValue({
    base: 5,
    xl: 5,
    lg: 5,
    md: 5,
    sm: 1
  })
  const colStart2 = Chakra.useBreakpointValue({
    base: 6,
    xl: 6,
    lg: 6,
    md: 6,
    sm: 1
  })
  return (
    <>
      <InfoHeader value={5.2} />
      <S.Container>
        <Chakra.Grid templateColumns="repeat(6, 1fr)" gap={3}>
          <Chakra.GridItem colSpan={6}>
            <S.IconWrapper>
              <I.CheckIcon size={42} color={ACCESSIBILITY_SUCCESS} />
            </S.IconWrapper>
            <S.TitleText>
              Parabéns! Seu cadastro ao portal foi criado.
            </S.TitleText>
            <S.DescriptionText data-testid="successDescription">
              Com ele, você pode ter acesso a informações básicas do portal do
              médico, como e-books e eventos virtuais.
            </S.DescriptionText>
            <S.DescriptionText data-testid="successDescriptionBold">
              Você também pode ter um cadastro na{' '}
              <S.Link href="http://americasmed.com.br" target="_blank">
                Rede Americas
              </S.Link>{' '}
              e atuar em nossos hospitais, com acesso a uma série de serviços.
              Para isso, basta clicar no botão{' '}
              {`"Continuar cadastro completo".`}
            </S.DescriptionText>
            <S.DescriptionText data-testid="successFinalDescription">
              Não consegue concluir o cadastro completo agora? Não tem problema,
              você também pode acessar a página de login posteriormente com seu
              usuário e senha para concluir essa etapa.
            </S.DescriptionText>
          </Chakra.GridItem>
          <Chakra.GridItem colSpan={6}>
            <Chakra.Divider
              borderColor="secondary"
              marginTop="5vh"
              marginBottom="5vh"
            />
          </Chakra.GridItem>
          <Chakra.GridItem colStart={colStart1} colSpan={colSpan1}>
            <Link to={ROUTING_PATHS.Dashboard}>
              <Button variant="outlined" type="submit">
                Home
              </Button>
            </Link>
          </Chakra.GridItem>
          <Chakra.GridItem colStart={colStart2} colSpan={colSpan1}>
            <Link to={ROUTING_PATHS.SignUp_PersonalInfo}>
              <Button variant="normal" type="submit">
                Continuar cadastro completo
              </Button>
            </Link>
          </Chakra.GridItem>
        </Chakra.Grid>
      </S.Container>
    </>
  )
}

export default SignUpSuccess
