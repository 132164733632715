import * as Chakra from '@chakra-ui/react'
import * as I from 'src/assets/icons'
import AdditionalInfoForm from './components/form'
import HeadingText from 'src/components/signup/heading-text'
import Container from 'src/components/container'
import { THEME_PURPLE } from 'src/styles/colors'

const AdditionalInfo = () => {
  return (
    <>
      <Container value={100}>
        <Chakra.Grid templateColumns="repeat(6, 1fr)" gap={3}>
          <Chakra.GridItem colSpan={6}>
            <HeadingText
              icon={
                <I.AdditionalInfoIcon
                  fill={THEME_PURPLE}
                  stroke={THEME_PURPLE}
                />
              }
              title="Informações Adicionais"
              subtitle="Para finalizar seu cadastro complete suas informações pessoais."
            />
          </Chakra.GridItem>
        </Chakra.Grid>

        <Chakra.Grid templateColumns="repeat(5, 1fr)" gap={4}>
          <AdditionalInfoForm />
        </Chakra.Grid>
      </Container>
    </>
  )
}

export default AdditionalInfo
