import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import urls from 'src/constants/api'
import { store } from 'src/store'
import {
  IFileDetails,
  IUploadInformationRequest
} from 'src/store/documents-upload/types'
import { convertBase64 } from 'src/utils/convertBase64'
import { filesInformationAPI } from '../interceptors'

interface IApi {
  registrationApi: AxiosInstance
  uploadFileToStorage(
    fileDetails: IFileDetails,
    token: string
  ): Promise<AxiosResponse>
  uploadFileToStorageReject(
    fileDetails: IFileDetails,
    token: string
  ): Promise<AxiosResponse>
  uploadFileToSolicitation(
    fileDetails: IFileDetails,
    token: string,
    id: string,
    technicalEvaluationId: string
  ): Promise<AxiosResponse>
  deleteUploadedFile(code: string, token: string): Promise<AxiosResponse>
  deleteFileFromStorage(
    cpf: string,
    code: string,
    token: string
  ): Promise<AxiosResponse>
  saveUserPersonalDocuments(data: any, token: string): Promise<AxiosResponse>
  updateUserPersonalDocuments(data: any, token: string): Promise<AxiosResponse>
  getPersonalFile(cpf: string, token: string): Promise<AxiosResponse>
  getCompetencyMap(cpf: string, token: string): Promise<AxiosResponse>
}

export default (): IApi => {
  const registrationApi = axios.create({
    baseURL: urls.registrationAPI
  })

  registrationApi.interceptors.request.use(
    async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      request.headers.Authorization = `Bearer ${
        store.getState().userReducer.auth.accessToken
      }`
      return request
    }
  )

  return {
    registrationApi: registrationApi,

    uploadFileToStorage: async (
      fileDetails: IFileDetails,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
        // 'Content-Type': 'multipart/form-data'
      }
      // const formData = new FormData()
      // formData.append('Cpf', fileDetails.cpf)
      // formData.append('FormFile', fileDetails.file)
      // formData.append('FileType', fileDetails.fileType.toString())

      const fileBase64 = (await convertBase64(fileDetails.file)) as string

      const formData = {
        fileName: fileDetails.file.name,
        length: fileDetails.file.size,
        contentType: fileDetails.file.type,
        fileBase64: fileBase64.split(',')[1],
        Cpf: fileDetails.cpf
      }

      return registrationApi.post(`/Files/upload`, formData, {
        headers
      })
    },
    uploadFileToStorageReject: async (
      fileDetails: IFileDetails,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      const fileBase64 = (await convertBase64(fileDetails.file)) as string

      const formData = {
        fileName: fileDetails.file.name,
        length: fileDetails.file.size,
        contentType: fileDetails.file.type,
        fileBase64: fileBase64.split(',')[1]
      }

      return filesInformationAPI().post(`upload-reject-file`, formData, {
        headers
      })
    },

    uploadFileToSolicitation: async (
      fileDetails: IFileDetails,
      token: string,
      id: string,
      technicalEvaluationId: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
        // 'Content-Type': 'multipart/form-data'
      }
      // const formData = new FormData()
      // formData.append('FormFile', fileDetails.file)
      // formData.append('SolicitationId', id)
      // if (technicalEvaluationId) {
      //   formData.append('TechnicalEvaluationId', technicalEvaluationId)
      // }

      const fileBase64 = (await convertBase64(fileDetails.file)) as string

      const formData = {
        fileName: fileDetails.file.name,
        length: fileDetails.file.size,
        contentType: fileDetails.file.type,
        fileBase64: fileBase64.split(',')[1],
        SolicitationId: id
      }

      if (technicalEvaluationId) {
        Object.assign(formData, {
          TechnicalEvaluationId: technicalEvaluationId
        })
      }

      return filesInformationAPI().post(`/upload`, formData, {
        headers
      })
    },

    deleteUploadedFile: (
      code: string,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`,
        accept: '*/*'
      }
      return filesInformationAPI().delete(`/files?Codes=${code}`, {
        headers
      })
    },

    saveUserPersonalDocuments: (
      data: IUploadInformationRequest,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi.post(`/Files/document-information`, data, {
        headers
      })
    },

    updateUserPersonalDocuments: (
      data: IUploadInformationRequest,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi.put(`/Files/document-information`, data, {
        headers
      })
    },

    deleteFileFromStorage: (
      cpf: string,
      code: string,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`,
        accept: '*/*'
      }
      return registrationApi.delete(`/Files/delete?Cpf=${cpf}&Code=${code}`, {
        headers
      })
    },

    getPersonalFile: (cpf: string, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`,
        accept: '*/*'
      }
      return registrationApi.get(`Files/document-information?Cpf=${cpf}`, {
        headers
      })
    },
    getCompetencyMap: (cpf: string, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`,
        accept: '*/*'
      }
      return registrationApi.get(
        `Files/document-information-competency-map?Cpf=${cpf}`,
        {
          headers
        }
      )
    }
  }
}
