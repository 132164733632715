import { isValidElement, useEffect, useRef } from 'react'
import * as Chakra from '@chakra-ui/react'
import { useDropzone } from 'react-dropzone'
import { uuid } from 'uuidv4'
import { useDispatch, useSelector } from 'react-redux'
import { showModal } from 'src/store/message-modal/actions'
import FileStatus from './file-status'
import { DocumentType, IFileDetails } from 'src/store/documents-upload/types'
import { ApplicationState } from '../../store/rootReducer'
import {
  addFileStatus,
  clearFileStatus,
  clearUploadedFileCodes,
  getUploadedCompetencyMap,
  getUploadedFile
} from 'src/store/documents-upload/actions'
import * as S from './style'
import {
  ACCESSIBILITY_ERROR,
  ACCESSIBILITY_SUCCESS,
  THEME_GREY,
  THEME_PURPLE
} from 'src/styles/colors'
import { IDocumentsUploadProps } from './interfaces'
import { IoWarning } from 'react-icons/io5'
import Button from 'src/components/button/index'
import { MessageModalType } from 'src/store/message-modal/types'
import { ERROR } from 'src/constants'
import { useLocation } from 'react-router-dom'

const DocumentsUpload = (props: IDocumentsUploadProps) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const userState = useSelector(
    (state: ApplicationState) => state.userReducer.user
  )

  const documentsUploadState = useSelector(
    (state: ApplicationState) => state.documentsUploadReducer
  )
  const token = useSelector((state: ApplicationState) => state.userReducer.auth)

  const uploadInputRef = useRef(null)

  const onDropFile = (acceptedFiles: any[]) => {
    handleArrayOfFiles(acceptedFiles)
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFileDialogActive,
    isDragReject
  } = useDropzone({
    onDrop: onDropFile,
    accept: props.acceptedMimeTypes
  })

  useEffect(() => {
    dispatch(clearUploadedFileCodes())
    dispatch(clearFileStatus())
  }, [dispatch])

  useEffect(() => {
    if (
      userState &&
      userState.personalData &&
      userState.personalData.cpf &&
      token &&
      token.layerSevenToken
    ) {
      if (location.pathname === '/signup/upload-documents') {
        dispatch(
          getUploadedFile(
            userState.personalData.cpf,
            token && token.layerSevenToken,
            handleFile
          )
        )
      }

      if (location.pathname === '/signup/competency-map') {
        dispatch(
          getUploadedCompetencyMap(
            userState.personalData.cpf,
            token && token.layerSevenToken,
            handleFile
          )
        )
      }
    }
  }, [])

  const getExtensionsFromMimeTypes = () => {
    const mimeTypesAsArray = props.acceptedMimeTypes.split(',')
    let extensions = ''
    mimeTypesAsArray.forEach(
      (mimeType: string, index: number) =>
        (extensions +=
          '.' +
          mimeType.split('/')[1] +
          (index < mimeTypesAsArray.length - 1 ? ',' : ''))
    )
    return extensions
  }

  const getBorderColor = () => {
    if (isDragReject) return ACCESSIBILITY_ERROR
    else if (isDragActive) return ACCESSIBILITY_SUCCESS
    else if (isFileDialogActive) return THEME_PURPLE
    return THEME_GREY
  }

  const renderDropZone = () => (
    <S.DropzoneContainer
      {...getRootProps()}
      style={{ borderColor: getBorderColor() }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Solte seu documento</p>
      ) : (
        <p>Arraste e adicione seus documentos aqui</p>
      )}
    </S.DropzoneContainer>
  )

  const showErrorModal = (title: string, content: any) => {
    dispatch(showModal(MessageModalType.Error, title, <>{content}</>, 'voltar'))
  }

  const addFileStatusComponent = (component: any) => {
    dispatch(addFileStatus(component))
  }

  const handleFileFromSearchButton = (event: any) => {
    handleArrayOfFiles(event.target.files)
  }

  const handleArrayOfFiles = (arr: any[]) => {
    if (arr && arr.length > 0)
      [...arr].forEach((file) => {
        if (file.name.length < 99) {
          handleFile(file)
        } else {
          showErrorModal(ERROR, <p>Nome do arquivo maior que o permitido</p>)
        }
      })
  }

  const handleFile = (file: any) => {
    if (!userState || !userState.personalData?.cpf) {
      showErrorModal(ERROR, <p>Usuário precisa estar logado.</p>)
      return
    }

    const fileDetails: IFileDetails = {
      fileType: DocumentType.PersonalDocument,
      file: file,
      cpf: userState.personalData.cpf
    }

    const fileStatusKey = uuid()

    addFileStatusComponent(
      <FileStatus
        fileStatusKey={fileStatusKey}
        key={fileStatusKey}
        file={file}
        fileDetails={fileDetails}
        cpf={userState.personalData.cpf}
        acceptedExtensions={getExtensionsFromMimeTypes()}
        isModalReject={props.isModalReject}
        isUploadSolicitation={props.isUploadSolicitation}
      />
    )
  }

  // const deleteFile = (codeFile: any) => {
  //   if (codeFile) {
  //     if (
  //       userState &&
  //       userState.personalData &&
  //       userState &&
  //       userState.personalData.cpf &&
  //       token &&
  //       token.layerSevenToken
  //     ) {
  //       dispatch(
  //         deleteUploadedFileCode(
  //           userState.personalData.cpf,
  //           codeFile,
  //           codeFile,
  //           token.layerSevenToken
  //         )
  //       )
  //     }
  //   }
  // }

  const showWarning =
    props.isUploadPage &&
    documentsUploadState.uploadInformationData &&
    documentsUploadState.uploadInformationData.optionalChecklist &&
    documentsUploadState.uploadInformationData.requiredChecklist &&
    documentsUploadState.uploadedFileCodes.filter((file) => !!file.code)
      .length <
      documentsUploadState.uploadInformationData.optionalChecklist.length +
        documentsUploadState.uploadInformationData.requiredChecklist.length

  return (
    <>
      {props.title && (
        <Chakra.Heading
          w="90%"
          as="h1"
          size="xl"
          color="primary"
          d="flex"
          mt="10"
          mb="2"
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <Chakra.Text>{props.title}</Chakra.Text>
        </Chakra.Heading>
      )}
      {props.subTitle && (
        <Chakra.Text mb="5" fontSize="14px" color={THEME_GREY} w="90%">
          {props.subTitle}
        </Chakra.Text>
      )}
      {showWarning && (
        <S.WarningContainerFileCount>
          <IoWarning fontSize={32} color={THEME_PURPLE} />
          <S.WarningText>
            O número de documentos confirmados acima é diferente do número de
            upload de documentos. Certifique-se de que não esqueceu nenhum
            documento.
          </S.WarningText>
        </S.WarningContainerFileCount>
      )}
      <S.Container>
        <S.Upload>
          <Chakra.GridItem>
            <Chakra.GridItem>{renderDropZone()}</Chakra.GridItem>
            <Chakra.GridItem textAlign="center">OU</Chakra.GridItem>
            <Chakra.GridItem textAlign="center">
              <input
                type="file"
                style={{ display: 'none' }}
                ref={uploadInputRef}
                accept={getExtensionsFromMimeTypes()}
                onChange={handleFileFromSearchButton}
                multiple
              />
              <Button
                variant="outlined"
                onClick={() => {
                  if (uploadInputRef && uploadInputRef.current)
                    (uploadInputRef.current as any).click()
                }}
              >
                Pesquise no computador
              </Button>
            </Chakra.GridItem>
          </Chakra.GridItem>
        </S.Upload>
        <S.Status>
          <Chakra.GridItem>
            {documentsUploadState &&
            documentsUploadState.fileStatusComponents &&
            documentsUploadState.fileStatusComponents.length > 0
              ? documentsUploadState.fileStatusComponents.map((comp, index) => {
                  return (
                    <div key={index}>{isValidElement(comp) ? comp : null}</div>
                  )
                })
              : null}

            {/* {documentsUploadState &&
              documentsUploadState.data &&
              documentsUploadState.data.files &&
              documentsUploadState.data.files.map((file: any) => {
                const codeFile = file.code
                return (
                  <>
                    <Chakra.Grid pb={4}>
                      <Chakra.GridItem>
                        <Chakra.HStack spacing="-2px">
                          <S.FileStatusTextField>
                            {file.name}
                          </S.FileStatusTextField>
                          <S.FileStatusButton
                            w="auto"
                            onClick={() => {
                              deleteFile(codeFile)
                            }}
                          >
                            Excluir
                          </S.FileStatusButton>
                        </Chakra.HStack>
                      </Chakra.GridItem>
                      <S.FileStatus>Carregado com sucesso</S.FileStatus>
                    </Chakra.Grid>
                  </>
                )
              })} */}
          </Chakra.GridItem>
        </S.Status>
      </S.Container>
    </>
  )
}

export default DocumentsUpload
