import { THEME_PURPLE, THEME_WHITE } from 'src/styles/colors'
import { MessageIconProps } from './interface'

const MessagesCircleIcon = ({ fill, stroke, width }: MessageIconProps) => (
  <svg
    id="Mensagens"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height="90"
    viewBox="0 0 62 52"
  >
    <g id="Group_891" data-name="Group 891">
      <circle
        id="Ellipse_180"
        data-name="Ellipse 180"
        cx="23"
        cy="26"
        r="24"
        fill={fill}
        stroke={stroke}
      />
    </g>
    <g id="Comentar" transform="translate(5 5)">
      <path
        id="Path_34611"
        data-name="Path 34611"
        d="M572.433,1277.786a9.472,9.472,0,0,1-4.928-1.378l-5.567,1.075,1.879-5.276a7.613,7.613,0,0,1-.682-3.343v-.13a9.3,9.3,0,1,1,9.307,9.052Z"
        transform="translate(-554.439 -1248.12)"
        fill={THEME_PURPLE}
        stroke={THEME_WHITE}
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

export default MessagesCircleIcon
