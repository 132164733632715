import { UserProfileEnum } from '../login/types'

export enum MassRegistrationTypes {
  ON_UPLOAD_SUCCESS = '@uploadCsv/ON_UPLOAD_SUCCESS',
  ON_UPLOAD_ERROR = '@uploadCsv/ON_UPLOAD_ERROR',
  CLEAR_RESULT = '@uploadCsv/CLEAR_RESULT',
  CLEAR_MASS_REGISTRATION = '@uploadCsv/CLEAR_MASS_REGISTRATION'
}

export interface IMassRegistrationResult {
  name: string
  socialName: string
  cpf: string
  email: string
  councilNumber: string
  councilType: string
  councilState: string
  specialty: string
  celPhone: string
  role: UserProfileEnum
  status: string
  isError: boolean
}

export interface IMassRegistrationStates {
  error?: any
  result?: IMassRegistrationResult[]
}
