import * as S from './style'
import * as Chakra from '@chakra-ui/react'
import { THEME_GREY } from 'src/styles/colors'
import { IClinicsProps } from '../../interface'
import {
  cnpjMask,
  validateCnpj
} from 'src/utils/masks-validations/cnpj-helpers'
import { cepMask } from 'src/utils/masks-validations/cep-mask'
import { BiTrash, BiEdit } from 'react-icons/bi'
import { v4 } from 'uuid'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { fixedPhoneMask } from 'src/utils/masks-validations/fixed-phone-mask'
import Button from 'src/components/button/index'
import Select from 'src/components/input-select'
import { stateOption } from 'src/constants/options'
import CnpjInput from 'src/components/input-cnpj'
import CustomTextField from 'src/components/text-field'
import { IClinicInfo } from 'src/store/professional-information/types'
import { useState } from 'react'
import { isEmailValid } from 'src/utils/masks-validations/email-helpers'

const ClinicForm = ({
  clinicCnpj,
  clinicName,
  address,
  number,
  neighborhood,
  city,
  state,
  cep,
  secretaryName,
  secretaryEmail,
  clinicPhoneNumber,
  clinics,
  inputChange,
  selectChange,
  addClinicsInformation,
  updateClinicsInformation,
  deleteClinic,
  editClinic,
  register,
  errors
}: IClinicsProps) => {
  const [isEdittingClinic, setIsEdittingClinic] = useState(false)

  const colSpan1 = Chakra.useBreakpointValue({
    base: 6,
    xl: 6,
    lg: 6,
    md: 6,
    sm: 6
  })

  const colSpan2 = Chakra.useBreakpointValue({
    base: 3,
    xl: 3,
    lg: 3,
    md: 3,
    sm: 6
  })

  const colSpan3 = Chakra.useBreakpointValue({
    base: 2,
    xl: 2,
    lg: 2,
    md: 2,
    sm: 6
  })

  const colSpan4 = Chakra.useBreakpointValue({
    base: 3,
    xl: 3,
    lg: 3,
    md: 3,
    sm: 6
  })

  const colSpan5 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 6
  })

  const colSpan6 = Chakra.useBreakpointValue({
    base: 2,
    xl: 2,
    lg: 2,
    md: 4,
    sm: 6
  })

  const colSpan7 = Chakra.useBreakpointValue({
    base: 2,
    xl: 2,
    lg: 2,
    md: 6,
    sm: 6
  })

  const colStart = Chakra.useBreakpointValue({
    base: 4,
    xl: 4,
    lg: 4,
    md: 1,
    sm: 1
  })

  const validateFieldsClinic =
    !validateCnpj(clinicCnpj) ||
    !clinicName ||
    !address ||
    !number ||
    !neighborhood ||
    !city ||
    !state ||
    !cep ||
    !clinicPhoneNumber

  return (
    <>
      <Chakra.Grid templateColumns="repeat(6, 1fr)" gap={4}>
        <Chakra.GridItem colSpan={colSpan1} m="20px 0 0 0">
          <S.Heading>
            <S.Title>Informações das Clínicas</S.Title>
          </S.Heading>
          <Chakra.Text mb="5" ml="3" fontSize="14px" color={THEME_GREY}>
            Complete com os dados profissionais das suas clínicas.
          </Chakra.Text>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan2}>
          <Chakra.FormControl>
            <Chakra.FormLabel>CNPJ da Clínica</Chakra.FormLabel>
            <CnpjInput
              register={register('clinicCnpj')}
              name="clinicCnpj"
              onChange={inputChange}
              type="text"
              value={cnpjMask(clinicCnpj)}
              isDisabled={isEdittingClinic}
              isInvalid={
                !!clinicCnpj && !validateCnpj(clinicCnpj) ? true : false
              }
            />
            {clinicCnpj && !validateCnpj(clinicCnpj) ? (
              <S.ErrorLabel color="danger">
                <WarningTwoIcon color="danger" />
                *CNPJ Inválido
              </S.ErrorLabel>
            ) : (
              <S.ErrorLabel />
            )}
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan2}>
          <Chakra.FormControl>
            <Chakra.FormLabel>Nome da Clínica</Chakra.FormLabel>
            <CustomTextField
              register={register('clinicName')}
              type="text"
              value={clinicName}
              onChange={inputChange}
              data-testid="clinicName"
            />
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan3}>
          <Chakra.FormControl>
            <Chakra.FormLabel>CEP</Chakra.FormLabel>
            <CustomTextField
              register={register('cep')}
              type="text"
              value={cepMask(cep)}
              onChange={inputChange}
              data-testid="cep"
            />
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan4}>
          <Chakra.FormControl>
            <Chakra.FormLabel>Endereço</Chakra.FormLabel>
            <CustomTextField
              register={register('address')}
              type="text"
              value={address}
              onChange={inputChange}
              data-testid="address"
            />
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan5}>
          <Chakra.FormControl>
            <Chakra.FormLabel>Número</Chakra.FormLabel>
            <CustomTextField
              register={register('number')}
              maxLength={7}
              type="text"
              value={number}
              onChange={inputChange}
              data-testid="number"
            />
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan3}>
          <Chakra.FormControl>
            <Chakra.FormLabel>Bairro</Chakra.FormLabel>
            <CustomTextField
              register={register('neighborhood')}
              type="text"
              value={neighborhood}
              onChange={inputChange}
              data-testid="neighborhood"
            />
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan4}>
          <Chakra.FormControl>
            <Chakra.FormLabel>Cidade</Chakra.FormLabel>
            <CustomTextField
              register={register('city')}
              type="text"
              value={city}
              onChange={inputChange}
              data-testid="city"
            />
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan5}>
          <Chakra.FormControl>
            <Chakra.FormLabel>UF</Chakra.FormLabel>
            <Select
              data-testid="state"
              name="state"
              onChange={selectChange}
              value={state}
            >
              <>
                <option value="" disabled hidden>
                  Selecione
                </option>
                {stateOption}
              </>
            </Select>
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan3}>
          <Chakra.FormControl>
            <Chakra.FormLabel>Telefone da Clínica</Chakra.FormLabel>
            <CustomTextField
              register={register('clinicPhoneNumber')}
              type="text"
              value={fixedPhoneMask(clinicPhoneNumber)}
              onChange={inputChange}
              data-testid="clinicPhoneNumber"
            />
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan6}>
          <Chakra.FormControl>
            <Chakra.FormLabel>Nome da(o) Secretária(o)</Chakra.FormLabel>
            <CustomTextField
              register={register('secretaryName')}
              type="text"
              value={secretaryName}
              onChange={inputChange}
              data-testid="secretaryName"
              maxLength={512}
            />
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan7}>
          <Chakra.FormControl>
            <Chakra.FormLabel>E-mail da(o) Secretária(o)</Chakra.FormLabel>
            <CustomTextField
              register={register('secretaryEmail')}
              isInvalid={errors?.emailError}
              name="secretaryEmail"
              type="email"
              value={secretaryEmail}
              onChange={inputChange}
              data-testid="secretaryEmail"
            />
            {secretaryEmail && !isEmailValid(secretaryEmail) ? (
              <S.ErrorLabel color="danger">
                <WarningTwoIcon color="danger" />
                *Formato incorreto
              </S.ErrorLabel>
            ) : (
              <S.ErrorLabel></S.ErrorLabel>
            )}
          </Chakra.FormControl>
        </Chakra.GridItem>
      </Chakra.Grid>
      <Chakra.Grid templateColumns="repeat(4, 1fr)" gap={4} mt={6}>
        <Chakra.GridItem colSpan={colSpan1} colStart={colStart}>
          <Button
            type="button"
            onClick={() => {
              if (!isEdittingClinic) {
                addClinicsInformation()
              } else {
                updateClinicsInformation()
                setIsEdittingClinic(false)
              }
            }}
            disabled={validateFieldsClinic}
            variant="outlined"
          >
            {`${!isEdittingClinic ? 'Incluir' : 'Atualizar'} Clínica`}
          </Button>
        </Chakra.GridItem>
      </Chakra.Grid>
      {clinics && clinics.length > 0 && (
        <S.TitleClinic id="titleClinic">Clínicas cadastradas:</S.TitleClinic>
      )}
      <Chakra.Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <Chakra.GridItem colSpan={colSpan1}>
          <S.ClinicCardSection>
            {clinics &&
              clinics.map((clinic: IClinicInfo) => (
                <S.ClinicCard key={v4()}>
                  <S.ClinicCardName>
                    <Chakra.Box display="flex" alignItems="center">
                      {clinic.name}
                    </Chakra.Box>
                    <Chakra.VStack
                      display="flex"
                      justifyContent="center"
                      mb="-28px"
                    >
                      <S.ClinicCardIcon style={{ paddingRight: '25px' }}>
                        <BiEdit
                          size={24}
                          color={THEME_GREY}
                          onClick={() => {
                            setIsEdittingClinic(true)
                            editClinic(clinic)
                          }}
                          cursor="pointer"
                        />
                      </S.ClinicCardIcon>
                      <S.ClinicCardIcon>
                        <BiTrash
                          size={24}
                          color={THEME_GREY}
                          onClick={() => deleteClinic(clinic)}
                          cursor="pointer"
                        />
                      </S.ClinicCardIcon>
                    </Chakra.VStack>
                  </S.ClinicCardName>
                  <S.ClinicCardAdress>
                    {clinic.address}, {clinic.number}
                    <br />
                    {clinic.neighborhood}
                    <br />
                    {clinic.city}
                    <br />
                    {clinic.state}
                    <br />
                    CEP {clinic.postalCode}
                    <br />
                  </S.ClinicCardAdress>
                </S.ClinicCard>
              ))}
          </S.ClinicCardSection>
        </Chakra.GridItem>
      </Chakra.Grid>
    </>
  )
}

export default ClinicForm
