import { getMassRegistrationOriginFromEnum } from 'src/utils'
import {
  COUNTRIES,
  CRM_KINDS,
  STATES,
  SPECIALITIES,
  WORK_AREAS,
  SCHOOLING_LEVELS,
  MaritalState,
  Genders,
  MassRegistrationOrigin
} from '.'

export const stateOption = STATES.map((state) => (
  <option key={state} value={state}>
    {state}
  </option>
))

export const countriesOption = COUNTRIES.map((country) => (
  <option key={country} value={country}>
    {country}
  </option>
))

export const specialitysOption = SPECIALITIES.map((speciality) => (
  <option key={speciality} value={speciality}>
    {speciality}
  </option>
))

export const crmKindsOptions = CRM_KINDS.map((crmKind, index) => (
  <option key={index} value={crmKind}>
    {crmKind}
  </option>
))

export const workAreaOption = WORK_AREAS.map((workArea) => (
  <option key={workArea} value={workArea}>
    {workArea}
  </option>
))

export const schoolingOption = SCHOOLING_LEVELS.map((schoolingLevel) => (
  <option key={schoolingLevel} value={schoolingLevel}>
    {schoolingLevel}
  </option>
))

export const maritalStatusOptions: any[] = Object.values(MaritalState).map(
  (maritalStatus) => (
    <option key={maritalStatus} value={maritalStatus}>
      {maritalStatus}
    </option>
  )
)
export const genderOptions: any[] = Object.values(Genders).map((gender) => (
  <option key={gender} value={gender}>
    {gender}
  </option>
))

export const registrationOriginOptions = Object.values(MassRegistrationOrigin)
  .filter((e) => typeof e !== 'string')
  .map((massRegistrationOrigin: any) => (
    <option key={massRegistrationOrigin} value={massRegistrationOrigin}>
      {getMassRegistrationOriginFromEnum(
        massRegistrationOrigin as MassRegistrationOrigin
      )}
    </option>
  ))
