import { RegisteredInfoTypes } from './types'
import { IThunkAction } from '../shared/types'

const initialState = {
  registeredInfo: {},
  error: null
}

export default function registeredInfoReducer(
  state = initialState,
  action: IThunkAction
) {
  switch (action.type) {
    case RegisteredInfoTypes.GET_REGISTERED_INFO_SUCCESS:
      return {
        ...state,
        registeredInfo: action.payload,
        error: null
      }
    case RegisteredInfoTypes.GET_REGISTERED_INFO_ERROR:
      return {
        ...state,
        registeredInfo: {},
        error: action.payload
      }
    case RegisteredInfoTypes.UPDATE_PERSONAL_REGISTERED_INFO:
      return {
        ...state,
        registeredInfo: action.payload,
        error: null
      }
    case RegisteredInfoTypes.UPDATE_PERSONAL_REGISTERED_INFO_ERROR:
      return {
        ...state,
        registeredInfo: {},
        error: action.payload
      }
    case RegisteredInfoTypes.UPDATE_REGISTERED_PROFESSIONAL_INFO:
      return {
        ...state,
        registeredInfo: action.payload,
        error: null
      }
    case RegisteredInfoTypes.UPDATE_REGISTERED_PROFESSIONAL_INFO_ERROR:
      return {
        ...state,
        registeredInfo: {},
        error: action.payload
      }
    case RegisteredInfoTypes.UPDATE_REGISTERED_CLINIC_INFO:
      return {
        ...state,
        registeredInfo: action.payload,
        error: null
      }
    case RegisteredInfoTypes.UPDATE_REGISTERED_CLINIC_INFO_ERROR:
      return {
        ...state,
        registeredInfo: {},
        error: action.payload
      }
    default:
      return state
  }
}
