import { BrowserRouter, Switch } from 'react-router-dom'
import { CreateAccount } from 'src/pages/signup'
import Login from 'src/pages/login'
import PersonalInformation from 'src/pages/signup/personal-information'
import SignUpSuccess from 'src/pages/feedback/success'
import SignUpFailed from 'src/pages/feedback/failed'
import SignUpCompleteSuccess from 'src/pages/feedback/complete-success'
import SignUpCompleteError from 'src/pages/feedback/complete-error'
import Dashboard from 'src/pages/dashboard'
import ProfessionalInformation from 'src/pages/signup/professional-information'
import AdditionalInfo from 'src/pages/signup/additional-info'
import DocumentsUploadForm from 'src/pages/signup/documents-upload'
import CompetencyMap from 'src/pages/signup/competency-map'
import PasswordReset from 'src/pages/password-reset'
import DocumentsConsult from 'src/pages/documents-consult'
import SolicitationDetails from 'src/pages/solicitation-details'
import CreateRequest from 'src/pages/create-request'
import Solicitation from 'src/pages/solicitation'
import Home from 'src/pages/home'
import PublicRoute from './public-route'
import PrivateRoute from './private-route'
import ROUTING_PATHS from './paths'
import RegisteredInformation from 'src/pages/registered-information'
import ForgotPassword from 'src/pages/forgot-password'
import { VerifyRouterFlag } from 'src/components/flags/router'

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path={ROUTING_PATHS.Forgot_Password}>
          <ForgotPassword />
        </PublicRoute>

        <PublicRoute exact path={ROUTING_PATHS.Reset_Password}>
          <PasswordReset />
        </PublicRoute>

        <PublicRoute exact path={ROUTING_PATHS.Root}>
          <Login />
        </PublicRoute>

        <PublicRoute exact path={ROUTING_PATHS.SignUp_CreateAccount}>
          <CreateAccount />
        </PublicRoute>

        <PrivateRoute exact path={`${ROUTING_PATHS.Solicitation}/:status`}>
          <VerifyRouterFlag flag={window._flags_.ROUTE_SOLICITATIONS_ENABLED}>
            <Solicitation />
          </VerifyRouterFlag>
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.Solicitation}>
          <VerifyRouterFlag flag={window._flags_.ROUTE_SOLICITATIONS_ENABLED}>
            <Solicitation />
          </VerifyRouterFlag>
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.SignUp_Success}>
          <SignUpSuccess />
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.SignUp_Fail}>
          <SignUpFailed />
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.SignUp_CompleteSuccess}>
          <SignUpCompleteSuccess />
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.SignUp_CompleteError}>
          <SignUpCompleteError />
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.SignUp_PersonalInfo}>
          <PersonalInformation />
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.SignUp_ProfessionalInfo}>
          <ProfessionalInformation />
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.SignUp_AdditionalInfo}>
          <AdditionalInfo />
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.SignUp_CompetencyMap}>
          <CompetencyMap />
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.SignUp_UploadDocuments}>
          <DocumentsUploadForm />
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.Home}>
          <Home />
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.Dashboard}>
          <Dashboard />
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.Documents_Consult}>
          <DocumentsConsult />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={`${ROUTING_PATHS.Solicitation_Details}/:id/:technicalEvaluationId?`}
        >
          <VerifyRouterFlag
            flag={window._flags_.ROUTE_SOLICITATION_DETAILS_ENABLED}
          >
            <SolicitationDetails />
          </VerifyRouterFlag>
        </PrivateRoute>

        <PrivateRoute exact path={ROUTING_PATHS.Create_Request}>
          <CreateRequest />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={`${ROUTING_PATHS.Registered_Information}/:id`}
        >
          <RegisteredInformation />
        </PrivateRoute>

        <PublicRoute path="*">
          <Login />
        </PublicRoute>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
