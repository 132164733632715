import Modal from 'src/components/modal'
import * as Chakra from '@chakra-ui/react'
import * as S from './styles'
import { MessageModalType } from 'src/store/message-modal/types'
import { THEME_PURPLE } from 'src/styles/colors'
import { Dispatch, SetStateAction } from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'

interface HistoryModalProps {
  showHistoryModal: boolean
  toggleHistoryModal: Dispatch<SetStateAction<boolean>>
  historyDocuments: any
}

const HistoryModal = ({
  showHistoryModal,
  toggleHistoryModal,
  historyDocuments
}: HistoryModalProps) => {
  moment.locale('pt-br')
  return (
    <Modal
      isOpen={showHistoryModal}
      onClose={toggleHistoryModal}
      size="sm"
      title="Histórico"
      titleButton=""
      type={MessageModalType.History}
      isCentered={true}
      scrollBehavior="inside"
    >
      {Object.keys(historyDocuments).length === 0 ? (
        <p>Nenhum dado foi encontrado.</p>
      ) : (
        <Chakra.Grid>
          <Chakra.GridItem>
            <Chakra.Text fontSize="14px" lineHeight={1}>
              Aqui você tem acesso a todas as tarefas realizadas com este
              documento.
            </Chakra.Text>
            <S.ModalSubtitle>
              Alterado em:{' '}
              {moment(historyDocuments.lastModificationDate).format(
                'dddd, DD [de] MMMM'
              )}
            </S.ModalSubtitle>
          </Chakra.GridItem>
          {historyDocuments.logFiles.map((log: any, index: number) => (
            <Chakra.GridItem mb={4} key={index}>
              <S.ModalDate
                textAlign="start"
                fontSize="14px"
                color={THEME_PURPLE}
              >
                {moment(log.modificationDate).format('dddd, DD [de] MMMM')}
              </S.ModalDate>
              <Chakra.Divider
                borderColor={THEME_PURPLE}
                borderBottomWidth="1.5px"
              />
              {log.logItens.map((item: any, indexLog: number) => (
                <Chakra.Flex key={indexLog} direction={'column'}>
                  <S.NameAuthor color={THEME_PURPLE}>
                    {item.fullName}
                  </S.NameAuthor>
                  <S.ModalText lineHeight={1} fontSize="15px">
                    <S.TextType>Tipo do documento:</S.TextType>{' '}
                    {item.type ? item.type : '-'}
                  </S.ModalText>
                  <S.ModalText lineHeight={1} fontSize="15px">
                    <S.TextType>Status:</S.TextType> {item.status}
                  </S.ModalText>
                  {item.justification ? (
                    <S.ModalText lineHeight={1} fontSize="15px">
                      <S.TextType>Motivo:</S.TextType> {item.justification}
                    </S.ModalText>
                  ) : null}
                </Chakra.Flex>
              ))}
            </Chakra.GridItem>
          ))}
        </Chakra.Grid>
      )}
    </Modal>
  )
}

export default HistoryModal
