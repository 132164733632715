import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { RiLockUnlockLine } from 'react-icons/ri'
import * as Chakra from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import HeadingText from 'src/components/signup/heading-text'
import { loginSchema } from 'src/utils/masks-validations/password-validation'
import PasswordField from 'src/components/password-field'
import { ApplicationState } from 'src/store/rootReducer'
import { IResetPasswordPayload } from 'src/store/login/types'
import { resetPassword } from 'src/store/login/actions'
import * as storage from 'src/utils/storage'
import { CPF_KEY_RESET_PASSWORD } from 'src/store/login/constants'
import { THEME_PURPLE } from 'src/styles/colors'
import * as S from './styles'
import Button from 'src/components/button/index'
import Header from 'src/components/header'
import { showModal } from 'src/store/message-modal/actions'
import { MessageModalType } from 'src/store/message-modal/types'
import ROUTING_PATHS from 'src/routes/paths'
import { TIMEOUT_AFTER_RESET_PASSWORD, ERROR } from 'src/constants'

const PasswordReset = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [showPassConfirmationError, setShowPassConfirmationError] =
    useState(false)
  const [cpf, setCpf] = useState('')

  const { register } = useForm({
    resolver: yupResolver(loginSchema)
  })

  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  })

  const userState = useSelector((state: ApplicationState) => state.userReducer)

  const token = useSelector((state: ApplicationState) => state.userReducer.auth)

  useEffect(() => {
    const storedCpf = storage.getStorage(CPF_KEY_RESET_PASSWORD)
    if (storedCpf && storedCpf.length > 0) setCpf(storedCpf)

    return () => {
      storage.removeFromStorage(CPF_KEY_RESET_PASSWORD)
    }
  }, [])

  let redirectTimeout: any

  const resetPasswordFromForm = () => {
    setShowPassConfirmationError(false)
    const { currentPassword, newPassword, newPasswordConfirmation } = values

    if (newPassword !== newPasswordConfirmation) {
      setShowPassConfirmationError(true)
      return
    }

    if (
      !userState.user ||
      !userState.user.personalData ||
      !userState.user.personalData.cpf ||
      userState.user.personalData.cpf.length < 1
    )
      return

    const payload: IResetPasswordPayload = {
      cpf,
      currentPassword,
      newPassword,
      confirmNewPassword: newPasswordConfirmation
    }

    if (token && token.layerSevenToken) {
      dispatch(
        resetPassword(
          payload,
          token.layerSevenToken,
          () => {
            dispatch(
              showModal(
                MessageModalType.Success,
                'Sucesso',
                'Senha alterada com sucesso.',
                'Voltar para o login',
                () => {
                  if (redirectTimeout) {
                    clearTimeout(redirectTimeout)
                    redirectTimeout = undefined
                  }
                  history.push(ROUTING_PATHS.Root)
                }
              )
            )
            redirectTimeout = setTimeout(() => {
              history.push(ROUTING_PATHS.Root)
            }, TIMEOUT_AFTER_RESET_PASSWORD)
          },
          () => {
            dispatch(
              showModal(
                MessageModalType.Error,
                ERROR,
                'Não foi possível redefinir a senha.',
                'Voltar'
              )
            )
          }
        )
      )
    }
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
  }

  const isFormValid =
    !values.currentPassword ||
    !values.newPassword ||
    !values.newPasswordConfirmation

  return (
    <>
      <Header border={true} />
      <S.Container>
        <Chakra.VStack align="stretch">
          <Chakra.Box>
            <HeadingText
              icon={<RiLockUnlockLine fill={THEME_PURPLE} />}
              subtitle="Digite a nova senha que deseja usar em sua conta."
              title="Alterar senha"
            />
          </Chakra.Box>
          <Chakra.Box maxW={350}>
            <Chakra.FormLabel>Senha atual</Chakra.FormLabel>
            <PasswordField
              register={register('currentPassword')}
              onChange={onInputChange}
              value={values.currentPassword}
            />
          </Chakra.Box>
          <Chakra.Box maxW={350}>
            <Chakra.FormLabel>Nova senha</Chakra.FormLabel>
            <PasswordField
              register={register('newPassword')}
              onChange={onInputChange}
              value={values.newPassword}
              showRequirements
            />
          </Chakra.Box>
          <Chakra.Box maxW={350} paddingTop={5}>
            <Chakra.FormLabel>Repetir nova senha</Chakra.FormLabel>
            <PasswordField
              register={register('newPasswordConfirmation')}
              onChange={onInputChange}
              value={values.newPasswordConfirmation}
            />
            {showPassConfirmationError && (
              <S.PasswordMatchError>
                As senhas digitadas não são as mesmas.
              </S.PasswordMatchError>
            )}
          </Chakra.Box>
        </Chakra.VStack>
        <Chakra.Grid templateColumns="repeat(5, 1fr)" gap={4}>
          <Chakra.GridItem colSpan={5}>
            <Chakra.Divider
              borderColor="secondary"
              marginTop="40px"
              marginBottom="20px"
            />
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={1} colStart={4}>
            <Link onClick={() => history.goBack()} to="#">
              <Button variant="outlined">Cancelar</Button>
            </Link>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={1}>
            <Button
              variant="normal"
              onClick={resetPasswordFromForm}
              disabled={isFormValid}
            >
              Salvar
            </Button>
          </Chakra.GridItem>
        </Chakra.Grid>
      </S.Container>
    </>
  )
}

export default PasswordReset
