import { THEME_PURPLE } from 'src/styles/colors'
import * as Chakra from '@chakra-ui/react'
import styled from 'styled-components'

export const ModalDate = styled(Chakra.Text)`
  font-weight: 600;
  font-size: 14px;
  text-align: start;
`

export const ModalSubtitle = styled(Chakra.Text)`
  font-size: 15px;
  font-weight: 600;
  color: ${THEME_PURPLE};
  padding: 12px 0;
`

export const ModalText = styled(Chakra.Text)`
  font-size: 14px;
  text-align: start;
  margin-top: 10px;
`

export const NameAuthor = styled(Chakra.Text)`
  font-size: 14px;
  text-align: start;
  margin-top: 10px;
  font-weight: 700;
  text-transform: uppercase;
`

export const TextType = styled.span`
  font-weight: 500;
`
