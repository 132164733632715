import * as S from './styles'
import * as Chakra from '@chakra-ui/react'
import { THEME_GREY } from 'src/styles/colors'
import Button from 'src/components/button'

const AlertModal = () => {
  return (
    <S.Box>
      <S.AlertModal>
        <Chakra.Box display="flex" alignItems="center">
          <S.ModalTitle>Histórico</S.ModalTitle>
          <S.ModalLink>Ver todos os alertas</S.ModalLink>
        </Chakra.Box>
        <Chakra.Spacer />

        <Chakra.Divider
          borderColor="#00000029"
          marginTop="1vh"
          marginBottom="2vh"
        />
        <Chakra.Box
          maxHeight="80px"
          overflow="auto"
          sx={{
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '3px',
              borderRadius: '8px',
              backgroundColor: `rgba(0, 0, 0, 0.05)`
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: `#707070`
            }
          }}
        >
          <Chakra.Text color={THEME_GREY} fontSize="10px">
            10/05/2021
          </Chakra.Text>
          <Chakra.Checkbox size="md" colorScheme="gray">
            <Chakra.Text fontSize="16px" color={THEME_GREY} fontWeight="bold">
              Severidade crítica
            </Chakra.Text>
          </Chakra.Checkbox>
          <S.AlertDescription>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </S.AlertDescription>
        </Chakra.Box>
        <Button variant="normal" maxW="85px" fontSize="12px">
          Ver detalhes
        </Button>
      </S.AlertModal>
    </S.Box>
  )
}

export default AlertModal
