import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import * as S from './styles'
import InfiniteScroll from 'react-infinite-scroll-component'

export const statusDescription = (status: string) => {
  const statusDescriptions: any = {
    Nova: 'Solicitação criada pelo médico porém não analisada.',
    'Em análise': 'Solicitação em análise pelo relacionamento médico.',
    Rejeitada: 'Solicitação rejeitada após análise',
    Pendência: 'Solicitação com pendência',
    Aprovada: 'Solicitação aprovada',
    Bloqueado: 'Solicitação bloqueada',
    Cancelada: 'Solicitação cancelada',
    'Aguardando Aprovação do Diretor': 'Solicitação aguardando diretor'
  }

  return statusDescriptions[status]
}

const TableHead = ({ columns, handleSorting }: any) => {
  const [sortField, setSortField] = useState('')
  const [order, setOrder] = useState('asc')

  const handleSortingChange = (accessor: any) => {
    const sortOrder = accessor === sortField && order === 'asc' ? 'desc' : 'asc'
    setSortField(accessor)
    setOrder(sortOrder)
    handleSorting(accessor, sortOrder)
  }

  return (
    <S.THead>
      <tr>
        {columns.map(({ label, accessor, sortable }: any) => {
          const cl = sortable
            ? sortField && sortField === accessor && order === 'asc'
              ? 'up'
              : sortField && sortField === accessor && order === 'desc'
              ? 'down'
              : 'default'
            : ''

          return (
            <S.Th
              key={accessor}
              className={cl}
              onClick={() => handleSortingChange(accessor)}
            >
              {label}
              {cl === 'up' && <S.ArrowUp />}
              {cl === 'down' && <S.ArrowDown />}
              {cl === 'default' && <S.ArrowInvisible />}
            </S.Th>
          )
        })}
      </tr>
    </S.THead>
  )
}

const OrderStatusRow = ({ data, item }: any) => {
  const history = useHistory()
  function sendTo(id: any, technicalEvaluationId: any) {
    if (window._flags_.ROUTE_SOLICITATION_DETAILS_ENABLED) {
      if (technicalEvaluationId) {
        history.push(`/solicitation-details/${id}/${technicalEvaluationId}`)
      } else {
        history.push(`/solicitation-details/${id}`)
      }
    }
  }
  return (
    <S.Tr
      key={item}
      onClick={() => sendTo(data.id, data.technicalEvaluationId)}
    >
      <S.Td>{data.applicantName}</S.Td>
      <S.Td>
        {data.councilType} {data.councilNumber} - {data.councilState}
      </S.Td>
      <S.Td>{data.submissionDate}</S.Td>
      {data?.hospitals?.length > 1 ? (
        <>
          <S.TdHospital>
            <S.TooltipCard
              label={data.hospitalsStatus.map((hospital: any) => (
                <span key={hospital}>
                  <S.HospitalName> {hospital.name} </S.HospitalName>
                  <S.HospitalStatus status={hospital.status}>
                    {hospital.status}
                  </S.HospitalStatus>
                </span>
              ))}
            >
              <span>
                <S.HospitalIcon hospitals={data?.hospitals?.length} />
              </span>
            </S.TooltipCard>

            <S.Hospital>{data.hospitals[0]}</S.Hospital>
          </S.TdHospital>
        </>
      ) : (
        <S.Td>{data.hospitals}</S.Td>
      )}
      <S.Td>
        <S.StatusBox status={data.status}>
          <S.StatusText alignItems="center">{data.status}</S.StatusText>
          <S.TooltipCard label={statusDescription(data.status)}>
            <span>
              <S.StatusIcon status={data.status} />
            </span>
          </S.TooltipCard>
        </S.StatusBox>
      </S.Td>
    </S.Tr>
  )
}

const renderRow = (data: any, item: any) => (
  <OrderStatusRow key={item} item={item} data={data} />
)

const TableBody = ({ tableData }: any) => {
  return <tbody>{tableData.map(renderRow)}</tbody>
}

const Table = ({ solicitations, update }: any) => {
  const [tableData, setTableData] = useState(solicitations)

  useEffect(() => {
    setTableData(solicitations)
  }, [solicitations])

  const columns = [
    { label: 'Nome do solicitante', accessor: 'applicantName', sortable: true },
    {
      label: 'Conselho',
      accessor: 'councilType' && 'councilNumber' && 'councilState',
      sortable: true
    },
    { label: 'Data de submissão', accessor: 'submissionDate', sortable: true },
    { label: 'Hospital', accessor: 'hospitals', sortable: true },
    { label: 'Status', accessor: 'status', sortable: true }
  ]

  const handleSorting = (sortField: string | number, sortOrder: string) => {
    if (sortField) {
      const sorted = [...tableData].sort((a: any, b: any) => {
        if (a[sortField] === null) return 1
        if (b[sortField] === null) return -1
        if (a[sortField] === null && b[sortField] === null) return 0
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true
          }) * (sortOrder === 'asc' ? 1 : -1)
        )
      })
      setTableData(sorted)
    }
  }

  return (
    <>
      {solicitations && solicitations.length > 0 ? (
        <InfiniteScroll
          dataLength={solicitations.length}
          next={update}
          hasMore={true}
          loader={<h2></h2>}
          scrollableTarget="tableContainerId"
        >
          <S.Table>
            <TableHead {...{ columns, handleSorting }} />
            <TableBody {...{ columns, tableData }} />
          </S.Table>
        </InfiniteScroll>
      ) : (
        <h2>Nenhuma solicitação foi encontrada.</h2>
      )}
    </>
  )
}

export default Table
