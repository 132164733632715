import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import {
  THEME_GREY,
  THEME_MEDIUM_GREY,
  THEME_PURPLE,
  THEME_WHITE
} from 'src/styles/colors'

export const Input = styled(Chakra.Input)`
  background-color: ${THEME_WHITE};
  border: 1px solid ${THEME_GREY};
  cursor: pointer;
  height: 46px;
  color: ${THEME_GREY};
  color: ${(props) => (props.value ? THEME_GREY : THEME_MEDIUM_GREY)};

  &:focus {
    border: 2px solid ${THEME_PURPLE};
    background-color: white;
    outline-style: none;
    box-shadow: none;
    color: ${THEME_GREY};
  }

  ::-webkit-calendar-picker-indicator {
    opacity: 0.4;
    margin-right: -8px;
  }
`
