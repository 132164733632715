import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiLockUnlockLine } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import * as Chakra from '@chakra-ui/react'
import HeadingText from 'src/components/signup/heading-text'
import { THEME_PURPLE } from 'src/styles/colors'
import * as S from './styles'
import Button from 'src/components/button/index'
import Header from 'src/components/header'
import CpfInput from 'src/components/input-cpf'
import { Props } from './interface'
import ROUTING_PATHS from 'src/routes/paths'
import { forgotPasswordWithoutRecaptcha } from 'src/store/forgot-password/actions'
import { validateCpf } from 'src/utils/masks-validations/cpf-helpers'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { useHistory } from 'react-router-dom'
import { MessageModalType } from 'src/store/message-modal/types'
import Modal from 'src/components/modal'

const ForgotPassword = () => {
  const [values, setValues] = useState<Props>({ cpf: '' })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [recaptcha, setRecaptcha] = useState('')
  const [showModal, toggleModal] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const { register } = useForm()

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
  }

  let redirectTimeout: any

  // const disabledButton = !validateCpf(values.cpf) || _.isEmpty(recaptcha)
  const disabledButton = !validateCpf(values.cpf)

  const submitForm = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()

    // dispatch(
    //   forgotPassword(values.cpf, recaptcha, () => {
    //     toggleModal(true)
    //     redirectTimeout = setTimeout(() => {
    //       history.push(ROUTING_PATHS.Root)
    //     }, 5000)
    //   })
    // )

    dispatch(
      forgotPasswordWithoutRecaptcha(values.cpf, () => {
        toggleModal(true)
        redirectTimeout = setTimeout(() => {
          history.push(ROUTING_PATHS.Root)
        }, 5000)
      })
    )
  }

  const colSpanHeader = Chakra.useBreakpointValue({
    md: 2,
    sm: 1
  })

  const colSpanInput = Chakra.useBreakpointValue({
    lg: 1,
    md: 3,
    sm: 3
  })

  const colStartInput = Chakra.useBreakpointValue({
    lg: 3,
    md: 2,
    sm: 2
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const verifyCallback = (code: string | null) => {
    if (code != null) {
      setRecaptcha(code)
    } else {
      setRecaptcha('')
    }
  }

  const sendToHome = () => {
    if (redirectTimeout) {
      clearTimeout(redirectTimeout)
      redirectTimeout = undefined
    }

    history.push(ROUTING_PATHS.Root)
  }

  return (
    <>
      <Header border={true} />
      <S.Container>
        <Chakra.Grid>
          <Chakra.GridItem colSpan={colSpanHeader} colStart={1}>
            <HeadingText
              align="center"
              icon={<RiLockUnlockLine fill={THEME_PURPLE} />}
              subtitle="Por favor Informe o cpf cadastrado abaixo. Te enviaremos as instruções por e-mail."
              title="Recupere sua senha"
            />
          </Chakra.GridItem>
        </Chakra.Grid>
        <form onSubmit={submitForm}>
          <Chakra.Grid templateColumns="repeat(5, 1fr)">
            <Chakra.GridItem colSpan={colSpanInput} colStart={colStartInput}>
              <Chakra.FormControl>
                <Chakra.FormLabel>*CPF</Chakra.FormLabel>
                <CpfInput
                  register={register}
                  name="cpf"
                  onChange={inputChange}
                  type="text"
                  placeholder="Digite o CPF"
                  value={values.cpf}
                />
              </Chakra.FormControl>
            </Chakra.GridItem>

            <Chakra.GridItem
              colSpan={colSpanInput}
              colStart={colStartInput}
              marginTop={3}
              marginBottom={5}
            >
              {values.cpf && !validateCpf(values.cpf) && (
                <S.ErrorLabel color="danger">
                  <WarningTwoIcon color="danger" />
                  *CPF Inválido
                </S.ErrorLabel>
              )}
              <S.ForgotPassword to={ROUTING_PATHS.Login}>
                Voltar para o login
              </S.ForgotPassword>
            </Chakra.GridItem>

            {/* <Chakra.GridItem colSpan={1} colStart={3} marginBottom={10}>
              <S.Recaptcha
                sitekey="6LcJjacfAAAAAPRi9LH5UzAH_l5cbaiXXQ366Qz2"
                onChange={verifyCallback}
              />
            </Chakra.GridItem> */}

            <Chakra.GridItem colSpan={colSpanInput} colStart={colStartInput}>
              <Chakra.FormControl>
                <Button
                  variant="normal"
                  type="submit"
                  disabled={disabledButton}
                >
                  Enviar
                </Button>
              </Chakra.FormControl>
            </Chakra.GridItem>
          </Chakra.Grid>
        </form>

        {showModal && (
          <Modal
            isOpen={showModal}
            onClose={toggleModal}
            size="sm"
            title="Sucesso"
            titleButton=""
            type={MessageModalType.Success}
          >
            <Chakra.Grid>
              <Chakra.GridItem>
                <p>
                  Uma nova senha provisória foi enviada para o e-mail cadastrado
                </p>
              </Chakra.GridItem>
              <Chakra.Grid marginTop="5" templateColumns="1fr" gap={4}>
                <Chakra.GridItem colSpan={2}>
                  <Button variant="normal" onClick={() => sendToHome()}>
                    Ir para home
                  </Button>
                </Chakra.GridItem>
              </Chakra.Grid>
            </Chakra.Grid>
          </Modal>
        )}
      </S.Container>
    </>
  )
}

export default ForgotPassword
