import { THEME_GREY, THEME_PURPLE } from '../../../styles/colors'
import styled, { css } from 'styled-components'
import * as Chakra from '@chakra-ui/layout'
import { Banner } from 'src/assets/img/index'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  background: url(${Banner}) no-repeat center center;
`
export const ContainerText = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10vh 0;
  padding: 10vh;
  background-color: white;
  @media (max-width: 900px) {
    width: 80%;
    padding: 5vh;
  }
`
const Text = css`
  margin-bottom: 32px;
  text-align: center;
`
export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SuccessText = styled(Chakra.Text)`
  margin: 0 auto;
  top: 243px;
  text-align: center;
  margin-bottom: 206px;
`

export const TitleText = styled(Chakra.Text)`
  ${Text};
  font-size: 36px;
  color: ${THEME_PURPLE};
  font-family: SoletoBold;
`

export const DescriptionText = styled(Chakra.Text)`
  ${Text};
  font-size: 18px;
  color: ${THEME_GREY};
`
