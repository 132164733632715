import { FilesListTypes, IFilesList } from './types'

const initialState: IFilesList = {
  files: [],
  registrationStep: null,
  error: null,
  deleteFileLoading: false
}

const filesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FilesListTypes.GET_FILES_SUCCESS:
      return {
        ...state,
        files: action.payload.files,
        error: null,
        deleteFileLoading: false
      }

    case FilesListTypes.GET_REGISTRATION_STEP_SUCCESS:
      return {
        ...state,
        registrationStep: action.payload.registrationStep,
        error: null
      }

    case FilesListTypes.GET_FILES_ERROR:
      return {
        ...state,
        files: [],
        registrationStep: null,
        error: action.payload.error
      }

    case FilesListTypes.SHOW_DELETE_LOADING:
      return {
        ...state,
        deleteFileLoading: action.payload.showLoading
      }

    case FilesListTypes.CLEAR_FILES_LIST:
      return initialState

    default:
      return state
  }
}
export default filesReducer
