export const enum DownloadFiles {
  DOWNLOAD_FILES_ERROR = '@files/DOWNLOAD_FILES_ERROR',
  DOWNLOAD_FILES_SUCCESS = '@files/DOWNLOAD_FILES_SUCCESS',
  DOWNLOAD_WORKFLOW_FILES_ERROR = '@files/DOWNLOAD_FILES_ERROR',
  DOWNLOAD_WORKFLOW_FILES_SUCCESS = '@files/DOWNLOAD_FILES_SUCCESS',
  CLEAR_DOWNLOAD_FILES = '@files/DOWNLOAD_FILES_SUCCESS'
}

export interface IDownloadFilesStates {
  file: any
  error: any
}
