import Header from 'src/components/header'
import * as Chakra from '@chakra-ui/react'
import * as S from './styles'
import NavigationMenu from 'src/components/nav'
import HeadingText from 'src/components/signup/heading-text'
import { useHistory, useParams } from 'react-router-dom'
import Button from 'src/components/button'
import RegisteredProfessionalInfo from './components/professional-info'
import RegisteredPersonalInformation from './components/personal-information'
import RegisteredAdditionalInfo from './components/additional-info'
import RegisteredCliniclnfo from './components/clinic-info'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'src/store/rootReducer'
import {
  getRegisteredInfo,
  updateRegisteredClinicInfo
} from 'src/store/registered-info/actions'
import moment from 'moment'
import { IClinicInfo } from 'src/store/professional-information/types'
import _ from 'lodash'

const RegisteredInformation = () => {
  const token = useSelector((state: ApplicationState) => state.userReducer.auth)
  const solicitation: any = useSelector(
    (state: ApplicationState) => state.solicitationDetailReducer.solicitation
  )
  const registeredInfoState = useSelector(
    (state: ApplicationState) => state.registeredInfoReducer.registeredInfo
  )

  const dispatch = useDispatch()
  const history = useHistory()

  const [values, setValues] = useState({
    fullName: '',
    socialName: '',
    rg: '',
    rgExpeditor: '',
    emissionDate: '',
    placeOfBirth: '',
    citizenship: '',
    dateBirth: '',
    gender: '',
    maritalStatus: '',
    partnerName: '',
    email: '',
    celPhone: '',
    registeredHospitals: '',
    crmKind: '',
    crmNumber: '',
    crmState: '',
    mainSpecialty: '',
    rqE1: '',
    actuationArea1: '',
    secoundSpecialty: '',
    rqE2: '',
    actuationArea2: '',
    educationLevel: '',
    completionDate: '',
    cnpj: '',
    memberTeamHospitals: '',
    interestResearch: '',
    teamOrBossName: '',
    crmBossNumber: '',
    reason: '',
    descriptionInterest: '',
    clinicCnpj: '',
    clinicName: '',
    address: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    cep: '',
    clinicPhoneNumber: '',
    secretaryName: '',
    secretaryEmail: '',
    cpf: '',
    zipCode: '',
    homeAddress: '',
    numberHome: '',
    uf: '',
    district: '',
    cityHome: '',
    complement: '',
    phoneNumber: '',
    soccerTeam: '',
    observationHobbies: '',
    enjoyWine: '',
    musicalGenre: ''
  })
  const [clinicsInfo, setClinicsInfo] = useState<IClinicInfo[]>([])
  const { id: idSolicitation } = useParams<any>()

  const cpf = solicitation.cpfHealthProfessional
  useEffect(() => {
    if (token && token.layerSevenToken) {
      dispatch(
        getRegisteredInfo(
          solicitation.cpfHealthProfessional,
          token.layerSevenToken
        )
      )
    }
  }, [dispatch, solicitation.cpfHealthProfessional])

  const inputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    setValues({
      ...values,
      [name]: value
    })
  }

  const selectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
  }

  const clearClinicValues = () => {
    setValues({
      ...values,
      clinicCnpj: '',
      clinicName: '',
      address: '',
      number: '',
      neighborhood: '',
      city: '',
      state: '',
      cep: '',
      clinicPhoneNumber: '',
      secretaryName: '',
      secretaryEmail: ''
    })
  }

  const deleteClinic = (clinic: IClinicInfo) => {
    const newList = clinicsInfo.filter(
      (clinicsMap) => clinicsMap.cnpj !== clinic.cnpj
    )
    setClinicsInfo(newList)
  }

  const editClinic = (clinic: IClinicInfo) => {
    setValues({
      ...values,
      clinicCnpj: clinic.cnpj,
      clinicName: clinic.name,
      address: clinic.address,
      number: clinic.number,
      neighborhood: clinic.neighborhood,
      city: clinic.city,
      state: clinic.state,
      cep: clinic.postalCode,
      clinicPhoneNumber: clinic.clinicPhoneNumber,
      secretaryName: clinic.secretaryName,
      secretaryEmail: clinic.secretaryEmail
    })
  }

  const updateClinicInformation = () => {
    const updatedClinics = clinicsInfo.map((clinic: IClinicInfo) => {
      if (clinic.cnpj === values.clinicCnpj) {
        return {
          cnpj: clinic.cnpj,
          name: values.clinicName,
          address: values.address,
          number: values.number,
          neighborhood: values.neighborhood,
          city: values.city,
          state: values.state,
          postalCode: values.cep,
          clinicPhoneNumber: values.clinicPhoneNumber,
          secretaryName: values.secretaryName,
          secretaryEmail: values.secretaryEmail
        }
      }
      return clinic
    })
    setClinicsInfo(updatedClinics)
    clearClinicValues()
    putClinics(updatedClinics)
  }

  const putClinics = (updatedClinics: any) => {
    if (token && token.layerSevenToken) {
      dispatch(
        updateRegisteredClinicInfo(
          { cpf: cpf, clinicsInformation: updatedClinics },
          token.layerSevenToken
        )
      )
    }
  }

  const colSpan1 = Chakra.useBreakpointValue({
    base: 2,
    xl: 2,
    lg: 2,
    md: 6,
    sm: 6
  })

  const colSpan2 = Chakra.useBreakpointValue({
    base: 4,
    xl: 4,
    lg: 4,
    md: 6,
    sm: 6
  })

  useEffect(() => {
    if (!_.isEmpty(registeredInfoState)) {
      let emissionDate
      if (registeredInfoState.personalInfo?.emissionDate)
        emissionDate = moment(
          new Date(registeredInfoState.personalInfo?.emissionDate)
        )

      const emissionDateFormatted = emissionDate
        ? emissionDate.utc().format('yyyy-MM-DD')
        : ''

      let dateBirth
      if (registeredInfoState.personalInfo?.dateBirth)
        dateBirth = moment(
          new Date(registeredInfoState.personalInfo?.dateBirth)
        )

      const dateBirthFormatted = dateBirth
        ? dateBirth.utc().format('yyyy-MM-DD')
        : ''

      let completionDate
      if (registeredInfoState.professionalInfo?.completionDate)
        completionDate = moment(
          new Date(registeredInfoState.professionalInfo?.completionDate)
        )

      const completionDateFormatted = completionDate
        ? completionDate.utc().format('yyyy-MM-DD')
        : ''
      if (registeredInfoState.clinicsInfo) {
        setClinicsInfo(registeredInfoState.clinicsInfo)
      }
      setValues({
        ...values,
        fullName: registeredInfoState.personalInfo?.fullName || '',
        socialName: registeredInfoState.personalInfo?.socialName || '',
        rg: registeredInfoState.personalInfo?.rg || '',
        rgExpeditor: registeredInfoState.personalInfo?.rgExpeditor || '',
        emissionDate: emissionDateFormatted || '',
        placeOfBirth: registeredInfoState.personalInfo?.placeOfBirth || '',
        citizenship: registeredInfoState.personalInfo?.citizenship || '',
        dateBirth: dateBirthFormatted || '',
        gender: registeredInfoState.personalInfo?.gender || '',
        maritalStatus: registeredInfoState.personalInfo?.maritalStatus || '',
        partnerName: registeredInfoState.personalInfo?.partnerName || '',
        email: registeredInfoState.personalInfo?.email || '',
        celPhone: registeredInfoState.personalInfo?.celPhone || '',
        registeredHospitals:
          registeredInfoState.professionalInfo?.selectedHospitals || '',
        crmKind: registeredInfoState.professionalInfo?.crmKind || '',
        crmNumber: registeredInfoState.professionalInfo?.crmNumber || '',
        crmState: registeredInfoState.professionalInfo?.crmState || '',
        mainSpecialty:
          registeredInfoState.professionalInfo?.mainSpecialty || '',
        rqE1: registeredInfoState.professionalInfo?.rqE1 || '',
        actuationArea1:
          registeredInfoState.professionalInfo?.actuationArea1 || '',
        secoundSpecialty:
          registeredInfoState.professionalInfo?.secoundSpecialty || '',
        rqE2: registeredInfoState.professionalInfo?.rqE2 || '',
        actuationArea2:
          registeredInfoState.professionalInfo?.actuationArea2 || '',
        educationLevel:
          registeredInfoState.professionalInfo?.educationLevel || '',
        completionDate: completionDateFormatted || '',
        cnpj: registeredInfoState.professionalInfo?.cnpj || '',
        memberTeamHospitals:
          registeredInfoState.professionalInfo?.memberTeamHospitals || '',
        interestResearch:
          registeredInfoState.professionalInfo?.interestResearch || '',
        teamOrBossName:
          registeredInfoState.professionalInfo?.teamNameTeamLeader || '',
        crmBossNumber:
          registeredInfoState.professionalInfo?.crmTeamLeader || '',
        reason:
          registeredInfoState.professionalInfo?.reasonForRegistering || '',
        descriptionInterest:
          registeredInfoState.professionalInfo?.descriptionInterest || '',
        clinicCnpj: '',
        clinicName: '',
        address: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        cep: '',
        clinicPhoneNumber: '',
        secretaryName: '',
        secretaryEmail: '',
        cpf: solicitation.cpfHealthProfessional || '',
        zipCode: registeredInfoState.additionalInfo?.zipCode || '',
        homeAddress: registeredInfoState.additionalInfo?.homeAddress || '',
        numberHome: registeredInfoState.additionalInfo?.number || '',
        uf: registeredInfoState.additionalInfo?.uf || '',
        district: registeredInfoState.additionalInfo?.district || '',
        cityHome: registeredInfoState.additionalInfo?.city || '',
        complement: registeredInfoState.additionalInfo?.complement || '',
        phoneNumber: registeredInfoState.additionalInfo?.phoneNumber || '',
        soccerTeam: registeredInfoState.additionalInfo?.soccerTeam || '',
        observationHobbies:
          registeredInfoState.additionalInfo?.observationHobbies || '',
        enjoyWine: registeredInfoState.additionalInfo?.enjoyWine || '',
        musicalGenre: registeredInfoState.additionalInfo?.musicalGenre || ''
      })
    } else {
      setValues({
        fullName: '',
        socialName: '',
        rg: '',
        rgExpeditor: '',
        emissionDate: '',
        placeOfBirth: '',
        citizenship: '',
        dateBirth: '',
        gender: '',
        maritalStatus: '',
        partnerName: '',
        email: '',
        celPhone: '',
        registeredHospitals: '',
        crmKind: '',
        crmNumber: '',
        crmState: '',
        mainSpecialty: '',
        rqE1: '',
        actuationArea1: '',
        secoundSpecialty: '',
        rqE2: '',
        actuationArea2: '',
        educationLevel: '',
        completionDate: '',
        cnpj: '',
        memberTeamHospitals: '',
        interestResearch: '',
        teamOrBossName: '',
        crmBossNumber: '',
        reason: '',
        descriptionInterest: '',
        clinicCnpj: '',
        clinicName: '',
        address: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        cep: '',
        clinicPhoneNumber: '',
        secretaryName: '',
        secretaryEmail: '',
        cpf: '',
        zipCode: '',
        homeAddress: '',
        numberHome: '',
        uf: '',
        district: '',
        cityHome: '',
        complement: '',
        phoneNumber: '',
        soccerTeam: '',
        observationHobbies: '',
        enjoyWine: '',
        musicalGenre: ''
      })
    }
  }, [registeredInfoState])
  return (
    <>
      <Header border />
      <S.Container>
        <Chakra.Grid templateColumns="repeat(6, 1fr)">
          <Chakra.GridItem marginTop={8} colSpan={colSpan1}>
            <NavigationMenu />
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={colSpan2}>
            <HeadingText
              title="Dados do cadastrado"
              subtitle="Visualize os dados necessários para a aprovação do cadastro médico."
            />

            <Chakra.Tabs variant="line">
              <Chakra.TabList>
                <S.Tab>Informações pessoais</S.Tab>
                <S.Tab>Informações profissionais</S.Tab>
                <S.Tab>Clínicas cadastradas</S.Tab>
                <S.Tab>Informações adicionais</S.Tab>
              </Chakra.TabList>
              <Chakra.TabPanels>
                <S.TabPanel>
                  <RegisteredPersonalInformation
                    cpf={cpf}
                    inputChange={inputChange}
                    selectChange={selectChange}
                    values={values}
                  />
                </S.TabPanel>
                <S.TabPanel>
                  <RegisteredProfessionalInfo
                    cpf={cpf}
                    inputChange={inputChange}
                    selectChange={selectChange}
                    values={values}
                  />
                </S.TabPanel>
                <S.TabPanel>
                  <RegisteredCliniclnfo
                    inputChange={inputChange}
                    selectChange={selectChange}
                    values={values}
                    editClinic={editClinic}
                    deleteClinic={deleteClinic}
                    updateClinicInformation={updateClinicInformation}
                    clinicsInformation={clinicsInfo}
                  />
                </S.TabPanel>
                <S.TabPanel>
                  <RegisteredAdditionalInfo
                    inputChange={inputChange}
                    selectChange={selectChange}
                    values={values}
                  />
                </S.TabPanel>
              </Chakra.TabPanels>
            </Chakra.Tabs>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={6}>
            <Chakra.Divider
              borderColor="secondary"
              marginTop="5vh"
              marginBottom="5vh"
            />
          </Chakra.GridItem>

          <Chakra.GridItem colStart={6}>
            <Button
              variant="normal"
              onClick={() =>
                solicitation.id
                  ? history.push(`/solicitation-details/${solicitation.id}`)
                  : history.push(`/solicitation-details/${idSolicitation}`)
              }
            >
              Voltar
            </Button>
          </Chakra.GridItem>
        </Chakra.Grid>
      </S.Container>
    </>
  )
}

export default RegisteredInformation
