import { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { cellphoneMask } from 'src/utils/masks-validations/cell-phone-mask'
import {
  counselNumberValidation,
  crefitoValidation
} from 'src/utils/masks-validations/crm-validation'
import {
  stateOption,
  specialitysOption,
  crmKindsOptions
} from 'src/constants/options'
import { validateCpf } from 'src/utils/masks-validations/cpf-helpers'
import {
  emailSchema,
  registerSchema
} from 'src/utils/masks-validations/password-validation'
import Button from 'src/components/button'
import Select from 'src/components/input-select'
import CpfInput from 'src/components/input-cpf'
import * as Chakra from '@chakra-ui/react'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as S from './styles'
import {
  clearError,
  register as registerUser,
  updateUser
} from 'src/store/login/actions'
import { THEME_GREY } from 'src/styles/colors'
import CustomTextField from 'src/components/text-field'
import PasswordField from 'src/components/password-field'
import ROUTING_PATHS from 'src/routes/paths'
import { ApplicationState } from 'src/store/rootReducer'

interface IFormInput {
  fullName: string
  socialName: string
  cpf: string
  crmKind: string
  crmNumber: string
  crmState: string
  speciality: string
  email: string
  celPhone: string
  password: string
  confirmPassword: string
  allowReceiveInformations: boolean
  acceptedTermsPrivacyUsePortal: boolean
}

const CreateAccountForm = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const userState = useSelector(
    (state: ApplicationState) => state.userReducer.user
  )

  const layerSevenToken = useSelector(
    (state: ApplicationState) => state.userReducer.auth?.layerSevenToken
  )

  const [values, setValues] = useState({
    fullName: '',
    socialName: '',
    cpf: '',
    crmKind: '',
    crmNumber: '',
    crmState: '',
    speciality: '',
    email: '',
    celPhone: '',
    password: '',
    confirmPassword: ''
  })

  const [allowReceiveInformations, setAllowInformation] = useState(false)
  const [acceptedTermsPrivacyUsePortal, setAcceptTerms] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>({
    resolver: yupResolver(isEditMode ? emailSchema : registerSchema)
  })

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    if (name === 'celPhone') {
      setValues({
        ...values,
        [name]: cellphoneMask(value)
      })
      return
    }

    setValues({
      ...values,
      [name]: value
    })
  }

  const selectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
  }

  useEffect(() => {
    dispatch(clearError())
    if (userState?.personalData?.cpf) {
      setIsEditMode(true)
      setValues({
        ...values,
        fullName: userState?.personalData?.fullName || '',
        socialName: userState?.personalData?.socialName || '',
        cpf: userState?.personalData?.cpf || '',
        crmKind: userState?.personalData?.crmKind || '',
        crmNumber: userState?.personalData?.crmNumber || '',
        crmState: userState?.personalData?.crmState || '',
        speciality: userState?.personalData?.speciality || '',
        email: userState?.personalData?.email || '',
        celPhone: userState?.personalData?.celPhone || '',
        password: '',
        confirmPassword: ''
      })

      setAllowInformation(
        userState?.personalData?.allowReceiveInformations || false
      )
      setAcceptTerms(
        userState?.personalData?.acceptedTermsPrivacyUsePortal || false
      )
    }
  }, [dispatch])

  useEffect(() => {
    if (userState?.personalData?.cpf) {
      reset(values)
    }
  }, [reset, values])

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const {
      fullName,
      socialName,
      cpf,
      crmNumber,
      email,
      celPhone,
      password,
      confirmPassword
    } = data

    const { crmKind, crmState, speciality } = values
    if (!isEditMode) {
      dispatch(
        registerUser(
          {
            fullName,
            socialName,
            cpf,
            crmKind,
            crmNumber,
            crmState,
            speciality,
            email,
            celPhone,
            password,
            confirmPassword,
            allowReceiveInformations,
            acceptedTermsPrivacyUsePortal
          },
          () => {
            history.push(ROUTING_PATHS.SignUp_Success)
          }
        )
      )
    } else {
      if (layerSevenToken) {
        const updateData = {
          fullName: values.fullName,
          socialName: values.socialName,
          cpf: values.cpf,
          crmKind: values.crmKind,
          crmNumber: values.crmNumber,
          crmState: values.crmState,
          speciality: values.speciality,
          email: values.email,
          celPhone: values.celPhone,
          allowReceiveInformations,
          acceptedTermsPrivacyUsePortal
        }
        dispatch(
          updateUser(updateData, layerSevenToken, () => {
            history.push(ROUTING_PATHS.SignUp_PersonalInfo)
          })
        )
      }
    }
  }

  const validateFields =
    !values.fullName ||
    !values.cpf ||
    !values.crmKind ||
    !values.crmNumber ||
    !values.crmState ||
    !values.speciality ||
    !values.email ||
    !values.celPhone ||
    (!isEditMode && (!values.password || !values.confirmPassword))

  const colSpan1 = Chakra.useBreakpointValue({ base: 3, lg: 3, md: 3, sm: 6 })
  const colSpan2 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 3,
    sm: 6
  })
  const colSpan3 = Chakra.useBreakpointValue({
    base: 2,
    xl: 2,
    lg: 4,
    md: 6,
    sm: 6
  })
  const colSpan4 = Chakra.useBreakpointValue({
    base: 6,
    xl: 6,
    lg: 6,
    md: 6,
    sm: 6
  })
  const colSpan5 = Chakra.useBreakpointValue({
    base: 2,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 6
  })

  const colStart2 = Chakra.useBreakpointValue({
    base: 6,
    xl: 6,
    lg: 6,
    md: 6,
    sm: 1
  })

  return (
    <Chakra.Grid gap={3}>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Chakra.GridItem colSpan={colSpan1}>
          <Chakra.FormControl>
            <Chakra.FormLabel>*Nome Completo</Chakra.FormLabel>
            <CustomTextField
              register={register('fullName')}
              isInvalid={errors?.fullName ? true : false}
              name="fullName"
              onChange={inputChange}
              type="text"
              value={values.fullName}
              maxLength={512}
            />
            {errors?.fullName ? (
              <S.ErrorLabel color="danger">
                <WarningTwoIcon color="danger" />
                *Campo obrigatório
              </S.ErrorLabel>
            ) : (
              <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
            )}
          </Chakra.FormControl>
        </Chakra.GridItem>

        <Chakra.GridItem colSpan={colSpan1}>
          <Chakra.FormControl>
            <Chakra.FormLabel>Nome Social</Chakra.FormLabel>
            <CustomTextField
              register={register('socialName')}
              name="socialName"
              onChange={inputChange}
              type="text"
              value={values.socialName}
            />
          </Chakra.FormControl>
        </Chakra.GridItem>

        <Chakra.GridItem colSpan={colSpan3}>
          <Chakra.FormControl>
            <Chakra.FormLabel>*CPF</Chakra.FormLabel>
            <CpfInput
              register={register}
              isInvalid={!!values.cpf && !validateCpf(values.cpf)}
              name="cpf"
              onChange={inputChange}
              type="text"
              value={values.cpf}
              disabled={isEditMode}
            />
            {values.cpf && !validateCpf(values.cpf) ? (
              <S.ErrorLabel color="danger">
                <WarningTwoIcon color="danger" />
                *CPF Inválido
              </S.ErrorLabel>
            ) : (
              <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
            )}
          </Chakra.FormControl>
        </Chakra.GridItem>

        <Chakra.GridItem colSpan={colSpan2}>
          <Chakra.FormControl>
            <Chakra.FormLabel>*Tipo de Conselho</Chakra.FormLabel>
            <Select
              disabled={false}
              name="crmKind"
              onChange={selectChange}
              value={values.crmKind}
            >
              <>
                <option value="" disabled selected hidden>
                  Selecione
                </option>
                {crmKindsOptions}
              </>
            </Select>
            {errors?.crmKind ? (
              <S.ErrorLabel color="danger">
                <WarningTwoIcon color="danger" />
                *Campo obrigatório
              </S.ErrorLabel>
            ) : (
              <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
            )}
          </Chakra.FormControl>
        </Chakra.GridItem>

        <Chakra.GridItem colSpan={colSpan2}>
          <Chakra.FormControl>
            <Chakra.FormLabel>*Nº do Conselho</Chakra.FormLabel>
            <CustomTextField
              register={register('crmNumber')}
              isInvalid={errors?.crmNumber ? true : false}
              maxLength={9}
              name="crmNumber"
              onChange={inputChange}
              value={
                values.crmKind === 'CREFITO'
                  ? crefitoValidation(values.crmNumber)
                  : counselNumberValidation(values.crmNumber)
              }
            />
            {errors?.crmNumber ? (
              <S.ErrorLabel color="danger">
                <WarningTwoIcon color="danger" />
                *Campo obrigatório
              </S.ErrorLabel>
            ) : (
              <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
            )}
          </Chakra.FormControl>
        </Chakra.GridItem>

        <Chakra.GridItem colSpan={colSpan2}>
          <Chakra.FormControl>
            <Chakra.FormLabel>*UF do Conselho</Chakra.FormLabel>
            <Select
              disabled={false}
              name="crmState"
              onChange={selectChange}
              required={errors?.crmState ? true : false}
              value={values.crmState}
            >
              <>
                <option value="" disabled selected hidden>
                  Selecione
                </option>
                {stateOption}
              </>
            </Select>
            {errors?.crmState ? (
              <S.ErrorLabel color="danger">
                <WarningTwoIcon color="danger" />
                *Campo obrigatório
              </S.ErrorLabel>
            ) : (
              <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
            )}
          </Chakra.FormControl>
        </Chakra.GridItem>

        <Chakra.GridItem colSpan={colSpan2}>
          <Chakra.FormControl>
            <Chakra.FormLabel>*Especialidade</Chakra.FormLabel>
            <Select
              disabled={false}
              name="speciality"
              onChange={selectChange}
              required={errors?.speciality ? true : false}
              value={values.speciality}
            >
              <>
                <option value="" disabled selected hidden>
                  Selecione
                </option>
                {specialitysOption}
              </>
            </Select>
            {errors?.speciality ? (
              <S.ErrorLabel color="danger">
                <WarningTwoIcon color="danger" />
                *Campo obrigatório
              </S.ErrorLabel>
            ) : (
              <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
            )}
          </Chakra.FormControl>
        </Chakra.GridItem>

        <Chakra.GridItem colSpan={colSpan3}>
          <Chakra.FormControl>
            <Chakra.FormLabel>*E-mail</Chakra.FormLabel>
            <CustomTextField
              name="email"
              isInvalid={!!errors?.email}
              register={register('email')}
              onChange={inputChange}
              type="email"
              value={values.email}
            />
            {errors?.email ? (
              <S.ErrorLabel color="danger">
                <WarningTwoIcon color="danger" />
                *Campo obrigatório
              </S.ErrorLabel>
            ) : (
              <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
            )}
          </Chakra.FormControl>
        </Chakra.GridItem>

        <Chakra.GridItem colSpan={colSpan3}>
          <Chakra.FormControl>
            <Chakra.FormLabel>*Celular</Chakra.FormLabel>
            <CustomTextField
              register={register('celPhone', { required: true })}
              isInvalid={
                cellphoneMask(values.celPhone).length > 1 &&
                cellphoneMask(values.celPhone).length < 15
              }
              name="celPhone"
              onChange={inputChange}
              type="text"
              value={cellphoneMask(values.celPhone)}
            />
            {cellphoneMask(values.celPhone).length > 1 &&
            cellphoneMask(values.celPhone).length < 15 ? (
              <S.ErrorLabel color="danger">
                <WarningTwoIcon color="danger" />
                *Telefone Inválido
              </S.ErrorLabel>
            ) : (
              <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
            )}
          </Chakra.FormControl>
        </Chakra.GridItem>

        {!isEditMode && (
          <>
            <Chakra.GridItem colSpan={colSpan3} colStart={1}>
              <Chakra.FormControl>
                <Chakra.FormLabel>*Senha</Chakra.FormLabel>
                <PasswordField
                  register={!isEditMode ? register('password') : null}
                  onChange={inputChange}
                  value={values.password}
                />
                <S.ErrorLabel>
                  *Mínimo 8 caracteres (maiúscula e minúscula), números e
                  símbolos
                </S.ErrorLabel>
              </Chakra.FormControl>
            </Chakra.GridItem>

            <Chakra.GridItem colSpan={colSpan3}>
              <Chakra.FormControl>
                <Chakra.FormLabel>*Confirmar senha</Chakra.FormLabel>
                <PasswordField
                  register={!isEditMode ? register('confirmPassword') : null}
                  value={values.confirmPassword}
                  onChange={inputChange}
                  isInvalid={
                    !isEditMode &&
                    !!values.confirmPassword &&
                    values.confirmPassword !== values.password
                  }
                />
                {!isEditMode &&
                values.confirmPassword &&
                values.confirmPassword !== values.password ? (
                  <S.ErrorLabel color="danger">
                    <WarningTwoIcon color="danger" />
                    *As senhas digitadas não são as mesmas
                  </S.ErrorLabel>
                ) : (
                  <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
                )}
              </Chakra.FormControl>
            </Chakra.GridItem>
          </>
        )}

        <Chakra.GridItem colSpan={colSpan4}>
          <Chakra.Checkbox
            {...register('allowReceiveInformations')}
            colorScheme="purple"
            isChecked={allowReceiveInformations}
            name="allowReceiveInformations"
            onChange={() => setAllowInformation(!allowReceiveInformations)}
            variant="normal"
          >
            <Chakra.Text fontSize="14px" color={THEME_GREY}>
              Permitir o recebimento de informações do Americas no seu celular e
              email. As comunicações relacionadas ao seu cadastro continuarão
              sendo enviadas mesmo que não selecione esta caixa
            </Chakra.Text>
          </Chakra.Checkbox>
        </Chakra.GridItem>

        <Chakra.GridItem colSpan={colSpan4}>
          <Chakra.Checkbox
            {...register('acceptedTermsPrivacyUsePortal', { required: true })}
            colorScheme="purple"
            isChecked={acceptedTermsPrivacyUsePortal}
            name="acceptedTermsPrivacyUsePortal"
            onChange={() => setAcceptTerms(!acceptedTermsPrivacyUsePortal)}
            variant="normal"
          >
            <Chakra.Text fontSize="14px" color={THEME_GREY}>
              Declaro que li e aceito os{' '}
              <S.Link href="https://www.americasmed.com.br/comunicado-de-privacidade-de-americas-servicos-medicos">
                Termos de privacidade e uso do portal
              </S.Link>
            </Chakra.Text>
          </Chakra.Checkbox>
        </Chakra.GridItem>

        <Chakra.GridItem colSpan={colSpan4}>
          <Chakra.Divider
            borderColor="secondary"
            marginTop="5vh"
            marginBottom="5vh"
          />
        </Chakra.GridItem>

        <Chakra.GridItem colStart={colStart2} colSpan={colSpan5}>
          <Button
            disabled={
              validateFields ||
              !acceptedTermsPrivacyUsePortal ||
              !validateCpf(values.cpf)
            }
            variant="normal"
            type="submit"
          >
            {isEditMode ? 'Atualizar ' : 'Concluir '}
            cadastro do portal
          </Button>
        </Chakra.GridItem>
      </S.Form>
    </Chakra.Grid>
  )
}
export default CreateAccountForm
