import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { THEME_GREY, THEME_PURPLE, THEME_WHITE } from 'src/styles/colors'

export const Container = styled(Chakra.Box)`
  box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.3);
  background-color: ${THEME_WHITE};
  border-radius: 5px;
  padding: 18px;
  margin: 0;
`
export const SessionTitle = styled(Chakra.Text)`
  font-size: 16px;
  font-weight: bold;
  color: ${THEME_PURPLE};
  text-decoration: underline;
`

export const ListItem = styled(Chakra.ListItem)`
  display: flex;
  font-weight: bold;
  color: ${THEME_GREY};
  padding: 2px;
  font-size: 14px;
  align-items: center;
`

export const Text = styled(Chakra.Text)`
  color: ${THEME_GREY};
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  margin-top: 12px;
  display: flex;
`

export const InputLabel = styled(Chakra.FormLabel)`
  color: ${THEME_GREY};
  font-size: 16px;
`
