import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'src/store/rootReducer'
import { Redirect, Route } from 'react-router-dom'
import { signInSuccess } from 'src/store/login/actions'
import * as storage from 'src/utils/storage'

const PrivateRoute = ({ children, ...rest }: any) => {
  const dispatch = useDispatch()
  const userState = useSelector((state: ApplicationState) => state.userReducer)

  const isAuthenticated = () => {
    if (!userState.auth?.isActive) return false

    if (
      !userState.auth?.accessToken ||
      !userState.auth?.layerSevenToken ||
      !userState.user ||
      !userState.user.personalData ||
      !userState.user.personalData.cpf
    ) {
      const userStored = storage.getUserFromStorage()
      if (!userStored) return false

      dispatch(signInSuccess(userStored))
    }
    return true
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
