const DocumentUploadIcon = () => (
  <svg
    id="Upload_documentos"
    data-name="Upload documentos"
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g
      id="Group_6596"
      data-name="Group 6596"
      transform="translate(-1031.061 -1016.039)"
    >
      <g id="Group_6571" data-name="Group 6571">
        <path
          id="Path_3133"
          data-name="Path 3133"
          d="M31.149,26.319a2.915,2.915,0,0,1-2.915,2.915H4.915A2.915,2.915,0,0,1,2,26.319V5.915A2.915,2.915,0,0,1,4.915,3H12.2l2.915,4.372H28.234a2.915,2.915,0,0,1,2.915,2.915Z"
          transform="translate(1035.061 1021.04)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          id="Line_223"
          data-name="Line 223"
          y2="8.829"
          transform="translate(1051.822 1034.861)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          id="Line_224"
          data-name="Line 224"
          x2="8.829"
          transform="translate(1047.407 1039.276)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </g>
    <rect
      id="Rectangle_3575"
      data-name="Rectangle 3575"
      width="42"
      height="42"
      fill="none"
    />
  </svg>
)

export default DocumentUploadIcon
