import {
  DocumentUploadStateType,
  IDocumentsUploadState,
  IFileCodeUploaded
} from './types'

const initialState: IDocumentsUploadState = {
  loading: false,
  errorFileUpload: undefined,
  errorInformationUpload: undefined,
  data: {},
  files: [],
  fileStatusComponents: [],
  uploadedFileCodes: [],
  uploadInformationData: {
    requiredChecklist: [],
    optionalChecklist: [],
    fileCodes: [],
    curriculum: '',
    linkedin: '',
    cpf: ''
  },
  documentsSent: false
}

export default function documentsUploadReducer(
  state: IDocumentsUploadState = initialState,
  action: any
) {
  switch (action.type) {
    case DocumentUploadStateType.ADD_FILE_STATUS:
      return {
        ...state,
        fileStatusComponents: [...state.fileStatusComponents, action.payload]
      }

    case DocumentUploadStateType.REMOVE_FILE_STATUS:
      return {
        ...state,
        fileStatusComponents: [
          ...state.fileStatusComponents.filter(
            (comp: any) => comp.key !== action.payload
          )
        ]
      }

    case DocumentUploadStateType.CLEAR_FILE_STATUS:
      return {
        ...state,
        fileStatusComponents: []
      }

    case DocumentUploadStateType.GET_FILE_UPLOADED: {
      return {
        ...state,
        data: action.payload
      }
    }

    case DocumentUploadStateType.GET_COMPETENCY_MAP: {
      return {
        ...state,
        data: action.payload
      }
    }

    case DocumentUploadStateType.SAVE_UPLOADED_FILE_CODE:
      return {
        ...state,
        uploadedFileCodes: [...state.uploadedFileCodes, action.payload]
      }

    case DocumentUploadStateType.CLEAR_UPLOADED_FILE_CODES:
      return {
        ...state,
        uploadedFileCodes: []
      }

    case DocumentUploadStateType.DELETE_UPLOADED_FILE_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        errorFileUpload: undefined
      }

    case DocumentUploadStateType.DELETE_UPLOADED_FILE_CODE_SUCCESS: {
      const newDataFile = state.data

      if (state.data?.files?.length > 0) {
        newDataFile.files = state.data.files.filter(
          (file: any) => file.code !== action.payload.code
        )
      }

      return {
        ...state,
        data: newDataFile,
        fileStatusComponents: [
          ...state.fileStatusComponents.filter(
            (comp: any) => comp.key !== action.payload.key
          )
        ],
        uploadedFileCodes: [
          ...state.uploadedFileCodes.filter(
            (fileKeyCode: IFileCodeUploaded) =>
              fileKeyCode.code !== action.payload.code
          )
        ],
        errorFileUpload: undefined,
        loading: false
      }
    }

    case DocumentUploadStateType.DELETE_UPLOADED_FILE_CODE_ERROR:
      return {
        ...state,
        errorFileUpload: action.payload,
        loading: false
      }

    case DocumentUploadStateType.UPLOAD_INFORMATION_REQUEST:
      return {
        ...state,
        errorInformationUpload: undefined,
        loading: true,
        documentsSent: false
      }

    case DocumentUploadStateType.UPLOAD_INFORMATION_SUCCESS:
      return {
        ...state,
        uploadInformationData: action.payload,
        errorInformationUpload: undefined,
        loading: false,
        documentsSent: true
      }

    case DocumentUploadStateType.UPLOAD_INFORMATION_ERROR:
      return {
        ...state,
        errorInformationUpload: action.payload,
        loading: false
      }

    case DocumentUploadStateType.ADD_REQUIRED_CHECK: {
      const requiredChecklist = state.uploadInformationData.requiredChecklist

      const hasInRequired = requiredChecklist.includes(action.payload)

      if (!hasInRequired) {
        requiredChecklist.push(action.payload)
      }

      return {
        ...state,
        uploadInformationData: {
          ...state.uploadInformationData,
          requiredChecklist: requiredChecklist
        }
      }
    }

    case DocumentUploadStateType.REMOVE_REQUIRED_CHECK: {
      return {
        ...state,
        uploadInformationData: {
          ...state.uploadInformationData,
          requiredChecklist: [
            ...state.uploadInformationData.requiredChecklist.filter(
              (check) => check !== action.payload
            )
          ]
        }
      }
    }

    case DocumentUploadStateType.ADD_OPTIONAL_CHECK:
      return {
        ...state,
        uploadInformationData: {
          ...state.uploadInformationData,
          optionalChecklist: [
            ...state.uploadInformationData.optionalChecklist,
            action.payload
          ]
        }
      }

    case DocumentUploadStateType.REMOVE_OPTIONAL_CHECK:
      return {
        ...state,
        uploadInformationData: {
          ...state.uploadInformationData,
          optionalChecklist: [
            ...state.uploadInformationData.optionalChecklist.filter(
              (check) => check !== action.payload
            )
          ]
        }
      }

    case DocumentUploadStateType.GET_FILE_UPLOADED_ERROR:
      return {
        ...state,
        data: {},
        fileStatusComponents: [],
        uploadedFileCodes: []
      }

    case DocumentUploadStateType.CLEAR_DOCUMENT_UPLOAD:
      return initialState

    case DocumentUploadStateType.DELETE_SOLICITATION_FILE_SUCCESS:
      return {
        ...state,
        errorFileUpload: undefined
      }

    case DocumentUploadStateType.DELETE_SOLICITATION_FILE_ERROR:
      return {
        ...state,
        errorFileUpload: action.payload
      }

    default:
      return state
  }
}
