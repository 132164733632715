import { THEME_GREY } from 'src/styles/colors'

const AttentionIcon = () => (
  <svg
    id="Atençao"
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g
      id="Group_6542"
      data-name="Group 6542"
      transform="translate(-842.125 -828.209)"
    >
      <path
        id="Path_217"
        data-name="Path 217"
        d="M32.564,37.723,20.817,17.043a4.984,4.984,0,0,0-8.378,0L.692,37.723A4.665,4.665,0,0,0,4.88,44.77h23.5A4.665,4.665,0,0,0,32.564,37.723ZM16.628,41.037a1.868,1.868,0,1,1,1.949-1.866A1.911,1.911,0,0,1,16.628,41.037Zm1.949-7.464a1.95,1.95,0,0,1-3.9,0v-9.33a1.95,1.95,0,0,1,3.9,0Z"
        transform="translate(846.126 819.45)"
        fill={THEME_GREY}
      />
      <rect
        id="Rectangle_3214"
        data-name="Rectangle 3214"
        width="10.62"
        height="23.096"
        transform="translate(857.571 839.545)"
        fill={THEME_GREY}
      />
      <path
        id="Path_3051"
        data-name="Path 3051"
        d="M1367.062,739.519h.587a1.429,1.429,0,0,0,1.469-1.441V727.7a1.428,1.428,0,0,0-1.469-1.441h-.587a1.43,1.43,0,0,0-1.47,1.441v10.472a1.481,1.481,0,0,0,1.47,1.345"
        transform="translate(-504.521 115.401)"
        fill="#fff"
      />
      <path
        id="Path_3052"
        data-name="Path 3052"
        d="M1367.045,750.306a1.828,1.828,0,1,0-1.863-1.828,1.846,1.846,0,0,0,1.863,1.828"
        transform="translate(-504.11 109.498)"
        fill="#fff"
      />
    </g>
    <rect
      id="Rectangle_3556"
      data-name="Rectangle 3556"
      width="42"
      height="42"
      fill="none"
    />
  </svg>
)

export default AttentionIcon
