import { IThunkAction } from '../shared/types'
import { IProfessionalInformationStates, ProfessionalInfoTypes } from './types'

const initialState: IProfessionalInformationStates = {
  error: undefined,
  professionalData: undefined
}

const professionalInformationReducer = (
  state: IProfessionalInformationStates = initialState,
  action: IThunkAction
): IProfessionalInformationStates => {
  switch (action.type) {
    case ProfessionalInfoTypes.UPDATE_PROFESSIONAL_INFO: {
      return {
        ...state,
        professionalData: {
          mainSpecialty: action.payload.professionalInformation.mainSpecialty,
          rqE1: action.payload.professionalInformation.rqE1,
          actuationArea1: action.payload.professionalInformation.actuationArea1,
          secoundSpecialty:
            action.payload.professionalInformation.secoundSpecialty,
          rqE2: action.payload.professionalInformation.rqE2,
          actuationArea2: action.payload.professionalInformation.actuationArea2,
          educationLevel: action.payload.professionalInformation.educationLevel,
          completionDate: action.payload.professionalInformation.completionDate,
          interestResearch:
            action.payload.professionalInformation.interestResearch,
          accreditationApplication: {
            selectedHospitals:
              action.payload.professionalInformation.accreditationApplication
                ?.selectedHospitals,
            memberTeamHospitals:
              action.payload.professionalInformation.accreditationApplication
                ?.memberTeamHospitals,
            reasonForRegistering:
              action.payload.professionalInformation.accreditationApplication
                ?.reasonForRegistering,
            teamNameTeamLeader:
              action.payload.professionalInformation.accreditationApplication
                ?.teamNameTeamLeader,
            crmTeamLeader:
              action.payload.professionalInformation.accreditationApplication
                ?.crmTeamLeader,
            descriptionInterest:
              action.payload.professionalInformation.accreditationApplication
                ?.descriptionInterest
          },
          clinicsInformation:
            action.payload.professionalInformation.clinicsInformation &&
            action.payload.professionalInformation.clinicsInformation.length > 0
              ? [...action.payload.professionalInformation.clinicsInformation]
              : []
        }
      }
    }
    case ProfessionalInfoTypes.SAVE_PROFESSIONAL_INFO: {
      return {
        ...state,
        professionalData: {
          mainSpecialty: action.payload.professionalInformation.mainSpecialty,
          rqE1: action.payload.professionalInformation.rqE1,
          actuationArea1: action.payload.professionalInformation.actuationArea1,
          secoundSpecialty:
            action.payload.professionalInformation.secoundSpecialty,
          rqE2: action.payload.professionalInformation.rqE2,
          actuationArea2: action.payload.professionalInformation.actuationArea2,
          educationLevel: action.payload.professionalInformation.educationLevel,
          completionDate: action.payload.professionalInformation.completionDate,
          interestResearch:
            action.payload.professionalInformation.interestResearch,
          accreditationApplication: {
            selectedHospitals:
              action.payload.professionalInformation.accreditationApplication
                ?.selectedHospitals,
            memberTeamHospitals:
              action.payload.professionalInformation.accreditationApplication
                ?.memberTeamHospitals,
            reasonForRegistering:
              action.payload.professionalInformation.accreditationApplication
                ?.reasonForRegistering,
            teamNameTeamLeader:
              action.payload.professionalInformation.accreditationApplication
                ?.teamNameTeamLeader,
            crmTeamLeader:
              action.payload.professionalInformation.accreditationApplication
                ?.crmTeamLeader,
            descriptionInterest:
              action.payload.professionalInformation.accreditationApplication
                ?.descriptionInterest
          },
          clinicsInformation:
            action.payload.professionalInformation.clinicsInformation &&
            action.payload.professionalInformation.clinicsInformation.length > 0
              ? [...action.payload.professionalInformation.clinicsInformation]
              : []
        }
      }
    }
    case ProfessionalInfoTypes.GET_PROFESSIONAL_INFO_SUCCESS: {
      return {
        ...state,
        professionalData: {
          mainSpecialty: action.payload.mainSpecialty,
          rqE1: action.payload.rqE1,
          actuationArea1: action.payload.actuationArea1,
          secoundSpecialty: action.payload.secoundSpecialty,
          rqE2: action.payload.rqE2,
          actuationArea2: action.payload.actuationArea2,
          educationLevel: action.payload.educationLevel,
          completionDate: action.payload.completionDate,
          interestResearch: action.payload.interestResearch,
          accreditationApplication: {
            selectedHospitals:
              action.payload.accreditationApplication?.selectedHospitals,
            memberTeamHospitals:
              action.payload.accreditationApplication?.memberTeamHospitals,
            reasonForRegistering:
              action.payload.accreditationApplication?.reasonForRegistering,
            teamNameTeamLeader:
              action.payload.accreditationApplication?.teamNameTeamLeader,
            crmTeamLeader:
              action.payload.accreditationApplication?.crmTeamLeader,
            descriptionInterest:
              action.payload.accreditationApplication?.descriptionInterest
          },
          clinicsInformation:
            action.payload.clinicsInformation &&
            action.payload.clinicsInformation.length > 0
              ? [...action.payload.clinicsInformation]
              : []
        }
      }
    }

    case ProfessionalInfoTypes.CLEAR_PROFESSIONAL_INFO:
      return initialState

    default:
      return state
  }
}

export default professionalInformationReducer
