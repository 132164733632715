import Service from 'src/services/additional-info'
import { AdditionalInfo } from './types'
import { hideSpinner, showSpinner } from 'src/store/spinner/actions'
import { IAdditionalData } from './types'

const saveAdditionalInfoSuccess = ({ data }: any) => ({
  type: AdditionalInfo.ADDITIONAL_INFO_SUCCESS,
  payload: data
})

const saveAdditionalInfoError = (error: any) => ({
  type: AdditionalInfo.ADDITIONAL_INFO_ERROR,
  payload: error
})

export const saveAdditionalInfo =
  (
    data: IAdditionalData,
    token: string,
    onSuccessCallback: any,
    onErrorCallback: any
  ) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .saveAdditionalInfo(data, token)
      .then((response: any) => {
        dispatch(saveAdditionalInfoSuccess(response))

        if (onSuccessCallback) onSuccessCallback()
      })
      .catch((error: any) => {
        dispatch(saveAdditionalInfoError(error.message))

        if (onErrorCallback) onErrorCallback()
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const getAdditionalInfoSuccess = (data: IAdditionalData) => ({
  type: AdditionalInfo.GET_ADDITIONAL_INFO_SUCCESS,
  payload: data
})

const getAdditionalInfoError = (error: any) => ({
  type: AdditionalInfo.GET_ADDITIONAL_INFO_ERROR,
  payload: error
})

export const getAllAdditionalInfo =
  (cpf: string, token: string) => (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .getAdditionalInfo(cpf, token)
      .then((response: any) => {
        dispatch(getAdditionalInfoSuccess(response.data))
      })
      .catch((error: any) => {
        dispatch(getAdditionalInfoError(error))
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const updateAdditionalInfo =
  (data: IAdditionalData, token: string, onSuccessCallback: any) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .updateAdditionalInfo(data, token)
      .then((response: any) => {
        dispatch(saveAdditionalInfoSuccess(response))
        if (onSuccessCallback) onSuccessCallback()
      })
      .catch((error: any) => {
        dispatch(saveAdditionalInfoError(error))
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const clearAdditionalInfo = () => ({
  type: AdditionalInfo.CLEAR_ADDITIONAL_INFO
})
