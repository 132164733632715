import * as S from './styles'
import { Fade, useDisclosure } from '@chakra-ui/react'
import { VerifyMenuItemFlag } from '../flags/menu-item'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'src/store/rootReducer'
import { UserProfileEnum } from '../../store/login/types'
import { useEffect, useState } from 'react'

const Navigation = ({ onOpenMassModal }: any) => {
  const filters = useSelector(
    (state: ApplicationState) => state.solicitationsReducer.filters
  )

  const { isOpen, onToggle } = useDisclosure()
  const [filterPerProfile, setFilterPerProfile] = useState<string[]>([])
  const [showAll, setShowAll] = useState<boolean>(true)
  const [showOpenMenu, setShowOpenMenu] = useState<boolean>(true)

  const authState = useSelector(
    (state: ApplicationState) => state.userReducer.auth
  )

  useEffect(() => {
    verifyTypeProfile()
  }, [filters])

  const verifyTypeProfile = () => {
    const userProfileId = Number(authState?.userProfile)
    const nameProfile = UserProfileEnum[userProfileId]

    switch (nameProfile) {
      case 'DIRECTOR':
        setFilterPerProfile(['Aguardando Aprovação do Diretor', 'Aprovada'])
        setShowAll(false)
        setShowOpenMenu(false)
        break
      default:
        {
          const newFilters = filters.status.map((filter: any) => filter.name)
          setFilterPerProfile(newFilters)
          setShowAll(true)
        }
        break
    }
  }

  return (
    <nav>
      <S.Ul>
        <S.Li>
          <S.HomeIcon />
          <S.Redirect to="/home">Home</S.Redirect>
        </S.Li>
        <VerifyMenuItemFlag flag={window._flags_.ROUTE_SOLICITATIONS_ENABLED}>
          {filters && showOpenMenu ? (
            <S.Li>
              <S.RequestIcon />
              <S.SolicitationsButton onClick={onToggle}>
                Solicitações
              </S.SolicitationsButton>
            </S.Li>
          ) : (
            <S.Li>
              <S.RequestIcon />
              <S.Redirect to="/solicitations">Solicitações</S.Redirect>
            </S.Li>
          )}
          {isOpen && (
            <S.Li>
              <Fade in={isOpen}>
                <S.SolicitationsLinks display={isOpen}>
                  {filters && showAll && (
                    <S.Redirect to="/solicitations">
                      Todas as solicitações
                    </S.Redirect>
                  )}
                  {filters &&
                    filters.status &&
                    filters.status.map((filter: any) => {
                      return filterPerProfile.includes(filter.name) ? (
                        <S.Redirect
                          key={filter.id}
                          to={`/solicitations/${filter.id}`}
                        >
                          {filter.name}
                        </S.Redirect>
                      ) : null
                    })}
                </S.SolicitationsLinks>
              </Fade>
            </S.Li>
          )}
        </VerifyMenuItemFlag>

        <S.Li>
          <S.ConsultDocumentsIcon />
          <S.Redirect to="/documents-consult">Consultar documentos</S.Redirect>
        </S.Li>

        <S.Li
          style={{
            display: 'flex'
          }}
        >
          <S.CreateMassRegistrationIcon />
          <S.Redirect to="#" onClick={onOpenMassModal}>
            Criar acesso médico
          </S.Redirect>
        </S.Li>
      </S.Ul>
    </nav>
  )
}

export default Navigation
