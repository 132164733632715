import { THEME_GREY } from '../../styles/colors'

const HomeIcon = () => (
  <svg
    id="home-icon"
    data-name="home-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g id="Group_891" data-name="Group 891" transform="translate(0.059 0.059)">
      <g
        id="Ellipse_54"
        data-name="Ellipse 54"
        transform="translate(-0.059 -0.059)"
        fill="#fff"
        stroke={THEME_GREY}
        strokeWidth="0.5"
      >
        <circle cx="21" cy="21" r="21" stroke="none" />
        <circle cx="21" cy="21" r="20.75" fill="none" />
      </g>
      <g id="Ajuda" transform="translate(-0.059 -0.059)">
        <rect
          id="Retângulo_7671"
          data-name="Retângulo 7671"
          width="42"
          height="42"
          fill="none"
        />
      </g>
    </g>
    <g
      id="Componente_196"
      data-name="Componente 196"
      transform="translate(6 6)"
    >
      <g
        id="Grupo_6515"
        data-name="Grupo 6515"
        transform="translate(5.715 5.001)"
      >
        <path
          id="Caminho_3127"
          data-name="Caminho 3127"
          d="M3,9.289,12.371,2l9.371,7.289V20.742a2.082,2.082,0,0,1-2.082,2.082H5.082A2.082,2.082,0,0,1,3,20.742Z"
          transform="translate(-3 -2)"
          fill="none"
          stroke={THEME_GREY}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Caminho_3128"
          data-name="Caminho 3128"
          d="M9,22.412V12h6.247V22.412"
          transform="translate(-2.753 -1.588)"
          fill="none"
          stroke={THEME_GREY}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
      <rect
        id="Retângulo_3539"
        data-name="Retângulo 3539"
        width="30"
        height="30"
        fill="none"
      />
    </g>
  </svg>
)

export default HomeIcon
