import { useEffect } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import Header from 'src/components/header'
import MassRegistrationModal from './components/modal-mass-registration/'
import NavigationMenu from 'src/components/nav'
import { ApplicationState } from 'src/store/rootReducer'
import { getFilters } from 'src/store/solicitation-page/actions'

import * as S from './styles'

const Home = () => {
  const dispatch = useDispatch()
  const {
    isOpen: showMassModal,
    onOpen: onOpenMassModal,
    onClose: onCloseMassModal
  } = useDisclosure()

  const userState = useSelector(
    (state: ApplicationState) => state.userReducer.user
  )

  const auth = useSelector((state: ApplicationState) => state.userReducer.auth)

  useEffect(() => {
    if (userState && userState.personalData) {
      if (auth && auth.layerSevenToken) {
        dispatch(getFilters(auth.layerSevenToken))
      }
    }
  }, [dispatch])

  return (
    <>
      <Header border />
      <S.Container>
        <NavigationMenu onOpenMassModal={onOpenMassModal} />
      </S.Container>
      <MassRegistrationModal
        isOpen={showMassModal}
        onClose={onCloseMassModal}
      />
    </>
  )
}

export default Home
