import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'
import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

export const Container = styled(Chakra.Box)`
  border: 1px solid ${THEME_PURPLE};
  border-radius: 5px;
  background-color: #fbfbfb;
  margin: 0;
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 10px;
`
export const Title = styled.p`
  color: ${THEME_GREY};
  font-weight: bold;
  font-size: 16px;
  margin-left: 6px;
`

export const Text = styled.p`
  font-family: SoletoLight;
  color: ${THEME_GREY};
  margin-left: 6px;
  font-size: 16px;
`

export const UnderlineText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
export const Tooltip = styled.div`
  cursor: pointer;
`
export const TooltipBox = styled.div`
  border-radius: 4px;
  display: none;
`

export const TooltipCard = styled.div`
  & ${Tooltip}:hover + ${TooltipBox} {
    border: 1px solid ${THEME_GREY};
    color: ${THEME_PURPLE};
    background-color: #fff;
    display: block;
    width: 240px;
    padding: 8px;
    position: absolute;
    z-index: 10;
    margin-top: 10px;
    margin-right: 10px;
  }
`

export const StatusIcon = styled(AiOutlineInfoCircle)`
  margin-left: 10px;
  font-size: 20px;
`
