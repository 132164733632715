import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { ACCESSIBILITY_SUCCESS, THEME_GREY } from 'src/styles/colors'

export const Hr = styled.hr`
  border-top: 1px solid ${THEME_GREY};
`

export const Message = styled(Chakra.Box)`
  border: 1px solid ${ACCESSIBILITY_SUCCESS};
  border-left: 4px solid ${ACCESSIBILITY_SUCCESS};
  margin: 20px 0;
`
