import { useForm, SubmitHandler } from 'react-hook-form'
import * as Chakra from '@chakra-ui/react'
import { stateOption } from 'src/constants/options'
import Button from 'src/components/button'
import Select from 'src/components/input-select'
import { IRegisteredAdditionalInfo } from './interface'
import * as S from './styles'
import CustomTextField from 'src/components/text-field'
import { useDispatch, useSelector } from 'react-redux'
import { showSuccessToast } from 'src/utils/toast'
import { updateAdditionalInfo } from 'src/store/additional-info/actions'
import { ApplicationState } from 'src/store/rootReducer'
import { getRegisteredInfo } from 'src/store/registered-info/actions'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { fixedPhoneMask } from 'src/utils/masks-validations/fixed-phone-mask'

const RegisteredAdditionalInfo = ({
  inputChange,
  selectChange,
  values
}: IRegisteredAdditionalInfo) => {
  const token = useSelector((state: ApplicationState) => state.userReducer.auth)
  const solicitation: any = useSelector(
    (state: ApplicationState) => state.solicitationDetailReducer.solicitation
  )
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit: SubmitHandler<IRegisteredAdditionalInfo> = async () => {
    const {
      cpf,
      zipCode,
      homeAddress,
      uf,
      district,
      complement,
      phoneNumber,
      soccerTeam,
      observationHobbies,
      enjoyWine,
      musicalGenre
    } = values

    const onSuccessCallback = () => {
      if (token && token.layerSevenToken) {
        dispatch(
          getRegisteredInfo(
            solicitation.cpfHealthProfessional,
            token.layerSevenToken
          )
        )
      }
      return showSuccessToast('', 'Dados atualizados com sucesso.')
    }

    if (token && token.layerSevenToken) {
      const number = values.numberHome
      const city = values.cityHome
      dispatch(
        updateAdditionalInfo(
          {
            cpf,
            zipCode,
            homeAddress,
            number,
            uf,
            district,
            city,
            complement,
            phoneNumber,
            soccerTeam,
            observationHobbies,
            enjoyWine,
            musicalGenre
          },
          token.layerSevenToken,
          onSuccessCallback
        )
      )
    }
  }

  const colSpan1 = Chakra.useBreakpointValue({
    base: 2,
    xl: 2,
    lg: 2,
    md: 2,
    sm: 6
  })

  const colSpan2 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 5
  })

  const colSpan3 = Chakra.useBreakpointValue({
    base: 3,
    xl: 3,
    lg: 3,
    md: 3,
    sm: 5
  })

  return (
    <S.Container>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Chakra.Grid templateColumns="repeat(6, 1fr)" gap={3}>
          <Chakra.GridItem colSpan={colSpan1}>
            <Chakra.FormControl>
              <S.InputLabel>CEP residencial</S.InputLabel>
              <CustomTextField
                register={register('zipCode')}
                isInvalid={errors?.zipCode ? true : false}
                name="zipCode"
                onChange={inputChange}
                type="text"
                value={values.zipCode}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>
          <Chakra.GridItem colSpan={colSpan3}>
            <Chakra.FormControl>
              <S.InputLabel>Endereço residencial</S.InputLabel>
              <CustomTextField
                register={register('homeAddress')}
                name="homeAddress"
                onChange={inputChange}
                type="text"
                value={values.homeAddress}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>
          <Chakra.GridItem colSpan={colSpan2}>
            <Chakra.FormControl>
              <S.InputLabel>Número</S.InputLabel>
              <CustomTextField
                register={register('numberHome')}
                name="numberHome"
                onChange={inputChange}
                type="text"
                value={values.numberHome}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>
          <Chakra.GridItem colSpan={colSpan3}>
            <Chakra.FormControl>
              <S.InputLabel>UF</S.InputLabel>
              <Select
                name="uf"
                disabled={false}
                onChange={selectChange}
                required={errors?.uf ? true : false}
                value={values.uf}
              >
                <>
                  <option value="" disabled selected hidden>
                    Selecione
                  </option>
                  {stateOption}
                </>
              </Select>
            </Chakra.FormControl>
          </Chakra.GridItem>
          <Chakra.GridItem colSpan={colSpan1}>
            <Chakra.FormControl>
              <S.InputLabel>Bairro</S.InputLabel>
              <CustomTextField
                register={register('district')}
                isInvalid={errors?.district ? true : false}
                name="district"
                onChange={inputChange}
                value={values.district}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>
          <Chakra.GridItem colSpan={colSpan2}>
            <Chakra.FormControl>
              <S.InputLabel>Cidade</S.InputLabel>
              <CustomTextField
                register={register('cityHome')}
                name="cityHome"
                onChange={inputChange}
                value={values.cityHome}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>
          <Chakra.GridItem colSpan={colSpan1}>
            <Chakra.FormControl>
              <S.InputLabel>Complemento</S.InputLabel>
              <CustomTextField
                register={register('complement')}
                isInvalid={errors?.complement ? true : false}
                name="complement"
                onChange={inputChange}
                type="text"
                value={values.complement}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>
          <Chakra.GridItem colSpan={colSpan1}>
            <Chakra.FormControl>
              <S.InputLabel>Tel Residencial</S.InputLabel>
              <CustomTextField
                register={register('phoneNumber')}
                name="phoneNumber"
                onChange={inputChange}
                type="text"
                value={fixedPhoneMask(values.phoneNumber)}
              />
              {values.phoneNumber.length > 1 &&
              values.phoneNumber.length < 14 ? (
                <S.ErrorLabel color="danger">
                  <WarningTwoIcon color="danger" />
                  *Telefone Inválido
                </S.ErrorLabel>
              ) : null}
            </Chakra.FormControl>
          </Chakra.GridItem>
          <Chakra.GridItem colSpan={colSpan1}>
            <Chakra.FormControl>
              <S.InputLabel>Time de futebol</S.InputLabel>
              <CustomTextField
                register={register('soccerTeam')}
                isInvalid={errors?.soccerTeam ? true : false}
                name="soccerTeam"
                onChange={inputChange}
                type="soccerTeam"
                value={values.soccerTeam}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>
          <Chakra.GridItem colSpan={colSpan3}>
            <Chakra.FormControl>
              <S.InputLabel>Aprecia vinhos?</S.InputLabel>
              <Select
                name="enjoyWine"
                disabled={false}
                onChange={selectChange}
                value={values.enjoyWine}
              >
                <>
                  <option value="" disabled selected hidden>
                    Selecione
                  </option>
                  <option value="Sim">Sim</option>
                  <option value="Nao">Não</option>
                </>
              </Select>
            </Chakra.FormControl>
          </Chakra.GridItem>
          <Chakra.GridItem colSpan={colSpan3}>
            <Chakra.FormControl>
              <S.InputLabel>Estilo musical</S.InputLabel>
              <CustomTextField
                register={register('musicalGenre')}
                isInvalid={errors?.musicalGenre ? true : false}
                name="musicalGenre"
                onChange={inputChange}
                type="musicalGenre"
                value={values.musicalGenre}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>
          <Chakra.GridItem colSpan={6}>
            <Chakra.FormControl>
              <S.InputLabel>Observações e Hobbies</S.InputLabel>
              <CustomTextField
                register={register('observationHobbies')}
                name="observationHobbies"
                onChange={inputChange}
                type="text"
                value={values.observationHobbies}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>
          <Chakra.GridItem colStart={6} colSpan={1}>
            <Button variant="normal" type="submit">
              Salvar alterações
            </Button>
          </Chakra.GridItem>
        </Chakra.Grid>
      </S.Form>
    </S.Container>
  )
}
export default RegisteredAdditionalInfo
