import { SelectProps } from './interface'
import * as S from './styles'

const Select = ({
  children,
  disabled,
  required = false,
  id,
  name,
  onChange,
  value,
  register,
  ...rest
}: SelectProps) => {
  return (
    <S.Select
      data-testid="input-select"
      disabled={disabled}
      id={id}
      name={name}
      onChange={onChange}
      required={required}
      value={value}
      {...register}
      {...rest}
    >
      {children}
    </S.Select>
  )
}

export default Select
