import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import * as Chakra from '@chakra-ui/react'

import HeadingText from 'src/components/signup/heading-text'
import { ApplicationState } from 'src/store/rootReducer'
import Button from 'src/components/button'
import { Table, Tbody, Td, Th, Tr } from '@chakra-ui/react'
import { IMassRegistrationResult } from 'src/store/mass-registration/types'
import { AiOutlineUser } from 'react-icons/ai'
import { Data } from 'react-csv/components/CommonPropTypes'
import { ERRORS_CSV_NAME } from './constants'

import { ACCESSIBILITY_ERROR, ACCESSIBILITY_SUCCESS } from 'src/styles/colors'
import * as I from 'src/assets/icons'
import * as S from './styles'
import { getRoleFromEnum } from 'src/utils'

// Header for the csv with errors
const headers = [
  { label: 'NomeCompleto', key: 'name' },
  { label: 'NomeSocial', key: 'socialName' },
  { label: 'CPF', key: 'cpf' },
  { label: 'TipoConselho', key: 'councilType' },
  { label: 'NumeroConselho', key: 'councilNumber' },
  { label: 'UFConselho', key: 'councilState' },
  { label: 'Especialidade', key: 'specialty' },
  { label: 'Email', key: 'email' },
  { label: 'Celular', key: 'celPhone' }
]

const MassRegistrationResult = ({ onClose }: any) => {
  const massRegistrationResult = useSelector(
    (state: ApplicationState) => state.massRegistration.result
  )

  const [errorsList, setErrorsList] = useState<IMassRegistrationResult[]>()

  useEffect(() => {
    if (massRegistrationResult && massRegistrationResult.length > 0) {
      setErrorsList(
        massRegistrationResult.filter(
          (result: IMassRegistrationResult) => result.isError
        )
      )
    } else {
      setErrorsList(undefined)
    }
  }, [massRegistrationResult])

  const AllSuccessMessage = () => (
    <S.SuccessContainer>
      <I.CheckIcon size={42} color={ACCESSIBILITY_SUCCESS} />
      Usuários adicionados com sucesso!
    </S.SuccessContainer>
  )

  return (
    <Chakra.ModalContent>
      <Chakra.ModalCloseButton />
      <Chakra.ModalBody>
        <HeadingText title="Novo Cadastro" icon={<AiOutlineUser />} />
        <span>Usuários com erros: </span>
        <span style={{ color: ACCESSIBILITY_ERROR }}>
          {errorsList ? errorsList.length : 0}
        </span>
        <br />
        <span>Usuários adicionados com sucesso: </span>
        <span style={{ color: ACCESSIBILITY_SUCCESS }}>
          {
            massRegistrationResult?.filter(
              (result: IMassRegistrationResult) => !result.isError
            ).length
          }
        </span>
        <br />
        <br />
        {errorsList && errorsList.length > 0 ? (
          <Table>
            <S.TableHead>
              <Tr>
                <Th>NOME COMPLETO</Th>
                <Th>TIPO/NÚMERO CONSELHO</Th>
                <Th>FUNÇÃO</Th>
                <Th>STATUS</Th>
              </Tr>
            </S.TableHead>
            <Tbody>
              {errorsList.map((result: IMassRegistrationResult) => (
                <Tr key={v4()}>
                  <Td>{result.name}</Td>
                  <Td>
                    {`${result.councilType ? result.councilType : ''} ${
                      result.councilNumber ? result.councilNumber : ''
                    }`}
                  </Td>
                  <Td>{getRoleFromEnum(result.role)}</Td>
                  <Td>{result.status}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <AllSuccessMessage />
        )}
      </Chakra.ModalBody>

      <Chakra.ModalFooter>
        {errorsList && errorsList.length > 0 && (
          <S.ButtonDownloadCsv
            data={errorsList as Data}
            headers={headers}
            filename={ERRORS_CSV_NAME}
          >
            Fazer download de arquivo com erros
          </S.ButtonDownloadCsv>
        )}
        <Button onClick={onClose} variant="normal" maxW="200px">
          Concluir cadastro
        </Button>
      </Chakra.ModalFooter>
    </Chakra.ModalContent>
  )
}

export default MassRegistrationResult
