import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { THEME_PURPLE } from 'src/styles/colors'

export const Grid = styled(Chakra.Grid)`
  height: ${(props) => props.height};
`

export const ForgotPassword = styled(Link)`
  color: ${THEME_PURPLE};
  text-decoration: underline;
`
