import { Modal, ModalOverlay } from '@chakra-ui/react'
import MassRegistrationUploadCsv from './upload'
import MassRegistrationResult from './result'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from 'src/store/rootReducer'
import { clearMassRegistration } from 'src/store/mass-registration/actions'

const MassRegistrationModal = ({ isOpen, onClose }: any) => {
  const dispatch = useDispatch()
  const [showResult, setShowResult] = useState<boolean>(false)

  const massRegistrationState = useSelector(
    (state: ApplicationState) => state.massRegistration
  )

  useEffect(() => {
    const hasResult =
      !!massRegistrationState &&
      !!massRegistrationState.result &&
      massRegistrationState.result.length > 0

    setShowResult(hasResult)
  }, [massRegistrationState, massRegistrationState.result])

  const closeModal = () => {
    dispatch(clearMassRegistration())
    onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal} size="5xl">
        <ModalOverlay />
        {!showResult ? (
          <MassRegistrationUploadCsv />
        ) : (
          <MassRegistrationResult onClose={closeModal} />
        )}
      </Modal>
    </>
  )
}

export default MassRegistrationModal
