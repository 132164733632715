import { AdditionalInfoIconProps } from './interface'

const AdditionalInfoIcon = ({ fill, stroke }: AdditionalInfoIconProps) => {
  return (
    <svg
      id="Informações_adicionais"
      data-name="Informações adicionais"
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <g
        id="Group_6546"
        data-name="Group 6546"
        transform="translate(-1172.457 -828)"
      >
        <g
          id="Group_5606"
          data-name="Group 5606"
          transform="translate(14.457 463)"
        >
          <circle
            id="Ellipse_43"
            data-name="Ellipse 43"
            cx="16.5"
            cy="16.5"
            r="16.5"
            transform="translate(1163 370)"
            fill="#fff"
          />
          <g
            id="Group_5565"
            data-name="Group 5565"
            transform="translate(20 142)"
          >
            <g
              id="Group_5473"
              data-name="Group 5473"
              transform="translate(1142 227)"
            >
              <g
                id="Group_5294"
                data-name="Group 5294"
                transform="translate(0 0)"
              >
                <g
                  id="Group_683"
                  data-name="Group 683"
                  transform="translate(0 0)"
                >
                  <g id="Group_5605" data-name="Group 5605">
                    <path
                      id="Path_175"
                      data-name="Path 175"
                      d="M17.271,34.543A17.271,17.271,0,1,1,34.543,17.271,17.291,17.291,0,0,1,17.271,34.543Zm0-32.384A15.113,15.113,0,1,0,32.384,17.271,15.129,15.129,0,0,0,17.271,2.159Z"
                      fill={fill}
                      stroke={stroke}
                      strokeWidth="1.2"
                    />
                    <g
                      id="Group_5604"
                      data-name="Group 5604"
                      transform="translate(9.844 9.344)"
                    >
                      <path
                        id="Path_3152"
                        data-name="Path 3152"
                        d="M142.972,243.1H129c-.551,0-1-.693-1-1.548s.447-1.548,1-1.548h13.974c.551,0,1,.693,1,1.548S143.523,243.1,142.972,243.1Z"
                        transform="translate(-128 -233.562)"
                        fill={fill}
                        stroke={stroke}
                        strokeWidth="0.5"
                      />
                      <path
                        id="Path_3153"
                        data-name="Path 3153"
                        d="M241.548,143.97c-.854,0-1.548-.447-1.548-1V129c0-.551.693-1,1.548-1s1.548.447,1.548,1v13.974C243.1,143.523,242.4,143.97,241.548,143.97Z"
                        transform="translate(-233.563 -128)"
                        fill={fill}
                        stroke={stroke}
                        strokeWidth="0.5"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <rect
        id="Rectangle_3560"
        data-name="Rectangle 3560"
        width="42"
        height="42"
        fill="none"
      />
    </svg>
  )
}

export default AdditionalInfoIcon
