import { AxiosResponse } from 'axios'
import { registrationApi } from '../interceptors'

interface IApi {
  getRegisteredInfo(cpf: string, token: string): Promise<AxiosResponse>
  updateRegisteredPersonalInfo(data: any, token: string): Promise<AxiosResponse>
  updateRegisteredProfessionalInfo(
    data: any,
    token: string
  ): Promise<AxiosResponse>
  updateRegisteredClinicInfo(data: any, token: string): Promise<AxiosResponse>
}

export default (): IApi => {
  return {
    getRegisteredInfo: (cpf: string, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return registrationApi().get(
        `/Registration/registered-data-health-professional?Cpf=${cpf}`,
        {
          headers
        }
      )
    },

    updateRegisteredPersonalInfo: (
      data: any,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi().put(
        `/Registration/registered-data-personal-information`,
        data,
        {
          headers
        }
      )
    },

    updateRegisteredProfessionalInfo: (
      data: any,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi().put(
        `/Registration/registered-data-professional-information`,
        data,
        {
          headers
        }
      )
    },

    updateRegisteredClinicInfo: (
      data: any,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi().put(`/Registration/clinic-update`, data, {
        headers
      })
    }
  }
}
