import * as Chakra from '@chakra-ui/layout'
import * as I from '../../assets/icons'
import * as S from './style'
import { useDispatch } from 'react-redux'
import { signOut } from '../../store/login/actions'
import Header from 'src/components/header'
import { ACCESSIBILITY_SUCCESS } from 'src/styles/colors'

const Dashboard = () => {
  const dispatch = useDispatch()

  return (
    <>
      <Header />
      <Chakra.Grid container padding="0px 114px">
        <Chakra.GridItem w="70%" justifySelf="center">
          <S.IconWrapper>
            <I.CheckIcon size={42} color={ACCESSIBILITY_SUCCESS} />
          </S.IconWrapper>
          <S.TitleText>Bem-vindo(a) ao Portal do Médico,</S.TitleText>
          <S.SubTitle>
            Estou ciente que esta solicitação é a apresentação preliminar de
            documentos para cadastro ao corpo clínico da Rede Americas e que o
            cadastro dependerá dos critérios estabelecidos pelas comissões de
            cadastro de cada um dos Hospitais.
          </S.SubTitle>
        </Chakra.GridItem>
        <Chakra.Spacer h={32} />
        <Chakra.GridItem
          w="70%"
          justifySelf="center"
          style={{ textAlign: 'center' }}
        >
          <a href="#" onClick={() => dispatch(signOut())}>
            Sair
          </a>
        </Chakra.GridItem>
      </Chakra.Grid>
    </>
  )
}

export default Dashboard
