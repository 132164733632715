import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { THEME_GREEN, THEME_WHITE } from 'src/styles/colors'

export const RootContainer = styled(Chakra.Flex)`
  width: 100%;
  z-index: 100;
  text-align: center;
  padding: 5vh 7vw;
  border-bottom: 2px solid;
  border-color: ${(props) => (props.border ? THEME_GREEN : THEME_WHITE)};
  margin-bottom: ${(props) => (props.border ? '32px' : '0px')};
  @media (max-width: 700px) {
    justify-content: space-around;
  }
`

export const Logo = styled.div`
  img {
    max-width: 237px;
    height: auto;
  }
  @media (max-width: 1200px) {
    img {
      max-width: 168px;
    }
  }
`

export const UserBox = styled(Chakra.Box)`
  display: flex;
  align-items: center;
`
