import { DownloadFiles, IDownloadFilesStates } from './types'

const initialState: IDownloadFilesStates = {
  file: '',
  error: null
}

export default function filesDownload(state = initialState, action: any) {
  switch (action.type) {
    case DownloadFiles.DOWNLOAD_FILES_SUCCESS:
      return {
        ...state,
        file: action.payload,
        error: null
      }

    case DownloadFiles.DOWNLOAD_FILES_ERROR:
      return {
        ...state,
        file: '',
        error: action.payload.error
      }

    case DownloadFiles.CLEAR_DOWNLOAD_FILES:
      return initialState

    default:
      return state
  }
}
