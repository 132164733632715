import { CreateAccountIconProps } from './interface'

const CreateAccountIcon = ({ stroke }: CreateAccountIconProps) => (
  <svg
    id="Criar_conta"
    data-name="Criar conta"
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g
      id="Group_6511"
      data-name="Group 6511"
      transform="translate(-475.813 -645.207)"
    >
      <path
        id="Path_3143"
        data-name="Path 3143"
        d="M22.862,23.745V20.83A5.83,5.83,0,0,0,17.032,15H6.83A5.83,5.83,0,0,0,1,20.83v2.915"
        transform="translate(479.812 655.696)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <circle
        id="Ellipse_122"
        data-name="Ellipse 122"
        cx="5.83"
        cy="5.83"
        r="5.83"
        transform="translate(485.913 653.207)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <line
        id="Line_237"
        data-name="Line 237"
        y2="8.829"
        transform="translate(508.908 660.933)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <line
        id="Line_238"
        data-name="Line 238"
        x1="8.829"
        transform="translate(504.493 665.348)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <rect
      id="Rectangle_3535"
      data-name="Rectangle 3535"
      width="42"
      height="42"
      fill="none"
    />
  </svg>
)

export default CreateAccountIcon
