import * as React from 'react'
import { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import * as Chakra from '@chakra-ui/react'
import { THEME_GREY } from 'src/styles/colors'
import InformationForm from './components/form/information-form'
import AccreditationForm from './components/form/accreditation-form'
import ClinicForm from './components/form/clinic-form'
import Button from 'src/components/button'
import {
  IInformationFormProps,
  IAccreditationProps,
  IClinicsProps,
  IParentFormProps
} from './interface'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'src/store/rootReducer'
import { clearError } from 'src/store/login/actions'
import moment from 'moment'
import {
  getProfessionalInfo,
  saveProfessionalInfo,
  updateProfessionalInfo
} from 'src/store/professional-information/actions'
import { getHospitalsList } from 'src/store/hospitals-list/actions'
import { showErrorToast } from 'src/utils/toast'
import {
  IClinicInfo,
  IHospitalInfo,
  IProfessionalData
} from 'src/store/professional-information/types'
import { ERROR, FORMS_MIN_YEAR } from 'src/constants'
import ROUTING_PATHS from 'src/routes/paths'
import Container from 'src/components/container'
import { v4 as uuidv4 } from 'uuid'

const ProfessionalInformation = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const userState = useSelector((state: ApplicationState) => state.userReducer)
  const profissionalInfoState = useSelector(
    (state: ApplicationState) => state.professionalInfoReducer
  )

  const hospitals = useSelector(
    (state: ApplicationState) => state.hospitalsListReducer.hospitals
  )

  const token = useSelector((state: ApplicationState) => state.userReducer.auth)

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm()

  const [values, setValues] = useState<IParentFormProps>({
    mainSpecialty: userState.user?.personalData?.speciality || '',
    rqE1: '',
    actuationArea1: '',
    secoundSpecialty: '',
    rqE2: '',
    actuationArea2: '',
    educationLevel: '',
    completionDate: '',
    clinicCnpj: '',
    clinicName: '',
    address: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    cep: '',
    clinicPhoneNumber: '',
    secretaryName: '',
    secretaryEmail: '',
    reason: '',
    teamOrBossName: '',
    crmBossNumber: '',
    interestDescription: '',
    memberTeamHospitals: false,
    interestResearch: false
  })

  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedHospitals, setSelectedHospitals] = useState<IHospitalInfo[]>(
    []
  )
  const [clinicsInformation, setClinicsInformation] = useState<IClinicInfo[]>(
    []
  )

  useEffect(() => {
    dispatch(clearError())

    if (token && token.layerSevenToken) {
      dispatch(getHospitalsList(token.layerSevenToken))
    }
    if (
      userState.user &&
      userState.user.personalData &&
      userState.user.personalData.cpf
    ) {
      if (token && token.layerSevenToken) {
        dispatch(
          getProfessionalInfo(
            userState.user.personalData.cpf,
            token.layerSevenToken
          )
        )
      }
    }
  }, [dispatch, userState.user])

  useEffect(() => {
    if (
      userState.user &&
      profissionalInfoState.professionalData &&
      profissionalInfoState.professionalData.mainSpecialty
    ) {
      setIsEditMode(true)

      let completionDate
      if (profissionalInfoState.professionalData.completionDate)
        completionDate = moment(
          new Date(profissionalInfoState.professionalData.completionDate)
        )

      const completionDateFormatted = completionDate
        ? completionDate.utc().format('yyyy-MM-DD')
        : ''

      setValues({
        ...values,
        mainSpecialty: profissionalInfoState.professionalData.mainSpecialty,
        rqE1: profissionalInfoState.professionalData.rqE1 || '',
        actuationArea1:
          profissionalInfoState.professionalData.actuationArea1 || '',
        secoundSpecialty:
          profissionalInfoState.professionalData.secoundSpecialty || '',
        rqE2: profissionalInfoState.professionalData.rqE2 || '',
        actuationArea2:
          profissionalInfoState.professionalData.actuationArea2 || '',
        educationLevel:
          profissionalInfoState.professionalData.educationLevel || '',
        completionDate: completionDateFormatted,
        reason:
          profissionalInfoState.professionalData.accreditationApplication &&
          profissionalInfoState.professionalData.accreditationApplication
            .reasonForRegistering
            ? profissionalInfoState.professionalData.accreditationApplication
                .reasonForRegistering
            : '',
        teamOrBossName:
          profissionalInfoState.professionalData.accreditationApplication &&
          profissionalInfoState.professionalData.accreditationApplication
            .teamNameTeamLeader
            ? profissionalInfoState.professionalData.accreditationApplication
                .teamNameTeamLeader
            : '',
        crmBossNumber:
          profissionalInfoState.professionalData.accreditationApplication &&
          profissionalInfoState.professionalData.accreditationApplication
            .crmTeamLeader
            ? profissionalInfoState.professionalData.accreditationApplication
                .crmTeamLeader
            : '',
        interestDescription:
          profissionalInfoState.professionalData.accreditationApplication &&
          profissionalInfoState.professionalData.accreditationApplication
            .descriptionInterest
            ? profissionalInfoState.professionalData.accreditationApplication
                .descriptionInterest
            : '',
        memberTeamHospitals:
          profissionalInfoState.professionalData.accreditationApplication &&
          profissionalInfoState.professionalData.accreditationApplication
            .memberTeamHospitals
            ? profissionalInfoState.professionalData.accreditationApplication
                .memberTeamHospitals === 'true'
            : false,
        interestResearch: profissionalInfoState.professionalData
          .interestResearch
          ? profissionalInfoState.professionalData.interestResearch === 'true'
          : false
      })

      if (
        profissionalInfoState.professionalData.clinicsInformation &&
        profissionalInfoState.professionalData.clinicsInformation.length >= 0
      )
        setClinicsInformation(
          profissionalInfoState.professionalData.clinicsInformation
        )

      const existingHospitals =
        profissionalInfoState.professionalData.accreditationApplication
          ?.selectedHospitals

      if (existingHospitals && existingHospitals.length > 0) {
        setSelectedHospitals(existingHospitals)
      }
    }
  }, [dispatch, profissionalInfoState.professionalData])

  const validateFields = values.memberTeamHospitals
    ? !values.crmBossNumber ||
      !values.teamOrBossName ||
      !values.mainSpecialty ||
      selectedHospitals.length < 1
    : !values.mainSpecialty || selectedHospitals.length < 1

  const inputChange = (e: any) => {
    const { name, value } = e.currentTarget
    setValues({
      ...values,
      [name]: value
    })
  }

  const changeMultiplesInput = (data: any) => {
    setValues({
      ...values,
      ...data
    })
  }

  const selectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
  }

  const clearClinicValues = () => {
    setValues({
      ...values,
      clinicCnpj: '',
      clinicName: '',
      address: '',
      number: '',
      neighborhood: '',
      city: '',
      state: '',
      cep: '',
      clinicPhoneNumber: '',
      secretaryName: '',
      secretaryEmail: ''
    })
  }

  const addClinicsInformation = () => {
    const setClinics = new Set()
    const newClinic: IClinicInfo = {
      cnpj: values.clinicCnpj,
      name: values.clinicName,
      address: values.address,
      number: values.number,
      neighborhood: values.neighborhood,
      city: values.city,
      state: values.state,
      postalCode: values.cep,
      clinicPhoneNumber: values.clinicPhoneNumber,
      secretaryName: values.secretaryName,
      secretaryEmail: values.secretaryEmail
    }

    const clinicRegister: IClinicInfo[] = [...clinicsInformation, newClinic]

    const filterClinic = clinicRegister.filter((clinic) => {
      const duplicateClinic = setClinics.has(clinic.cnpj)

      setClinics.add(clinic.cnpj)

      if (duplicateClinic) {
        showErrorToast(
          'Clínica não cadastrada.',
          'Esse CNPJ já foi cadastrado anteriormente, verifique a informação e tente novamente.'
        )
      } else {
        return !duplicateClinic
      }
    })

    setClinicsInformation(filterClinic)

    clearClinicValues()
  }

  const deleteClinic = (clinic: IClinicInfo) => {
    const newList = clinicsInformation.filter(
      (clinicsMap) => clinicsMap.cnpj !== clinic.cnpj
    )
    setClinicsInformation(newList)
  }

  const editClinic = (clinic: IClinicInfo) => {
    setValues({
      ...values,
      clinicCnpj: clinic.cnpj,
      clinicName: clinic.name,
      address: clinic.address,
      number: clinic.number,
      neighborhood: clinic.neighborhood,
      city: clinic.city,
      state: clinic.state,
      cep: clinic.postalCode,
      clinicPhoneNumber: clinic.clinicPhoneNumber,
      secretaryName: clinic.secretaryName,
      secretaryEmail: clinic.secretaryEmail
    })
  }

  const updateClinicInformation = () => {
    const updatedClinics = clinicsInformation.map((clinic: IClinicInfo) => {
      if (clinic.cnpj === values.clinicCnpj) {
        return {
          cnpj: clinic.cnpj,
          name: values.clinicName,
          address: values.address,
          number: values.number,
          neighborhood: values.neighborhood,
          city: values.city,
          state: values.state,
          postalCode: values.cep,
          clinicPhoneNumber: values.clinicPhoneNumber,
          secretaryName: values.secretaryName,
          secretaryEmail: values.secretaryEmail
        }
      }
      return clinic
    })
    setClinicsInformation(updatedClinics)
    clearClinicValues()
  }

  const onSubmit: SubmitHandler<
    IInformationFormProps & IAccreditationProps & IClinicsProps
  > = () => {
    const {
      mainSpecialty,
      secoundSpecialty,
      rqE1,
      rqE2,
      completionDate,
      actuationArea1,
      actuationArea2,
      teamOrBossName,
      crmBossNumber,
      educationLevel,
      reason,
      interestDescription,
      memberTeamHospitals,
      interestResearch
    } = values

    if (
      !userState.user ||
      !userState.user.personalData ||
      !userState.user.personalData.cpf
    ) {
      showErrorToast(ERROR, 'Faça login novamente')
      return
    } else {
      const educationCompletionDate = new Date(completionDate)
      const today = new Date()

      if (educationCompletionDate >= today) {
        showErrorToast(
          ERROR,
          'Data de conclusão não pode ser maior que a data atual.'
        )
        return
      } else if (
        new Date(educationCompletionDate).getFullYear() < FORMS_MIN_YEAR
      ) {
        showErrorToast(ERROR, 'Data de conclusão inválida.')
        return
      }
    }

    if (selectedHospitals.length < 1) {
      showErrorToast(ERROR, 'É preciso selecionar pelo menos um hospital')
      return
    }

    const cpf = userState.user.personalData.cpf

    const professionalInfo: IProfessionalData = {
      cpf,
      mainSpecialty,
      rqE1,
      actuationArea1,
      secoundSpecialty,
      rqE2,
      actuationArea2,
      educationLevel,
      completionDate,
      interestResearch: interestResearch.toString(),
      accreditationApplication: {
        cpf,
        selectedHospitals,
        memberTeamHospitals: memberTeamHospitals.toString(),
        reasonForRegistering: reason,
        teamNameTeamLeader: teamOrBossName,
        crmTeamLeader: crmBossNumber,
        descriptionInterest: interestDescription
      },
      clinicsInformation
    }

    const hospitals =
      professionalInfo.accreditationApplication.selectedHospitals

    professionalInfo.accreditationApplication.selectedHospitals =
      hospitals?.map((item) => {
        // item.sapId = item.id
        item.id = uuidv4()
        return item
      })

    const onSuccessCallback = () => {
      history.push(ROUTING_PATHS.SignUp_CompetencyMap)
    }

    if (token && token.layerSevenToken) {
      if (!isEditMode) {
        dispatch(
          saveProfessionalInfo(
            professionalInfo,
            onSuccessCallback,
            token.layerSevenToken
          )
        )
      } else {
        dispatch(
          updateProfessionalInfo(
            professionalInfo,
            onSuccessCallback,
            token.layerSevenToken
          )
        )
      }
    }
  }

  return (
    <>
      <Container value={40}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InformationForm
            mainSpecialty={values.mainSpecialty}
            rqE1={values.rqE1}
            actuationArea1={values.actuationArea1}
            secoundSpecialty={values.secoundSpecialty}
            rqE2={values.rqE2}
            actuationArea2={values.actuationArea2}
            educationLevel={values.educationLevel}
            completionDate={values.completionDate}
            inputChange={inputChange}
            selectChange={selectChange}
            register={register}
          />

          <Chakra.Divider w="100%" borderColor={THEME_GREY} marginTop="40px" />

          <AccreditationForm
            reason={values.reason}
            teamOrBossName={values.teamOrBossName}
            crmBossNumber={values.crmBossNumber}
            interestDescription={values.interestDescription}
            interestResearch={values.interestResearch}
            setSelectedHospitals={setSelectedHospitals}
            selectedHospitals={selectedHospitals}
            hospitals={hospitals}
            memberTeamHospitals={values.memberTeamHospitals}
            inputChange={inputChange}
            register={register}
            setValueFormRegister={setValue}
            changeMultiplesInput={changeMultiplesInput}
          />
          <Chakra.Divider w="100%" borderColor={THEME_GREY} marginTop="40px" />

          <ClinicForm
            clinicCnpj={values.clinicCnpj}
            clinicName={values.clinicName}
            address={values.address}
            number={values.number}
            neighborhood={values.neighborhood}
            city={values.city}
            state={values.state}
            cep={values.cep}
            clinicPhoneNumber={values.clinicPhoneNumber}
            secretaryName={values.secretaryName}
            secretaryEmail={values.secretaryEmail}
            clinics={clinicsInformation}
            addClinicsInformation={addClinicsInformation}
            updateClinicsInformation={updateClinicInformation}
            inputChange={inputChange}
            selectChange={selectChange}
            register={register}
            deleteClinic={deleteClinic}
            editClinic={editClinic}
            errors={errors}
          />
          <Chakra.GridItem colSpan={6}>
            <Chakra.Divider
              borderColor="secondary"
              marginTop="5vh"
              marginBottom="5vh"
            />
          </Chakra.GridItem>
          <Chakra.Grid gap={4}>
            <Chakra.GridItem colStart={4}>
              <Link to={ROUTING_PATHS.SignUp_PersonalInfo}>
                <Button variant="outlined">Voltar</Button>
              </Link>
            </Chakra.GridItem>
            <Chakra.GridItem colStart={5}>
              <Button disabled={validateFields} variant="normal" type="submit">
                Avançar
              </Button>
            </Chakra.GridItem>
          </Chakra.Grid>
        </form>
      </Container>
    </>
  )
}
export default ProfessionalInformation
