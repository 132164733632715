import styled from 'styled-components'
import { GridItem, Text } from '@chakra-ui/react'
import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'

export const ChecklistGridItem = styled(GridItem)`
  box-shadow: 0px 0px 30px #00000015;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 40px;
`

export const ItemText = styled(Text)`
  font-size: 14px;
  color: ${THEME_GREY};
  font-weight: bold;
`

export const Link = styled.a`
  color: ${THEME_PURPLE};
  text-decoration: underline;
`
