import { AxiosResponse } from 'axios'
import { filesInformationAPI, workflowAPI } from '../interceptors'

interface ISolicitations {
  getSolicitationDetails(
    token: string,
    id: string,
    technicalEvaluationId?: string
  ): Promise<AxiosResponse>
  getSolicitationDocuments(
    token: string,
    cpf: string,
    technicalEvaluationId?: number
  ): Promise<AxiosResponse>
  getExclusionReason(token: string): Promise<AxiosResponse>
  updateFileDisapprove(data: any, token: string): Promise<AxiosResponse>
  updateFileApprove(data: any, token: string): Promise<AxiosResponse>
  updateFileUndo(data: any, token: string): Promise<AxiosResponse>
  createApproveSolicitation(data: any, token: string): Promise<AxiosResponse>
  createRejectSolicitation(data: any, token: string): Promise<AxiosResponse>
  getFileType(token: string): Promise<AxiosResponse>
  changeFileType(data: any, token: string): Promise<AxiosResponse>
  directorUpdateFileDisapprove(data: any, token: string): Promise<AxiosResponse>
  directorUpdateFileApproveDirector(
    data: any,
    token: string
  ): Promise<AxiosResponse>
  directorUpdateFileUndo(data: any, token: string): Promise<AxiosResponse>
  directorApproveSolicitation(data: any, token: string): Promise<AxiosResponse>
  directorRejectSolicitation(data: any, token: string): Promise<AxiosResponse>
  getHistory(data: any, token: string): Promise<AxiosResponse>
}

export default (): ISolicitations => {
  return {
    getSolicitationDetails: (
      token: string,
      id: string,
      technicalEvaluationId?: string
    ): Promise<AxiosResponse> => {
      let url = `/Workflow/solicitation-detail?Id=${id}`
      if (technicalEvaluationId) {
        url = url + `&technicalEvaluationId=${technicalEvaluationId}`
      }
      const headers = {
        'X-Authorization': `${token}`
      }
      return workflowAPI().get(url, {
        headers
      })
    },
    getSolicitationDocuments: (
      token: string,
      cpf: string,
      technicalEvaluationId?: number
    ): Promise<AxiosResponse> => {
      let url = `/files?Cpf=${cpf}`
      if (technicalEvaluationId) {
        url = url + `&technicalEvaluationId=${technicalEvaluationId}`
      }
      const headers = {
        'X-Authorization': `${token}`
      }
      return filesInformationAPI().get(url, {
        headers
      })
    },
    getExclusionReason: (token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return workflowAPI().get(`/Workflow/exclusion-reason/`, {
        headers
      })
    },
    updateFileDisapprove: (
      data: any,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return filesInformationAPI().put(`/file-disapprove/`, data, {
        headers
      })
    },
    updateFileApprove: (data: any, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return filesInformationAPI().put(`/file-approve/`, data, {
        headers
      })
    },
    updateFileUndo: (data: any, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return filesInformationAPI().put(`/file-undo/`, data, {
        headers
      })
    },
    createApproveSolicitation: (
      data: any,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return workflowAPI().post(
        `/Workflow/approve-solicitation-registrationstep/`,
        data,
        {
          headers
        }
      )
    },
    createRejectSolicitation: (
      data: any,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return workflowAPI().post(`/Workflow/solicitation-rejected/`, data, {
        headers
      })
    },
    getFileType: (token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return filesInformationAPI().get(`/file-types/`, {
        headers
      })
    },
    changeFileType: (data: any, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return filesInformationAPI().put(`/file-type/`, data, {
        headers
      })
    },

    directorUpdateFileDisapprove: (
      data: any,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return filesInformationAPI().put(
        `/file-disapprove-tech-evaluation/`,
        data,
        {
          headers
        }
      )
    },
    directorUpdateFileApproveDirector: (
      data: any,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return filesInformationAPI().put(`/file-approve-tech-evaluation/`, data, {
        headers
      })
    },
    directorUpdateFileUndo: (
      data: any,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return filesInformationAPI().put(`/file-undo-tech-evaluation/`, data, {
        headers
      })
    },
    directorApproveSolicitation: (
      data: any,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return workflowAPI().post(`/Workflow/approve-tech-evaluation/`, data, {
        headers
      })
    },
    directorRejectSolicitation: (
      data: any,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return workflowAPI().post(`/Workflow/disapprove-tech-evaluation`, data, {
        headers
      })
    },
    getHistory: (data: any, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return filesInformationAPI().get(`/file-logs?FileId=${data}`, {
        headers
      })
    }
  }
}
