import { THEME_PURPLE, THEME_GREY } from '../../../styles/colors'
import styled, { css } from 'styled-components'
import * as Chakra from '@chakra-ui/layout'

export const Container = styled(Chakra.Container)`
  max-width: 80%;
  margin: 0 auto;
  padding: 5vh 0;
`
const Text = css`
  margin-bottom: 32px;
  text-align: center;
`
export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const TitleText = styled(Chakra.Text)`
  ${Text};
  color: ${THEME_PURPLE};
  font-family: SoletoBold;
  font-size: 36px;
`
export const DescriptionText = styled(Chakra.Text)`
  ${Text};
  color: ${THEME_GREY};
  font-size: 18px;
`
export const DescriptionBoldText = styled(Chakra.Text)`
  ${Text};
  color: ${THEME_GREY};
  font-family: SoletoBold;
`
