import { AxiosResponse } from 'axios'
import { ISignInParams } from 'src/store/login/types'
import { RegistrationStep } from 'src/store/registration-step/types'
import { IResetPasswordPayload } from 'src/store/login/types'
import { authApi, registrationApi } from '../interceptors'

interface IApi {
  signInByCpfAndPassword(
    credentials: ISignInParams,
    token: string
  ): Promise<AxiosResponse>
  savePersonalInfo(data: any, token: string): Promise<AxiosResponse>
  getPersonalInfo(cpf: string, token: string): Promise<AxiosResponse>
  register(data: any, token: string): Promise<AxiosResponse>
  updateUser(data: any, token: string): Promise<AxiosResponse>
  getUserLoggedIn(cpf: string, token: string): Promise<AxiosResponse>
  getUserRegistrationStep(cpf: string, token: string): Promise<AxiosResponse>
  createUserRegistrationStep(
    cpf: string,
    step: RegistrationStep,
    token: string
  ): Promise<AxiosResponse>
  updateUserRegistrationStep(
    cpf: string,
    newStep: RegistrationStep,
    token: string
  ): Promise<AxiosResponse>
  resetPassword(
    payload: IResetPasswordPayload,
    token: string
  ): Promise<AxiosResponse>
  signOut(token: string): Promise<AxiosResponse>
  forgotPassword(
    cpf: string,
    token: string,
    layerSevenToken: string
  ): Promise<AxiosResponse>
  forgotPasswordWithoutRecaptcha(
    cpf: string,
    token: string
  ): Promise<AxiosResponse>
}

export default (): IApi => {
  return {
    forgotPassword: (
      cpf: string,
      token: string,
      layerSevenToken: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${layerSevenToken}`
      }
      return authApi().post(
        `/Auth/reset-password`,
        {
          cpf,
          tokenRecaptcha: token
        },
        {
          headers
        }
      )
    },
    forgotPasswordWithoutRecaptcha: (
      cpf: string,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return authApi().post(
        `/Auth/reset-password`,
        {
          cpf
        },
        {
          headers
        }
      )
    },
    signInByCpfAndPassword: (
      credentials: ISignInParams,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return authApi().post(
        `/Auth/login`,
        {
          cpf: credentials.cpf,
          password: credentials.password
        },
        {
          headers
        }
      )
    },

    signOut: (token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return authApi().post(`/Auth/logout`, null, {
        headers
      })
    },

    resetPassword: (
      payload: IResetPasswordPayload,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return authApi().post('/Auth/change-password', payload, {
        headers
      })
    },

    savePersonalInfo: (data: any, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi().put(`/Registration/health-professional`, data, {
        headers
      })
    },

    getPersonalInfo: (cpf: string, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi().get(
        `/Registration/health-professional?cpf=${cpf}`,
        {
          headers
        }
      )
    },

    register: (data: any, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return authApi().post(`/Auth/register`, data, { headers })
    },

    updateUser: (data: any, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi().put(
        `/Registration/health-professional-partial`,
        data,
        {
          headers
        }
      )
    },

    getUserLoggedIn: (cpf: string, token: any): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }

      return registrationApi().get(`/Registration/logged-user?cpf=${cpf}`, {
        headers
      })
    },

    getUserRegistrationStep: (
      cpf: string,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi().get(
        `/Registration/get-registration-step?cpf=${cpf}`,
        {
          headers
        }
      )
    },

    createUserRegistrationStep: (
      cpf: string,
      step: RegistrationStep,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi().post(
        '/Registration/registration-step',
        {
          cpf,
          registryStep: step
        },
        {
          headers
        }
      )
    },

    updateUserRegistrationStep: (
      cpf: string,
      newStep: RegistrationStep,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi().put(
        '/Registration/update-registrationstep',
        {
          cpf,
          registryStep: newStep
        },
        {
          headers
        }
      )
    }
  }
}
