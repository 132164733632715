import styled from 'styled-components'

export const Container = styled.div`
  background-image: linear-gradient(to right, #421a71, #8d056e);
  height: auto;
  width: 100%;
  margin-bottom: 32px;

  @media (max-width: 1200px) {
    display: none;
  }
`
