import { THEME_GREY } from '../../styles/colors'

const TrashBinIcon = ({ fill = THEME_GREY, size }: any) => (
  <svg
    id="delete"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 42 42"
  >
    <g
      id="Group_6591"
      data-name="Group 6591"
      transform="translate(-571.039 -1015.832)"
    >
      <g id="Group_6566" data-name="Group 6566">
        <path
          id="Path_3146"
          data-name="Path 3146"
          d="M3,6H29.234"
          transform="translate(576.038 1021.663)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Path_3147"
          data-name="Path 3147"
          d="M25.4,7.83v20.4a2.915,2.915,0,0,1-2.915,2.915H7.915A2.915,2.915,0,0,1,5,28.234V7.83m4.372,0V4.915A2.915,2.915,0,0,1,12.287,2h5.83a2.915,2.915,0,0,1,2.915,2.915V7.83"
          transform="translate(576.953 1019.833)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          id="Line_242"
          data-name="Line 242"
          y2="8.829"
          transform="translate(588.927 1035.112)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          id="Line_243"
          data-name="Line 243"
          y2="8.829"
          transform="translate(595.549 1035.112)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </g>
    <rect
      id="Rectangle_3570"
      data-name="Rectangle 3570"
      width="42"
      height="42"
      fill="none"
    />
  </svg>
)

export default TrashBinIcon
