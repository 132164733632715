import { DownloadFiles } from './types'
import Service from '../../services/files'
import { saveAs } from 'file-saver'
import { handleLayerSevenRequestError } from '../layer-seven/actions'

const downloadFilesSuccess = (data: any, name: string) => ({
  type: DownloadFiles.DOWNLOAD_FILES_SUCCESS,
  payload: saveAs(data, name)
})

const downloadFilesError = (error: any) => ({
  type: DownloadFiles.DOWNLOAD_FILES_ERROR,
  payload: error
})

const downloadWorkflowFilesSuccess = (data: any, name: string) => ({
  type: DownloadFiles.DOWNLOAD_WORKFLOW_FILES_SUCCESS,
  payload: saveAs(data, name)
})

const downloadWorkflowFilesError = (error: any) => ({
  type: DownloadFiles.DOWNLOAD_WORKFLOW_FILES_ERROR,
  payload: error
})

export const downloadFiles =
  (code: string, name: string, token: string) => (dispatch: any) => {
    Service()
      .filesDownload(code, token)
      .then((response: any) => {
        dispatch(downloadFilesSuccess(response.data, name))
      })
      .catch((error: any) => {
        dispatch(downloadFilesError(error))
        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(downloadFiles(code, name, newToken))
          )
        )
      })
  }

export const showFile =
  (code: string, name: string, token: string) => (dispatch: any) => {
    Service()
      .filesDownload(code, token)
      .then((response: any) => {
        const file = new Blob([response.data], { type: response.data.type })
        const fileUrl = URL.createObjectURL(file)
        window.open(fileUrl, '_blank')
      })
      .catch((error: any) => {
        dispatch(downloadFilesError(error))
        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(downloadFiles(code, name, newToken))
          )
        )
      })
  }

export const downloadWorkflowFiles =
  (code: string, name: string, token: string) => (dispatch: any) => {
    Service()
      .workflowFilesDownload(code, token)
      .then((response: any) => {
        dispatch(downloadWorkflowFilesSuccess(response.data, name))
      })
      .catch((error: any) => {
        dispatch(
          downloadWorkflowFilesError(error),
          dispatch(
            handleLayerSevenRequestError(error, (newToken: string) =>
              dispatch(downloadWorkflowFiles(code, name, newToken))
            )
          )
        )
      })
  }

export const showWorkflowFile =
  (code: string, name: string, token: string) => (dispatch: any) => {
    Service()
      .workflowFilesDownload(code, token)
      .then((response: any) => {
        const file = new Blob([response.data], { type: response.data.type })
        const fileUrl = URL.createObjectURL(file)
        window.open(fileUrl, '_blank')
      })
      .catch((error: any) => {
        dispatch(downloadWorkflowFilesError(error))
        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(downloadWorkflowFiles(code, name, newToken))
          )
        )
      })
  }

export const clearDownloadFiles = () => ({
  type: DownloadFiles.CLEAR_DOWNLOAD_FILES
})
