export const enum competencyMap {
  GET_DOCUMENT_SUCCESS = '@competency-map/GET_DOCUMENT_SUCCESS',
  GET_DOCUMENT_ERROR = '@competency-map/GET_DOCUMENT_ERROR',
  CLEAR_COMPETENCY_MAP = '@competency-map/CLEAR_COMPETENCY_MAP',
  SAVE_COMPETENCY_MAP_SUCCESS = '@competency-map/SAVE_COMPETENCY_MAP_SUCCESS',
  SAVE_COMPETENCY_MAP_ERROR = '@competency-map/SAVE_COMPETENCY_MAP_ERROR',
  UPDATE_COMPETENCY_MAP_SUCCESS = '@competency-map/UPDATE_COMPETENCY_MAP_SUCCESS',
  UPDATE_COMPETENCY_MAP_ERROR = '@competency-map/UPDATE_COMPETENCY_MAP_ERROR'
}

export interface ICompetencyMapStates {
  documentType: any
  data: any
  error?: any
}
