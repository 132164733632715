import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Chakra from '@chakra-ui/react'
import { ApplicationState } from 'src/store/rootReducer'
import HeadingText from 'src/components/signup/heading-text'
import * as S from './styles'
import * as I from '../../../../../assets/icons'
import { uploadCsv } from 'src/store/mass-registration/actions'
import { showErrorToast } from 'src/utils/toast'
import saveAs from 'file-saver'
import { Select } from '@chakra-ui/react'
import { registrationOriginOptions } from 'src/constants/options'
import { MassRegistrationOrigin } from 'src/constants'
import { ERROR } from 'src/constants'

const MassRegistrationUploadCsv = () => {
  const dispatch = useDispatch()
  const userState = useSelector(
    (state: ApplicationState) => state.userReducer.user
  )
  const token = useSelector((state: ApplicationState) => state.userReducer.auth)

  const [registrationOrigin, setRegistrationOrigin] = useState<
    MassRegistrationOrigin | undefined
  >()

  const [fileUploaded, setFileUploaded] = useState<File>()

  const acceptedExtensions = ['text/csv', 'application/vnd.ms-excel']

  const uploadInputRef = useRef(null)

  const handleFile = (file: File) => {
    if (!userState || !userState.personalData?.cpf) {
      showErrorToast(ERROR, 'Usuário precisa estar logado.')
      return
    }

    if (!acceptedExtensions.includes(file.type)) {
      showErrorToast(ERROR, 'Extensão de arquivo não permitida.')
      return
    }

    setFileUploaded(file)
  }

  const submitFile = () => {
    if (
      fileUploaded &&
      registrationOrigin &&
      userState &&
      userState.personalData &&
      userState.personalData.cpf &&
      token &&
      token.layerSevenToken
    ) {
      dispatch(
        uploadCsv(fileUploaded, token.layerSevenToken, registrationOrigin)
      )
      setFileUploaded(undefined)
    }
  }

  const selectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRegistrationOrigin(+e.target.value)
  }

  const UploadButton = () => (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={uploadInputRef}
        accept={acceptedExtensions.join(',')}
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0)
            handleFile(event.target.files[0])
        }}
        multiple
        data-testid="file-input"
      />
      <Chakra.Button
        variant="normal"
        maxW="300px"
        onClick={() => {
          if (uploadInputRef && uploadInputRef.current)
            (uploadInputRef.current as any).click()
        }}
      >
        Anexar arquivo
      </Chakra.Button>
    </>
  )

  const validateFields = !registrationOrigin || !fileUploaded

  return (
    <Chakra.ModalContent>
      <Chakra.ModalCloseButton />
      <Chakra.ModalBody>
        <HeadingText
          title="Novo Cadastro"
          subtitle="Siga os passos para fazer o cadastramento em massa do corpo médico:"
        />
        {/* Section 1 */}
        <Chakra.HStack alignItems="start">
          <Chakra.Flex w="5%" minW="50px">
            <S.SectionNumber>1</S.SectionNumber>
          </Chakra.Flex>
          <Chakra.VStack w="95%" alignItems="start">
            <S.SectionTitle justifyContent="start">
              Preencha a lista (caso você já tenha uma, basta anexá-la no passo
              2)
            </S.SectionTitle>
            <Chakra.Button
              variant="normal"
              maxW="300px"
              onClick={() => {
                saveAs(
                  `${window._env_.REACT_APP_GALLERY_AMIL}/gerdoc/1641906323734template_carga_medicosv1.csv`,
                  'template_carga.csv'
                )
              }}
            >
              Download da lista
            </Chakra.Button>
          </Chakra.VStack>
        </Chakra.HStack>

        <Chakra.Divider marginY="16px" borderWidth="2px" />

        {/* Section 2 */}
        <Chakra.HStack alignItems="start">
          <Chakra.Flex w="5%" minW="50px">
            <S.SectionNumber>2</S.SectionNumber>
          </Chakra.Flex>
          <Chakra.VStack w="95%" alignItems="start">
            <S.SectionTitle justifyContent="start">
              Selecione a origem do cadastro e anexe a lista preenchida (somente
              arquivos csv):
            </S.SectionTitle>
            <Chakra.FormControl>
              <Chakra.FormLabel>Origem do cadastro</Chakra.FormLabel>
              <Select
                name="registrationOrigin"
                disabled={false}
                onChange={selectChange}
                value={registrationOrigin}
              >
                <>
                  <option value="" disabled selected hidden>
                    Selecione
                  </option>
                  {registrationOriginOptions}
                </>
              </Select>
            </Chakra.FormControl>
            {!fileUploaded ? (
              <UploadButton />
            ) : (
              <>
                <S.UploadedFileName w="100%">
                  <S.FileUploadedContainer>
                    <span>{fileUploaded.name}</span>
                  </S.FileUploadedContainer>
                  <Chakra.IconButton
                    isRound
                    variant="ghost"
                    aria-label="Excluir planilha"
                    onClick={() => setFileUploaded(undefined)}
                    icon={<I.TrashBinIcon size={28} />}
                    p={4}
                    w="60px"
                    h="60px"
                  />
                </S.UploadedFileName>
              </>
            )}
          </Chakra.VStack>
        </Chakra.HStack>
      </Chakra.ModalBody>

      <Chakra.ModalFooter>
        <Chakra.Button
          onClick={() => {
            submitFile()
          }}
          disabled={validateFields}
          variant="normal"
          maxW="200px"
        >
          Concluir
        </Chakra.Button>
      </Chakra.ModalFooter>
    </Chakra.ModalContent>
  )
}

export default MassRegistrationUploadCsv
