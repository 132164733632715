import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Chakra from '@chakra-ui/react'
import InputFilter from 'src/components/filter'
import HeadingText from 'src/components/signup/heading-text'
import { cpfMask } from 'src/utils/masks-validations/cpf-helpers'
import * as S from './styles'
import * as I from '../../assets/icons'
import { getFilesList, deleteFile } from 'src/store/files-list/actions'
import { downloadFiles } from 'src/store/files-download/actions'
import { THEME_GREY } from 'src/styles/colors'
import Button from 'src/components/button'
import Header from 'src/components/header'
import Modal from 'src/components/modal'
import Loader from 'src/components/loader'
import { MessageModalType } from 'src/store/message-modal/types'
import { ApplicationState } from 'src/store/rootReducer'
import Navigation from 'src/components/nav'
import MassRegistrationModal from '../home/components/modal-mass-registration'
import { useDisclosure } from '@chakra-ui/react'

const DocumentsConsult = () => {
  const dispatch = useDispatch()

  const files = useSelector(
    (state: ApplicationState) => state.filesReducer.files
  )

  const registrationStep = useSelector(
    (state: ApplicationState) => state.filesReducer.registrationStep
  )

  const deleteLoading = useSelector(
    (state: ApplicationState) => state.filesReducer.deleteFileLoading
  )

  const token = useSelector((state: ApplicationState) => state.userReducer.auth)

  const [values, setValues] = useState({
    cpf: ''
  })

  const userState = useSelector(
    (state: ApplicationState) => state.userReducer.user
  )

  const [fileToExclude, setFileToExclude] = useState({ cpf: '', code: '' })

  useEffect(() => {
    setFileToExclude({ cpf: '', code: '' })
  }, [files])

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: cpfMask(value)
    })
  }

  const handleSubmit = () => {
    if (token && token.layerSevenToken) {
      dispatch(getFilesList(values.cpf, token.layerSevenToken))
    }
  }

  const handleDownload = (code: string, name: string) => {
    if (token && token.layerSevenToken) {
      dispatch(downloadFiles(code, name, token.layerSevenToken))
    }
  }

  const handleDeleteFile = () => {
    if (token && token.layerSevenToken) {
      dispatch(
        deleteFile(
          fileToExclude.cpf,
          fileToExclude.code,
          token.layerSevenToken,
          files
        )
      )
    }
  }

  const colSpan1 = Chakra.useBreakpointValue({
    base: 2,
    xl: 2,
    lg: 2,
    md: 6,
    sm: 6
  })

  const colSpan2 = Chakra.useBreakpointValue({
    base: 4,
    xl: 4,
    lg: 4,
    md: 6,
    sm: 6
  })
  const {
    isOpen: showMassModal,
    onOpen: onOpenMassModal,
    onClose: onCloseMassModal
  } = useDisclosure()

  const showExcludeModal = fileToExclude.code !== ''

  return (
    <>
      <Header />
      <S.Container>
        <Chakra.Grid templateColumns="repeat(6, 1fr)" gap={3}>
          <S.MenuContainer colSpan={colSpan1}>
            <Navigation onOpenMassModal={onOpenMassModal} />
          </S.MenuContainer>
          <MassRegistrationModal
            isOpen={showMassModal}
            onClose={onCloseMassModal}
          />
          <Chakra.GridItem colSpan={colSpan2}>
            <HeadingText title="Consulta de Documentos do Médico" />
            <Chakra.Text fontSize="18px" fontWeight="bold" color={THEME_GREY}>
              Informe o CPF do Médico
            </Chakra.Text>
            <InputFilter
              name="cpf"
              value={cpfMask(values.cpf)}
              placeholder="Informe um CPF"
              onChange={inputChange}
            />
            <S.AlignButton>
              <Button
                maxW="200"
                disabled={values.cpf.length < 14}
                variant="normal"
                type="button"
                onClick={handleSubmit}
              >
                Consultar
              </Button>
            </S.AlignButton>
          </Chakra.GridItem>
        </Chakra.Grid>

        <S.Ul>
          {files?.map(
            (file: { code: string; name: string; cpf: string }): any => {
              return (
                <div key={file.code}>
                  <S.Item key={file.code}>
                    <S.Download
                      onClick={() => handleDownload(file.code, file.name)}
                    >
                      <S.PdfIcon />
                      <S.ButtonTitle>{file.name}</S.ButtonTitle>
                    </S.Download>

                    {registrationStep && registrationStep !== 6 && (
                      <Chakra.IconButton
                        isRound
                        variant="ghost"
                        aria-label="Excluir arquivo"
                        icon={<I.TrashBinIcon size={24} />}
                        onClick={() =>
                          setFileToExclude({ cpf: file.cpf, code: file.code })
                        }
                        p={2}
                        w="30px"
                        h="30px"
                      />
                    )}
                  </S.Item>
                </div>
              )
            }
          )}

          {showExcludeModal && (
            <Modal
              isOpen={showExcludeModal}
              onClose={() => setFileToExclude({ cpf: '', code: '' })}
              size="sm"
              title="Deseja excluir este documento?"
              titleButton=""
              isCentered={true}
              type={MessageModalType.Error}
            >
              <Chakra.Grid>
                <Chakra.Flex>
                  <Button
                    variant="normal"
                    onClick={() => handleDeleteFile()}
                    disabled={deleteLoading}
                  >
                    Excluir
                  </Button>
                </Chakra.Flex>
              </Chakra.Grid>
            </Modal>
          )}
        </S.Ul>
      </S.Container>
    </>
  )
}

export default DocumentsConsult
