import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'
import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'

export const ModalFooter = styled(Chakra.ModalFooter)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media screen and (max-width: 400px) {
    grid-template-columns: 1fr;
  }
`

export const ModalBody = styled(Chakra.ModalBody)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.p`
  color: ${THEME_PURPLE};
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
`

export const Text = styled.p`
  color: ${THEME_GREY};
  text-align: center;
  font-size: 16px;
  margin-top: 15px;
`
