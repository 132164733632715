import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import urls from 'src/constants/api'
import { store } from 'src/store'
import { workflowAPI } from '../interceptors'

interface IApi {
  authApi: AxiosInstance
  registrationApi: AxiosInstance
  getFiles(cpf: string, token: string): Promise<AxiosResponse>
  filesDownload(code: string, token: string): Promise<AxiosResponse>
  workflowFilesDownload(code: string, token: string): Promise<AxiosResponse>
  getDocument(token: string): Promise<AxiosResponse>
  getVaccineTerm(): Promise<AxiosResponse>
  getHospitalTerm(): Promise<AxiosResponse>
}

export default (): IApi => {
  const authApi = axios.create({ baseURL: urls.authAPI })

  const registrationApi = axios.create({
    baseURL: urls.registrationAPI
  })

  registrationApi.interceptors.request.use(
    async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      request.headers.Authorization = `Bearer ${
        store.getState().userReducer.auth.accessToken
      }`
      return request
    }
  )

  const amilDocumentsApi = axios.create({
    baseURL: urls.amilAPI
  })

  return {
    authApi: authApi,
    registrationApi: registrationApi,

    getFiles: (cpf: string, token: string) => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi.get(`Files/files-information?Cpf=${cpf}`, {
        headers
      })
    },

    filesDownload: async (code: string, token: string) => {
      const headers = {
        'X-Authorization': `${token}`
      }
      const response = await registrationApi.get(
        `Files/download?FileCode=${code}`,
        {
          headers,
          responseType: 'blob'
        }
      )
      return await response
    },

    workflowFilesDownload: async (code: string, token: string) => {
      const headers = {
        'X-Authorization': `${token}`
      }
      const response = await workflowAPI().get(
        `FileInformation/download?FileCode=${code}`,
        {
          headers,
          responseType: 'blob'
        }
      )
      return await response
    },

    getDocument: async () => {
      const headers = {
        Accept: 'application/vnd.amil.gerdoc.site.v1+json'
      }
      return amilDocumentsApi.get('/documentos/lista/chave/mapa_competencia', {
        headers: headers
      })
    },

    getVaccineTerm: async () => {
      const headers = {
        Accept: 'application/vnd.amil.gerdoc.site.v1+json'
      }
      return amilDocumentsApi.get(
        '/documentos/lista/chave/termo_vacina?tag=contexto_americas&tag=site',
        {
          headers: headers
        }
      )
    },
    getHospitalTerm: async () => {
      const headers = {
        Accept: 'application/vnd.amil.gerdoc.site.v1+json'
      }
      return amilDocumentsApi.get(
        '/documentos/lista/chave/termo_uso_hospitais?tag=contexto_americas&tag=site',
        {
          headers: headers
        }
      )
    }
  }
}
