import Header from 'src/components/header'
import NavigationMenu from 'src/components/nav'
import HeadingText from 'src/components/signup/heading-text'
import * as Chakra from '@chakra-ui/react'
import * as S from './styles'
import { Link, useHistory, withRouter } from 'react-router-dom'
import Button from 'src/components/button'
import { ACCESSIBILITY_ERROR, THEME_PURPLE } from 'src/styles/colors'
import Table from './components/table'
import { IoWarning } from 'react-icons/io5'
import {
  FavoriteIcon,
  MessagesCircleIcon,
  ProfessionalInfoCircleIcon
} from 'src/assets/icons'
import { TiStarFullOutline } from 'react-icons/ti'
import { ChangeEvent, useEffect, useState } from 'react'
import TableDetails from './components/tableDetails'
import AlertModal from './components/alertModal'
import WarningMessage from './components/warningMessage'
import ChangeAnalystModal from './components/changeAnalystModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  createApproveSolicitation,
  createRejectSolicitation,
  getSolicitationDetails,
  getExclusionReason,
  directorApproveSolicitation,
  directorRejectSolicitation
} from 'src/store/solicitation-details/actions'
import { ApplicationState } from 'src/store/rootReducer'
import { VerifyChatFlag } from 'src/components/flags/chat'
import Modal from 'src/components/modal'
import { MessageModalType } from 'src/store/message-modal/types'
import Select from 'src/components/input-select'
import DocumentsUpload from 'src/components/documents-upload'
import { VerifyWarningMessageFlag } from 'src/components/flags/warning-message'
import { VerifyAlertFlag } from 'src/components/flags/alert'
import { VerifyFavoriteButtonFlag } from 'src/components/flags/favorite-button'
import ROUTING_PATHS from 'src/routes/paths'
import { UserProfileEnum } from 'src/store/login/types'
import Loader from 'src/components/loader'
import MassRegistrationModal from '../home/components/modal-mass-registration'
import { useDisclosure } from '@chakra-ui/react'

const verifyStatus = (status: string) => {
  const statusDescriptions: any = {
    Nova: false,
    'Em Análise': true,
    Rejeitada: false,
    Pendência: true,
    Aprovada: false,
    Bloqueado: false,
    Cancelada: false,
    'Aguardando Aprovação do Diretor': true
  }

  return statusDescriptions[status]
}

const SolicitationDetails = ({ match: { params } }: any) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [showAlert, setAlert] = useState<boolean>(false)
  const [showChangeAnalyst, setChangeAnalyst] = useState<boolean>(false)
  const [showModalApprove, toggleModalApprove] = useState(false)
  const [showModalApproveError, toggleModalApproveError] = useState(false)
  const [messageModal, toggleMessageModal] = useState('')
  const [showModalReject, toggleModalReject] = useState(false)
  const [showModalRejectError, toggleModalRejectError] = useState(false)
  const [showModalRejectSuccess, toggleModalRejectSucess] = useState(false)
  const [isFavorite, setIsFavorite] = useState<boolean>(false)
  const [values, setValue] = useState({
    justification: '',
    reasonReject: '',
    rejectDocument: ''
  })
  const filesInformation = useSelector(
    (state: ApplicationState) =>
      state.solicitationDetailReducer.filesInformation
  )
  const solicitation = useSelector(
    (state: ApplicationState) => state.solicitationDetailReducer.solicitation
  )
  const auth = useSelector((state: ApplicationState) => state.userReducer.auth)
  const uploadDoc = useSelector(
    (state: ApplicationState) => state.documentsUploadReducer
  )
  const excludeReason = useSelector(
    (state: ApplicationState) => state.solicitationDetailReducer.excludeReason
  )

  const documents = useSelector(
    (state: ApplicationState) =>
      state.solicitationDetailReducer.filesInformation
  )

  const loading = useSelector(
    (state: ApplicationState) => state.solicitationDetailReducer.loading
  )

  const userProfileId = Number(auth?.userProfile)
  const nameProfile = UserProfileEnum[userProfileId]

  const colSpan1 = Chakra.useBreakpointValue({
    base: 2,
    xl: 2,
    lg: 2,
    md: 6,
    sm: 6
  })

  const colSpan2 = Chakra.useBreakpointValue({
    base: 4,
    xl: 4,
    lg: 4,
    md: 6,
    sm: 6
  })

  useEffect(() => {
    if (auth && auth.layerSevenToken) {
      dispatch(
        getSolicitationDetails(
          auth.layerSevenToken,
          params.id,
          params.technicalEvaluationId
        )
      )
      dispatch(getExclusionReason(auth.layerSevenToken))
    }
  }, [])

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target

    setValue({
      ...values,
      [name]: value
    })
  }

  const selectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setValue({
      ...values,
      [name]: value
    })
  }
  const approveSolicitation = () => {
    const onSuccessCallback = () => {
      toggleModalApprove(true)
    }

    const onErrorCallback = (errorMessage: any) => {
      toggleModalApproveError(true)
      toggleMessageModal(errorMessage)
    }

    const hasApproved = filesInformation.some(
      (file: any) =>
        (file.fileTypeId === '8' || file.fileTypeId === 8) &&
        file.fileStatus === 'Aprovado'
    )

    if (!hasApproved) {
      onErrorCallback(
        'Para aprovar uma solicitação, pelo menos UM mapa de competência deve estar aprovado.'
      )
      return
    }

    if (auth && auth.layerSevenToken) {
      if (nameProfile === 'DIRECTOR') {
        dispatch(
          directorApproveSolicitation(
            { technicalEvaluationId: params.technicalEvaluationId },
            auth.layerSevenToken,
            onSuccessCallback,
            onErrorCallback
          )
        )
      } else {
        dispatch(
          createApproveSolicitation(
            { solicitationId: params.id },
            auth.layerSevenToken,
            onSuccessCallback,
            onErrorCallback
          )
        )
      }
    }
  }

  const rejectSolicitation = () => {
    const onSuccessCallback = () => {
      toggleModalReject(false)
      toggleModalRejectSucess(true)
    }

    const onErrorCallback = () => {
      toggleModalRejectError(true)
    }

    if (auth && auth.layerSevenToken) {
      const { reasonReject, justification } = values
      const fileCodes = uploadDoc.uploadedFileCodes.map(
        (data: any) => data.code
      )

      if (nameProfile === 'DIRECTOR') {
        dispatch(
          directorRejectSolicitation(
            {
              technicalEvaluationId: params.technicalEvaluationId,
              step: 'string',
              exclusionReasonId: reasonReject,
              justification: justification,
              fileCodes: fileCodes
            },
            auth.layerSevenToken,
            onSuccessCallback,
            onErrorCallback
          )
        )
      } else {
        dispatch(
          createRejectSolicitation(
            {
              solicitationId: params.id,
              step: 'string',
              exclusionReasonId: reasonReject,
              justification: justification,
              fileCodes: fileCodes
            },
            auth.layerSevenToken,
            onSuccessCallback,
            onErrorCallback
          )
        )
      }
    }
  }

  const closeModalReject = () => {
    toggleModalRejectSucess(false)
    history.push(ROUTING_PATHS.Solicitation)
  }

  const sendToSolicitationList = () => {
    history.push(ROUTING_PATHS.Solicitation)
  }

  const disabledWithRelationship =
    nameProfile !== 'DIRECTOR' &&
    solicitation.status !== 'Em Análise' &&
    solicitation.status !== 'Nova'
  const disabledWithDirector = !solicitation.isEditable
  const {
    isOpen: showMassModal,
    onOpen: onOpenMassModal,
    onClose: onCloseMassModal
  } = useDisclosure()
  return (
    <>
      <Header border />
      <S.Container>
        <Chakra.Grid templateColumns="repeat(6, 1fr)" gap={3}>
          <Chakra.GridItem colSpan={colSpan1}>
            <NavigationMenu onOpenMassModal={onOpenMassModal} />
          </Chakra.GridItem>
          <MassRegistrationModal
            isOpen={showMassModal}
            onClose={onCloseMassModal}
          />

          <Chakra.GridItem colSpan={colSpan2}>
            <S.ContainerTitle justifyContent="space-between">
              <HeadingText
                title="Detalhe solicitação"
                subtitle="Acompanhe os detalhes do caso e documentos necessários para a aprovação do cadastro."
              />

              {window._flags_.ALERTS_ENABLED ||
              window._flags_.FAVORITE_BUTTON_ENABLED ? (
                <Chakra.Box>
                  <S.FavoriteContainer>
                    <Chakra.Box display="flex" alignItems="center">
                      <VerifyAlertFlag>
                        <Chakra.IconButton
                          _hover={{ bgColor: 'none' }}
                          variant="ghost"
                          aria-label="Acompanhar caso"
                          onClick={() => setAlert(!showAlert)}
                          icon={
                            <IoWarning
                              fontSize={28}
                              color={ACCESSIBILITY_ERROR}
                            />
                          }
                          w="1px"
                          h="2px"
                        />

                        <S.FavoriteContainerText>
                          Alertas
                        </S.FavoriteContainerText>
                      </VerifyAlertFlag>

                      <VerifyFavoriteButtonFlag>
                        <Chakra.IconButton
                          _hover={{ bgColor: 'none' }}
                          variant="ghost"
                          aria-label="Acompanhar caso"
                          onClick={() => setIsFavorite(!isFavorite)}
                          icon={
                            !isFavorite ? (
                              <TiStarFullOutline
                                fontSize={28}
                                color={THEME_PURPLE}
                              />
                            ) : (
                              <FavoriteIcon size="28px" />
                            )
                          }
                          w="1px"
                          h="2px"
                        />
                        <S.FavoriteContainerText color={THEME_PURPLE}>
                          Acompanhar
                        </S.FavoriteContainerText>
                      </VerifyFavoriteButtonFlag>
                    </Chakra.Box>
                  </S.FavoriteContainer>
                  <Chakra.GridItem colSpan={4}>
                    {showAlert && <AlertModal />}
                  </Chakra.GridItem>
                </Chakra.Box>
              ) : null}
            </S.ContainerTitle>

            <ChangeAnalystModal
              isOpen={showChangeAnalyst}
              onClose={() => setChangeAnalyst(false)}
            />

            {verifyStatus(solicitation.status) ? (
              <VerifyWarningMessageFlag>
                <WarningMessage openModal={() => setChangeAnalyst(true)} />
              </VerifyWarningMessageFlag>
            ) : null}

            <TableDetails solicitationDetails={solicitation} />

            <S.ButtonsContainer>
              <S.CardButton
                onClick={() =>
                  history.push(`/registered-info/${solicitation.id}`)
                }
              >
                <Chakra.Flex alignItems="center">
                  <ProfessionalInfoCircleIcon
                    fill={THEME_PURPLE}
                    width="8rem"
                    stroke={THEME_PURPLE}
                  />
                  <S.CardTitle>Dados do cadastro</S.CardTitle>
                </Chakra.Flex>
                <S.CardText>
                  Quer revisar algum dado do cadastro em análise?
                </S.CardText>
              </S.CardButton>

              <VerifyChatFlag>
                <S.CardButton>
                  <Chakra.Flex alignItems="center">
                    <MessagesCircleIcon
                      fill={THEME_PURPLE}
                      width="8rem"
                      stroke={THEME_PURPLE}
                    />
                    <S.CardTitle>Chat</S.CardTitle>
                  </Chakra.Flex>
                  <S.CardText>
                    Converse, consulte e crie pendências referentes a este
                    cadastro.
                  </S.CardText>
                </S.CardButton>
              </VerifyChatFlag>
            </S.ButtonsContainer>

            {loading ? <Loader /> : <Table documents={documents} />}
          </Chakra.GridItem>
        </Chakra.Grid>

        <Chakra.Grid templateColumns="repeat(6, 1fr)" justifyContent={'end'}>
          <Chakra.GridItem colSpan={6}>
            <Chakra.Divider
              borderColor="secondary"
              marginTop="5vh"
              marginBottom="5vh"
            />
          </Chakra.GridItem>

          <Chakra.GridItem colStart={6}>
            <S.ButtonsContainer>
              <Chakra.Box>
                <Button variant="outlined" onClick={sendToSolicitationList}>
                  Lista de solicitações
                </Button>
              </Chakra.Box>
              <Chakra.Box>
                <Button
                  variant="outlined"
                  onClick={() => toggleModalReject(true)}
                  disabled={
                    nameProfile === 'DIRECTOR'
                      ? disabledWithDirector
                      : disabledWithRelationship
                  }
                >
                  Rejeitar solicitação
                </Button>
              </Chakra.Box>
              <Chakra.Box>
                <Button
                  variant="normal"
                  onClick={() => approveSolicitation()}
                  disabled={
                    nameProfile === 'DIRECTOR'
                      ? disabledWithDirector
                      : disabledWithRelationship
                  }
                >
                  {nameProfile === 'DIRECTOR'
                    ? `Aprovar solicitação`
                    : `Encaminhar para aprovação`}
                </Button>
              </Chakra.Box>
            </S.ButtonsContainer>
          </Chakra.GridItem>
        </Chakra.Grid>
      </S.Container>
      {showModalApprove && (
        <Modal
          isOpen={showModalApprove}
          onClose={toggleModalApprove}
          size="md"
          title={
            nameProfile === 'DIRECTOR'
              ? `Solicitação aprovada`
              : `Encaminhada para aprovação`
          }
          titleButton=""
          isCentered={true}
          type={MessageModalType.Success}
        >
          <Chakra.Grid>
            <Chakra.GridItem mb={6}>
              {nameProfile === 'DIRECTOR'
                ? `A Solicitação foi aprovada pelo sistema`
                : `A solicitação foi enviada para aprovação do(s) Diretor(es)
                  Médico(s) da(s) Unidade(s).`}
            </Chakra.GridItem>
            <Link to={ROUTING_PATHS.Solicitation}>
              <Button variant="normal" type="submit">
                OK
              </Button>
            </Link>
          </Chakra.Grid>
        </Modal>
      )}
      {showModalApproveError && (
        <Modal
          isOpen={showModalApproveError}
          onClose={toggleModalApproveError}
          size="md"
          title={
            nameProfile === 'DIRECTOR'
              ? `Não foi possível aprovar a solicitação`
              : `Não foi possível encaminhar a sua solicitação`
          }
          titleButton=""
          isCentered={true}
          type={MessageModalType.Error}
        >
          <Chakra.Grid>
            <Chakra.GridItem mb={6}>{messageModal}</Chakra.GridItem>

            <Button
              variant="normal"
              type="submit"
              onClick={() => toggleModalApproveError(false)}
            >
              OK
            </Button>
          </Chakra.Grid>
        </Modal>
      )}
      {showModalReject && (
        <Modal
          isOpen={showModalReject}
          onClose={toggleModalReject}
          size="4xl"
          title="Deseja rejeitar esse cadastro?"
          titleButton=""
          type={MessageModalType.Error}
        >
          <Chakra.Grid>
            <Chakra.GridItem>
              Justifique porque a solicitação deve ser recusada.
            </Chakra.GridItem>
            <Chakra.GridItem margin="5px 0">
              <Select
                disabled={false}
                data-testid="reasonReject"
                name="reasonReject"
                onChange={selectChange}
                value={values.reasonReject}
              >
                <>
                  <option value="" disabled>
                    Escolha um motivo
                  </option>
                  {excludeReason.map((data: any) => (
                    <option value={data.id} key={data.id}>
                      {data.name}
                    </option>
                  ))}
                </>
              </Select>
            </Chakra.GridItem>
            <Chakra.GridItem>
              <Chakra.GridItem margin="20px 0 0 0">
                <strong>
                  Caso haja algum documento para validar a rejeição, faça o
                  upload aqui:
                </strong>
              </Chakra.GridItem>
              <DocumentsUpload
                acceptedMimeTypes="image/jpeg, image/jpg, image/png, application/pdf"
                isModalReject={true}
              />
            </Chakra.GridItem>
            <Chakra.GridItem>
              <Chakra.GridItem margin="20px 0 0 0">
                <strong> Detalhe aqui o motivo da rejeição:</strong>
              </Chakra.GridItem>
              <Chakra.Textarea
                value={values.justification}
                onChange={handleInputChange}
                name="justification"
                margin="10px 0"
              />
            </Chakra.GridItem>

            <Chakra.Grid marginTop="5" templateColumns="repeat(4, 1fr)" gap={4}>
              <Chakra.GridItem color={ACCESSIBILITY_ERROR} colSpan={4}>
                <strong>
                  Ao clicar em rejeitar, essa solicitação será cancelada e não
                  será possível desfazer essa ação.
                </strong>
              </Chakra.GridItem>
              <Chakra.GridItem colSpan={2}>
                <Button
                  variant="outlined"
                  onClick={() => toggleModalReject(false)}
                >
                  Cancelar
                </Button>
              </Chakra.GridItem>
              <Chakra.GridItem colSpan={2}>
                <Button
                  variant="normal"
                  disabled={!values.justification}
                  onClick={() => rejectSolicitation()}
                >
                  Rejeitar
                </Button>
              </Chakra.GridItem>
            </Chakra.Grid>
          </Chakra.Grid>
        </Modal>
      )}
      {showModalRejectError && (
        <Modal
          isOpen={showModalRejectError}
          onClose={toggleModalRejectError}
          size="md"
          title="Não foi possível rejeitar esse cadastro"
          titleButton=""
          isCentered={true}
          type={MessageModalType.Error}
        >
          <Chakra.Grid>
            <Chakra.GridItem mb={6}>
              Ocorreu um erro. Por favor tente mais tarde.
            </Chakra.GridItem>

            <Button
              variant="normal"
              type="submit"
              onClick={() => toggleModalRejectError(false)}
            >
              OK
            </Button>
          </Chakra.Grid>
        </Modal>
      )}
      {showModalRejectSuccess && (
        <Modal
          isOpen={showModalRejectSuccess}
          onClose={closeModalReject}
          size="md"
          title="Esta solicitação foi rejeitada com sucesso"
          titleButton=""
          isCentered={true}
          type={MessageModalType.Success}
        >
          <Chakra.Grid mt={6}>
            <Link to={ROUTING_PATHS.Solicitation}>
              <Button variant="normal" type="submit">
                OK
              </Button>
            </Link>
          </Chakra.Grid>
        </Modal>
      )}
    </>
  )
}

export default withRouter(SolicitationDetails)
