import * as React from 'react'
import { useEffect, useState } from 'react'
import * as Chakra from '@chakra-ui/react'
import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'
import Carousel from '../../carousel/index'
import { AiOutlineUser } from 'react-icons/ai'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'
import { BiTrash } from 'react-icons/bi'
import { ICardProps, IAccreditationProps, Hospitals } from '../../interface'
import * as S from './style'
import Select from 'src/components/input-select'
import CustomTextField from 'src/components/text-field'
import { useForm } from 'react-hook-form'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { counselNumberValidation } from 'src/utils/masks-validations/crm-validation'

const AccreditationForm = ({
  reason,
  teamOrBossName,
  crmBossNumber,
  interestDescription,
  setSelectedHospitals,
  selectedHospitals,
  hospitals,
  memberTeamHospitals,
  interestResearch,
  inputChange,
  register,
  setValueFormRegister,
  changeMultiplesInput
}: IAccreditationProps) => {
  const {
    formState: { errors }
  } = useForm()
  const [recommendedHospitals] = useState<Hospitals[]>([])

  const [activeSlide, setActiveSlide] = useState(0)
  const [hospitalsSelected, setHospitalsSelected] = useState<Hospitals[]>([])

  const slideMaxWidth = '1500px'

  useEffect(() => {
    setHospitalsSelected(selectedHospitals)
  }, [selectedHospitals])

  const renderCarousel = () => {
    if (!recommendedHospitals || recommendedHospitals.length < 1) return 'a'
    const Card = (props: ICardProps) => (
      <S.HospitalCard
        onClick={() => {
          addSelectedHospital(props.hospital)
        }}
        key={props.key}
        _hover={{ backgroundColor: THEME_PURPLE, color: 'white' }}
      >
        <Chakra.Grid
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(2, 1fr)"
        >
          <Chakra.GridItem>
            <Chakra.HStack>
              <div>
                <Chakra.Avatar
                  bg={THEME_PURPLE}
                  icon={<AiOutlineUser fontSize="1.5rem" color="white" />}
                />
              </div>
              <div>{props.hospital.name}</div>
            </Chakra.HStack>
          </Chakra.GridItem>
        </Chakra.Grid>
      </S.HospitalCard>
    )

    return (
      <Carousel
        updateOnItemClick
        backwardBtnProps={{
          children: <IoIosArrowBack color={THEME_GREY} size={70} />,
          style: { fontSize: '24px' }
        }}
        forwardBtnProps={{
          children: <IoIosArrowForward color={THEME_GREY} size={70} />,
          style: { fontSize: '24px' }
        }}
        itemsToShow={3}
        itemsToScroll={1}
        onRequestChange={setActiveSlide}
        containerProps={{
          style: {
            width: '100%',
            justifyContent: 'space-between'
          }
        }}
        innerProps={{
          style: { width: slideMaxWidth, maxWidth: '1500px !important' }
        }}
        activeSlideIndex={activeSlide}
        activeSlideProps={{
          style: {
            background: THEME_PURPLE
          }
        }}
        speed={400}
      >
        {recommendedHospitals?.map((hospital: Hospitals) => {
          return <Card key={hospital.name} hospital={hospital} />
        })}
      </Carousel>
    )
  }

  const addSelectedHospital = (hospital: Hospitals) => {
    if (hospitalsSelected.some((hosp) => hosp.name === hospital.name)) return
    const newList = [...hospitalsSelected]
    newList.push(hospital)
    setHospitalsSelected([...newList])
    setSelectedHospitals([...newList])
  }

  const deleteSelectedHospital = (hospital: Hospitals) => {
    const newList: Hospitals[] = hospitalsSelected.filter(
      (hosp) => hosp.name !== hospital.name
    )
    setHospitalsSelected([...newList])
    setSelectedHospitals([...newList])
  }

  const onInputChangeCustom = (value: any, inputName: string) => {
    inputChange({
      currentTarget: {
        name: inputName,
        value: value
      }
    })
  }

  const onChangeRadioTeamMember = (value: string) => {
    if (value === 'false') {
      changeMultiplesInput({
        crmBossNumber: '',
        teamOrBossName: '',
        memberTeamHospitals: JSON.parse(value)
      })
      setValueFormRegister('crmBossNumber', '')
      setValueFormRegister('teamOrBossName', '')
    } else {
      onInputChangeCustom(JSON.parse(value), 'memberTeamHospitals')
    }
  }

  const onChangeResearchInterest = (value: string) => {
    onInputChangeCustom(value, 'interestResearch')
  }

  const createHospitalsOptions = () => {
    return (
      <>
        <option value="" disabled selected hidden>
          Selecione
        </option>
        {hospitals?.map((hospital) => {
          return (
            <option value={hospital.name} key={hospital.name}>
              {hospital.name}
            </option>
          )
        })}
      </>
    )
  }

  const colSpan2 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 6,
    sm: 6
  })
  const colSpan3 = Chakra.useBreakpointValue({
    base: 3,
    xl: 3,
    lg: 4,
    md: 6,
    sm: 6
  })
  const colSpan4 = Chakra.useBreakpointValue({
    base: 6,
    xl: 6,
    lg: 6,
    md: 6,
    sm: 6
  })

  return (
    <>
      <Chakra.GridItem colSpan={colSpan4} m="30px 0">
        <S.Heading>
          <S.Title>Solicitação de credenciamento</S.Title>
        </S.Heading>
        <Chakra.Text mb="5" ml="3" fontSize="14px" color={THEME_GREY}>
          Por favor escolha um ou mais hospitais para se cadastrar.
        </Chakra.Text>
      </Chakra.GridItem>

      <Chakra.Grid style={{ display: 'none' }}>
        <Chakra.Center marginBottom="30px">
          <Chakra.Heading as="h2" size="lg" color={THEME_GREY} isTruncated>
            Hospitais recomendados do grupo:
          </Chakra.Heading>
        </Chakra.Center>
        {renderCarousel()}
      </Chakra.Grid>

      <S.NumberedSection>
        <S.NumberedSectionNumber>1</S.NumberedSectionNumber>
        <S.NumberedSectionTitle>Hospitais/unidades</S.NumberedSectionTitle>
      </S.NumberedSection>
      <Chakra.Grid marginLeft="50px">
        <Chakra.GridItem colSpan={colSpan2}>
          <Chakra.FormControl marginTop="20px">
            <Chakra.FormLabel>Hospital/Unidade</Chakra.FormLabel>
            <Select
              disabled={false}
              data-testid="hospitalName"
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const foundHospital = hospitals?.find(
                  (hospital: { name: string }) => {
                    if (
                      hospital.name.toLowerCase() ===
                      (event.target.value as string).toLowerCase()
                    )
                      return hospital
                  }
                )
                if (foundHospital) addSelectedHospital(foundHospital)
              }}
            >
              <>
                {hospitals && hospitals.length > 0 ? (
                  <>{createHospitalsOptions()} </>
                ) : (
                  <>
                    <option value="" disabled selected hidden>
                      Selecione
                    </option>
                    <option>Nenhum hospital encontrado</option>
                  </>
                )}
              </>
            </Select>
          </Chakra.FormControl>
        </Chakra.GridItem>
      </Chakra.Grid>

      <Chakra.Grid marginLeft="50px" marginTop="20px">
        <Chakra.GridItem colSpan={3}>
          <Chakra.Wrap w="100%">
            {hospitalsSelected?.map((hospital: Hospitals) => (
              <Chakra.HStack key={hospital.name}>
                <Chakra.Text color={THEME_GREY}>
                  <strong>{hospital.name}</strong>
                </Chakra.Text>
                <BiTrash
                  size={24}
                  color={THEME_GREY}
                  onClick={() => {
                    deleteSelectedHospital(hospital)
                  }}
                  cursor="pointer"
                  style={{ marginRight: '10px' }}
                />
              </Chakra.HStack>
            ))}
          </Chakra.Wrap>
        </Chakra.GridItem>
      </Chakra.Grid>

      <Chakra.Divider
        w="100%"
        borderColor={THEME_GREY}
        marginTop="20px"
        marginBottom="20px"
      />

      <S.NumberedSection>
        <S.NumberedSectionNumber>2</S.NumberedSectionNumber>
        <S.NumberedSectionTitle>
          Será membro de alguma equipe nos hospitais?
        </S.NumberedSectionTitle>
      </S.NumberedSection>
      <Chakra.Grid templateColumns="repeat(3, 1fr)" gap={4} marginLeft="50px">
        <Chakra.GridItem colSpan={3}>
          <Chakra.RadioGroup
            onChange={onChangeRadioTeamMember}
            name="memberTeamHospitals"
            value={memberTeamHospitals?.toString()}
            data-testid="hospitalTeamMember"
          >
            <Chakra.VStack alignItems="start">
              <Chakra.Radio colorScheme="purple" value="true">
                Sim (ao marcar esta opção está ciente de que precisará de
                comprovação posterior)
              </Chakra.Radio>
              <Chakra.Radio colorScheme="purple" value="false">
                Não
              </Chakra.Radio>
            </Chakra.VStack>
          </Chakra.RadioGroup>
          <Chakra.GridItem colSpan={colSpan3}>
            <Chakra.FormControl>
              <Chakra.FormLabel>
                <strong>
                  Por qual motivo deseja se cadastrar no(s) hospital(is) da Rede
                  Americas?
                </strong>
              </Chakra.FormLabel>
              <CustomTextField
                register={register('reason')}
                type="text"
                value={reason}
                onChange={inputChange}
                data-testid="reason"
              />
            </Chakra.FormControl>
          </Chakra.GridItem>
          {(memberTeamHospitals === 'true' || memberTeamHospitals === true) && (
            <Chakra.Grid templateColumns="repeat(4, 1fr)" gap={5}>
              <Chakra.GridItem colSpan={colSpan3}>
                <Chakra.FormControl>
                  <Chakra.FormLabel>
                    <strong>
                      Nome da(s) equipe(s) ou chefe(s) da(s) equipe(s)
                    </strong>
                  </Chakra.FormLabel>
                  <CustomTextField
                    register={register('teamOrBossName')}
                    type="text"
                    value={teamOrBossName}
                    onChange={inputChange}
                    data-testid="teamOrBossName"
                  />
                  {errors?.teamOrBossName &&
                  (memberTeamHospitals === 'true' ||
                    memberTeamHospitals === true) ? (
                    <S.ErrorLabel color="danger">
                      <WarningTwoIcon color="danger" />
                      *Campo obrigatório
                    </S.ErrorLabel>
                  ) : (
                    (memberTeamHospitals === 'true' ||
                      memberTeamHospitals === true) && (
                      <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
                    )
                  )}
                </Chakra.FormControl>
              </Chakra.GridItem>
              <Chakra.GridItem colSpan={colSpan2}>
                <Chakra.FormControl>
                  <Chakra.FormLabel>
                    <strong>CRM/CRO do(a) chefe da equipe</strong>
                  </Chakra.FormLabel>
                  <CustomTextField
                    register={register('crmBossNumber')}
                    type="text"
                    value={
                      crmBossNumber
                        ? counselNumberValidation(crmBossNumber)
                        : ''
                    }
                    onChange={inputChange}
                    data-testid="crmBossNumber"
                    isInvalid={!memberTeamHospitals}
                  />
                  {errors?.crmBossNumber &&
                  (memberTeamHospitals === 'true' ||
                    memberTeamHospitals === true) ? (
                    <S.ErrorLabel color="danger">
                      <WarningTwoIcon color="danger" />
                      *Campo obrigatório
                    </S.ErrorLabel>
                  ) : (
                    (memberTeamHospitals === 'true' ||
                      memberTeamHospitals === true) && (
                      <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
                    )
                  )}
                </Chakra.FormControl>
              </Chakra.GridItem>
            </Chakra.Grid>
          )}
          <Chakra.GridItem colSpan={3}>
            <Chakra.FormControl>
              <Chakra.FormLabel>
                <strong>
                  Se desejar, faça uma breve descrição sobre o seu interesse em
                  fazer parte do nosso corpo clínico:
                </strong>
              </Chakra.FormLabel>
              <S.TextareaField
                focusBorderColor={THEME_PURPLE}
                type="text"
                {...register('interestDescription')}
                value={interestDescription}
                onChange={inputChange}
                data-testid="interestDescription"
              />
            </Chakra.FormControl>
          </Chakra.GridItem>
        </Chakra.GridItem>
      </Chakra.Grid>

      <Chakra.Divider
        w="100%"
        borderColor={THEME_GREY}
        marginTop="20px"
        marginBottom="20px"
      />

      <S.NumberedSection>
        <S.NumberedSectionNumber>3</S.NumberedSectionNumber>
        <S.NumberedSectionTitle>
          Você tem interesse em desenvolver projetos de pesquisa?
        </S.NumberedSectionTitle>
      </S.NumberedSection>
      <Chakra.Grid templateColumns="repeat(3, 1fr)" gap={4} marginLeft="50px">
        <Chakra.GridItem colSpan={3}>
          <Chakra.RadioGroup
            onChange={onChangeResearchInterest}
            value={interestResearch?.toString()}
          >
            <Chakra.VStack alignItems="start">
              <Chakra.Radio colorScheme="purple" type="radio" value="true">
                Sim
              </Chakra.Radio>
              <Chakra.Radio colorScheme="purple" type="radio" value="false">
                Não
              </Chakra.Radio>
            </Chakra.VStack>
          </Chakra.RadioGroup>
        </Chakra.GridItem>
      </Chakra.Grid>
    </>
  )
}

export default AccreditationForm
