import * as S from './styles'
import * as I from 'src/assets/icons'
import { useEffect } from 'react'
import { showModal } from 'src/store/message-modal/actions'
import { MessageModalType } from 'src/store/message-modal/types'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'src/store/rootReducer'
import { IApiError } from 'src/store/shared/types'
import { UNKOWN_ERROR } from 'src/constants'

const StepsList = () => {
  const dispatch = useDispatch()

  const userState = useSelector((state: ApplicationState) => state.userReducer)

  const getModalError = (error: any) => {
    if (typeof error.map === 'function') {
      if (error.length < 1) return UNKOWN_ERROR

      // If the errors are returned in the IApiError format (temporary gambi, while we wait a backend fix)
      if (error[0].ErrorCode) {
        return error.map((apiError: IApiError, index: number) => {
          return (
            <div key={apiError.ErrorCode + apiError.ErrorMessage}>
              {apiError.ErrorMessage}
              {index >= error.length - 1 ? '.' : '; '}
            </div>
          )
        })
      } else {
        // If the errors are returned in the string[] (temporary gambi, while we wait a backend fix)
        return error.map((apiError: string, index: number) => {
          return (
            <div key={apiError}>
              {index >= error.length - 1
                ? apiError[apiError.length - 1] === '.'
                  ? apiError.substring(0, apiError.length - 1) + '.'
                  : apiError + '.'
                : apiError + '; '}
            </div>
          )
        })
      }
    } else if (error && typeof error === 'string' && error.length > 0) {
      return error
    } else {
      return UNKOWN_ERROR
    }
  }

  useEffect(() => {
    if (userState.error && userState.error.length > 0) {
      dispatch(
        showModal(
          MessageModalType.Error,
          'Um ou mais erros foram encontrados.',

          <div>{getModalError(userState.error)}</div>,
          'Voltar'
        )
      )
    }
  }, [userState.error, dispatch])

  return (
    <>
      <S.Ul>
        <S.List>
          <I.CreateAccountIcon stroke="#fff" />
          <p>
            Crie sua
            <br /> conta
          </p>
        </S.List>
        <S.List>
          <I.PersonalInfosIcon fill={'#fff'} />
          <p>Informações Pessoais</p>
        </S.List>
        <S.List>
          <I.ProfessionalInfosIcon />
          <p>Informações Profissionais</p>
        </S.List>
        <S.List>
          <I.CompetencyIcon stroke="#fff" />
          <p>Mapa de competências</p>
        </S.List>
        <S.List>
          <I.DocumentUploadIcon />
          <p>Upload de documentos</p>
        </S.List>
        <S.List>
          <I.AddIcon />
          <p>Informações adicionais</p>
        </S.List>
      </S.Ul>
    </>
  )
}

export default StepsList
