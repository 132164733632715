export const enum FilesListTypes {
  GET_FILES_ERROR = '@files/GET_FILES_ERROR',
  GET_FILES_SUCCESS = '@files/GET_FILES_SUCCESS',
  GET_REGISTRATION_STEP_SUCCESS = '@files/GET_REGISTRATION_STEP_SUCCESS',
  CLEAR_FILES_LIST = '@files/CLEAR_FILES_LIST',
  SHOW_DELETE_LOADING = '@files/SHOW_DELETE_LOADING'
}

export interface IFilesList {
  files: []
  registrationStep: null
  error: any
  deleteFileLoading: boolean
}
