export const convertBase64 = (file: Blob) => {
  return new Promise((resolve: any) => {
    let baseURL: string | ArrayBuffer = ''

    // Make new FileReader
    const reader = new FileReader()

    // Convert the file to base64 text
    reader.readAsDataURL(file)

    // on reader load something...
    reader.onload = () => {
      if (reader.result) {
        baseURL = reader.result
      }
      resolve(baseURL)
    }
  })
}
