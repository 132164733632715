import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'

export const Container = styled(Chakra.Container)`
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 5vh;
`
export const Tab = styled(Chakra.Tab)`
  @media (max-width: 700px) {
    max-width: 80px;
    font-size: 11px;
  }
`

export const TabPanel = styled(Chakra.TabPanel)`
  padding: 12px 0;
`
