import { THEME_GREY } from 'src/styles/colors'

interface Props {
  size?: string
}

export const TimeIcon = ({ size }: Props) => (
  <svg
    id="Icone_Tempo"
    data-name="Icone / Tempo"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 42 42"
  >
    <g
      id="Grupo_43993"
      data-name="Grupo 43993"
      transform="translate(-105.056 -693.684)"
    >
      <rect
        id="Retângulo_7668"
        data-name="Retângulo 7668"
        width="1.258"
        height="2.293"
        transform="matrix(0.349, -0.937, 0.937, 0.349, 128.405, 719.848)"
        fill={THEME_GREY}
      />
      <rect
        id="Retângulo_7669"
        data-name="Retângulo 7669"
        width="1.274"
        height="3.792"
        transform="translate(125.296 701.305)"
        fill={THEME_GREY}
      />
      <rect
        id="Retângulo_7670"
        data-name="Retângulo 7670"
        width="1.258"
        height="6.119"
        transform="translate(117.191 715.664) rotate(-69.603)"
        fill={THEME_GREY}
      />
      <path
        id="Caminho_34629"
        data-name="Caminho 34629"
        d="M141.178,702.715h-1.254V700.47a1.532,1.532,0,0,0-1.53-1.531H134.24a1.532,1.532,0,0,0-1.53,1.531v2.245h-1.254V700.47a2.789,2.789,0,0,1,2.784-2.789h4.155a2.789,2.789,0,0,1,2.784,2.789Z"
        transform="translate(-10.384 0)"
        fill={THEME_GREY}
        fillRule="evenodd"
      />
      <path
        id="Caminho_34630"
        data-name="Caminho 34630"
        d="M164.763,714.913a16.122,16.122,0,0,0-3.88-3.54l.685-1.057a17.434,17.434,0,0,1,4.176,3.812Z"
        transform="translate(-26.133 -6.762)"
        fill={THEME_GREY}
        fillRule="evenodd"
      />
      <path
        id="Caminho_34631"
        data-name="Caminho 34631"
        d="M130.373,740.386a10.03,10.03,0,0,1-10.024-10.021h1.258a8.766,8.766,0,1,0,8.766-8.756v-1.257a10.013,10.013,0,1,1,0,20.026Z"
        transform="translate(-4.439 -12.133)"
        fill={THEME_GREY}
        fillRule="evenodd"
      />
      <path
        id="Caminho_34632"
        data-name="Caminho 34632"
        d="M125.95,713.236A12.668,12.668,0,1,0,138.59,725.9a12.654,12.654,0,0,0-12.639-12.667m0,26.595a13.927,13.927,0,1,1,13.9-13.927,13.912,13.912,0,0,1-13.9,13.927"
        transform="translate(0 -7.651)"
        fill={THEME_GREY}
        fillRule="evenodd"
      />
      <path
        id="Caminho_34633"
        data-name="Caminho 34633"
        d="M137.776,735.543a2.233,2.233,0,1,0,2.238,2.237v0a2.237,2.237,0,0,0-2.238-2.233m0,5.723a3.49,3.49,0,1,1,3.5-3.494v0a3.5,3.5,0,0,1-3.5,3.49"
        transform="translate(-11.889 -19.591)"
        fill={THEME_GREY}
        fillRule="evenodd"
      />
    </g>
    <rect
      id="Retângulo_7671"
      data-name="Retângulo 7671"
      width="42"
      height="42"
      fill="none"
    />
  </svg>
)
export default TimeIcon
