import { ReactNode } from 'react'
import * as Chakra from '@chakra-ui/react'
import * as S from './styles'

import { ACCESSIBILITY_SUCCESS, THEME_GREY } from 'src/styles/colors'
import CheckIcon from 'src/assets/icons/Check'
import { UndoIcon } from 'src/assets/icons/undo'
import { RejectIcon, TrashBinIcon } from 'src/assets/icons'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { ApplicationState } from 'src/store/rootReducer'
import { useSelector } from 'react-redux'

interface ButtonActionsProps {
  actionButton: () => void
  children: ReactNode
  labelButton: string
}

interface DocumentButtonsDirectorProps {
  approveDocument: () => void
  undoDocument: () => void
  rejectDocument: () => void
  openHistoryModal: () => void
  deleteDocument: () => void
  data: any
}

const ButtonAction = ({
  actionButton,
  children,
  labelButton
}: ButtonActionsProps) => {
  return (
    <S.TooltipCard>
      <>
        <Chakra.IconButton
          _hover={{ bgColor: 'none' }}
          _active={{ bgColor: 'none' }}
          variant="ghost"
          aria-label="Toggle Modal"
          onClick={() => actionButton()}
        >
          <S.TooltipCard label={labelButton}>
            <span>{children}</span>
          </S.TooltipCard>
        </Chakra.IconButton>

        <S.TooltipBox>{labelButton}</S.TooltipBox>
      </>
    </S.TooltipCard>
  )
}

export const DocumentButtonsDirector = ({
  approveDocument,
  undoDocument,
  rejectDocument,
  openHistoryModal,
  deleteDocument,
  data
}: DocumentButtonsDirectorProps) => {
  const userState = useSelector((state: ApplicationState) => state.userReducer)
  const solicitation = useSelector(
    (state: ApplicationState) => state.solicitationDetailReducer.solicitation
  )
  return (
    <>
      <Chakra.Flex alignItems="flex">
        {solicitation.isEditable ? (
          <>
            {data.cpfUploader === userState.user?.personalData?.cpf ? (
              <>
                {data.fileStatus == 'Pendente' && (
                  <>
                    <ButtonAction
                      actionButton={approveDocument}
                      labelButton="Aprovar"
                    >
                      <CheckIcon color={ACCESSIBILITY_SUCCESS} size={35} />
                    </ButtonAction>
                    <ButtonAction
                      actionButton={deleteDocument}
                      labelButton="Excluir"
                    >
                      <TrashBinIcon size={32} />
                    </ButtonAction>
                  </>
                )}
              </>
            ) : (
              <>
                {' '}
                {data.fileStatus == 'Pendente' &&
                data.fileType === 'Mapa de competência' ? (
                  <>
                    <ButtonAction
                      actionButton={approveDocument}
                      labelButton="Aprovar"
                    >
                      <CheckIcon color={ACCESSIBILITY_SUCCESS} size={35} />
                    </ButtonAction>
                    <ButtonAction
                      actionButton={rejectDocument}
                      labelButton="Rejeitar"
                    >
                      <RejectIcon size="35px" />
                    </ButtonAction>
                  </>
                ) : null}
              </>
            )}

            {data.fileStatus !== 'Pendente' &&
            data.fileType === 'Mapa de competência' ? (
              <ButtonAction actionButton={undoDocument} labelButton="Desfazer">
                <UndoIcon size="30" color={THEME_GREY} />
              </ButtonAction>
            ) : null}
          </>
        ) : null}

        <ButtonAction actionButton={openHistoryModal} labelButton="Histórico">
          <AiOutlineClockCircle size="28px" />
        </ButtonAction>
      </Chakra.Flex>
    </>
  )
}
