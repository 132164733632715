import styled from 'styled-components'
import { Button, Progress } from '@chakra-ui/react'

export const FileStatusTextField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 16px;
  border-width: 2px;
  border-radius: 5px 0px 0px 5px;
  border-style: solid;
  border-color: #c4c4c4;
  color: black;
  transition: border 0.24s ease-in-out;
  height: 46px;
  width: 83%;
  min-width: 200px;
  font-size: 14px;
  overflow-x: hidden;
  overflow-y: hidden;
`

export const FileStatusButton = styled(Button)`
  border-width: 2px;
  border-radius: 0px 5px 5px 0px;
  border-style: solid;
  color: black;
  transition: border 0.24s ease-in-out;
  height: 46px;
  width: 17%;
  min-width: 70px;
  margin-left: -5px;
  border-color: #c4c4c4;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: hidden;
`

export const FileStatusProgress = styled(Progress)`
  width: 100%;
  margin-top: 4px;
  margin-left: 1px;
  height: 4px;
`
