import { hideSpinner, showSpinner } from '../../store/spinner/actions'
import Service from 'src/services/professional-information'
import { setUserFail } from '../login/actions'
import { updateRegistrationStep } from '../registration-step/actions'
import { RegistrationStep } from '../registration-step/types'
import { handleLayerSevenRequestError } from '../layer-seven/actions'
import { AxiosResponse } from 'axios'
import { IProfessionalData, ProfessionalInfoTypes } from './types'

export const saveProfessionalInfo =
  (data: IProfessionalData, onSuccessCallback: any, token: string) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .saveProfessionalInfo(data, token)
      .then((res: AxiosResponse) => {
        dispatch(saveProfessionalInfoSuccess(res.data))
        if (onSuccessCallback) onSuccessCallback()
        dispatch(
          updateRegistrationStep(
            res.data.professionalInformation.cpf,
            RegistrationStep.ProfessionalInfo,
            token
          )
        )
      })
      .catch((error: any) => {
        if (error.request && error.request.response)
          dispatch(setUserFail(JSON.parse(error.request.response).errors))
        dispatch(hideSpinner())
        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(saveProfessionalInfo(data, history, newToken))
          )
        )
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const saveProfessionalInfoSuccess = (data: any) => ({
  payload: data,
  type: ProfessionalInfoTypes.SAVE_PROFESSIONAL_INFO
})

export const getProfessionalInfo =
  (cpf: string, token: string) => (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .getProfessionalInfo(cpf, token)
      .then((res: AxiosResponse) => {
        dispatch(getProfessionalInfoSuccess(res.data))
      })
      .catch((error: any) => {
        if (error.request && error.request.response)
          dispatch(
            getProfessionalInfoError(JSON.parse(error.request.response).errors)
          )
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const getProfessionalInfoSuccess = (data: any) => ({
  payload: data,
  type: ProfessionalInfoTypes.GET_PROFESSIONAL_INFO_SUCCESS
})

export const getProfessionalInfoError = (error: any) => ({
  payload: error,
  type: ProfessionalInfoTypes.GET_PROFESSIONAL_INFO_ERROR
})

export const updateProfessionalInfo =
  (data: IProfessionalData, onSuccessCallback: any, token: string) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .updateProfessionalInfo(data, token)
      .then((res: AxiosResponse) => {
        if (onSuccessCallback) onSuccessCallback()
        dispatch(
          updateRegistrationStep(
            res.data.professionalInformation.cpf,
            RegistrationStep.CompetencyMap,
            token
          )
        )
        dispatch(
          updateProfessionalInfoSuccess(res.data.professionalInformation)
        )
      })
      .catch((error: any) => {
        if (error.request && error.request.response)
          dispatch(setUserFail(JSON.parse(error.request.response).errors))
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const updateProfessionalInfoSuccess = (data: any) => ({
  payload: data,
  type: ProfessionalInfoTypes.UPDATE_PROFESSIONAL_INFO
})

export const clearProfessinalInfo = () => ({
  type: ProfessionalInfoTypes.CLEAR_PROFESSIONAL_INFO
})
