import { getSolicitationDetailsTypes } from './types'
import Service from 'src/services/solicitation-details'
import { hideSpinner, showSpinner } from 'src/store/spinner/actions'

const getSolicitationDetail = (data: any) => ({
  type: getSolicitationDetailsTypes.GET_SOLICITATION_DETAILS_SUCCESS,
  payload: data
})

const getSolicitationDetailError = (error: any) => ({
  type: getSolicitationDetailsTypes.GET_SOLICITATION_DETAILS_ERROR,
  payload: error
})

const getSolicitationDocumentsSuccess = (data: any) => ({
  type: getSolicitationDetailsTypes.GET_SOLICITATION_DOCUMENTS_SUCCESS,
  payload: data
})

const getSolicitationDocumentsError = (error: any) => ({
  type: getSolicitationDetailsTypes.GET_SOLICITATION_DOCUMENTS_ERROR,
  payload: error
})

const getExclusionReasonSuccess = (data: any) => ({
  type: getSolicitationDetailsTypes.GET_EXCLUSION_REASON_SUCCESS,
  payload: data
})

const getExclusionReasonError = (error: any) => ({
  type: getSolicitationDetailsTypes.GET_EXCLUSION_REASON_ERROR,
  payload: error
})

const updateFileDisapproved = (data: any) => ({
  type: getSolicitationDetailsTypes.UPDATE_FILE_DISAPPROVE_SUCCESS,
  payload: data
})

const updateFileDisapprovedError = (error: any) => ({
  type: getSolicitationDetailsTypes.UPDATE_FILE_DISAPPROVE_ERROR,
  payload: error
})

const updateFileApproved = (data: any) => ({
  type: getSolicitationDetailsTypes.UPDATE_FILE_DISAPPROVE_SUCCESS,
  payload: data
})

const updateFileApprovedError = (error: any) => ({
  type: getSolicitationDetailsTypes.UPDATE_FILE_DISAPPROVE_ERROR,
  payload: error
})

const updateFileUndone = (data: any) => ({
  type: getSolicitationDetailsTypes.UPDATE_FILE_UNDO_SUCCESS,
  payload: data
})

const updateFileUndoneError = (error: any) => ({
  type: getSolicitationDetailsTypes.UPDATE_FILE_UNDO_ERROR,
  payload: error
})

const createApprovedSolicitation = (data: any) => ({
  type: getSolicitationDetailsTypes.CREATE_APPROVE_SOLICITATION_SUCCESS,
  payload: data
})

const createApprovedSolicitationError = (error: any) => ({
  type: getSolicitationDetailsTypes.CREATE_APPROVE_SOLICITATION_ERROR,
  payload: error
})

const createRejectedSolicitation = (data: any) => ({
  type: getSolicitationDetailsTypes.CREATE_APPROVE_SOLICITATION_SUCCESS,
  payload: data
})

const createRejectedSolicitationError = (error: any) => ({
  type: getSolicitationDetailsTypes.CREATE_APPROVE_SOLICITATION_ERROR,
  payload: error
})

const changeStatusDocument = (data: any) => ({
  type: getSolicitationDetailsTypes.CHANGE_STATUS_DOCUMENT,
  payload: data
})

const changeStatusDocumentDirector = (data: any) => ({
  type: getSolicitationDetailsTypes.CHANGE_STATUS_DOCUMENT_DIRECTOR,
  payload: data
})

const changeLoading = (data: boolean) => ({
  type: getSolicitationDetailsTypes.CHANGE_LOADING,
  payload: data
})

const getFileType = (data: any) => ({
  type: getSolicitationDetailsTypes.GET_FILE_TYPES_SUCCESS,
  payload: data
})

const getFileTypeError = (error: any) => ({
  type: getSolicitationDetailsTypes.GET_FILE_TYPES_ERROR,
  payload: error
})

const changeFileType = (data: any) => ({
  type: getSolicitationDetailsTypes.CHANGE_FILE_TYPE_SUCCESS,
  payload: data
})

const changeFileTypeError = (error: any) => ({
  type: getSolicitationDetailsTypes.CHANGE_FILE_TYPE_ERROR,
  payload: error
})

const getHistoryFile = (data: any) => ({
  type: getSolicitationDetailsTypes.CHANGE_HISTORY_FILE,
  payload: data
})

const getHistoryFileError = (error: any) => ({
  type: getSolicitationDetailsTypes.CHANGE_HISTORY_FILE_ERROR,
  payload: error
})

const changeStatusSolicitation = (data: any) => ({
  type: getSolicitationDetailsTypes.CHANGE_STATUS_SOLICITATION,
  payload: data
})

export const getSolicitationDetails =
  (token: string, id: string, technicalEvaluationId?: string) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .getSolicitationDetails(token, id, technicalEvaluationId)
      .then((response: any) => {
        dispatch(getSolicitationDetail(response.data))
        const technicalEvaluationId =
          response.data.technicalEvaluationId === 0
            ? null
            : response.data.technicalEvaluationId
        dispatch(
          getSolicitationDocuments(
            token,
            response.data.cpfHealthProfessional,
            technicalEvaluationId
          )
        )
      })
      .catch((error: any) => {
        dispatch(getSolicitationDetailError(error))
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const getSolicitationDocuments =
  (token: string, cpf: string, technicalEvaluationId?: number) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .getSolicitationDocuments(token, cpf, technicalEvaluationId)
      .then((response: any) => {
        dispatch(
          getSolicitationDocumentsSuccess(response.data.filesInformation)
        )
      })
      .catch((error) => {
        dispatch(getSolicitationDocumentsError(error))
      })
      .finally(() => hideSpinner())
  }

export const getExclusionReason = (token: string) => (dispatch: any) => {
  Service()
    .getExclusionReason(token)
    .then((response: any) => {
      dispatch(getExclusionReasonSuccess(response.data.exclusionReasons))
    })
    .catch((error) => {
      dispatch(getExclusionReasonError(error))
    })
    .finally(() => hideSpinner())
}

export const updateFileDisapprove =
  (data: any, token: string) => (dispatch: any) => {
    dispatch(showSpinner())
    dispatch(changeLoading(true))
    Service()
      .updateFileDisapprove(data, token)
      .then((response: any) => {
        dispatch(updateFileDisapproved(response.data))
        dispatch(
          changeStatusDocument({
            idFile: data.fileId,
            statusId: 3,
            statusName: 'Rejeitado'
          })
        )
      })
      .catch((error: any) => {
        dispatch(updateFileDisapprovedError(error))
      })
      .finally(() => {
        dispatch(hideSpinner())
        dispatch(changeLoading(false))
      })
  }

export const updateFileApprove =
  (data: any, token: string) => (dispatch: any) => {
    const filesIdList = data.fileId
    dispatch(showSpinner())
    dispatch(changeLoading(true))
    Service()
      .updateFileApprove(data, token)
      .then((response: any) => {
        dispatch(updateFileApproved(response.data))
        filesIdList.forEach((id: any) =>
          dispatch(
            changeStatusDocument({
              idFile: id,
              statusId: 2,
              statusName: 'Aprovado'
            })
          )
        )
      })
      .catch((error: any) => {
        dispatch(updateFileApprovedError(error))
      })
      .finally(() => {
        dispatch(hideSpinner())
        dispatch(changeLoading(false))
      })
  }

export const updateFileUndo = (data: any, token: string) => (dispatch: any) => {
  dispatch(showSpinner())
  dispatch(changeLoading(true))
  Service()
    .updateFileUndo(data, token)
    .then((response: any) => {
      dispatch(updateFileUndone(response.data))
      dispatch(
        changeStatusDocument({
          idFile: data.fileId,
          statusId: 1,
          statusName: 'Pendente'
        })
      )
    })
    .catch((error: any) => {
      dispatch(updateFileUndoneError(error))
    })
    .finally(() => {
      dispatch(hideSpinner())
      dispatch(changeLoading(false))
    })
}

export const createApproveSolicitation =
  (
    data: any,
    token: string,
    onSuccessCallback: any = null,
    onErrorCallback: any = null
  ) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .createApproveSolicitation(data, token)
      .then((response: any) => {
        dispatch(changeStatusSolicitation('Aguardando Aprovação do Diretor'))
        dispatch(createApprovedSolicitation(response.data))
        if (onSuccessCallback) onSuccessCallback()
      })
      .catch((error: any) => {
        const errorMessage = error.response.data.errors
        dispatch(createApprovedSolicitationError(error))
        if (onErrorCallback) onErrorCallback(errorMessage)
      })
      .finally(() => {
        dispatch(hideSpinner())
        dispatch(changeLoading(false))
      })
  }

export const createRejectSolicitation =
  (
    data: any,
    token: string,
    onSuccessCallback: any = null,
    onErrorCallback: any = null
  ) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .createRejectSolicitation(data, token)
      .then((response: any) => {
        dispatch(changeStatusSolicitation('Rejeitada'))
        dispatch(createRejectedSolicitation(response.data))
        if (onSuccessCallback) onSuccessCallback()
      })
      .catch((error: any) => {
        const errorMessage = error.response.data.errors
        dispatch(createRejectedSolicitationError(error))
        if (onErrorCallback) onErrorCallback(errorMessage)
      })
      .finally(() => {
        dispatch(hideSpinner())
      })
  }
export const changeFileTypes =
  (token: string, data: any, changeTable: any) => (dispatch: any) => {
    Service()
      .changeFileType(data, token)
      .then((response: any) => {
        dispatch(changeFileType(response.data))
        changeTable()
      })
      .catch((error: any) => {
        dispatch(changeFileTypeError(error))
      })
  }

export const getFileTypes = (token: string) => (dispatch: any) => {
  Service()
    .getFileType(token)
    .then((response: any) => {
      dispatch(getFileType(response.data.fileTypes))
    })
    .catch((error: any) => {
      dispatch(getFileTypeError(error))
    })
}

export const directorUpdateFileDisapprove =
  (data: any, token: string) => (dispatch: any) => {
    dispatch(showSpinner())
    dispatch(changeLoading(true))
    Service()
      .directorUpdateFileDisapprove(data, token)
      .then(() => {
        dispatch(updateFileApproved(''))
        dispatch(
          changeStatusDocumentDirector({
            fileInfoTechEvaluationId: data.fileInfoTechEvaluationId,
            statusId: 3,
            statusName: 'Rejeitado'
          })
        )
      })
      .catch((error: any) => {
        dispatch(updateFileDisapprovedError(error))
      })
      .finally(() => {
        dispatch(hideSpinner())
        dispatch(changeLoading(false))
      })
  }

export const directorUpdateFileApprove =
  (data: any, token: string) => (dispatch: any) => {
    const fileInfoTechEvaluationId = data.fileInfoTechEvaluationId
    dispatch(showSpinner())
    dispatch(changeLoading(true))
    Service()
      .directorUpdateFileApproveDirector(data, token)
      .then(() => {
        dispatch(updateFileApproved(''))
        fileInfoTechEvaluationId.forEach((id: any) => {
          dispatch(
            changeStatusDocumentDirector({
              fileInfoTechEvaluationId: id,
              statusId: 2,
              statusName: 'Aprovado'
            })
          )
        })
      })
      .catch((error: any) => {
        dispatch(updateFileApprovedError(error))
      })
      .finally(() => {
        dispatch(hideSpinner())
        dispatch(changeLoading(false))
      })
  }

export const directorUpdateFileUndo =
  (data: any, token: string) => (dispatch: any) => {
    dispatch(showSpinner())
    dispatch(changeLoading(true))
    Service()
      .directorUpdateFileUndo(data, token)
      .then(() => {
        dispatch(updateFileApproved(''))
        dispatch(
          changeStatusDocumentDirector({
            fileInfoTechEvaluationId: data.fileInfoTechEvaluationId,
            statusId: 1,
            statusName: 'Pendente'
          })
        )
      })
      .catch((error: any) => {
        dispatch(updateFileUndoneError(error))
      })
      .finally(() => {
        dispatch(hideSpinner())
        dispatch(changeLoading(false))
      })
  }

export const directorApproveSolicitation =
  (
    data: any,
    token: string,
    onSuccessCallback: any = null,
    onErrorCallback: any = null
  ) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .directorApproveSolicitation(data, token)
      .then((response: any) => {
        dispatch(changeStatusSolicitation('Aprovada'))
        dispatch(createApprovedSolicitation(response.data))
        if (onSuccessCallback) onSuccessCallback()
      })
      .catch((error: any) => {
        const errorMessage = error.response.data.errors
        dispatch(createApprovedSolicitationError(error))
        if (onErrorCallback) onErrorCallback(errorMessage)
      })
      .finally(() => {
        dispatch(hideSpinner())
        dispatch(changeLoading(false))
      })
  }

export const directorRejectSolicitation =
  (
    data: any,
    token: string,
    onSuccessCallback: any = null,
    onErrorCallback: any = null
  ) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .directorRejectSolicitation(data, token)
      .then((response: any) => {
        dispatch(changeStatusSolicitation('Rejeitada'))
        dispatch(createRejectedSolicitation(response.data))
        if (onSuccessCallback) onSuccessCallback()
      })
      .catch((error: any) => {
        const errorMessage = error.response.data.errors
        dispatch(createRejectedSolicitationError(error))
        if (onErrorCallback) onErrorCallback(errorMessage)
      })
      .finally(() => {
        dispatch(hideSpinner())
      })
  }

export const getHistory = (data: any, token: string) => (dispatch: any) => {
  dispatch(showSpinner())
  Service()
    .getHistory(data, token)
    .then((response: any) => {
      dispatch(getHistoryFile(response.data))
    })
    .catch((error: any) => {
      dispatch(getHistoryFileError(error))
    })
    .finally(() => {
      dispatch(hideSpinner())
    })
}
