import * as Chakra from '@chakra-ui/react'
import * as I from 'src/assets/icons'
import PersonalInformationForm from './components/form'
import HeadingText from 'src/components/signup/heading-text'
import Container from 'src/components/container'
import { THEME_PURPLE } from 'src/styles/colors'

const PersonalInformation = () => {
  return (
    <Container value={22}>
      <Chakra.Grid templateColumns="repeat(6, 1fr)" gap={3}>
        <Chakra.GridItem colSpan={6}>
          <HeadingText
            icon={<I.PersonalInfosIcon fill={THEME_PURPLE} />}
            title="Informações Pessoais"
            subtitle="Complete seus dados pessoais para seguir com o cadastro completo
            na Rede Americas"
          />
        </Chakra.GridItem>
      </Chakra.Grid>
      <Chakra.Grid templateColumns="repeat(5, 1fr)" gap={4}>
        <PersonalInformationForm />
      </Chakra.Grid>
    </Container>
  )
}

export default PersonalInformation
