import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'

export const Box = styled(Chakra.Box)`
  background-color: #fff;
  border: 1px solid ${THEME_GREY};
  border-radius: 8px;
  min-height: 500px;
  position: absolute;
  width: 492px;
`

export const ModalBox = styled(Chakra.Box)`
  margin: 24px 20px 32px;
`

export const BoxTitle = styled(Chakra.Text)`
  margin: 32px 0 27px;
`

export const WrapperCheckbox = styled(Chakra.SimpleGrid)`
  width: 100%;
`

export const Checkbox = styled(Chakra.Checkbox)`
  color: ${THEME_PURPLE};
  width: 92px;
`

export const ButtonContainer = styled(Chakra.Stack)`
  margin: 10px 0;
`

export const MyAnalysis = styled(Chakra.Stack)`
  margin: 20px 0 0;
`
