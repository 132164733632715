import { getSolicitations } from './types'
import Service from 'src/services/solicitation'
import { hideSpinner, showSpinner } from 'src/store/spinner/actions'

const getSolicitationListSuccess = (data: any) => ({
  type: getSolicitations.GET_SOLICITATION_SUCCESS,
  payload: data
})

const getSolicitationListError = (error: any) => ({
  type: getSolicitations.GET_SOLICITATION_ERROR,
  payload: error
})

export const getSolicitationList =
  (
    token: string,
    currentData: any[],
    pageNumber: number,
    pageSize: number,
    filter?: string,
    hospitals?: number[],
    status?: number[],
    onlyMyAnalyzes?: boolean
  ) =>
  (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .getSolicitations(
        token,
        pageNumber,
        pageSize,
        filter,
        hospitals,
        status,
        onlyMyAnalyzes
      )
      .then((response: any) => {
        response.data.solicitations = currentData.concat(
          response.data.solicitations
        )
        dispatch(getSolicitationListSuccess(response.data))
      })
      .catch((error: any) => {
        if (currentData.length > 0) return
        dispatch(getSolicitationListError(error))
      })
      .then(() => dispatch(hideSpinner()))
  }

const getFilterSuccess = (data: any) => ({
  type: getSolicitations.GET_FILTER_SUCCESS,
  payload: data
})

const getFilterError = (error: any) => ({
  type: getSolicitations.GET_FILTER_ERROR,
  payload: error
})

export const getFilters = (token: string) => (dispatch: any) => {
  Service()
    .getFilters(token)
    .then((response: any) => dispatch(getFilterSuccess(response.data)))
    .catch((error: any) => {
      dispatch(getFilterError(error))
    })
}
