export const enum getSolicitationDetailsTypes {
  GET_SOLICITATION_DETAILS_ERROR = '@solicitation-details/GET_SOLICITATION_DETAILS_ERROR',
  GET_SOLICITATION_DETAILS_SUCCESS = '@solicitation-details/GET_SOLICITATION_DETAILS_SUCCESS',
  GET_SOLICITATION_DOCUMENTS_SUCCESS = '@solicitation-details/GET_SOLICITATION_DOCUMENTS_SUCCESS',
  GET_SOLICITATION_DOCUMENTS_ERROR = '@solicitation-details/GET_SOLICITATION_DOCUMENTS_ERROR',
  GET_EXCLUSION_REASON_SUCCESS = '@solicitation-details/GET_EXCLUSION_REASON_ERROR',
  GET_EXCLUSION_REASON_ERROR = '@solicitation-details/GET_EXCLUSION_REASON_ERROR',
  UPDATE_FILE_DISAPPROVE_ERROR = '@solicitation-details/UPDATE_FILE_DISAPPROVE_ERROR',
  UPDATE_FILE_DISAPPROVE_SUCCESS = '@solicitation-details/UPDATE_FILE_DISAPPROVE_SUCCESS',
  UPDATE_FILE_APPROVE_ERROR = '@solicitation-details/UPDATE_FILE_APPROVE_ERROR',
  UPDATE_FILE_APPROVE_SUCCESS = '@solicitation-details/UPDATE_FILE_APPROVE_SUCCESS',
  UPDATE_FILE_UNDO_ERROR = '@solicitation-details/UPDATE_FILE_APPROVE_ERROR',
  UPDATE_FILE_UNDO_SUCCESS = '@solicitation-details/UPDATE_FILE_APPROVE_SUCCESS',
  CREATE_APPROVE_SOLICITATION_SUCCESS = '@solicitation-details/ CREATE_APPROVE_SOLICITATION_SUCCESS',
  CREATE_APPROVE_SOLICITATION_ERROR = '@solicitation-details/ CREATE_APPROVE_SOLICITATION_ERROR',
  CREATE_REJECT_SOLICITATION_SUCCESS = '@solicitation-details/ CREATE_REJECT_SOLICITATION_SUCCESS',
  CREATE_REJECT_SOLICITATION_ERROR = '@solicitation-details/ CREATE_REJECT_SOLICITATION_ERROR',
  CHANGE_STATUS_DOCUMENT = '@solicitation-details/CHANGE_STATUS_DOCUMENT',
  CHANGE_STATUS_DOCUMENT_DIRECTOR = '@solicitation-details/CHANGE_STATUS_DOCUMENT_DIRECTOR',
  CHANGE_LOADING = '@solicitation-details/CHANGE_LOADING',
  GET_FILE_TYPES_SUCCESS = '@solicitation-details/GET_FILE_TYPES_SUCCESS',
  GET_FILE_TYPES_ERROR = '@solicitation-details/GET_FILE_TYPES_ERROR',
  CHANGE_FILE_TYPE_SUCCESS = '@solicitation-details/CHANGE_FILE_TYPE_SUCCESS',
  CHANGE_FILE_TYPE_ERROR = '@solicitation-details/CHANGE_FILE_TYPE_ERROR',
  CHANGE_HISTORY_FILE = '@solicitation-details/CHANGE_HISTORY_FILE',
  CHANGE_HISTORY_FILE_ERROR = '@solicitation-details/CHANGE_HISTORY_FILE_ERROR',
  CHANGE_STATUS_SOLICITATION = '@solicitation-details/CHANGE_STATUS_SOLICITATION'
}
