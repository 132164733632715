import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { BsFileEarmarkPdf } from 'react-icons/bs'

export const Container = styled(Chakra.Container)`
  max-width: 90%;
  margin-bottom: 5vh;
`

export const MenuContainer = styled(Chakra.GridItem)`
  margin-top: 20px;
`

export const AlignButton = styled(Chakra.GridItem)`
  margin-top: 10px;
`

export const Ul = styled.ul`
  margin: 20px 0;
`

export const Item = styled.li`
  align-items: center;
  display: flex;
  margin: 5px 0;
`
export const Download = styled.button`
  display: flex;
  align-items: center;
`

export const PdfIcon = styled(BsFileEarmarkPdf)`
  color: red;
`

export const ButtonTitle = styled.p`
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.7);
`
