import { hideSpinner, showSpinner } from '../spinner/actions'
import Service from 'src/services/registered-info'
import { RegisteredInfoTypes } from './types'
import { AxiosResponse } from 'axios'

const getRegisteredInfoSuccess = (data: any) => ({
  type: RegisteredInfoTypes.GET_REGISTERED_INFO_SUCCESS,
  payload: data
})

const getRegisteredInfoError = (error: any) => ({
  type: RegisteredInfoTypes.GET_REGISTERED_INFO_ERROR,
  payload: error
})

export const getRegisteredInfo =
  (id: string, token: string) => (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .getRegisteredInfo(id, token)
      .then((response: any) => {
        dispatch(getRegisteredInfoSuccess(response.data))
      })
      .catch((error: any) => {
        dispatch(getRegisteredInfoError(error))
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const updateRegisteredPersonalInfoSuccess = (data: any) => ({
  payload: data,
  type: RegisteredInfoTypes.UPDATE_PERSONAL_REGISTERED_INFO
})

export const updateRegisteredPersonalInfoError = (error: any) => ({
  type: RegisteredInfoTypes.UPDATE_PERSONAL_REGISTERED_INFO_ERROR,
  payload: error
})

export const updateRegisteredPersonalInfo =
  (data: any, token: string, onSuccessCallback: any) => (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .updateRegisteredPersonalInfo(data, token)
      .then((response: AxiosResponse) => {
        if (onSuccessCallback) onSuccessCallback()
        dispatch(updateRegisteredPersonalInfoSuccess(response.data))
      })
      .catch((error: any) => {
        dispatch(updateRegisteredPersonalInfoError(error))
      })
      .finally(() => dispatch(hideSpinner()))
  }
export const updateRegisteredProfessionalInfoSucess = (data: any) => ({
  payload: data,
  type: RegisteredInfoTypes.UPDATE_REGISTERED_PROFESSIONAL_INFO
})

export const updateRegisteredProfessionalInfoError = (error: any) => ({
  payload: error,
  type: RegisteredInfoTypes.UPDATE_REGISTERED_PROFESSIONAL_INFO_ERROR
})

export const updateRegisteredProfessionalInfo =
  (data: any, token: string, onSuccessCallback: any) => (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .updateRegisteredProfessionalInfo(data, token)
      .then((response: AxiosResponse) => {
        if (onSuccessCallback) onSuccessCallback()
        dispatch(updateRegisteredProfessionalInfoSucess(response.data))
      })
      .catch((error: any) => {
        dispatch(updateRegisteredProfessionalInfoError(error))
      })
      .finally(() => dispatch(hideSpinner()))
  }

export const updateRegisteredClinicInfoSucess = (data: any) => ({
  payload: data,
  type: RegisteredInfoTypes.UPDATE_REGISTERED_CLINIC_INFO
})

export const updateRegisteredClinicInfoError = (error: any) => ({
  payload: error,
  type: RegisteredInfoTypes.UPDATE_REGISTERED_CLINIC_INFO_ERROR
})

export const updateRegisteredClinicInfo =
  (data: any, token: string) => (dispatch: any) => {
    dispatch(showSpinner())
    Service()
      .updateRegisteredClinicInfo(data, token)
      .then((response: AxiosResponse) => {
        dispatch(updateRegisteredClinicInfoSucess(response.data))
      })
      .catch((error: any) => {
        dispatch(updateRegisteredClinicInfoError(error))
      })
      .finally(() => dispatch(hideSpinner()))
  }
