import { getSolicitationDetailsTypes } from './types'

const initialState = {
  solicitation: {},
  filesInformation: [],
  filesTypes: [],
  error: null,
  loading: false,
  excludeReason: [],
  historyDocuments: {}
}

export default function solicitationDetail(state = initialState, action: any) {
  switch (action.type) {
    case getSolicitationDetailsTypes.GET_SOLICITATION_DETAILS_SUCCESS:
      return {
        ...state,
        solicitation: action.payload,
        error: null
      }

    case getSolicitationDetailsTypes.GET_SOLICITATION_DETAILS_ERROR:
      return {
        ...state,
        solicitation: {},
        error: action.payload
      }

    case getSolicitationDetailsTypes.GET_SOLICITATION_DOCUMENTS_SUCCESS:
      return {
        ...state,
        filesInformation: action.payload,
        error: null
      }

    case getSolicitationDetailsTypes.GET_SOLICITATION_DOCUMENTS_ERROR:
      return {
        ...state,
        filesInformation: [],
        error: action.payload
      }

    case getSolicitationDetailsTypes.GET_EXCLUSION_REASON_SUCCESS:
      return {
        ...state,
        excludeReason: action.payload,
        error: null
      }

    case getSolicitationDetailsTypes.GET_EXCLUSION_REASON_ERROR:
      return {
        ...state,
        excludeReason: [],
        error: action.payload
      }

    case getSolicitationDetailsTypes.UPDATE_FILE_DISAPPROVE_SUCCESS:
      return {
        ...state,
        error: null
      }

    case getSolicitationDetailsTypes.UPDATE_FILE_DISAPPROVE_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case getSolicitationDetailsTypes.UPDATE_FILE_APPROVE_SUCCESS:
      return {
        ...state,
        error: null
      }

    case getSolicitationDetailsTypes.UPDATE_FILE_APPROVE_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case getSolicitationDetailsTypes.UPDATE_FILE_UNDO_SUCCESS:
      return {
        ...state,
        error: null
      }

    case getSolicitationDetailsTypes.UPDATE_FILE_UNDO_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case getSolicitationDetailsTypes.CREATE_APPROVE_SOLICITATION_SUCCESS:
      return {
        ...state,
        error: null
      }

    case getSolicitationDetailsTypes.CREATE_APPROVE_SOLICITATION_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case getSolicitationDetailsTypes.CREATE_REJECT_SOLICITATION_SUCCESS:
      return {
        ...state,
        error: null
      }

    case getSolicitationDetailsTypes.CREATE_REJECT_SOLICITATION_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case getSolicitationDetailsTypes.CHANGE_STATUS_DOCUMENT: {
      const newFileInformation: any = state.filesInformation

      newFileInformation.map((item: any) => {
        if (item?.fileId === action.payload.idFile) {
          item.fileStatusId = action.payload.statusId
          item.fileStatus = action.payload.statusName
        }
      })

      return {
        ...state,
        filesInformation: newFileInformation,
        error: null
      }
    }

    case getSolicitationDetailsTypes.CHANGE_STATUS_DOCUMENT_DIRECTOR: {
      const newFileInformation: any = state.filesInformation

      newFileInformation.map((item: any) => {
        if (
          item?.fileInfoTechEvaluationId ===
          action.payload.fileInfoTechEvaluationId
        ) {
          item.fileStatusId = action.payload.statusId
          item.fileStatus = action.payload.statusName
        }
      })

      return {
        ...state,
        filesInformation: newFileInformation,
        error: null
      }
    }

    case getSolicitationDetailsTypes.CHANGE_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: null
      }
    case getSolicitationDetailsTypes.GET_FILE_TYPES_SUCCESS:
      return {
        ...state,
        filesTypes: action.payload,
        error: null
      }

    case getSolicitationDetailsTypes.GET_FILE_TYPES_ERROR:
      return {
        ...state,
        filesTypes: [],
        error: action.payload
      }
    case getSolicitationDetailsTypes.CHANGE_FILE_TYPE_SUCCESS: {
      const newFileInformation: any = state.filesInformation

      const fileType: any = state.filesTypes.find(
        (item: any) => item.id === action.payload.fileTypeId
      )

      newFileInformation.map((item: any) => {
        if (item?.fileId === action.payload.fileId) {
          item.fileTypeId = fileType.id
          item.fileType = fileType.name
        }
      })

      return {
        ...state,
        filesInformation: newFileInformation,
        error: null
      }
    }

    case getSolicitationDetailsTypes.CHANGE_FILE_TYPE_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case getSolicitationDetailsTypes.CHANGE_HISTORY_FILE:
      return {
        ...state,
        historyDocuments: action.payload,
        error: null
      }

    case getSolicitationDetailsTypes.CHANGE_HISTORY_FILE_ERROR:
      return {
        ...state,
        historyDocuments: {},
        error: action.payload
      }
    case getSolicitationDetailsTypes.CHANGE_STATUS_SOLICITATION: {
      const solicitation: any = state.solicitation
      solicitation.status = action.payload

      return {
        ...state,
        solicitation: solicitation
      }
    }
    default:
      return state
  }
}
