import styled, { css } from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import {
  ACCESSIBILITY_ERROR,
  THEME_GREY,
  THEME_PURPLE,
  THEME_WHITE
} from 'src/styles/colors'

export const Container = styled(Chakra.Box)`
  box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.3);
  background-color: ${THEME_WHITE};
  border-radius: 5px;
  padding: 18px;
  margin: 0;
`
const Text = css`
  margin-bottom: 12px;
  text-align: center;
`

export const ClinicSection = styled.div`
  width: 100%;
`

export const Link = styled.a`
  color: ${THEME_PURPLE};
  text-decoration: underline;
`

export const Form = styled.form`
  display: contents;
`
export const InputLabel = styled(Chakra.FormLabel)`
  color: ${THEME_GREY};
  font-size: 16px;
`

export const InputField = styled(Chakra.Input)`
  border: 1px solid ${THEME_GREY};
  height: 46px;
`

export const ErrorLabel = styled.p`
  color: ${(props) =>
    props.color === 'danger' ? ACCESSIBILITY_ERROR : THEME_GREY};
  font-weight: 600;
  font-size: 14px;
`

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TitleText = styled(Chakra.Text)`
  ${Text};
  color: ${THEME_PURPLE};
  font-family: SoletoBold;
  font-size: 36px;
`

export const DescriptionText = styled(Chakra.Text)`
  ${Text};
  color: ${THEME_GREY};
  font-size: 18px;
  margin-bottom: 52px;
`
