import { ACCESSIBILITY_ERROR } from 'src/styles/colors'

interface Props {
  size?: string
}

const RejectIcon = ({ size }: Props) => (
  <svg
    id="Icone_Error"
    data-name="Icone / Error"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 42 42"
  >
    <g
      id="Grupo_6548"
      data-name="Grupo 6548"
      transform="translate(-476.227 -923.436)"
    >
      <path
        id="Caminho_3101"
        data-name="Caminho 3101"
        d="M12.832,11.556,22.846,1.542A.9.9,0,1,0,21.57.265L11.555,10.28,1.541.265A.9.9,0,0,0,.264,1.542L10.278,11.556.264,21.571a.9.9,0,0,0,1.277,1.277L11.555,12.833,21.57,22.847a.9.9,0,1,0,1.277-1.277Z"
        transform="translate(485.227 932.436)"
        fill="none"
        stroke={ACCESSIBILITY_ERROR}
        strokeWidth="2"
      />
    </g>
    <rect
      id="Retângulo_3562"
      data-name="Retângulo 3562"
      width="42"
      height="42"
      fill="none"
    />
  </svg>
)

export default RejectIcon
