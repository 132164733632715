import { HospitalsListTypes, IHospitalListState } from './types'

const initialState: IHospitalListState = {
  hospitals: [],
  error: null
}

export default function hospitalsList(state = initialState, action: any) {
  switch (action.type) {
    case HospitalsListTypes.GET_HOSPITALS_LIST_SUCCESS:
      return {
        ...state,
        hospitals: action.payload.hospitals,
        error: null
      }

    case HospitalsListTypes.GET_HOSPITALS_LIST_ERROR:
      return {
        hospitals: [],
        error: action.payload
      }

    case HospitalsListTypes.CLEAR_HOSPITALS_LIST:
      return initialState

    default:
      return state
  }
}
