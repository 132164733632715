import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDisclosure } from '@chakra-ui/react'
import * as Chakra from '@chakra-ui/react'
import HeadingText from 'src/components/signup/heading-text'
import * as S from './styles'
import Header from 'src/components/header'
import { AiOutlineNotification } from 'react-icons/ai'
import { THEME_PURPLE } from 'src/styles/colors'
import Navigation from 'src/components/nav'
import Button from 'src/components/button'
import Table from './components/table'
import FilterModal from './components/filter'
import { ApplicationState } from 'src/store/rootReducer'
import {
  getFilters,
  getSolicitationList
} from 'src/store/solicitation-page/actions'
import { useParams } from 'react-router'
import MassRegistrationModal from '../home/components/modal-mass-registration'
import { Filter } from './components/filter/types'

const PAGE_SIZE = 10

const Solicitation = () => {
  const { status } = useParams<any>()

  const dispatch = useDispatch()
  const [showFilter, setFilter] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [filterValues, setFilterValues] = useState<Filter>({
    filter: '',
    hospitalsId: [],
    statusId: [],
    myAnalysis: false
  })

  const solicitations = useSelector(
    (state: ApplicationState) => state.solicitationsReducer.solicitations
  )

  const filters = useSelector(
    (state: ApplicationState) => state.solicitationsReducer.filters
  )

  const auth = useSelector((state: ApplicationState) => state.userReducer.auth)

  const [solicitationsState, setSolicitationsState] = useState(solicitations)

  const initialDispatch = () => {
    if (auth && auth.layerSevenToken) {
      setSolicitationsState([])
      dispatch(getFilters(auth.layerSevenToken))

      dispatch(
        getSolicitationList(
          auth.layerSevenToken,
          [],
          1,
          PAGE_SIZE,
          '',
          [],
          status ? [status] : [],
          false
        )
      )

      setPageNumber(1)
      setFilterValues({
        filter: '',
        hospitalsId: [],
        statusId: [],
        myAnalysis: false
      })
    }
  }

  useEffect(() => {
    initialDispatch()
  }, [status])

  useEffect(() => {
    setSolicitationsState(solicitations)
  }, [solicitations])

  const fetchSolicitaionDataByFilter = (values: any) => {
    const statusParam = status ? [status] : []

    if (auth && auth.layerSevenToken) {
      dispatch(
        getSolicitationList(
          auth.layerSevenToken,
          [],
          1,
          PAGE_SIZE,
          values.filter != '' ? values.filter : '',
          values.hospitalsId.length > 0 ? values.hospitalsId : [],
          values.statusId.length > 0 ? values.statusId : statusParam,
          values.myAnalysis
        )
      )
    }

    setFilterValues(values)
  }

  const fetchSolicitaionDataByPage = (page: number) => {
    if (page === pageNumber) return

    const statusParam = status ? [status] : []

    if (auth && auth.layerSevenToken) {
      dispatch(
        getSolicitationList(
          auth.layerSevenToken,
          solicitations,
          page,
          PAGE_SIZE,
          filterValues.filter != '' ? filterValues.filter : '',
          filterValues.hospitalsId.length > 0 ? filterValues.hospitalsId : [],
          filterValues.statusId.length > 0
            ? filterValues.statusId
            : statusParam,
          filterValues.myAnalysis
        )
      )
    }

    setPageNumber(page)
  }

  const {
    isOpen: showMassModal,
    onOpen: onOpenMassModal,
    onClose: onCloseMassModal
  } = useDisclosure()

  const colSpan1 = Chakra.useBreakpointValue({
    base: 2,
    xl: 2,
    lg: 2,
    md: 6,
    sm: 6
  })

  const colSpan2 = Chakra.useBreakpointValue({
    base: 4,
    xl: 4,
    lg: 4,
    md: 6,
    sm: 6
  })

  return (
    <>
      <Header border />
      <S.Container>
        <Chakra.Grid
          h="200px"
          templateRows="repeat(3, 1fr)"
          templateColumns="repeat(6, 1fr)"
          gap={4}
        >
          <S.MenuContainer colSpan={colSpan1}>
            <Navigation onOpenMassModal={onOpenMassModal} />
          </S.MenuContainer>
          <MassRegistrationModal
            isOpen={showMassModal}
            onClose={onCloseMassModal}
          />
          <Chakra.GridItem colSpan={colSpan2}>
            <HeadingText
              icon={<AiOutlineNotification stroke={THEME_PURPLE} />}
              title="Solicitações"
              subtitle="Acompanhe as solicitações de cadastro"
            />

            <Button
              maxW="200"
              onClick={() => setFilter(!showFilter)}
              variant="normal"
              type="button"
            >
              Filtros
            </Button>

            <S.FilterContainer>
              {showFilter && (
                <FilterModal
                  setFilter={setFilter}
                  filters={filters}
                  setFilterValues={fetchSolicitaionDataByFilter}
                  setPageNumber={setPageNumber}
                />
              )}
            </S.FilterContainer>

            <S.Box>
              <S.TableContainer id="tableContainerId">
                <Table
                  solicitations={solicitationsState}
                  update={() => fetchSolicitaionDataByPage(pageNumber + 1)}
                />
              </S.TableContainer>
            </S.Box>
          </Chakra.GridItem>
        </Chakra.Grid>
      </S.Container>
    </>
  )
}

export default Solicitation
