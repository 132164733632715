import * as S from './styles'
import { CnpjInput } from './types'
import { cnpjMask } from '../../utils/masks-validations/cnpj-helpers'

const cnpjInput = (props: CnpjInput) => {
  return (
    <S.Input
      data-testid="input-cnpj"
      maxLength="18"
      name={props.name}
      onChange={props.onChange}
      placeholder={props.placeholder}
      required={props.required}
      isDisabled={props.isDisabled}
      value={cnpjMask(props.value)}
      isInvalid={props.isInvalid}
      {...props.register}
      {...props}
    />
  )
}

export default cnpjInput
