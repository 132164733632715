const HospitalIcon = ({ hospitals }: any) => (
  <svg
    id="Tootip_hospitais"
    data-name="Tootip hospitais"
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="44.999"
    viewBox="0 0 45 44.999"
  >
    <g id="Group_45113" data-name="Group 45113" transform="translate(6 5.5)">
      <g id="Group_45114" data-name="Group 45114">
        <path
          id="Path_35027"
          data-name="Path 35027"
          d="M24.143,29.634V5.508H18.369c0-2.766-1.7-5.008-3.807-5.008s-3.807,2.242-3.807,5.008H4.981V29.634"
          fill="none"
          stroke="#636362"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <line
          id="Line_1767"
          data-name="Line 1767"
          y2="2.818"
          transform="translate(13.827 3.003)"
          fill="none"
          stroke="#636362"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <line
          id="Line_1768"
          data-name="Line 1768"
          y2="2.818"
          transform="translate(15.297 3.003)"
          fill="none"
          stroke="#636362"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <line
          id="Line_1769"
          data-name="Line 1769"
          x2="1.47"
          transform="translate(13.827 4.412)"
          fill="none"
          stroke="#636362"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_35028"
          data-name="Path 35028"
          d="M11.234,29.634V21.681H17.89v7.953"
          fill="none"
          stroke="#636362"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <line
          id="Line_1770"
          data-name="Line 1770"
          x1="30.472"
          transform="translate(0 29.634)"
          fill="none"
          stroke="#636362"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <rect
          id="Rectangle_7802"
          data-name="Rectangle 7802"
          width="2.302"
          height="3.029"
          transform="translate(7.91 15.463)"
          fill="none"
          stroke="#636362"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <rect
          id="Rectangle_7803"
          data-name="Rectangle 7803"
          width="2.302"
          height="3.029"
          transform="translate(13.411 15.463)"
          fill="none"
          stroke="#636362"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <rect
          id="Rectangle_7804"
          data-name="Rectangle 7804"
          width="2.302"
          height="3.029"
          transform="translate(18.912 15.463)"
          fill="none"
          stroke="#636362"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <rect
          id="Rectangle_7805"
          data-name="Rectangle 7805"
          width="2.302"
          height="3.029"
          transform="translate(7.91 9.329)"
          fill="none"
          stroke="#636362"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <rect
          id="Rectangle_7806"
          data-name="Rectangle 7806"
          width="2.302"
          height="3.029"
          transform="translate(13.411 9.329)"
          fill="none"
          stroke="#636362"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <rect
          id="Rectangle_7807"
          data-name="Rectangle 7807"
          width="2.302"
          height="3.029"
          transform="translate(18.912 9.329)"
          fill="none"
          stroke="#636362"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </g>
    <rect
      id="Rectangle_3567"
      data-name="Rectangle 3567"
      width="42"
      height="42"
      fill="none"
    />
    <g id="Group_45115" data-name="Group 45115" transform="translate(21 21)">
      <path
        id="Path_205"
        data-name="Path 205"
        d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
        transform="translate(0 0)"
        fill="#d0021b"
      />
      <text
        id="_2"
        data-name="2"
        transform="translate(3 7)"
        fill="#fff"
        fontSize="12"
        fontFamily="Soleto-Bold, Soleto"
        fontWeight="700"
        letterSpacing="-0.013em"
      >
        <tspan x="5.484" y="9">
          {hospitals}
        </tspan>
      </text>
    </g>
  </svg>
)

export default HospitalIcon
