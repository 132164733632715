import * as S from './styles'
import * as Chakra from '@chakra-ui/react'
import { THEME_PURPLE } from 'src/styles/colors'
import { IoWarning } from 'react-icons/io5'
import { ApplicationState } from 'src/store/rootReducer'
import { useSelector } from 'react-redux'
import moment from 'moment'

interface WarningMessage {
  openModal: () => void
}

const WarningMessage = ({ openModal }: WarningMessage) => {
  const solicitation = useSelector(
    (state: ApplicationState) => state.solicitationDetailReducer.solicitation
  )

  const user = useSelector((state: ApplicationState) => state.userReducer.user)
  const cpf = user?.personalData?.cpf

  const OtherAnalyst = solicitation?.owner?.ownerCPF === cpf ? false : true
  const lastModifiedDate = moment(solicitation.lastModifiedDate, 'DD/MM/YYYY')
  const daysGoneBy = moment().diff(lastModifiedDate, 'days')

  return (
    <>
      {OtherAnalyst ? (
        <S.Container>
          <IoWarning fontSize={30} color={THEME_PURPLE} />
          <Chakra.Flex flexDirection={'column'}>
            <S.Title>Solicitação em análise por outra pessoa.</S.Title>
            <Chakra.Flex marginTop={'5px'} marginBottom={'5px'}>
              <S.Text>
                Em análise por: {solicitation.lastModifiedBy}{' '}
                {solicitation.lastModifiedDate} | {daysGoneBy}d atrás
              </S.Text>
              <S.TooltipCard>
                <S.Tooltip>
                  <S.StatusIcon />
                </S.Tooltip>
                <S.TooltipBox>{solicitation?.owner?.ownerEmail}</S.TooltipBox>
              </S.TooltipCard>
            </Chakra.Flex>
            <S.Title onClick={openModal}>
              Clique aqui para{' '}
              <S.UnderlineText>assumir a solicitação</S.UnderlineText>
            </S.Title>
          </Chakra.Flex>
        </S.Container>
      ) : (
        <S.Container>
          <IoWarning fontSize={24} color={THEME_PURPLE} />
          <S.Text>Você acaba de assumir a análise desta solicitação.</S.Text>
        </S.Container>
      )}
    </>
  )
}

export default WarningMessage
