import { competencyMap } from './types'
import Service from '../../services/competency-map'
import { handleLayerSevenRequestError } from '../layer-seven/actions'
import { hideSpinner } from '../spinner/actions'

const getDocument = (data: any) => ({
  type: competencyMap.GET_DOCUMENT_SUCCESS,
  payload: data
})

const getDocumentError = (error: any) => ({
  type: competencyMap.GET_DOCUMENT_ERROR,
  payload: error
})

export const fetchDocument = (token: string) => (dispatch: any) => {
  Service()
    .getDocument(token)
    .then((response: any) => dispatch(getDocument(response.data)))
    .catch((error: any) => {
      dispatch(getDocumentError(error))

      dispatch(
        handleLayerSevenRequestError(error, (newToken: string) =>
          dispatch(fetchDocument(newToken))
        )
      )
    })
}

export const clearCompetencyMap = () => ({
  type: competencyMap.CLEAR_COMPETENCY_MAP
})

const saveCompetencyMapSuccess = (data: any) => ({
  type: competencyMap.SAVE_COMPETENCY_MAP_SUCCESS,
  payload: data
})

const saveCompetencyMapError = (error: any) => ({
  type: competencyMap.SAVE_COMPETENCY_MAP_ERROR,
  payload: error
})

export const saveCompetencyMap =
  (data: any, token: string) => (dispatch: any) => {
    Service()
      .saveCompetencyMap(data, token)
      .then(() => {
        dispatch(saveCompetencyMapSuccess(data))
      })
      .catch((error: Error) => {
        dispatch(saveCompetencyMapError(error))

        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(saveCompetencyMap(data, newToken))
          )
        )
      })
      .finally(() => dispatch(hideSpinner()))
  }

const updateCompetencyMapSuccess = (data: any) => ({
  type: competencyMap.UPDATE_COMPETENCY_MAP_SUCCESS,
  payload: data
})

const updateCompetencyMapError = (error: any) => ({
  type: competencyMap.UPDATE_COMPETENCY_MAP_ERROR,
  payload: error
})

export const updateCompetencyMap =
  (data: any, token: string) => (dispatch: any) => {
    Service()
      .updateCompetencyMap(data, token)
      .then(() => {
        dispatch(updateCompetencyMapSuccess(data))
      })
      .catch((error: Error) => {
        dispatch(updateCompetencyMapError(error))

        dispatch(
          handleLayerSevenRequestError(error, (newToken: string) =>
            dispatch(updateCompetencyMap(data, newToken))
          )
        )
      })
      .finally(() => dispatch(hideSpinner()))
  }
