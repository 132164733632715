import { combineReducers } from 'redux'

// Types
import { IUserStateTypes } from './login/types'
import { ISpinnerStateTypes } from './spinner/types'
import { IModalStateTypes } from './message-modal/types'
import { IDocumentsUploadState } from './documents-upload/types'
import { IRegistrationStepStates } from './registration-step/types'
import { IMassRegistrationStates } from './mass-registration/types'
import { IProfessionalInformationStates } from './professional-information/types'

//Reducers
import userReducer from './login/reducer'
import additionalInfoReducer from 'src/store/additional-info/reducer'
import professionalInfoReducer from 'src/store/professional-information/reducer'
import spinnerReducer from 'src/store/spinner/spinnerReducer'
import documentsUploadReducer from 'src/store/documents-upload/reducer'
import messageModal from 'src/store/message-modal/reducers'
import competencyMapReducer from 'src/store/competency-map/reducer'
import hospitalsListReducer from 'src/store/hospitals-list/reducer'
import filesReducer from 'src/store/files-list/reducer'
import filesDownloadReducer from 'src/store/files-download/reducer'
import registrationStep from './registration-step/reducer'
import massRegistration from './mass-registration/reducer'
import solicitationsReducer from './solicitation-page/reducers'
import solicitationDetailReducer from './solicitation-details/reducers'
import registeredInfoReducer from './registered-info/reducers'
import personalInfoReducer from './personal-information/reducer'

// Application state structure
export interface ApplicationState {
  userReducer: IUserStateTypes
  additionalInfoReducer: any
  personalInfoReducer: any
  professionalInfoReducer: IProfessionalInformationStates
  messageModal: IModalStateTypes
  spinnerReducer: ISpinnerStateTypes
  documentsUploadReducer: IDocumentsUploadState
  competencyMapReducer: any
  hospitalsListReducer: any
  filesReducer: any
  filesDownloadReducer: any
  registrationStep: IRegistrationStepStates
  massRegistration: IMassRegistrationStates
  solicitationsReducer: any
  solicitationDetailReducer: any
  registeredInfoReducer: any
}

export default combineReducers({
  userReducer,
  personalInfoReducer,
  professionalInfoReducer,
  competencyMapReducer,
  documentsUploadReducer,
  additionalInfoReducer,
  spinnerReducer,
  messageModal,
  hospitalsListReducer,
  filesReducer,
  filesDownloadReducer,
  registrationStep,
  massRegistration,
  solicitationsReducer,
  solicitationDetailReducer,
  registeredInfoReducer
})
