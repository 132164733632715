import { InputFilterProps } from './interface'
import * as S from './styles'
import * as I from 'src/assets/icons'
import { THEME_PURPLE } from 'src/styles/colors'

const InputFilter = ({
  name,
  value,
  placeholder,
  onChange
}: InputFilterProps) => {
  return (
    <>
      <S.Search>
        <S.Input
          data-testid="input-filter"
          name={name}
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
        <S.Icon>
          <I.SearchIcon size={'35px'} color={THEME_PURPLE} />
        </S.Icon>
      </S.Search>
    </>
  )
}

export default InputFilter
