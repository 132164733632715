import { IMassRegistrationStates, MassRegistrationTypes } from './types'

const initialState: IMassRegistrationStates = {
  error: undefined,
  result: undefined
}

const massRegistrationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case MassRegistrationTypes.ON_UPLOAD_SUCCESS: {
      return {
        ...state,
        error: undefined,
        result: [...action.payload]
      }
    }

    case MassRegistrationTypes.ON_UPLOAD_ERROR:
      return {
        error: action.payload,
        result: undefined
      }

    case MassRegistrationTypes.CLEAR_MASS_REGISTRATION: {
      return initialState
    }

    default:
      return initialState
  }
}

export default massRegistrationReducer
