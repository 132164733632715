import { THEME_GREY, THEME_PURPLE } from '../../../styles/colors'
import styled, { css } from 'styled-components'
import * as Chakra from '@chakra-ui/layout'

export const Container = styled(Chakra.Container)`
  max-width: 80%;
  margin: 0 auto;
  padding: 5vh 0;
`
const Text = css`
  margin-bottom: 32px;
  text-align: center;
`

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SuccessText = styled(Chakra.Text)`
  margin: 0 auto;
  top: 243px;
  text-align: center;
  margin-bottom: 206px;
`

export const TitleText = styled(Chakra.Text)`
  ${Text};
  font-size: 36px;
  color: ${THEME_PURPLE};
  font-family: SoletoBold;
`

export const DescriptionText = styled(Chakra.Text)`
  ${Text};
  font-size: 18px;
  color: ${THEME_GREY};
`

export const Link = styled.a`
  color: ${THEME_PURPLE};
  text-decoration: underline;
`
