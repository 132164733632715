import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import {
  ACCESSIBILITY_ERROR,
  THEME_GREY,
  THEME_PURPLE
} from 'src/styles/colors'

export const Link = styled.a.attrs({
  target: '_blank'
})`
  color: ${THEME_PURPLE};
  text-decoration: underline;
`

export const Form = styled.form`
  display: contents;
`

export const InputField = styled(Chakra.Input)`
  border: 1px solid ${THEME_GREY};
  height: 46px;
`

export const ErrorLabel = styled.p`
  color: ${(props) =>
    props.color === 'danger' ? ACCESSIBILITY_ERROR : THEME_GREY};
  font-weight: 600;
  font-size: 14px;
`
