import * as S from './styles'
import { ICpfInput } from './types'
import { cpfMask } from 'src/utils/masks-validations/cpf-helpers'

const cpfInput = ({
  name,
  onChange,
  placeholder,
  register,
  required,
  value,
  disabled,
  ...rest
}: ICpfInput) => {
  return (
    <S.Input
      {...register('cpf', { required: true })}
      {...rest}
      data-testid="input-cpf"
      maxLength="14"
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      value={cpfMask(value)}
      disabled={disabled}
    />
  )
}

export default cpfInput
