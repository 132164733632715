import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'
import * as I from 'src/assets/icons'
import { Container } from '@chakra-ui/react'

export const Ul = styled.ul`
  width: 100%;
  list-style: none;
  margin: 2vh 0 4vh 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    display: none;
  }
`

export const Li = styled.li`
  margin: 10px 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
`

const baseIcon = css`
  border: 1px solid ${THEME_GREY};
  border-radius: 25px;
  height: 30px;
  margin: 0 5px;
  padding: 3px;
  width: 30px;
  @media (max-width: 1000px) {
    height: 15px;
    width: 15px;
  }
`

export const Redirect = styled(Link)`
  color: ${THEME_GREY};
  padding-left: 5px;

  @media (min-width: 650px) and (max-width: 800px) {
    max-width: 100px;
    padding-left: 10px;
    font-size: 14px;
  }

  @media (min-width: 800px) and (max-width: 1200px) {
    padding-right: 30px;
    max-width: 150px;
    font-size: 14px;
  }

  &:hover {
    color: ${THEME_PURPLE};
  }
`

export const Button = styled.button`
  background-color: transparent;
  color: ${THEME_GREY};
  font-family: 'SoletoBold';
  font-weight: 800;

  &:hover {
    color: ${THEME_PURPLE};
  }
`

export const SolicitationsButton = styled.button`
  color: ${THEME_GREY};
  padding-left: 5px;
  font-weight: bold;

  @media (min-width: 650px) and (max-width: 800px) {
    max-width: 100px;
    padding-left: 10px;
    font-size: 14px;
  }

  @media (min-width: 800px) and (max-width: 1200px) {
    padding-right: 30px;
    max-width: 150px;
    font-size: 14px;
  }

  &:hover {
    color: ${THEME_PURPLE};
  }
`

export const SolicitationsLinks = styled(Container)`
  margin-left: 4vh;
  display: ${(props) => (props.display ? 'flex' : 'none')};
  flex-direction: column;
`

export const HomeIcon = styled(I.HomeIcon)`
  ${baseIcon};
`

export const ConsultDocumentsIcon = styled(I.ConsultDocumentsIcon)`
  ${baseIcon};
`

export const CreateMassRegistrationIcon = styled(I.CreateMassRegistrationIcon)`
  ${baseIcon};
`
export const RequestIcon = styled(I.RequestIcon)`
  ${baseIcon};
`
