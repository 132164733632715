import * as Chakra from '@chakra-ui/react'
import styled from 'styled-components'

export const PasswordRequirements = styled(Chakra.VStack)`
  margin-bottom: 20px;
  padding-top: 8px;
`

export const Requeriments = styled.p`
  font-size: 16px;
  line-height: 0.5;
`
