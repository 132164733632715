import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'

export const Container = styled(Chakra.Box)`
  @media (min-width: 1200px) {
    display: none;
  }
`

export const ListItem = styled(Chakra.ListItem)`
  margin: 10px 0;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
export const ListLink = styled(Link)`
  color: ${THEME_GREY};
  text-decoration: none;
  font-family: SoletoRegular;
  font-size: 16px;

  &:active {
    color: ${THEME_PURPLE};
  }
`
