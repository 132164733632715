const ROUTING_PATHS = {
  Root: '/',
  Dashboard: '/dashboard',
  Home: '/home',
  Login: '/login',
  SignUp_CreateAccount: '/signup',
  SignUp_PersonalInfo: '/signup/personal-information',
  SignUp_ProfessionalInfo: '/signup/professional-information',
  SignUp_CompetencyMap: '/signup/competency-map',
  SignUp_UploadDocuments: '/signup/upload-documents',
  SignUp_AdditionalInfo: '/signup/additional-info',
  SignUp_Fail: '/signup/failed',
  SignUp_Success: '/signup/success',
  SignUp_CompleteError: '/signup/complete-error',
  SignUp_CompleteSuccess: '/signup/complete-success',
  Documents_Consult: '/documents-consult',
  Reset_Password: '/reset-password',
  Create_Request: '/create-request',
  Solicitation: '/solicitations',
  Solicitation_Details: '/solicitation-details',
  Registered_Information: '/registered-info',
  Forgot_Password: '/forgot-password'
}

export default ROUTING_PATHS
