import * as Chakra from '@chakra-ui/react'
import * as S from './styles'

function Loader() {
  return (
    <Chakra.Flex justifyContent="center">
      <S.LoaderContainer />
    </Chakra.Flex>
  )
}
export default Loader
