import styled from 'styled-components'
import { Progress } from '@chakra-ui/react'
import { THEME_GREY } from 'src/styles/colors'

export const ProgressBar = styled(Progress)`
  background-color: #fff;
  border: 1px solid ${THEME_GREY};
  border-radius: 4px;
  height: 35px;
  margin: 20px 114px;
  position: relative;
  & > div {
    background: transparent linear-gradient(180deg, #bbc500 0%, #1a9328 100%) 0%
      0% no-repeat padding-box;
  }
`
