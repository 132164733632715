export enum RegistrationStepTypes {
  GET_REGISTRATION_STEP_SUCCESS = '@user/GET_REGISTRATION_STEP_SUCCESS',
  CREATE_REGISTRATION_STEP_SUCCESS = '@user/CREATE_REGISTRATION_STEP_SUCCESS',
  UPDATE_REGISTRATION_STEP_SUCCESS = '@user/UPDATE_REGISTRATION_STEP_SUCCESS',
  CLEAR_REGISTRATION_STEP = '@user/CLEAR_REGISTRATION_STEP'
}

export enum RegistrationStep {
  NewAccount = 0,
  PersonalInfo = 1,
  ProfessionalInfo = 2,
  CompetencyMap = 3,
  UploadDocument = 4,
  AdditionalInfo = 5,
  Completed = 6
}

export interface IRegistrationStepStates {
  lastStepCompleted?: RegistrationStep
}
