export const enum MessageModalState {
  SHOW_MODAL = '@messageModal/SHOW_MODAL',
  HIDE_MODAL = '@messageModal/HIDE_MODAL'
}

export const enum MessageModalType {
  Error,
  Information,
  Success,
  History,
  Upload
}
export interface IModalStateTypes {
  type?: MessageModalType
  title: JSX.Element
  message?: JSX.Element
  titleButton: string
  onClickButton?: () => void
}
