import * as S from './styles'
import * as Chakra from '@chakra-ui/react'
import {
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  Modal,
  ModalContent
} from '@chakra-ui/react'
import { IoWarning } from 'react-icons/io5'
import { ACCESSIBILITY_ERROR } from 'src/styles/colors'
import Button from 'src/components/button'

interface ChangeAnalystModal {
  isOpen: boolean
  onClose: () => void
}

const ChangeAnalystModal = ({ isOpen, onClose }: ChangeAnalystModal) => {
  const sizes = Chakra.useBreakpointValue({
    base: 'md',
    xl: 'md',
    lg: 'md',
    md: 'md',
    sm: 'xs',
    xs: 'xs'
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc={true}
      size={sizes}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <S.ModalBody>
          <IoWarning fontSize={40} color={ACCESSIBILITY_ERROR}></IoWarning>
          <S.Title>Deseja assumir essa solicitação?</S.Title>
          <S.Text>
            Ao assumir a solicitação, você irá se tornar responsável pela
            análise.
          </S.Text>
        </S.ModalBody>

        <S.ModalFooter>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="normal">Assumir</Button>
        </S.ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ChangeAnalystModal
