import Header from 'src/components/header'
import HeadingText from 'src/components/signup/heading-text'

const CreateRequest = () => {
  return (
    <>
      <Header />
      <HeadingText title="Criar solicitação" />
    </>
  )
}

export default CreateRequest
