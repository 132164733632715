import { HamburgerIcon } from '@chakra-ui/icons'
import * as Chakra from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import ROUTING_PATHS from 'src/routes/paths'
import { UserProfileEnum } from 'src/store/login/types'
import { ApplicationState } from 'src/store/rootReducer'
import { THEME_PURPLE } from 'src/styles/colors'
import * as S from './styles'

const Menu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const userProfile = useSelector(
    (state: ApplicationState) => state.userReducer.auth?.userProfile
  )

  return (
    <S.Container>
      <HamburgerIcon onClick={onOpen} color={THEME_PURPLE} fontSize="24px" />

      <Chakra.Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <Chakra.DrawerOverlay />
        <Chakra.DrawerContent>
          <Chakra.DrawerCloseButton />
          <Chakra.DrawerBody>
            {userProfile && userProfile == UserProfileEnum.DOCTOR.toString() ? (
              <Chakra.List p={30}>
                <S.ListItem>
                  <S.ListLink to={ROUTING_PATHS.SignUp_CreateAccount}>
                    Crie sua conta
                  </S.ListLink>
                </S.ListItem>

                <S.ListItem>
                  <S.ListLink to={ROUTING_PATHS.SignUp_PersonalInfo}>
                    Informações pessoais
                  </S.ListLink>
                </S.ListItem>
                <S.ListItem>
                  <S.ListLink to={ROUTING_PATHS.SignUp_ProfessionalInfo}>
                    Informações profissionais
                  </S.ListLink>
                </S.ListItem>
                <S.ListItem>
                  <S.ListLink to={ROUTING_PATHS.SignUp_CompetencyMap}>
                    Mapa de competências
                  </S.ListLink>
                </S.ListItem>
                <S.ListItem>
                  <S.ListLink to={ROUTING_PATHS.SignUp_UploadDocuments}>
                    Upload de documentos
                  </S.ListLink>
                </S.ListItem>
                <S.ListItem>
                  <S.ListLink to={ROUTING_PATHS.SignUp_AdditionalInfo}>
                    Informações adicionais
                  </S.ListLink>
                </S.ListItem>
              </Chakra.List>
            ) : (
              <Chakra.List p={30}>
                <S.ListItem>
                  <S.ListLink to={ROUTING_PATHS.Home}>Home</S.ListLink>
                </S.ListItem>
                <S.ListItem>
                  <S.ListLink to={ROUTING_PATHS.Solicitation}>
                    Solicitações
                  </S.ListLink>
                </S.ListItem>
                <S.ListItem>
                  <S.ListLink to="#">Mensagens</S.ListLink>
                </S.ListItem>
                <S.ListItem>
                  <S.ListLink to="#">Notificações</S.ListLink>
                </S.ListItem>
                <S.ListItem>
                  <S.ListLink to={ROUTING_PATHS.Documents_Consult}>
                    Consultar documentos
                  </S.ListLink>
                </S.ListItem>
                <S.ListItem>
                  <S.ListLink to="#">Criar acesso</S.ListLink>
                </S.ListItem>
              </Chakra.List>
            )}
          </Chakra.DrawerBody>
        </Chakra.DrawerContent>
      </Chakra.Drawer>
    </S.Container>
  )
}

export default Menu
