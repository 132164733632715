import { THEME_GREY } from 'src/styles/colors'

const RequestIcon = () => (
  <svg
    id="Icone_Notificações"
    data-name="Icone / Notificações"
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="46.759"
    viewBox="0 0 42 46.759"
  >
    <g id="Grupo_891" data-name="Grupo 891" transform="translate(0 0.027)">
      <g
        id="Elipse_54"
        data-name="Elipse 54"
        fill="#fff"
        stroke={THEME_GREY}
        strokeWidth="0.5"
      >
        <circle cx="21" cy="21" r="21" stroke="none" />
        <circle cx="21" cy="21" r="20.75" fill="none" />
      </g>
    </g>
    <rect
      id="Retângulo_3571"
      data-name="Retângulo 3571"
      width="40"
      height="40.76"
      fill="none"
    />
    <g id="Solicitações" transform="translate(0 0.621)">
      <rect
        id="Retângulo_7671"
        data-name="Retângulo 7671"
        width="42"
        height="42"
        fill="none"
      />
      <g
        id="Grupo_44143"
        data-name="Grupo 44143"
        transform="translate(9.003 10)"
      >
        <path
          id="Caminho_34614"
          data-name="Caminho 34614"
          d="M714.437,691.743h.141a.875.875,0,0,0,.89-.86v-18.14a.875.875,0,0,0-.89-.86h-.141a13.923,13.923,0,0,0-1.263,1.192c-2.05,2.069-5.868,5.922-9.944,5.922h-6.636a1.38,1.38,0,0,0-1.4,1.352v2.929a1.38,1.38,0,0,0,1.4,1.352h6.647c4.062,0,7.895,3.853,9.944,5.918a13.684,13.684,0,0,0,1.252,1.2m.141,1.07h-.233c-.443,0-.8-.356-1.972-1.523-1.934-1.955-5.539-5.59-9.142-5.59h-6.636a2.47,2.47,0,0,1-2.511-2.422v-2.929a2.47,2.47,0,0,1,2.511-2.423h6.647c3.6,0,7.208-3.638,9.143-5.59,1.163-1.167,1.517-1.523,1.961-1.523h.233a1.968,1.968,0,0,1,2,1.93v18.14a1.969,1.969,0,0,1-2,1.93"
          transform="translate(-694.083 -670.813)"
          fill={THEME_GREY}
          fillRule="evenodd"
        />
        <path
          id="Caminho_34615"
          data-name="Caminho 34615"
          d="M741.957,691.82a.32.32,0,0,1-.09-.627,1.791,1.791,0,0,0,0-3.443.32.32,0,1,1,.18-.614,2.43,2.43,0,0,1,0,4.67.323.323,0,0,1-.09.013"
          transform="translate(-719.811 -678.67)"
          fill={THEME_GREY}
          fillRule="evenodd"
        />
        <path
          id="Caminho_34616"
          data-name="Caminho 34616"
          d="M713.986,694.639a.506.506,0,0,1-.5-.513V687.7a.5.5,0,1,1,1,0v6.423a.506.506,0,0,1-.5.513"
          transform="translate(-705.005 -679.821)"
          fill={THEME_GREY}
          fillRule="evenodd"
        />
        <path
          id="Caminho_34617"
          data-name="Caminho 34617"
          d="M700.8,703.288l.994,3.946a.759.759,0,0,0,.681.508h1.459a.378.378,0,0,0,.261-.214.31.31,0,0,0,.036-.284l-.971-1.44a3.354,3.354,0,0,1-.474-1.923,5.417,5.417,0,0,0-.034-.588Zm3.134,5.1h-1.459a1.436,1.436,0,0,1-1.344-1l-1.191-4.735h3.4l.045.272a6.112,6.112,0,0,1,.081.94,2.865,2.865,0,0,0,.368,1.609l.971,1.438a.873.873,0,0,1-.018.945,1.029,1.029,0,0,1-.858.534"
          transform="translate(-698.417 -687.842)"
          fill={THEME_GREY}
          stroke={THEME_GREY}
          strokeWidth="0.1"
          fillRule="evenodd"
        />
      </g>
    </g>
    {/* <g
      id="Grupo_43964"
      data-name="Grupo 43964"
      transform="translate(27 30.809)"
    >
      <path
        id="Caminho_205"
        data-name="Caminho 205"
        d="M7.5,0A7.5,7.5,0,1,1,0,7.5,7.5,7.5,0,0,1,7.5,0Z"
        transform="translate(0 0.95)"
        fill="#d0021b"
      />
      <text
        id="_2"
        data-name="2"
        transform="translate(3 3)"
        fill="#fff"
        fontSize="12"
        fontFamily="Soleto-Bold, Soleto"
        fontWeight="700"
        letterSpacing="-0.013em"
      >
        <tspan x="0.984" y="9">
          2
        </tspan>
      </text>
    </g> */}
  </svg>
)

export default RequestIcon
