import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import * as I from 'src/assets/icons'
import { THEME_GREY, THEME_PURPLE, THEME_WHITE } from 'src/styles/colors'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { statusColors } from 'src/pages/solicitation/components/table/styles'

export const RequestContainer = styled.div`
  box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.3);
  padding: 20px 14px;
  background-color: ${THEME_WHITE};
  margin: 20px 0;
  border-radius: 5px;
  width: 100%;
`

export const RoundIcon = styled(Chakra.Icon)`
  color: ${THEME_PURPLE};
  font-size: 14px;
  margin-top: 4px;
`

export const TableContainer = styled(Chakra.Box)`
  overflow-x: auto;
  scrollbar-color: ${THEME_PURPLE} #f1f1f1;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: ${THEME_PURPLE};
  }
`

export const Table = styled.table`
  border: 1px solid #d1d3d4;
  width: 100%;
`

export const THead = styled.thead`
  color: ${THEME_PURPLE};
`

export const TBody = styled.tbody`
  color: ${THEME_PURPLE};
`

export const Th = styled.th`
  background-color: ${THEME_WHITE};
  padding: 8px 10px;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
  text-transform: uppercase;
`

export const Tr = styled.tr`
  border-top: 1px solid #d1d3d4;
  border-bottom: 1px solid #d1d3d4;
`

export const Td = styled.td`
  color: ${THEME_GREY};
  padding: 8px 10px;
`

export const TdHospital = styled.td`
  align-items: center;
  color: ${THEME_GREY};
  display: flex;
`
export const TooltipBox = styled.div`
  display: none;
`
export const TooltipCard = styled(Chakra.Tooltip)`
  border: 1px solid ${THEME_GREY};
  border-radius: 4px;
  color: ${THEME_PURPLE};
  background-color: ${THEME_WHITE};
  display: block;
  width: max-content;
  padding: 8px;
`
export const HospitalName = styled.div`
  font-size: 16px;
  font-weight: bold;
`

export const HospitalStatus = styled.div<{ status: string }>`
  font-size: 14px;
  margin-bottom: 10px;
  color: ${({ status }) => statusColors[status]};
`

export const HospitalIcon = styled(I.HospitalIcon)`
  height: 30px;
  width: 30px;
`
export const Hospital = styled.p`
  position: relative;
  left: 12px;
`

export const Status = styled.div`
  border-radius: 10px;
  background-color: ${THEME_PURPLE};
  height: 14px;
  width: 14px;
  margin-right: 4px;
`

export const StatusBox = styled(Chakra.Flex)<{ status: string }>`
  border: 1.5px solid ${({ status }) => statusColors[status]};
  border-radius: 5px;
  align-items: center;
  padding: 4px;
  justify-content: space-between;

  color: ${({ status }) => statusColors[status]};
`

export const StatusText = styled(Chakra.Text)`
  font-family: 'SoletoMedium';
  font-size: 14px;
  margin-right: 4px;
  padding: 4px;
`

export const StatusIcon = styled(AiOutlineInfoCircle)<{ status: string }>`
  font-size: 18px;
  svg {
    stroke: ${({ status }) => statusColors[status]};
  }
`
