declare global {
  interface Window {
    _flags_: {
      WARNING_MESSAGE_ENABLED: boolean
      ALERTS_ENABLED: boolean
      FAVORITE_BUTTON_ENABLED: boolean
      CHAT_ENABLED: boolean
      COMMENTS_ENABLED: boolean
      REQUIRED_DOCUMENTATION_ENABLED: boolean
      ROUTE_SOLICITATION_DETAILS_ENABLED: boolean
      ROUTE_SOLICITATIONS_ENABLED: boolean
      FORGOT_PASSWORD_ENABLED: boolean
    }
    _env_: {
      REACT_APP_ENVIRONMENT: string
      REACT_APP_AUTH_API: string
      REACT_APP_WORKFLOW: string
      REACT_APP_REGISTRATION_API: string
      REACT_APP_AMIL_API: string
      REACT_APP_FILE_IMPORTER_API: string
      REACT_APP_BFF: string
      REACT_APP_GALLERY_AMIL: string
    }
  }
}

export default {
  authAPI: window._env_?.REACT_APP_AUTH_API,
  registrationAPI: window._env_?.REACT_APP_REGISTRATION_API,
  amilAPI: window._env_?.REACT_APP_AMIL_API,
  bffUrl: window._env_?.REACT_APP_BFF,
  fileImporter: window._env_?.REACT_APP_AUTH_API,
  workflowAPI: window._env_?.REACT_APP_WORKFLOW,
  filesInformationAPI: `${window._env_?.REACT_APP_WORKFLOW}/FileInformation`
}
