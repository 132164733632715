import * as I from '../../../../../assets/icons'
import * as S from './style'
import * as Chakra from '@chakra-ui/react'
import { THEME_PURPLE } from '../../../../../styles/colors'
import { WarningTwoIcon } from '@chakra-ui/icons'
import InputDate from '../../../../../components/input-date/index'
import { IInformationFormProps } from '../../interface'
import { useForm } from 'react-hook-form'
import {
  specialitysOption,
  workAreaOption,
  schoolingOption
} from 'src/constants/options'
import { rqeValidation } from 'src/utils/masks-validations/rqe-validation'
import HeadingText from 'src/components/signup/heading-text'
import Select from 'src/components/input-select'
import CustomTextField from 'src/components/text-field'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'src/store/rootReducer'
import { FORMS_MIN_YEAR } from 'src/constants'

const InformationForm = ({
  mainSpecialty,
  rqE1,
  actuationArea1,
  secoundSpecialty,
  rqE2,
  actuationArea2,
  educationLevel,
  completionDate,
  register,
  selectChange,
  inputChange
}: IInformationFormProps) => {
  const {
    formState: { errors }
  } = useForm()

  const userState = useSelector((state: ApplicationState) => state.userReducer)

  const colSpan1 = Chakra.useBreakpointValue({
    base: 3,
    xl: 3,
    lg: 3,
    md: 3,
    sm: 3
  })
  const colSpan2 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 3,
    sm: 3
  })
  const colSpan3 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 3
  })

  return (
    <>
      <Chakra.Grid templateColumns="repeat(3, 1fr)" gap={4}>
        <Chakra.GridItem colSpan={colSpan1}>
          <HeadingText
            icon={<I.ProfessionalInfosIcon color={THEME_PURPLE} />}
            title="Informações Profissionais"
            subtitle=" Complete seus dados profissionais para seguir com o cadastro
              completo na rede."
          />
        </Chakra.GridItem>

        <Chakra.GridItem colSpan={colSpan2}>
          <Chakra.FormControl>
            <Chakra.FormLabel>*Especialidade Principal</Chakra.FormLabel>
            <Select
              disabled={false}
              data-testid="mainSpecialty"
              name="mainSpecialty"
              onChange={selectChange}
              value={mainSpecialty}
            >
              <>
                <option value="" disabled selected hidden>
                  {userState.user?.personalData?.speciality
                    ? userState.user?.personalData?.speciality
                    : 'Selecione'}
                </option>
                {specialitysOption}
              </>
            </Select>
            {errors?.mainSpecialty ? (
              <S.ErrorLabel color="danger">
                <WarningTwoIcon color="danger" />
                *Campo obrigatório
              </S.ErrorLabel>
            ) : (
              <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
            )}
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan2}>
          <Chakra.FormControl>
            <Chakra.FormLabel>RQE</Chakra.FormLabel>
            <CustomTextField
              register={register('rqE1')}
              type="text"
              name="rqE1"
              onChange={inputChange}
              value={rqeValidation(rqE1)}
              data-testid="mainRqe"
            />
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan2}>
          <Chakra.FormControl>
            <Chakra.FormLabel>Área de atuação 1</Chakra.FormLabel>
            <Select
              disabled={false}
              data-testid="actuationArea1"
              onChange={selectChange}
              value={actuationArea1}
              name="actuationArea1"
            >
              <>
                <option value="" disabled selected hidden>
                  Selecione
                </option>
                {workAreaOption}
              </>
            </Select>
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan2}>
          <Chakra.FormControl>
            <Chakra.FormLabel>Especialidade Secundária</Chakra.FormLabel>
            <Select
              disabled={false}
              data-testid="secoundSpecialty"
              onChange={selectChange}
              value={secoundSpecialty}
              name="secoundSpecialty"
            >
              <>
                <option value="" disabled selected hidden>
                  Selecione
                </option>
                {specialitysOption}
              </>
            </Select>
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan2}>
          <Chakra.FormControl>
            <Chakra.FormLabel>RQE</Chakra.FormLabel>
            <CustomTextField
              register={register('rqE2')}
              type="text"
              name="rqE2"
              onChange={inputChange}
              value={rqeValidation(rqE2)}
              data-testid="rqE2"
            />
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan2}>
          <Chakra.FormControl>
            <Chakra.FormLabel>Área de atuação 2</Chakra.FormLabel>
            <Select
              disabled={false}
              data-testid="actuationArea2"
              onChange={selectChange}
              value={actuationArea2}
              name="actuationArea2"
            >
              <>
                <option value="" disabled selected hidden>
                  Selecione
                </option>
                {workAreaOption}
              </>
            </Select>
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan3}>
          <Chakra.FormControl>
            <Chakra.FormLabel>Nível de escolaridade</Chakra.FormLabel>
            <Select
              disabled={false}
              data-testid="educationLevel"
              onChange={selectChange}
              value={educationLevel}
              name="educationLevel"
            >
              <>
                <option value="" disabled selected hidden>
                  Selecione
                </option>
                {schoolingOption}
              </>
            </Select>
          </Chakra.FormControl>
        </Chakra.GridItem>
        <Chakra.GridItem colSpan={colSpan3}>
          <Chakra.FormControl>
            <Chakra.FormLabel>Data de Conclusão</Chakra.FormLabel>
            <InputDate
              name="completionDate"
              onChange={inputChange}
              value={completionDate}
            />
            {new Date(completionDate).getFullYear() < FORMS_MIN_YEAR ? (
              <S.ErrorLabel color="danger">
                <WarningTwoIcon color="danger" />
                *Data menor que 1900
              </S.ErrorLabel>
            ) : (
              <></>
            )}
          </Chakra.FormControl>
        </Chakra.GridItem>
      </Chakra.Grid>
    </>
  )
}

export default InformationForm
