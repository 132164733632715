import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as Chakra from '@chakra-ui/react'
import { Link, useHistory } from 'react-router-dom'
import _ from 'lodash'

import { fixedPhoneMask } from 'src/utils/masks-validations/fixed-phone-mask'
import { cepMask } from 'src/utils/masks-validations/cep-mask'
import { stateOption } from 'src/constants/options'
import Button from 'src/components/button'
import Select from 'src/components/input-select'
import {
  saveAdditionalInfo,
  getAllAdditionalInfo,
  updateAdditionalInfo
} from 'src/store/additional-info/actions'
import { IAdditionalForm } from '../../interface'
import { ApplicationState } from 'src/store/rootReducer'
import { showModal } from 'src/store/message-modal/actions'
import * as S from './styles'
import ROUTING_PATHS from 'src/routes/paths/index'
import CustomTextField from 'src/components/text-field'
import { MessageModalType } from 'src/store/message-modal/types'
import { updateRegistrationStep } from 'src/store/registration-step/actions'
import { RegistrationStep } from 'src/store/registration-step/types'
import { THEME_PURPLE } from 'src/styles/colors'

const AdditionalInfoForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const userState = useSelector((state: ApplicationState) => state.userReducer)

  const initialValues = useSelector(
    (state: ApplicationState) => state.additionalInfoReducer
  )

  const token = useSelector((state: ApplicationState) => state.userReducer.auth)

  const [values, setValues] = useState({
    cpf: '',
    zipCode: '',
    homeAddress: '',
    number: '',
    uf: '',
    district: '',
    city: '',
    complement: '',
    phoneNumber: '',
    soccerTeam: '',
    observationHobbies: '',
    enjoyWine: '',
    musicalGenre: ''
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IAdditionalForm>()

  useEffect(() => {
    if (!_.isEmpty(initialValues.additionalInfo)) {
      setValues({
        cpf: '',
        zipCode: initialValues.additionalInfo.zipCode,
        homeAddress: initialValues.additionalInfo.homeAddress,
        number: initialValues.additionalInfo.number,
        uf: initialValues.additionalInfo.uf,
        district: initialValues.additionalInfo.district,
        city: initialValues.additionalInfo.city,
        complement: initialValues.additionalInfo.complement,
        phoneNumber: initialValues.additionalInfo.phoneNumber,
        soccerTeam: initialValues.additionalInfo.soccerTeam,
        observationHobbies: initialValues.additionalInfo.observationHobbies,
        enjoyWine: initialValues.additionalInfo.enjoyWine,
        musicalGenre: initialValues.additionalInfo.musicalGenre
      })
    }
  }, [initialValues])

  useEffect(() => {
    if (
      userState.user &&
      userState.user.personalData &&
      userState.user.personalData.cpf &&
      token &&
      token.layerSevenToken
    )
      dispatch(
        getAllAdditionalInfo(
          userState.user.personalData.cpf,
          token.layerSevenToken
        )
      )
  }, [dispatch, userState.user])

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
  }

  const selectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target

    setValues({
      ...values,
      [name]: value
    })
  }

  const showErrorModal = () => {
    dispatch(
      showModal(
        MessageModalType.Error,
        'Informações de endereço incompletas.',
        <div>
          Por favor, retorne ao cadastro para completar as informações de
          endereço. <br />
        </div>,
        'Voltar'
      )
    )
  }

  const onSubmit: SubmitHandler<IAdditionalForm> = async (data) => {
    if (
      !userState.user ||
      !userState.user.personalData ||
      !userState.user.personalData.cpf
    )
      return
    const cpf = userState.user.personalData.cpf
    const { uf, enjoyWine } = values
    const newValues = { ...data, uf, enjoyWine, cpf }
    const withInitialValues = { ...values, cpf }

    if (!_.isEmpty(initialValues.additionalInfo)) {
      if (token && token.layerSevenToken) {
        dispatch(
          updateAdditionalInfo(withInitialValues, token.layerSevenToken, () => {
            if (token && token.layerSevenToken) {
              dispatch(
                updateRegistrationStep(
                  newValues.cpf,
                  RegistrationStep.AdditionalInfo,
                  token.layerSevenToken
                )
              )
              history.push(ROUTING_PATHS.SignUp_CompleteSuccess)
            }
          })
        )
        return
      }
    }
    if (hasAnyAddress && !hasAllAddresses) {
      showErrorModal()
    } else {
      if (token && token.layerSevenToken) {
        dispatch(
          saveAdditionalInfo(
            newValues,
            token.layerSevenToken,
            () => {
              if (token && token.layerSevenToken) {
                dispatch(
                  updateRegistrationStep(
                    newValues.cpf,
                    RegistrationStep.AdditionalInfo,
                    token.layerSevenToken
                  )
                )
                history.push(ROUTING_PATHS.SignUp_CompleteSuccess)
              }
            },
            () => {
              history.push(ROUTING_PATHS.SignUp_CompleteError)
            }
          )
        )
      }
    }
  }

  const hasAnyAddress =
    !_.isEmpty(values.zipCode) ||
    !_.isEmpty(values.homeAddress) ||
    !_.isEmpty(values.number) ||
    !_.isEmpty(values.district) ||
    !_.isEmpty(values.uf) ||
    !_.isEmpty(values.city)

  const hasAllAddresses =
    !_.isEmpty(values.zipCode) &&
    !_.isEmpty(values.homeAddress) &&
    !_.isEmpty(values.number) &&
    !_.isEmpty(values.district) &&
    !_.isEmpty(values.uf) &&
    !_.isEmpty(values.city)

  const colSpan1 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 2,
    sm: 5
  })

  const colSpan2 = Chakra.useBreakpointValue({
    base: 2,
    xl: 2,
    lg: 2,
    md: 2,
    sm: 5
  })

  const colSpan3 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 5
  })

  const colSpan4 = Chakra.useBreakpointValue({
    base: 2,
    xl: 2,
    lg: 2,
    md: 2,
    sm: 5
  })

  const colSpan5 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 3,
    sm: 5
  })

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Chakra.GridItem colSpan={colSpan1}>
        <Chakra.FormControl>
          <Chakra.FormLabel>CEP residencial</Chakra.FormLabel>
          <CustomTextField
            register={register('zipCode')}
            isInvalid={errors?.zipCode ? true : false}
            name="zipCode"
            onChange={inputChange}
            type="text"
            value={cepMask(values.zipCode)}
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan2}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Endereço residencial</Chakra.FormLabel>
          <CustomTextField
            register={register('homeAddress')}
            name="homeAddress"
            onChange={inputChange}
            type="text"
            value={values.homeAddress}
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan3}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Número</Chakra.FormLabel>
          <CustomTextField
            register={register('number')}
            name="number"
            onChange={inputChange}
            type="text"
            value={values.number}
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan1}>
        <Chakra.FormControl>
          <Chakra.FormLabel>UF</Chakra.FormLabel>
          <Select
            name="uf"
            disabled={false}
            onChange={selectChange}
            required={errors?.uf ? true : false}
            value={values.uf}
          >
            <>
              <option value="" disabled selected hidden>
                Selecione
              </option>
              {stateOption}
            </>
          </Select>
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan1}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Bairro</Chakra.FormLabel>
          <CustomTextField
            register={register('district')}
            isInvalid={errors?.district ? true : false}
            name="district"
            onChange={inputChange}
            value={values.district}
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan4}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Cidade</Chakra.FormLabel>
          <CustomTextField
            register={register('city')}
            name="city"
            onChange={inputChange}
            value={values.city}
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan3}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Complemento</Chakra.FormLabel>
          <CustomTextField
            register={register('complement')}
            isInvalid={errors?.complement ? true : false}
            name="complement"
            onChange={inputChange}
            type="text"
            value={values.complement}
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan1}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Tel Residencial</Chakra.FormLabel>
          <CustomTextField
            register={register('phoneNumber')}
            name="phoneNumber"
            onChange={inputChange}
            type="text"
            value={fixedPhoneMask(values.phoneNumber)}
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan4}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Time de futebol</Chakra.FormLabel>
          <CustomTextField
            register={register('soccerTeam')}
            isInvalid={errors?.soccerTeam ? true : false}
            name="soccerTeam"
            onChange={inputChange}
            type="soccerTeam"
            value={values.soccerTeam}
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan5}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Aprecia vinhos?</Chakra.FormLabel>
          <Select
            name="enjoyWine"
            disabled={false}
            onChange={selectChange}
            value={values.enjoyWine}
          >
            <>
              <option value="" disabled selected hidden>
                Selecione
              </option>
              <option value="Sim">Sim</option>
              <option value="Nao">Não</option>
            </>
          </Select>
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan4}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Estilo musical</Chakra.FormLabel>
          <CustomTextField
            register={register('musicalGenre')}
            isInvalid={errors?.musicalGenre ? true : false}
            name="musicalGenre"
            onChange={inputChange}
            type="musicalGenre"
            value={values.musicalGenre}
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={5}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Observações e Hobbies</Chakra.FormLabel>
          <S.TextareaField
            {...register('observationHobbies')}
            name="observationHobbies"
            focusBorderColor={THEME_PURPLE}
            value={values.observationHobbies}
            onChange={inputChange}
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={5}>
        <Chakra.Divider
          borderColor="secondary"
          marginTop="5vh"
          marginBottom="5vh"
        />
      </Chakra.GridItem>

      <Chakra.GridItem colStart={4}>
        <Link to={ROUTING_PATHS.SignUp_UploadDocuments}>
          <Button variant="outlined">Voltar</Button>
        </Link>
      </Chakra.GridItem>

      <Chakra.GridItem colStart={5}>
        <Button variant="normal" type="submit">
          Finalizar
        </Button>
      </Chakra.GridItem>
    </S.Form>
  )
}
export default AdditionalInfoForm
