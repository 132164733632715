import * as S from './styles'
import ProgressBar from './components/progress-bar'
import StepsList from './components/steps-list'
import Header from 'src/components/header'

interface Props {
  value?: number
}

const InfoHeader = ({ value = 10 }: Props) => {
  return (
    <>
      <Header />
      <S.Container>
        <ProgressBar value={value} />
        <StepsList />
      </S.Container>
    </>
  )
}

export default InfoHeader
