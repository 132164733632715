import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'
import {
  THEME_PURPLE,
  THEME_GREEN,
  THEME_GREY,
  THEME_MEDIUM_GREY,
  THEME_WHITE,
  THEME_LIGHT_WHITE,
  ACCESSIBILITY_SUCCESS,
  ACCESSIBILITY_ERROR
} from 'src/styles/colors'

const breakpoints = createBreakpoints({
  sm: '10em',
  md: '50em',
  lg: '75em',
  xl: '80em'
})

const theme = extendTheme({
  breakpoints,
  colors: {
    primary: THEME_PURPLE,
    secondary: THEME_GREEN
  },
  fonts: {
    body: 'SoletoRegular',
    heading: 'SoletoBold'
  },
  components: {
    Button: {
      baseStyle: {
        width: '100%',
        height: '46px',
        _focus: {
          outlineStyle: 'none',
          boxShadow: 'none'
        },
        _hover: { outline: 'none' }
      },
      sizes: {
        xl: {
          h: '46px',
          fontSize: 'lg',
          px: '32px'
        }
      },
      variants: {
        normal: {
          bg: THEME_PURPLE,
          color: THEME_WHITE,
          border: '1px solid ' + THEME_PURPLE,
          fontSize: '18px',
          _disabled: {
            bg: THEME_PURPLE,
            color: THEME_WHITE,
            border: '1px solid ' + THEME_PURPLE,
            fontSize: '18px'
          }
        },
        outlined: {
          bg: THEME_WHITE,
          color: THEME_PURPLE,
          border: '1px solid ' + THEME_PURPLE,
          fontSize: '18px',
          transition: '0.4s ease-in',
          _disabled: {
            bg: THEME_LIGHT_WHITE,
            color: THEME_PURPLE
          }
        },
        success: {
          bg: THEME_WHITE,
          color: ACCESSIBILITY_SUCCESS,
          border: '1px solid ' + ACCESSIBILITY_SUCCESS,
          fontSize: '18px',
          transition: '0.4s ease-in',
          _hover: { bg: ACCESSIBILITY_SUCCESS, color: THEME_WHITE }
        },
        error: {
          bg: THEME_WHITE,
          color: ACCESSIBILITY_ERROR,
          border: '1px solid ' + ACCESSIBILITY_ERROR,
          fontSize: '18px',
          transition: '0.4s ease-in',
          _hover: { bg: ACCESSIBILITY_ERROR, color: THEME_WHITE }
        }
      }
    },
    Link: {
      baseStyle: {
        color: THEME_PURPLE,
        fontSize: '14px',
        textDecoration: 'underline'
      }
    },
    FormLabel: {
      baseStyle: {
        color: THEME_GREY,
        fontFamily: 'SoletoBold',
        fontSize: '18px',
        margin: '10px 0 0 0'
      }
    },
    Input: {
      baseStyle: {
        bg: THEME_WHITE,
        border: '1px solid ' + THEME_GREY,
        h: '46px',
        _focus: {
          background: THEME_WHITE,
          border: '1px solid',
          borderColor: THEME_PURPLE
        },
        _hover: {
          background: THEME_WHITE,
          border: '1px solid',
          borderColor: THEME_PURPLE
        },
        _checked: {
          color: THEME_GREY
        },
        field: {
          _placeholder: {
            color: THEME_MEDIUM_GREY
          }
        }
      }
    },
    Checkbox: {
      baseStyle: {
        control: {
          bg: THEME_WHITE,
          border: '1px solid',
          borderColor: THEME_PURPLE,
          _focus: { outlineStyle: 'none', boxShadow: 'none' },
          _checked: {
            bg: THEME_PURPLE,
            border: '0',
            _hover: { bg: THEME_PURPLE, border: '0' }
          }
        }
      }
    },
    Radio: {
      baseStyle: {
        control: {
          bg: THEME_WHITE,
          border: '1px solid',
          borderColor: THEME_PURPLE,
          _focus: { outlineStyle: 'none', boxShadow: 'none' },
          _checked: {
            bg: THEME_PURPLE,
            border: '0',
            _hover: { bg: THEME_PURPLE, border: '0' }
          }
        }
      }
    },
    Select: {
      baseStyle: {
        icon: {
          width: '1rem',
          fontSize: '1rem'
        }
      }
    },
    Tabs: {
      variants: {
        line: {
          tab: {
            color: THEME_GREY,
            fontFamily: 'SoletoBold',
            fontSize: '14px',
            _selected: {
              color: THEME_PURPLE
            },
            _active: {
              outline: 'none'
            }
          }
        }
      }
    }
  }
})

export default theme
