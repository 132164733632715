import { CompetencyMapProps } from './interface'

const CompetencyMapIcon = ({ stroke }: CompetencyMapProps) => (
  <svg
    id="Mapa_competencias"
    data-name="Mapa competencias"
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g
      id="Group_6534"
      data-name="Group 6534"
      transform="translate(-842.645 -737.707)"
    >
      <g id="Group_6524" data-name="Group 6524">
        <path
          id="Path_3121"
          data-name="Path 3121"
          d="M21.489,4H24.4a2.915,2.915,0,0,1,2.915,2.915v20.4A2.915,2.915,0,0,1,24.4,30.234H6.915A2.915,2.915,0,0,1,4,27.319V6.915A2.915,2.915,0,0,1,6.915,4H9.83"
          transform="translate(847.645 743.022)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <rect
          id="Rectangle_3343"
          data-name="Rectangle 3343"
          width="11.037"
          height="6.622"
          rx="1"
          transform="translate(857.811 743.708)"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </g>
    <rect
      id="Rectangle_3548"
      data-name="Rectangle 3548"
      width="42"
      height="42"
      fill="none"
    />
  </svg>
)

export default CompetencyMapIcon
