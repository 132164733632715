import styled, { css } from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import {
  ACCESSIBILITY_ERROR,
  THEME_GREY,
  THEME_PURPLE
} from 'src/styles/colors'

const Text = css`
  margin-bottom: 12px;
  text-align: center;
`

export const ClinicSection = styled.div`
  width: 100%;
`

export const Link = styled.a`
  color: ${THEME_PURPLE};
  text-decoration: underline;
`

export const Form = styled.form`
  display: contents;
`

export const InputField = styled(Chakra.Input)`
  border: 1px solid ${THEME_GREY};
  height: 46px;
`

export const ErrorLabel = styled.p`
  color: ${(props) =>
    props.color === 'danger' ? ACCESSIBILITY_ERROR : THEME_GREY};
  font-weight: 600;
  font-size: 14px;
`

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TitleText = styled(Chakra.Text)`
  ${Text};
  color: ${THEME_PURPLE};
  font-family: SoletoBold;
  font-size: 36px;
`

export const DescriptionText = styled(Chakra.Text)`
  ${Text};
  color: ${THEME_GREY};
  font-size: 18px;
  margin-bottom: 52px;
`
