import * as Chakra from '@chakra-ui/react'
import { Logo } from '../../assets/img/index'
import * as S from './style'
import UserLoggedIn from './user-logged-in/index'
import { ApplicationState } from 'src/store/rootReducer'
import { useSelector } from 'react-redux'
import { HeaderProps } from './interface'
import Menu from './menu'

const Header = ({ border }: HeaderProps) => {
  const userState = useSelector(
    (state: ApplicationState) => state.userReducer.user
  )
  return (
    <S.RootContainer direction="row" border={border}>
      <Chakra.Flex w="100%" alignItems="center" justifyContent="space-between">
        {userState?.personalData?.cpf && <Menu />}
        <S.Logo>
          <Chakra.Image src={Logo} alt="Americas" />
        </S.Logo>

        {(userState?.personalData?.cpf ||
          userState?.personalData?.socialName ||
          userState?.personalData?.fullName) && (
          <S.UserBox>
            <UserLoggedIn />
          </S.UserBox>
        )}
      </Chakra.Flex>
    </S.RootContainer>
  )
}

export default Header
