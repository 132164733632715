import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import {
  ACCESSIBILITY_ERROR,
  THEME_GREY,
  THEME_PURPLE
} from 'src/styles/colors'

export const Heading = styled(Chakra.Heading)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
  font-size: 32px;
  color: ${THEME_PURPLE};
  text-align: left;
  @media (max-width: 900px) {
    font-size: 26px;
  }
`
export const Icon = styled.div`
  max-width: 18%;
`

export const Title = styled.div`
  max-width: 82%;
  padding: 0 0 0 10px;
`
export const TitleClinic = styled.div`
  max-width: 98%;
  padding: 60px 0 10px 20px;
  color: ${THEME_GREY};
  font-weight: bold;
  font-size: 20px;
`

export const NumberedSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0 5px 0;
`

export const NumberedSectionNumber = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 10px 0 0;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border: 1px solid ${THEME_GREY};
  border-radius: 50%;
  color: ${THEME_GREY};
`
export const NumberedSectionTitle = styled.div`
  width: 80%;
  align-items: left;
  font-weight: bold;
  color: ${THEME_GREY};
`
export const NumberedSectionContent = styled.div`
  width: 80%;
  align-items: left;
`

export const HospitalCard = styled(Chakra.Box)`
  width: 200px;
  height: 200px;
  border: 1px solid ${THEME_GREY};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  padding: 20px;
  background-color: white;
  margin: 20px;
  margin-bottom: 40px;
  border-radius: 5px;
  overflow: hidden;
`
export const ClinicCardSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media (max-width: 990px) {
    justify-content: space-around;
  }
`

export const ClinicCard = styled(Chakra.Box)`
  width: 200px;
  height: 200px;
  border: 1px solid ${THEME_GREY};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  padding: 20px;
  background-color: #fff;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 5px;
  overflow: hidden;
  @media (max-width: 990px) {
    margin: 5px;
  }
`

export const ClinicCardName = styled.div`
  color: ${THEME_PURPLE};
  font-weight: bold;
  display: flex;
  margin: 5px 0 10px 0;
  font-size: 14px;
  justify-content: space-between;
`

export const ClinicCardIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 8px;
  text-align: center;
`

export const ClinicCardAdress = styled.div`
  font-weight: bold;
  color: ${THEME_GREY};
  font-size: 12px;
  line-height: 25px;
  margin: 10px 0 5px 0;
`

export const InputField = styled(Chakra.Input)`
  background-color: #fff;
  border: 1px solid ${THEME_GREY};
  height: 46px;
`

export const TextareaField = styled(Chakra.Textarea)`
  background-color: #fff;
  border: 1px solid ${THEME_GREY};
  height: 120px;
`
export const ErrorLabel = styled.p`
  color: ${(props) =>
    props.color === 'danger' ? ACCESSIBILITY_ERROR : THEME_GREY};
  font-weight: 600;
  font-size: 14px;
`
