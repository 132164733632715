import React, { useEffect } from 'react'
import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import * as Chakra from '@chakra-ui/react'
import { rgMask } from 'src/utils/masks-validations/rg-mask'
import { fixedPhoneMask } from 'src/utils/masks-validations/fixed-phone-mask'
import {
  cnpjMask,
  validateCnpj
} from 'src/utils/masks-validations/cnpj-helpers'
import {
  countriesOption,
  maritalStatusOptions,
  stateOption,
  genderOptions
} from 'src/constants/options'
import Button from 'src/components/button'
import Select from 'src/components/input-select'
import { WarningTwoIcon } from '@chakra-ui/icons'
import InputDate from 'src/components/input-date'
import { useDispatch, useSelector } from 'react-redux'
import { clearError } from 'src/store/login/actions'
import {
  getPersonalInfo,
  savePersonalInfo
} from 'src/store/personal-information/actions'
import { ApplicationState } from 'src/store/rootReducer'
import * as S from './styles'
import { ADULT_AGE, ERROR, FORMS_MIN_YEAR } from 'src/constants'
import CnpjInput from 'src/components/input-cnpj'
import CustomTextField from 'src/components/text-field'
import { showErrorToast } from 'src/utils/toast'
import moment from 'moment'
import HeadingText from 'src/components/signup/heading-text'
import { THEME_PURPLE } from 'src/styles/colors'
import * as I from 'src/assets/icons'
import ROUTING_PATHS from 'src/routes/paths'
import _ from 'lodash'

interface IFormInput {
  rg: string
  rgExpeditor: string
  emissionDate: string
  placeOfBirth: string
  citizenship: string
  dateBirth: string
  gender: string
  cnpj: string
  maritalStatus: string
  partnerName: string
  clinicPhone: string
  secretaryName: string
  secretaryEmail: string
}

const PersonalInformationForm = () => {
  const dispatch = useDispatch()

  const userState = useSelector((state: ApplicationState) => state.userReducer)
  const personalInfoState = useSelector(
    (state: ApplicationState) => state.personalInfoReducer.personalData
  )

  const token = useSelector((state: any) => state.userReducer.auth)
  const cpf = userState.user?.personalData?.cpf
  const history = useHistory()
  const [values, setValues] = useState({
    rg: personalInfoState.rg || '',
    rgExpeditor: personalInfoState.rgExpeditor || '',
    emissionDate: '',
    placeOfBirth: personalInfoState.placeOfBirth || '',
    citizenship: personalInfoState.citizenship || '',
    dateBirth: '',
    gender: personalInfoState.gender || '',
    cnpj: personalInfoState.cnpj || '',
    maritalStatus: personalInfoState.maritalStatus || '',
    partnerName: personalInfoState.partnerName || '',
    clinicPhone: personalInfoState.clinicPhone || '',
    secretaryName: personalInfoState.secretaryName || '',
    secretaryEmail: personalInfoState.secretaryEmail || ''
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>()

  const inputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    setValues({
      ...values,
      [name]: value
    })
  }

  const selectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target

    setValues({
      ...values,
      [name]: value
    })
  }

  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  useEffect(() => {
    if (
      userState.user &&
      userState.user.personalData &&
      userState.user.personalData.cpf
    ) {
      if (token && token.layerSevenToken) {
        dispatch(
          getPersonalInfo(
            userState.user.personalData.cpf,
            token.layerSevenToken
          )
        )
      }
    }
  }, [dispatch, cpf])

  useEffect(() => {
    if (
      userState.user &&
      userState.user?.personalData &&
      userState.user?.personalData?.cpf
    ) {
      let emissionDate
      if (personalInfoState.emissionDate)
        emissionDate = moment(new Date(personalInfoState.emissionDate))

      const emissionDateFormatted = emissionDate
        ? emissionDate.utc().format('yyyy-MM-DD')
        : ''

      let dateBirth
      if (personalInfoState.dateBirth)
        dateBirth = moment(new Date(personalInfoState.dateBirth))

      const dateBirthFormatted = dateBirth
        ? dateBirth.utc().format('yyyy-MM-DD')
        : ''

      setValues({
        ...values,
        dateBirth: dateBirthFormatted,
        emissionDate: emissionDateFormatted
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userState.user])

  useEffect(() => {
    if (!_.isEmpty(userState.user)) {
      let emissionDate
      if (personalInfoState.emissionDate)
        emissionDate = moment(new Date(personalInfoState.emissionDate))

      const emissionDateFormatted = emissionDate
        ? emissionDate.utc().format('yyyy-MM-DD')
        : ''

      let dateBirth
      if (personalInfoState.dateBirth)
        dateBirth = moment(new Date(personalInfoState.dateBirth))

      const dateBirthFormatted = dateBirth
        ? dateBirth.utc().format('yyyy-MM-DD')
        : ''

      setValues({
        ...values,
        rg: personalInfoState.rg || '',
        rgExpeditor: personalInfoState.rgExpeditor || '',
        emissionDate: emissionDateFormatted || '',
        placeOfBirth: personalInfoState.placeOfBirth || '',
        citizenship: personalInfoState.citizenship || '',
        dateBirth: dateBirthFormatted || '',
        gender: personalInfoState.gender || '',
        cnpj: personalInfoState.cnpj || '',
        maritalStatus: personalInfoState.maritalStatus || '',
        partnerName: personalInfoState.partnerName || '',
        clinicPhone: personalInfoState.clinicPhone || '',
        secretaryName: personalInfoState.secretaryName || '',
        secretaryEmail: personalInfoState.secretaryEmail || ''
      })
    }
  }, [userState.user])

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const {
      rg,
      rgExpeditor,
      cnpj,
      partnerName,
      clinicPhone,
      secretaryName: assistantName,
      secretaryEmail: assistantEmail
    } = data

    const {
      citizenship,
      dateBirth,
      emissionDate,
      gender,
      maritalStatus,
      placeOfBirth
    } = values

    if (
      !userState.user ||
      !userState.user.personalData ||
      !userState.user.personalData.cpf
    ) {
      return
    } else {
      const birthDay = new Date(dateBirth)
      const today = new Date()
      const age = moment().diff(moment(birthDay), 'years')

      const documentEmissionDate = new Date(emissionDate)

      if (birthDay >= today) {
        showErrorToast(
          ERROR,
          'Data de nascimento não pode ser maior que a data atual.'
        )
        return
      } else if (age < ADULT_AGE) {
        showErrorToast(ERROR, 'Usuário precisa ser maior de idade.')
        return
      } else if (
        new Date(documentEmissionDate).getFullYear() < FORMS_MIN_YEAR
      ) {
        showErrorToast(ERROR, 'Data de emissão inválida.')
        return
      }

      const cpf = userState.user.personalData.cpf

      const onSuccessCallback = () => {
        history.push(ROUTING_PATHS.SignUp_ProfessionalInfo)
      }

      if (token && token.layerSevenToken) {
        dispatch(
          savePersonalInfo(
            {
              cpf,
              rg,
              rgExpeditor,
              emissionDate,
              placeOfBirth,
              citizenship,
              dateBirth,
              gender,
              maritalStatus,
              cnpj,
              partnerName,
              clinicPhone,
              assistantName,
              assistantEmail
            },
            token.layerSevenToken,
            onSuccessCallback
          )
        )
      }
      return
    }
  }

  const validateFields =
    !values.rg ||
    !values.rgExpeditor ||
    !values.emissionDate ||
    !values.placeOfBirth ||
    !values.citizenship ||
    !values.dateBirth

  const withoutPlaceBirth =
    !values.rg ||
    !values.rgExpeditor ||
    !values.emissionDate ||
    !values.citizenship ||
    !values.dateBirth

  const colSpan1 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 2,
    sm: 5
  })

  const colSpan2 = Chakra.useBreakpointValue({
    base: 5,
    xl: 5,
    lg: 5,
    md: 5,
    sm: 5
  })

  const colSpan3 = Chakra.useBreakpointValue({
    base: 2,
    xl: 2,
    lg: 2,
    md: 2,
    sm: 5
  })

  const colSpan4 = Chakra.useBreakpointValue({
    base: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 5
  })

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Chakra.GridItem colSpan={colSpan1}>
        <Chakra.FormControl>
          <Chakra.FormLabel>RG</Chakra.FormLabel>
          <CustomTextField
            register={register('rg')}
            isInvalid={errors?.rg ? true : false}
            name="rg"
            onChange={inputChange}
            type="text"
            value={values.rg}
            data-testid="rg"
            maxLength={30}
          />
          {errors?.rg ? (
            <S.ErrorLabel color="danger">
              <WarningTwoIcon color="danger" />
              *Campo obrigatório
            </S.ErrorLabel>
          ) : (
            <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
          )}
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan1}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Órgão Emissor</Chakra.FormLabel>
          <CustomTextField
            register={register('rgExpeditor')}
            isInvalid={errors?.rgExpeditor ? true : false}
            name="rgExpeditor"
            onChange={inputChange}
            type="text"
            value={values.rgExpeditor}
            data-testid="issuing"
            maxLength={8}
          />
          {errors?.rgExpeditor ? (
            <S.ErrorLabel color="danger">
              <WarningTwoIcon color="danger" />
              *Campo obrigatório
            </S.ErrorLabel>
          ) : (
            <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
          )}
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan4}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Data emissão</Chakra.FormLabel>
          <Chakra.InputGroup>
            <InputDate
              {...register('emissionDate')}
              name="emissionDate"
              onChange={inputChange}
              value={values.emissionDate}
            />
          </Chakra.InputGroup>
          {errors?.emissionDate ? (
            <S.ErrorLabel color="danger">
              <WarningTwoIcon color="danger" />
              *Campo obrigatório
            </S.ErrorLabel>
          ) : (
            <>
              {new Date(values.emissionDate).getFullYear() < FORMS_MIN_YEAR ? (
                <S.ErrorLabel color="danger">
                  <WarningTwoIcon color="danger" />
                  *Data menor que 1900
                </S.ErrorLabel>
              ) : (
                <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
              )}
            </>
          )}
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan1}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Nacionalidade</Chakra.FormLabel>
          <Select
            disabled={false}
            data-testid="citizenship"
            name="citizenship"
            onChange={selectChange}
            value={values.citizenship}
          >
            <>
              <option value="" disabled selected hidden>
                Selecione
              </option>
              {countriesOption}
            </>
          </Select>
          {errors?.citizenship ? (
            <S.ErrorLabel color="danger">
              <WarningTwoIcon color="danger" />
              *Campo obrigatório
            </S.ErrorLabel>
          ) : (
            <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
          )}
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan1}>
        <Chakra.FormControl id="placeOfBirth">
          <Chakra.FormLabel>Naturalidade</Chakra.FormLabel>
          {values.citizenship === 'Brasil' ? (
            <Select
              data-testid="placeOfBirth"
              disabled={false}
              name="placeOfBirth"
              onChange={selectChange}
              value={values.placeOfBirth}
            >
              <>
                <option value="" disabled selected hidden>
                  Selecione
                </option>
                {stateOption}
              </>
            </Select>
          ) : (
            <Select
              data-testid="placeOfBirth"
              disabled={values.citizenship !== 'Brasil'}
              name="placeOfBirth"
              onChange={selectChange}
              value=""
            >
              <option value="" disabled selected hidden>
                Selecione
              </option>
            </Select>
          )}
          {errors?.placeOfBirth ? (
            <S.ErrorLabel color="danger">
              <WarningTwoIcon color="danger" />
              *Campo obrigatório
            </S.ErrorLabel>
          ) : (
            <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
          )}
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan1}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Data de nascimento</Chakra.FormLabel>
          <Chakra.InputGroup>
            <InputDate
              {...register('dateBirth')}
              name="dateBirth"
              onChange={inputChange}
              value={values.dateBirth}
            />
          </Chakra.InputGroup>
          {errors?.dateBirth ? (
            <S.ErrorLabel color="danger">
              <WarningTwoIcon color="danger" />
              *Campo obrigatório
            </S.ErrorLabel>
          ) : (
            <S.ErrorLabel>*Campo obrigatório</S.ErrorLabel>
          )}
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan1}>
        <Chakra.FormControl id="gender">
          <Chakra.FormLabel>Sexo</Chakra.FormLabel>
          <Select
            disabled={false}
            data-testid="gender"
            name="gender"
            onChange={selectChange}
            value={values.gender}
          >
            <>
              <option value="" disabled selected hidden>
                Selecione
              </option>
              {genderOptions}
            </>
          </Select>
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan1}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Estado civil</Chakra.FormLabel>
          <Select
            disabled={false}
            data-testid="maritalStatus"
            name="maritalStatus"
            onChange={selectChange}
            value={values.maritalStatus}
          >
            <>
              <option value="" disabled selected hidden>
                Selecione
              </option>
              {maritalStatusOptions}
            </>
          </Select>
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan3}>
        <Chakra.FormControl id="spouse">
          <Chakra.FormLabel>Nome do Cônjuge</Chakra.FormLabel>
          <CustomTextField
            register={register('partnerName')}
            type="text"
            name="partnerName"
            onChange={inputChange}
            data-testid="spouse"
            value={values.partnerName}
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem
        colSpan={colSpan2}
        mt={'20px'}
        mb={'-10px'}
        display={'none'}
      >
        <HeadingText
          icon={<I.PersonalInfosIcon fill={THEME_PURPLE} />}
          title="Informações da Secretária"
          subtitle="Se preferir que sua secretária finalize o seu cadastro, insira os dados abaixo e depois clique em avançar."
        />
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan2} display={'none'}>
        <Chakra.FormControl>
          <Chakra.FormLabel>CNPJ</Chakra.FormLabel>
          <Chakra.InputGroup>
            <CnpjInput
              register={register('cnpj')}
              name="cnpj"
              onChange={inputChange}
              type="text"
              value={cnpjMask(values.cnpj)}
              isInvalid={
                !!values.cnpj && !validateCnpj(values.cnpj) ? true : false
              }
            />
          </Chakra.InputGroup>

          {values.cnpj && !validateCnpj(values.cnpj) ? (
            <S.ErrorLabel color="danger">
              <WarningTwoIcon color="danger" />
              *CNPJ Inválido
            </S.ErrorLabel>
          ) : (
            <S.ErrorLabel />
          )}
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan1} display={'none'}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Telefone da Clínica</Chakra.FormLabel>
          <CustomTextField
            register={register('clinicPhone')}
            type="text"
            name="clinicPhone"
            onChange={inputChange}
            value={fixedPhoneMask(values.clinicPhone)}
            data-testid="clinicPhone"
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan3} display={'none'}>
        <Chakra.FormControl>
          <Chakra.FormLabel>Nome do(a) secretário(a)</Chakra.FormLabel>
          <CustomTextField
            register={register('secretaryName')}
            type="text"
            name="secretaryName"
            onChange={inputChange}
            data-testid="secretaryName"
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={colSpan3} display={'none'}>
        <Chakra.FormControl>
          <Chakra.FormLabel>E-mail do(a) secretário(a)</Chakra.FormLabel>
          <CustomTextField
            register={register('secretaryEmail')}
            type="email"
            name="secretaryEmail"
            onChange={inputChange}
            data-testid="secretaryEmail"
          />
        </Chakra.FormControl>
      </Chakra.GridItem>

      <Chakra.GridItem colSpan={5}>
        <Chakra.Divider
          borderColor="secondary"
          marginTop="5vh"
          marginBottom="5vh"
        />
      </Chakra.GridItem>

      <Chakra.GridItem colStart={4}>
        <Link to={ROUTING_PATHS.SignUp_CreateAccount}>
          <Button variant="outlined">Voltar</Button>
        </Link>
      </Chakra.GridItem>

      <Chakra.GridItem colStart={5}>
        {values.citizenship === 'Brasil' ? (
          <Button disabled={validateFields} variant="normal" type="submit">
            Avançar
          </Button>
        ) : (
          <Button disabled={withoutPlaceBirth} variant="normal" type="submit">
            Avançar
          </Button>
        )}
      </Chakra.GridItem>
    </S.Form>
  )
}

export default PersonalInformationForm
