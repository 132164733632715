import styled from 'styled-components'
import { BsFileEarmarkPdf } from 'react-icons/bs'

export const Ul = styled.ul`
  margin: 20px 0;
`

export const Item = styled.li`
  align-items: center;
  display: flex;
  margin: 5px 0;
`
export const Download = styled.a.attrs({
  as: 'a',
  target: '_blank'
})`
  display: flex;
  align-items: center;
`

export const PdfIcon = styled(BsFileEarmarkPdf)`
  color: red;
`

export const ButtonTitle = styled.p`
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.7);
`
