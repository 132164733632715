import styled from 'styled-components'
import * as Chakra from '@chakra-ui/react'
import { THEME_GREY, THEME_PURPLE } from 'src/styles/colors'

export const TooltipBox = styled.div`
  display: none;
`
export const TooltipCard = styled(Chakra.Tooltip)`
  border: 1px solid ${THEME_GREY};
  border-radius: 4px;
  color: ${THEME_PURPLE};
  background-color: #fff;
  display: block;
  width: min-content;
  padding: 8px;
`
