import { IFileDetails } from 'src/store/documents-upload/types'

interface ExtendedFile extends File {
  code?: string
}

export interface IFileStatusProps {
  fileStatusKey: any
  file: ExtendedFile
  fileDetails: IFileDetails
  cpf: string
  acceptedExtensions: string
  isModalReject?: boolean
  isUploadSolicitation?: boolean
}

export enum FileStatusType {
  LOADING,
  SUCCESS,
  ERROR
}
