import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { MassRegistrationOrigin } from 'src/constants'
import { UserProfileEnum } from '../store/login/types'

export const capitalizeFirstLetter = (text: string) =>
  text.substring(0, 1).toUpperCase() +
  text.substring(1, text.length).toLowerCase()

export const getRoleFromEnum = (role: UserProfileEnum) => {
  switch (role) {
    case UserProfileEnum.ASSISTANT:
      return 'Assistente'
    case UserProfileEnum.DIRECTOR:
      return 'Diretor'
    case UserProfileEnum.RELATIONSHIP_MANAGER:
      return 'Equipe de Relacionamento'
    case UserProfileEnum.ADMINISTRATOR:
      return 'Administrador'
    case UserProfileEnum.DOCTOR:
    default:
      return 'Médico'
  }
}
export const getMassRegistrationOriginFromEnum = (
  registrationOriginOption: MassRegistrationOrigin
) => {
  switch (registrationOriginOption) {
    case MassRegistrationOrigin.EVENT:
      return 'Carga evento'
    case MassRegistrationOrigin.INDICATION_DIRECTOR:
      return 'Indicação do médico diretor'
    case MassRegistrationOrigin.RELATIONSHIP_TEAM:
      return 'Carga relacionamento médico'
  }
}

export const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
