import { InputDateProps } from './interface'
import * as S from './styles'
import * as Chakra from '@chakra-ui/react'
import { CalendarIcon } from '@chakra-ui/icons'
import { THEME_MEDIUM_GREY } from 'src/styles/colors'

const InputDate = ({ name, onChange, value }: InputDateProps) => {
  const isFirefox = () => {
    if (navigator.userAgent.indexOf('Firefox') != -1) return true
  }

  return (
    <Chakra.FormControl>
      <Chakra.InputGroup>
        <S.Input
          data-testid="input-date"
          name={name}
          onChange={onChange}
          type="date"
          value={value}
        />
        {isFirefox() ? (
          <Chakra.InputRightElement>
            <CalendarIcon color={THEME_MEDIUM_GREY} />
          </Chakra.InputRightElement>
        ) : (
          ''
        )}
      </Chakra.InputGroup>
    </Chakra.FormControl>
  )
}

export default InputDate
