import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import urls from 'src/constants/api'
import { store } from 'src/store'
import { IProfessionalData } from 'src/store/professional-information/types'

interface IApi {
  registrationApi: AxiosInstance
  getProfessionalInfo(cpf: string, token: string): Promise<AxiosResponse>
  saveProfessionalInfo(
    data: IProfessionalData,
    token: string
  ): Promise<AxiosResponse>
  updateProfessionalInfo(
    data: IProfessionalData,
    token: string
  ): Promise<AxiosResponse>
}

export default (): IApi => {
  const registrationApi = axios.create({
    baseURL: urls.registrationAPI
  })

  registrationApi.interceptors.request.use(
    async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      request.headers.Authorization = `Bearer ${
        store.getState().userReducer.auth.accessToken
      }`
      return request
    }
  )

  return {
    registrationApi: registrationApi,

    getProfessionalInfo: (
      cpf: string,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi.get(
        `/Registration/professional-information?cpf=${cpf}`,
        {
          headers
        }
      )
    },

    saveProfessionalInfo: (
      data: IProfessionalData,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi.post(`/Registration/professional-info`, data, {
        headers
      })
    },

    updateProfessionalInfo: (
      data: IProfessionalData,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`
      }
      return registrationApi.put(
        `/Registration/professional-information`,
        data,
        {
          headers
        }
      )
    }
  }
}
