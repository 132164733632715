import {
  ACCESSIBILITY_ERROR,
  ACCESSIBILITY_SUCCESS,
  THEME_GREY,
  THEME_LIGHT_GREY,
  THEME_MEDIUM_GREY,
  THEME_PURPLE,
  THEME_WHITE
} from 'src/styles/colors'
import * as Chakra from '@chakra-ui/react'
import styled from 'styled-components'
import { InfoOutlineIcon } from '@chakra-ui/icons'

const statusColors: any = {
  Rejeitado: ACCESSIBILITY_ERROR,
  Aprovado: ACCESSIBILITY_SUCCESS,
  Pendente: THEME_PURPLE
}

export const TableContainer = styled(Chakra.Box)`
  box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.3);
  padding: 20px 14px;
  background-color: ${THEME_WHITE};
  margin: 20px 0;
  border-radius: 5px;
  width: 100%;
`

export const Table = styled(Chakra.Table)`
  padding: 8px 4px;
  background-color: ${THEME_WHITE};
  border-radius: 5px;
  border: 1px solid #d1d3d4;
  margin-bottom: 24px;
`

export const THead = styled(Chakra.Thead)`
  color: ${THEME_PURPLE};
`

export const Th = styled(Chakra.Th)`
  padding: 8px 6px;
  color: ${THEME_PURPLE};
  font-weight: 600;
`

export const TBody = styled(Chakra.Tbody)`
  color: ${THEME_PURPLE};
`

export const Tr = styled(Chakra.Tr)`
  align-items: center;
`

export const Td = styled(Chakra.Td)`
  font-family: 'SoletoMedium';
  color: ${THEME_GREY};
  padding: 8px 6px;
`

export const FileName = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-width: 200px;
`

export const FileNameLink = styled(FileName)`
  cursor: pointer;
`

export const StatusBox = styled(Chakra.Flex)<{ status: string }>`
  border: 1.5px solid ${({ status }) => statusColors[status]};
  border-radius: 5px;
  align-items: center;
  padding: 4px;
  justify-content: space-between;

  color: ${({ status }) => statusColors[status]};
`

export const StatusText = styled(Chakra.Text)`
  font-family: 'SoletoMedium';
  font-size: 14px;
  margin-right: 4px;
  padding: 4px;
`

export const StatusIcon = styled(InfoOutlineIcon)<{ status: string }>`
  font-size: 18px;
  svg {
    stroke: ${({ status }) => statusColors[status]};
  }
`

export const DateContainer = styled(Chakra.Box)`
  border: 1px solid ${THEME_GREY};
  color: #d1d3d4;
  border-radius: 5px;
  padding: 8px;
`

export const Title = styled(Chakra.Heading)`
  font-size: 24px;
  border-bottom: 1px solid ${THEME_LIGHT_GREY};
  color: ${THEME_PURPLE};
  text-align: left;
`

export const ButtonsContainer = styled(Chakra.Flex)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`

export const IconText = styled(Chakra.Link)`
  font-family: 'SoletoLight';
  font-weight: 600;
  color: ${THEME_GREY};
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: ${THEME_PURPLE};
  }
`

export const Accordion = styled(Chakra.Accordion)`
  padding: 0;
`

export const DocumentsList = styled(Chakra.ListItem)`
  font-family: 'SoletoMedium';
  font-size: 14px;
  color: ${THEME_MEDIUM_GREY};
  padding: 4px 0;
`
export const TooltipBox = styled.div`
  display: none;
`
export const TooltipCard = styled(Chakra.Tooltip)`
  border: 1px solid ${THEME_GREY};
  border-radius: 4px;
  color: ${THEME_PURPLE};
  background-color: #fff;
  display: block;
  width: min-content;
  padding: 8px;
`
export const ModalSubtitle = styled(Chakra.Text)`
  font-size: 16px;
  font-weight: 600;
  color: ${THEME_PURPLE};
  padding: 12px 0;
`

export const ModalText = styled(Chakra.Text)`
  font-size: 14px;
  text-align: start;
  line-height: 12px;
`
