import styled from 'styled-components'

export const Ul = styled.ul`
  margin: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`
export const List = styled.li`
  width: 150px;
  padding: 10px 0;
  list-style: none;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
