import * as React from 'react'
import * as S from './styles'
import { IHeadingText } from './interface'
import * as Chakra from '@chakra-ui/react'

const HeadingText = ({
  fontSize = '14px',
  icon,
  subtitle,
  title,
  align = 'start'
}: IHeadingText) => {
  return (
    <>
      <Chakra.Grid templateColumns="repeat(1, 1fr)" gap={4}>
        <Chakra.GridItem>
          <S.Heading
            style={{
              justifyContent: align && align === 'center' ? align : 'flex-start'
            }}
          >
            {icon && <S.Icon>{icon}</S.Icon>}
            <S.Title>{title}</S.Title>
          </S.Heading>
        </Chakra.GridItem>
        <Chakra.GridItem>
          {subtitle && (
            <S.Subtitle
              fontSize={fontSize}
              style={{
                justifyContent:
                  align && align === 'center' ? align : 'flex-start'
              }}
            >
              {subtitle}
            </S.Subtitle>
          )}
        </Chakra.GridItem>
      </Chakra.Grid>
    </>
  )
}

export default HeadingText
