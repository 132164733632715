import { Store } from 'redux'
import rootReducer from './rootReducer'
import configureStore from './configure-store/configure-store'
import { persistStore } from 'redux-persist'

const store: Store = configureStore({
  reducer: rootReducer
})

const persistor = persistStore(store)

export { store, persistor }

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
