export const enum HospitalsListTypes {
  GET_HOSPITALS_LIST_SUCCESS = '@hospitals/GET_HOSPITALS_LIST_SUCCESS',
  GET_HOSPITALS_LIST_ERROR = '@hospitals/GET_HOSPITALS_LIST_ERROR',
  CLEAR_HOSPITALS_LIST = '@hospitals/GET_HOSPITALS_LIST_ERROR'
}

export interface IHospitalContact {
  name: string
  contactName: string
  contactEmail: string
}

export interface IHospitalListState {
  hospitals: any[]
  error?: any
}
