import { HospitalsListTypes } from './types'
import Service from 'src/services/hospitals-list'
import { handleLayerSevenRequestError } from '../layer-seven/actions'

const getHospitalsListSuccess = (data: any) => ({
  type: HospitalsListTypes.GET_HOSPITALS_LIST_SUCCESS,
  payload: data
})

const getHospitalsListError = (error: any) => ({
  type: HospitalsListTypes.GET_HOSPITALS_LIST_ERROR,
  payload: error
})

export const getHospitalsList = (token: string) => (dispatch: any) => {
  Service()
    .getHospitals(token)
    .then((response: any) => dispatch(getHospitalsListSuccess(response.data)))
    .catch((error: any) => {
      dispatch(getHospitalsListError(error))
      dispatch(
        handleLayerSevenRequestError(error, (newToken: string) =>
          dispatch(getHospitalsList(newToken))
        )
      )
    })
}

export const clearHospitalsList = () => ({
  type: HospitalsListTypes.CLEAR_HOSPITALS_LIST
})
