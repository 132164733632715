export enum DocumentUploadStateType {
  ADD_FILE_STATUS = '@documentUpload/ADD_FILE_STATUS',
  CLEAR_FILE_STATUS = '@documentUpload/CLEAR_FILE_STATUS',
  REMOVE_FILE_STATUS = '@documentUpload/REMOVE_FILE_STATUS',
  GET_FILE_UPLOADED = '@documentUpload/GET_FILE_UPLOADED',
  GET_FILE_UPLOADED_ERROR = '@documentUpload/GET_FILE_UPLOADED_ERROR',
  GET_COMPETENCY_MAP = '@documentUpload/GET_COMPETENCY_MAP',
  GET_COMPETENCY_MAP_ERROR = '@documentUpload/GET_COMPETENCY_MAP_ERROR',
  SAVE_UPLOADED_FILE_CODE = '@documentUpload/SAVE_UPLOADED_FILE_CODE',
  CLEAR_UPLOADED_FILE_CODES = '@documentUpload/CLEAR_UPLOADED_FILE_CODES',
  DELETE_UPLOADED_FILE_CODE_REQUEST = '@documentUpload/DELETE_UPLOADED_FILE_CODE_REQUEST',
  DELETE_UPLOADED_FILE_CODE_SUCCESS = '@documentUpload/DELETE_UPLOADED_FILE_CODE_SUCCESS',
  DELETE_UPLOADED_FILE_CODE_ERROR = '@documentUpload/DELETE_UPLOADED_FILE_CODE_ERROR',
  UPLOAD_INFORMATION_REQUEST = '@documentUpload/UPLOAD_INFORMATION_REQUEST',
  UPLOAD_INFORMATION_SUCCESS = '@documentUpload/UPLOAD_INFORMATION_SUCCESS',
  UPLOAD_INFORMATION_ERROR = '@documentUpload/UPLOAD_INFORMATION_ERROR',
  ADD_REQUIRED_CHECK = '@documentUpload/ADD_MANDATORY_CHECK',
  REMOVE_REQUIRED_CHECK = '@documentUpload/REMOVE_MANDATORY_CHECK',
  ADD_OPTIONAL_CHECK = '@documentUpload/ADD_ADDITIONAL_CHECK',
  REMOVE_OPTIONAL_CHECK = '@documentUpload/REMOVE_ADDITIONAL_CHECK',
  CLEAR_DOCUMENT_UPLOAD = '@documentUpload/CLEAR_DOCUMENT_UPLOAD',
  DELETE_SOLICITATION_FILE_SUCCESS = '@documentUpload/DELETE_SOLICITATION_FILE_SUCCESS',
  DELETE_SOLICITATION_FILE_ERROR = '@documentUpload/DELETE_SOLICITATION_FILE_ERROR'
}

export enum DocumentType {
  CompetencyMap = 0,
  PersonalDocument = 1
}

export enum RequiredDocumentsType {
  CRM_REGISTER,
  DEGREE,
  ETHICS_CERTIFICATE,
  SPECIALIST_TITLE,
  RESIDENCY_CERTIFICATE,
  VACCINATION_CARD,
  HOSPITAL_TERM,
  TEAM_LETTER,
  BADGE_PHOTO
}

export enum OptionalDocumentsType {
  TEAM_LETTER,
  MANDATORY_COURSE,
  PROOF_COMPETENCY
}

export interface IFileDetails {
  cpf: string
  file: File
  fileType: DocumentType
}

export interface IFileCodeUploaded {
  code?: string
  key: string
}

export interface IUploadInformationRequest {
  requiredChecklist: number[]
  optionalChecklist: number[]
  fileCodes: string[]
  curriculum: string
  linkedin: string
  cpf: string
}
export interface IDocumentsUploadState {
  loading?: boolean
  errorFileUpload?: any
  errorInformationUpload?: any
  data?: any
  files?: any
  fileStatusComponents: JSX.Element[]
  uploadedFileCodes: IFileCodeUploaded[]
  uploadInformationData: IUploadInformationRequest
  documentsSent?: boolean
}
