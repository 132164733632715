export const enum RegisteredInfoTypes {
  GET_REGISTERED_INFO_SUCCESS = '@registered-info/GET_REGISTERED_INFO',
  GET_REGISTERED_INFO_ERROR = '@registered-info/GET_REGISTERED_INFO_ERROR',
  UPDATE_PERSONAL_REGISTERED_INFO = '@registered-info/UPDATE_REGISTERED_INFO',
  UPDATE_PERSONAL_REGISTERED_INFO_ERROR = '@registered-info/UPDATE_REGISTERED_INFO_ERROR',
  UPDATE_REGISTERED_PROFESSIONAL_INFO = '@registered-info/UPDATE_REGISTERED_PROFESSIONAL_INFO',
  UPDATE_REGISTERED_PROFESSIONAL_INFO_ERROR = '@registered-info/UPDATE_REGISTERED_PROFESSIONAL_INFO_ERROR',
  UPDATE_REGISTERED_CLINIC_INFO = '@registered-info/UPDATE_REGISTERED_CLINIC_INFO',
  UPDATE_REGISTERED_CLINIC_INFO_ERROR = '@registered-info/UPDATE_REGISTERED_CLINIC_INFO_ERROR'
}
