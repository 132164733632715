import { useForm, SubmitHandler } from 'react-hook-form'
import * as Chakra from '@chakra-ui/react'
import { rgMask } from 'src/utils/masks-validations/rg-mask'
import {
  countriesOption,
  maritalStatusOptions,
  stateOption,
  genderOptions
} from 'src/constants/options'
import Button from 'src/components/button'
import Select from 'src/components/input-select'
import { WarningTwoIcon } from '@chakra-ui/icons'
import InputDate from 'src/components/input-date'
import * as S from './styles'
import CustomTextField from 'src/components/text-field'
import { cellphoneMask } from 'src/utils/masks-validations/cell-phone-mask'
import { ADULT_AGE, ERROR, FORMS_MIN_YEAR } from 'src/constants'
import { IRegisteredPersonalInformation } from './interface'
import { ApplicationState } from 'src/store/rootReducer'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  getRegisteredInfo,
  updateRegisteredPersonalInfo
} from 'src/store/registered-info/actions'
import { showErrorToast, showSuccessToast } from 'src/utils/toast'

const RegisteredPersonalInformation = ({
  inputChange,
  selectChange,
  values,
  cpf
}: IRegisteredPersonalInformation) => {
  const token = useSelector((state: ApplicationState) => state.userReducer.auth)
  const dispatch = useDispatch()
  const solicitation: any = useSelector(
    (state: ApplicationState) => state.solicitationDetailReducer.solicitation
  )

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit: SubmitHandler<IRegisteredPersonalInformation> = async () => {
    const {
      fullName,
      socialName,
      rg,
      rgExpeditor,
      emissionDate,
      placeOfBirth,
      citizenship,
      dateBirth,
      gender,
      maritalStatus,
      partnerName,
      email,
      celPhone
    } = values

    if (!solicitation.cpfHealthProfessional) {
      return
    } else {
      const birthDay = new Date(dateBirth)
      const today = new Date()
      const age = moment().diff(moment(birthDay), 'years')

      const documentEmissionDate = new Date(emissionDate)

      if (birthDay >= today) {
        showErrorToast(
          ERROR,
          'Data de nascimento não pode ser maior que a data atual.'
        )
        return
      } else if (age < ADULT_AGE) {
        showErrorToast(ERROR, 'Usuário precisa ser maior de idade.')
        return
      } else if (
        new Date(documentEmissionDate).getFullYear() < FORMS_MIN_YEAR
      ) {
        showErrorToast(ERROR, 'Data de emissão inválida.')
        return
      }

      const onSuccessCallback = () => {
        if (token && token.layerSevenToken) {
          dispatch(
            getRegisteredInfo(
              solicitation.cpfHealthProfessional,
              token.layerSevenToken
            )
          )
        }
        return showSuccessToast('', 'Dados atualizados com sucesso.')
      }

      if (token && token.layerSevenToken) {
        dispatch(
          updateRegisteredPersonalInfo(
            {
              cpf,
              fullName,
              socialName,
              rg,
              rgExpeditor,
              emissionDate,
              placeOfBirth,
              citizenship,
              dateBirth,
              gender,
              maritalStatus,
              partnerName,
              email,
              celPhone
            },
            token.layerSevenToken,
            onSuccessCallback
          )
        )
      }
    }
  }

  const colSpan1 = Chakra.useBreakpointValue({
    base: 3,
    xl: 3,
    lg: 3,
    md: 3,
    sm: 6
  })

  const colSpan2 = Chakra.useBreakpointValue({
    base: 2,
    xl: 2,
    lg: 2,
    md: 2,
    sm: 6
  })

  return (
    <S.Container>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Chakra.Grid templateColumns="repeat(6, 1fr)" gap={4}>
          <Chakra.GridItem colSpan={colSpan1}>
            <Chakra.FormControl>
              <S.InputLabel>Nome Completo</S.InputLabel>
              <CustomTextField
                register={register('fullName')}
                name="fullName"
                onChange={inputChange}
                type="text"
                value={values.fullName}
                maxLength={512}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={colSpan1}>
            <Chakra.FormControl>
              <S.InputLabel>Nome Social</S.InputLabel>
              <CustomTextField
                register={register('socialName')}
                name="socialName"
                onChange={inputChange}
                type="text"
                value={values.socialName}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>
          <Chakra.GridItem colSpan={colSpan2}>
            <Chakra.FormControl>
              <S.InputLabel>RG</S.InputLabel>
              <CustomTextField
                register={register('rg')}
                name="rg"
                onChange={inputChange}
                type="text"
                value={values.rg}
                data-testid="rg"
                maxLength={30}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={colSpan2}>
            <Chakra.FormControl>
              <S.InputLabel>Órgão Emissor</S.InputLabel>
              <CustomTextField
                register={register('rgExpeditor')}
                name="rgExpeditor"
                onChange={inputChange}
                type="text"
                value={values.rgExpeditor}
                data-testid="issuing"
                maxLength={8}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={colSpan2}>
            <Chakra.FormControl>
              <S.InputLabel>Data emissão</S.InputLabel>
              <Chakra.InputGroup>
                <InputDate
                  {...register('emissionDate')}
                  name="emissionDate"
                  onChange={inputChange}
                  value={values.emissionDate}
                />
              </Chakra.InputGroup>
              {errors?.emissionDate ? null : (
                <>
                  {new Date(values.emissionDate).getFullYear() <
                  FORMS_MIN_YEAR ? (
                    <S.ErrorLabel color="danger">
                      <WarningTwoIcon color="danger" />
                      *Data menor que 1900
                    </S.ErrorLabel>
                  ) : null}
                </>
              )}
            </Chakra.FormControl>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={colSpan2}>
            <Chakra.FormControl>
              <S.InputLabel>Nacionalidade</S.InputLabel>
              <Select
                disabled={false}
                data-testid="citizenship"
                name="citizenship"
                onChange={selectChange}
                value={values.citizenship}
              >
                <>
                  <option value="" disabled selected hidden>
                    Selecione
                  </option>
                  {countriesOption}
                </>
              </Select>
            </Chakra.FormControl>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={colSpan2}>
            <Chakra.FormControl id="placeOfBirth">
              <S.InputLabel>Naturalidade</S.InputLabel>
              {values.citizenship === 'Brasil' ? (
                <Select
                  data-testid="placeOfBirth"
                  disabled={false}
                  name="placeOfBirth"
                  onChange={selectChange}
                  value={values.placeOfBirth}
                >
                  <>
                    <option value="" disabled selected hidden>
                      Selecione
                    </option>
                    {stateOption}
                  </>
                </Select>
              ) : (
                <Select
                  data-testid="placeOfBirth"
                  disabled={values.citizenship !== 'Brasil'}
                  name="placeOfBirth"
                  onChange={selectChange}
                  value=""
                >
                  <option value="" disabled selected hidden>
                    Selecione
                  </option>
                </Select>
              )}
            </Chakra.FormControl>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={colSpan2}>
            <Chakra.FormControl>
              <S.InputLabel>Data de nascimento</S.InputLabel>
              <Chakra.InputGroup>
                <InputDate
                  {...register('dateBirth')}
                  name="dateBirth"
                  onChange={inputChange}
                  value={values.dateBirth}
                />
              </Chakra.InputGroup>
            </Chakra.FormControl>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={colSpan2}>
            <Chakra.FormControl id="gender">
              <S.InputLabel>Sexo</S.InputLabel>
              <Select
                disabled={false}
                data-testid="gender"
                name="gender"
                onChange={selectChange}
                value={values.gender}
              >
                <>
                  <option value="" disabled selected hidden>
                    Selecione
                  </option>
                  {genderOptions}
                </>
              </Select>
            </Chakra.FormControl>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={colSpan2}>
            <Chakra.FormControl>
              <S.InputLabel>Estado civil</S.InputLabel>
              <Select
                disabled={false}
                data-testid="maritalStatus"
                name="maritalStatus"
                onChange={selectChange}
                value={values.maritalStatus}
              >
                <>
                  <option value="" disabled selected hidden>
                    Selecione
                  </option>
                  {maritalStatusOptions}
                </>
              </Select>
            </Chakra.FormControl>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={colSpan2}>
            <Chakra.FormControl id="spouse">
              <S.InputLabel>Nome do Cônjuge</S.InputLabel>
              <CustomTextField
                register={register('partnerName')}
                type="text"
                name="partnerName"
                onChange={inputChange}
                data-testid="spouse"
                value={values.partnerName}
              />
            </Chakra.FormControl>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={colSpan1}>
            <Chakra.FormControl>
              <S.InputLabel>E-mail</S.InputLabel>
              <CustomTextField
                name="email"
                isInvalid={!!errors?.email}
                register={register('email')}
                onChange={inputChange}
                type="email"
                value={values.email}
              />
              {errors?.email ? (
                <S.ErrorLabel color="danger">
                  <WarningTwoIcon color="danger" />
                  *Campo obrigatório
                </S.ErrorLabel>
              ) : null}
            </Chakra.FormControl>
          </Chakra.GridItem>

          <Chakra.GridItem colSpan={colSpan1}>
            <Chakra.FormControl>
              <S.InputLabel>Celular</S.InputLabel>
              <CustomTextField
                register={register('celPhone', { required: true })}
                isInvalid={
                  values.celPhone.length > 1 && values.celPhone.length < 15
                }
                name="celPhone"
                onChange={inputChange}
                type="text"
                value={cellphoneMask(values.celPhone)}
              />
              {values.celPhone.length > 1 && values.celPhone.length < 15 ? (
                <S.ErrorLabel color="danger">
                  <WarningTwoIcon color="danger" />
                  *Telefone Inválido
                </S.ErrorLabel>
              ) : null}
            </Chakra.FormControl>
          </Chakra.GridItem>

          <Chakra.GridItem colStart={6} colSpan={1}>
            <Button variant="normal" type="submit">
              Salvar alterações
            </Button>
          </Chakra.GridItem>
        </Chakra.Grid>
      </S.Form>
    </S.Container>
  )
}

export default RegisteredPersonalInformation
