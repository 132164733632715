export const enum UserStates {
  USER_ERROR = '@login/USER_ERROR',
  SIGNIN_SUCCESS = '@login/SIGNIN_SUCCESS',
  SIGNUP_SUCCESS = '@login/SIGNUP_SUCCESS',
  SIGNUP_FAIL = '@login/SIGNUP_FAIL',
  SAVE_PERSONAL_INFO = '@login/SAVE_PERSONAL_INFO',
  SAVE_ACCOUNT_DATA = '@login/SAVE_ACCOUNT_DATA',
  SIGNOUT = '@login/SIGNOUT',
  CLEAR_ERROR = '@login/CLEAR_ERROR',
  GET_USER_LOGGED_IN_SUCCESS = '@login/GET_USER_LOGGED_IN_SUCCESS',
  RESET_PASSWORD_SUCCESS = '@login/RESET_PASSWORD_SUCCESS',
  GET_LAYER7_TOKEN_SUCCESS = '@login/GET_LAYER7_TOKEN_SUCCESS',
  GET_LAYER7_TOKEN_ERROR = '@login/GET_LAYER7_TOKEN_ERROR',
  INCREMENT_COUNTER_TOKEN_RETRIALS = '@login/INCREMENT_COUNTER_TOKEN_RETRIALS',
  SET_COUNTER_TOKEN_RETRIALS = '@login/SET_COUNTER_TOKEN_RETRIALS'
}

export interface IUser {
  personalData?: IPersonalData
}

export interface IPersonalData {
  id?: string
  cpf?: string
  email?: string
  fullName?: string
  socialName?: string
  crmKind?: string
  crmNumber?: string
  crmState?: string
  speciality?: string
  celPhone?: string
  allowReceiveInformations?: boolean
  acceptedTermsPrivacyUsePortal?: boolean
  rg?: string
  rgExpeditor?: string
  emissionDate?: string
  placeOfBirth?: string
  citizenship?: string
  dateBirth?: Date
  gender?: string
  maritalStatus?: string
  partnerName?: string
  clinicPhone?: string
  secretaryEmail?: string
  secretaryName?: string
  cnpj?: string
}

export enum UserProfileEnum {
  DOCTOR = 1,
  RELATIONSHIP_MANAGER = 2,
  DIRECTOR = 3,
  ASSISTANT = 4,
  ADMINISTRATOR = 5
}

export interface IAuthentication {
  accessToken?: string
  layerSevenToken?: string
  expiresIn?: number
  refreshToken?: string
  userProfile?: string
  isActive?: boolean
  tokenRetrialsCounter?: number
}

export interface ISignInParams {
  cpf: string
  password: string
}

export interface ISignUpParams {
  fullName: string
  socialName: string
  cpf: string
  crmKind: string
  crmNumber: string
  crmState: string
  speciality: string
  email: string
  celPhone: string
  password: string
  confirmPassword: string
  allowReceiveInformations: boolean
  acceptedTermsPrivacyUsePortal: boolean
}

export interface IUserClaim {
  type: string
  value: string
}

export interface IResetPasswordPayload {
  cpf: string
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

export interface IUserStateTypes {
  error?: any
  user?: IUser
  auth?: IAuthentication
}
