import { Props } from './interface'
import InfoHeader from 'src/components/signup/info-header'
import * as S from './styles'

const Container = ({ children, value }: Props) => (
  <>
    <InfoHeader value={value} />
    <S.Container>{children}</S.Container>
  </>
)

export default Container
