import { createStandaloneToast } from '@chakra-ui/react'

const toast = createStandaloneToast()

const TOAST_DEFAULTS = {
  duration: 9000,
  isClosable: true
}

export const showSuccessToast = (title: string, description: string) => {
  toast({
    title,
    description,
    status: 'success',
    duration: TOAST_DEFAULTS.duration,
    isClosable: TOAST_DEFAULTS.isClosable
  })
}

export const showErrorToast = (title: string, description: string) => {
  toast({
    title,
    description,
    status: 'error',
    duration: TOAST_DEFAULTS.duration,
    isClosable: TOAST_DEFAULTS.isClosable
  })
}
